type Cookie$Prop = {
  expires: number | string | Date;
};

class Cookies {
  public getCookie(name: string): string | undefined {
    const matches = document.cookie.match(
      // eslint-disable-next-line
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : void 0;
  }

  public setCookie(name: string, value: string | null, props: Partial<Cookie$Prop> = {}) {
    let exp = props.expires;
    if (typeof exp === 'number' && exp) {
      const d = new Date();
      d.setTime(d.getTime() + exp * 1000);
      exp = props.expires = d;
    }

    if (exp instanceof Date) {
      props.expires = exp.toUTCString();
    }

    value = value ? encodeURIComponent(value) : '';
    let updatedCookie = `${name}=${value}`;
    for (const propName in props) {
      if (Object.prototype.hasOwnProperty.call(props, propName)) {
        updatedCookie += '; ' + propName;
        const propValue = (props as any)[propName];
        if (propValue !== true) {
          updatedCookie += '=' + propValue;
        }
      }
    }
    document.cookie = updatedCookie;
  }

  public deleteCookie(name: string) {
    this.setCookie(name, null, { expires: -1 });
  }
}

export default new Cookies();
