import { refreshCookieName } from '@ravnur/auth/helpers/refresh-cookie-name';
import { removeAuthToken } from '@ravnur/auth/helpers/remove-auth-token';
import * as l10n from '@ravnur/l10n/service';
import { Module, Action, State, Mutation } from 'vuex-simple';

import pathes from '@/config/pathes';
import AuthRepository from '@/repositories/auth-repository';
import ThesaurusRepository, {
  LinearThesaurusRepository,
} from '@/repositories/thesaurus-repository';
import { ThesaurusModule } from '@/store/modules/generics/thesaurus';
import { toNodes } from '@/transformers/category';
import { AdvancedQuery, AdvancedSearch$Field } from '@/types/AdvancedSearch';
import { Auth$Response } from '@/types/Auth';
import { EmailDomain } from '@/types/EmailDomain';
import { Featured } from '@/types/Featured';
import { Folder } from '@/types/Folder';
import { Language } from '@/types/Language';
import { PredefinedMediaProperties } from '@/types/Media';

import { Metadata$Field } from '@/types/Metadata';

import { ApplicationModule } from './modules/application';
import { JobsModule } from './modules/jobs';
import { LiveModule } from './modules/live';
import { UploadModule } from './modules/upload';
import { UserModule } from './modules/user';
import $t from '@ravnur/l10n/$t';
import ModalService from '@ravnur/modal';
import enUS from '@/translations/en-US.json';

export class RooStore {
  @Module()
  public user = new UserModule();
  @Module()
  public application = new ApplicationModule();
  @Module()
  public upload = new UploadModule();
  @Module()
  public jobs = new JobsModule(this);
  @Module()
  public live = new LiveModule();

  @Module()
  public favoritesFolders = new ThesaurusModule(
    new ThesaurusRepository<Folder>('/my/favorites/folder')
  );

  @Module()
  public featuredVideos = new ThesaurusModule(
    new LinearThesaurusRepository<Featured>(pathes.FEATURED)
  );

  @Module()
  public whitelistedEmailDomains = new ThesaurusModule(
    new ThesaurusRepository<EmailDomain>(pathes.WHITELISTED_EMAIL_DOMAINS)
  );

  @Module()
  public languages = new ThesaurusModule(new LinearThesaurusRepository<Language>(pathes.LANGUAGES));

  @Module()
  public metadataFields = new ThesaurusModule(
    new LinearThesaurusRepository<Metadata$Field>(pathes.METADATA_FIELDS)
  );

  @Module()
  public advancedSearchFields = new ThesaurusModule(
    new LinearThesaurusRepository<AdvancedSearch$Field>(pathes.ADVANCED_SEARCH_FIELDS)
  );

  @Module()
  public advancedQueries = new ThesaurusModule(
    new LinearThesaurusRepository<AdvancedQuery>(pathes.ADVANCED_QUERIES)
  );

  @State()
  public predefinedMediaProperties: PredefinedMediaProperties = {};

  @State()
  public isLogouting = false;

  @Action()
  public handleAuthResponse(resp: Auth$Response) {
    const categories = resp.categories || [];
    this.user.changeCurrentUser(resp.user);
    this.user.changeUserPermissions(resp.userPermissions);
    this.application.changeDefaultMediaProperties(resp.predefinedMediaProperties);
    this.application.changeSites(resp.applications);
    this.application.changeCategories(toNodes(categories));
    this.application.changePortalSettings(resp.portalFeatures);
    this.application.changeLanguages(resp.supportedLocales);
    this.application.setPublicDomain(resp.publicDomain);
    this.application.setAnalyticsSettings(resp.portalConfiguration.analytics);
    this.application.setFooterSettings(resp.portalConfiguration.settings);
    this.upload.changeVideoFormats(resp.portalConfiguration);
    this.upload.changeAudioFormats(resp.portalConfiguration);

    l10n.init(generateLocaleInitInfo(resp), { [l10n.AVAILABLE_LOCALES.ENGLISH]: enUS });
    refreshCookieName(resp.cookieName);
  }

  @Action()
  public async logout() {
    if (this.upload.isProcessing) {
      const msg = $t('common', 'upload-interrupt');
      try {
        await ModalService.confirm(msg, {
          header: $t('common', 'uploading-in-progress'),
          confirmText: $t('common', 'upload-interrupt-confirm'),
          cancelText: $t('common', 'upload-interrupt-decline'),
        });
        this.upload.logout();
      } catch (e) {
        return;
      }
    }

    if (!this.user.isAuth) {
      location.reload();
      return;
    }
    this.startLogout();
    removeAuthToken();
    const repository = new AuthRepository();
    try {
      await repository.logout();
    } catch (e) {
      // noop
    } finally {
      this.addLogoutCode();
    }
  }

  @Mutation()
  public startLogout() {
    this.isLogouting = true;
  }

  @Mutation()
  public addLogoutCode() {
    this.isLogouting = true;
    const delim = location.href.indexOf('?') >= 0 ? '&' : '?';
    location.href = location.href + delim + 'successCode=success_logout';
    location.reload();
  }
}

function generateLocaleInitInfo(resp: Auth$Response): Translation$Locale {
  return {
    supportedLocales: resp.supportedLocales,
    translationHash: resp.translationHash,
    userLang: resp.user.languageId,
  };
}
