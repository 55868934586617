import { $Types } from '@ravnur/core/typings/tsx';
import { Prop } from '@ravnur/decorators';
import { Options, Vue } from 'vue-class-component';

import { ExternalAuthProvider } from '../../types/AuthProvider';

import { AuthService } from '../../types/AuthService';
import { Message } from '../../types/Message';
import WebLoginTabs from '../web-login-tabs/web-login-tabs';

import './auth-providers-list.scss';

type Props<T> = {
  service: AuthService<T>;
  providers: ExternalAuthProvider[];
  isRegistrationAvailable: boolean;
  isWebAuthAvailable: boolean;
  isAutoLoginAvailable: boolean;
};

type Emits<T> = {
  onAuth: (data: T) => void;
  onForgot: () => void;
  onMessage: (message: Message) => void;
};

const CN = 'auth-providers-list';

@Options({
  name: 'auth-providers-list',
  emits: ['auth', 'forgot', 'message', 'success'],
  watch: {
    isAutoAuthenticationNeeded: {
      immediate: true,
      handler(this: AuthProvidersList<unknown>) {
        this.handleProvidersChanged();
      },
    },
  },
})
export default class AuthProvidersList<T> extends Vue {
  declare $props: $Types<Props<T>, Emits<T>>;

  @Prop({}) service: AuthService<T>;
  @Prop({}) providers: ExternalAuthProvider[];
  @Prop({}) isRegistrationAvailable: boolean;
  @Prop({}) isWebAuthAvailable: boolean;
  @Prop({}) isAutoLoginAvailable: boolean;

  protected get isAutoAuthenticationNeeded() {
    return (
      this.isAutoLoginAvailable && !this.isWebLoginAvailable && this.externalProviders.length === 1
    );
  }

  private get isWebLoginAvailable(): boolean {
    return this.isWebAuthAvailable;
  }

  private get externalProviders() {
    return this.providers;
  }

  private get hasExternalProvider() {
    return !!this.externalProviders.length;
  }

  render() {
    if (this.isAutoAuthenticationNeeded) {
      return;
    }

    if (!this.hasExternalProvider) {
      return this.renderWebLoginForm();
    }

    return (
      <div class={CN}>
        <ul class={`${CN}__list`}>
          {this.externalProviders.map(this.renderExternalProvider)}
          {this.isWebLoginAvailable ? this.renderWebLoginProvider() : null}
        </ul>
      </div>
    );
  }

  protected handleProvidersChanged() {
    const { isAutoAuthenticationNeeded, externalProviders } = this;
    if (isAutoAuthenticationNeeded) {
      const [provider] = externalProviders;
      this.authViaExternalProvider(provider.loginEndpointPath);
    }
  }

  private renderWebLoginProvider() {
    return (
      <li key="web" class={`${CN}__provider`}>
        <l10n class={`${CN}__or`} group="login" tkey="webLoginDesc" />
        {this.renderWebLoginForm()}
      </li>
    );
  }

  private renderWebLoginForm() {
    return (
      <WebLoginTabs
        isRegistrationAvailable={this.isRegistrationAvailable}
        repository={this.service.repository}
        onAuth={this.handleAuthEvent}
        onForgot={this.moveToForgotPage}
        onMessage={this.handleMessage}
      />
    );
  }

  private renderExternalProvider(provider: ExternalAuthProvider) {
    return (
      <li key={provider.id} class={`${CN}__provider`}>
        {provider.description ? (
          <span class={`${CN}__provider-desc`} data-testid="description">
            {provider.description}
          </span>
        ) : null}

        <r-button
          class={`${CN}__provider-btn`}
          color="primary"
          data-testid="button"
          mode="secondary"
          onclick={() => this.authViaExternalProvider(provider.loginEndpointPath)}
        >
          <img
            alt={provider.displayName}
            class={[`${CN}__provider-icon`, 'icon', 'button__icon']}
            src={provider.thumbnail}
          />
          {provider.displayName}
        </r-button>
      </li>
    );
  }

  private handleMessage(message: Message) {
    this.$emit('message', message);
  }

  private handleAuthEvent(data: T) {
    this.$emit('auth', data);
  }

  private moveToForgotPage() {
    this.$emit('success');
  }

  private authViaExternalProvider(loginEndpointPath: string) {
    window.location.href = this.service.providerPathGenerator(loginEndpointPath);
  }
}
