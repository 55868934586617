import uidGenerator from '@/helpers/uid.generator';

import { AdvancedSearch$MatchRule } from '@/types/AdvancedSearch';

export default function createEmptyRule(): AdvancedSearch$MatchRule {
  return {
    type: null,
    value: null,
    secondValue: null,
    cond: null,
    _id: uidGenerator(),
    nested: null,
    openerPosition: 'bottom',
  };
}
