import { State, Getter, Mutation, Action } from 'vuex-simple';

import LiveEventsRepository from '@/repositories/live-events-repository';
import { LiveEvent } from '@/types/Live';
import { LiveEvent$Status } from '@ravnur/shared/types/Live';

const repository = new LiveEventsRepository();
const statuses = [LiveEvent$Status.SCHEDULED, LiveEvent$Status.ON_AIR, LiveEvent$Status.PREVIEW];

const EVENTS_PING_TIMEOUT = 10000;

export class LiveModule {
  @State()
  public events: LiveEvent[] = [];

  @Getter()
  public get all() {
    return this.events;
  }

  @Mutation()
  public updateEvents(newEvents: LiveEvent[]) {
    newEvents.forEach((event) => {
      const oldEvent = this.events.find((e) => e.id === event.id);

      if (oldEvent && oldEvent.status !== event.status) {
        this.events.splice(this.events.indexOf(oldEvent), 1, event);
      }

      if (!oldEvent?.id) {
        this.events.push(event);
      }
    });

    // remove completed events
    this.events.forEach((event) => {
      if (!newEvents.find((e) => e.id === event.id)) {
        this.events.splice(this.events.indexOf(event), 1);
      }
    });
  }

  @Action()
  async refresh() {
    try {
      const { items } = await repository.load({ statuses });
      this.updateEvents(items);
    } catch (e) {
      console.log(e);
    }
  }

  @Action()
  async pulling() {
    try {
      await this.refresh();
    } catch (e) {
      console.log(e);
    }

    setTimeout(this.pulling, EVENTS_PING_TIMEOUT);
  }
}
