/* eslint-disable @typescript-eslint/no-explicit-any */

export default function ary<T extends (...args: any[]) => any>(
  n: number,
  fn: T
): (...args: any[]) => any {
  return function arried(this: any, ...args: any[]) {
    return fn.apply(this, args.slice(0, n));
  };
}
