import { prop, Vue } from 'vue-class-component';

import head from '../../filters/head';
import { $Props } from '../../typings/tsx';

import './avatar.scss';

const CN = 'avatar';

type Avatar$User = Pick<User, 'displayName' | 'userpic'>;

class Props {
  user!: Avatar$User;
  title = prop({ default: '' });
  size = prop<Component$Size>({ default: 'sm' });
  noTitle?: boolean;
}

export type Avatar$Props = $Props<Props> & Partial<HTMLDivElement>;

export default class Avatar extends Vue.with(Props) {
  isError = false;

  get mode() {
    const { userpic } = this.user;

    return userpic && !this.isError ? 'userpick' : 'default';
  }

  render() {
    const { user } = this;
    const title = this.title || user.displayName;
    const { userpic } = user;

    const cn = {
      [CN]: true,
      [`${CN}--${this.size}`]: this.size,
      [`${CN}--${this.mode}`]: this.mode,
      [`${CN}--clickable`]: !!this.$attrs.onclick,
    };
    return (
      <span class={cn} title={this.noTitle ? '' : title} {...this.$attrs}>
        {userpic && !this.isError ? (
          <img alt={title} class={`${CN}__userpic`} src={userpic} onError={this.handleError} />
        ) : (
          _detectInitials(user.displayName)
        )}
        {this.$slots.default?.()}
      </span>
    );
  }

  private handleError() {
    this.isError = true;
  }
}

const from = 0;
const to = 2;

function _detectInitials(name: string): string {
  return name.split(/\s+/).map(head).slice(from, to).join('');
}
