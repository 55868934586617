import findIndex from '@ravnur/nanoutils/findIndex';
import { keyById } from '@ravnur/nanoutils/keyBy';
import { ref } from 'vue';

import { RULES } from './rules';
import toStorageFormat from './transformers/toStorageFormat';

export enum AVAILABLE_LOCALES {
  ENGLISH = 'en-US',
}

export const DEFAULT_LOCALE = AVAILABLE_LOCALES.ENGLISH;

export const DEFAULT_PLURAL_RULE_IDX: Plural$RuleIdx = 1;

const store = ref<Dictionary<Dictionary<Translation>>>({});
const def = ref<Dictionary<Dictionary<Translation>>>({});
const index = ref<Plural$RuleIdx>(DEFAULT_PLURAL_RULE_IDX);

export function getPluralRuleIndex(translation: Translation): Plural$RuleIdx {
  return translation.isDefault ? DEFAULT_PLURAL_RULE_IDX : index.value;
}

export function findFormIndex(idx: Plural$RuleIdx, count: number): number {
  const finder = (form: Plural$Form) => form.predicate(Math.abs(count));
  const formIndex = findIndex(finder, RULES[idx].forms);
  return typeof formIndex === 'number' ? formIndex : 0;
}

export function get(group: string, key: string): null | Translation {
  return store.value[group]?.[key] || def.value[group]?.[key] || null;
}

export function addTranslations(translations: Translation[]) {
  store.value = {
    ...store.value,
    ...toStorageFormat(translations),
  };
}

export function addDefaultTranslations(translations: Translation[]) {
  def.value = {
    ...def.value,
    ...toStorageFormat(translations),
  };
}

export function hasGroup(group: string): boolean {
  return !!store.value[group];
}

export function init(info: Translation$Locale, translations: Record<string, Translation[]>) {
  const localesAsCache = keyById(info.supportedLocales);
  const locale = localesAsCache[info.userLang]?.abbreviation || DEFAULT_LOCALE;
  index.value = localesAsCache[info.userLang]?.pluralRule || DEFAULT_PLURAL_RULE_IDX;

  addTranslations(translations[locale]);
}
