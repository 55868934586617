import { BaseRepository, PagerListResponse } from '@ravnur/http';

type ThesaurusQueryParams = {
  offset: number;
  count: number;
};

export type IThesaurusRepository<E extends Entity> = Omit<BaseRepository<E>, 'load'> & {
  fetchAll: (params: ThesaurusQueryParams) => Promise<LR<E>>;
};

type LR<E extends Entity> = PagerListResponse<E>;
export default class ThesaurusRepository<E extends Entity> extends BaseRepository<
  E,
  ThesaurusQueryParams,
  LR<E>
> {
  fetchAll(params: ThesaurusQueryParams): Promise<LR<E>> {
    return this.load(params);
  }
}

export class LinearThesaurusRepository<E extends Entity> extends BaseRepository<E> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public fetchAll(params: ThesaurusQueryParams): Promise<LR<E>> {
    return this.load().then((items) => ({ items, total: items.length }));
  }
}
