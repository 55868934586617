import { AuthError } from '@ravnur/auth/types/AuthError';
import { State, Mutation, Getter } from 'vuex-simple';

import { CategoryNode } from '@/types/Category';
import { StandardOption } from '@/types/Component';
import { PredefinedMediaProperties } from '@/types/Media';
import { PortalSettings } from '@/types/Settings';
import { Site } from '@/types/Site';
import { FooterSettings } from '@ravnur/shared/types/FooterSettings';
import { AnalyticsSettings } from '@ravnur/shared/types/AnalyticsSettings';

type PortalAuthError = {
  publicDomain?: string;
  defaultLanguageId?: string;
  portalFeatures?: PortalSettings;
} & AuthError;

export class ApplicationModule {
  @State()
  public categories: CategoryNode[] = [];

  @State()
  public sites: Site[] = [];

  @State()
  public settings: Nullable<PortalSettings> = null;

  @State()
  public predefinedMediaProperties: PredefinedMediaProperties = {};

  @State()
  public languages: Locale[] = [];

  @State()
  public publicDomain: Nullable<string> = null;

  @State()
  public myAccountDomain = '';

  @State()
  public logoPath = '';

  @State()
  public defaultLanguageId = '';

  @State()
  public footerSettings: Nullable<FooterSettings> = null;

  @State()
  public analyticsSettings: Nullable<AnalyticsSettings> = null;

  @Getter()
  public get hasCategories() {
    return !!this.categories.length;
  }

  @Getter()
  public get getApplicationCategories() {
    return this.categories;
  }

  @Getter()
  public get getApplicationSites() {
    return this.sites;
  }

  @Getter()
  public get getApplicationAsOptions() {
    return this.sites.map(_toOption);
  }

  @Getter()
  public get getCurrentSite() {
    if (process.env.NODE_ENV === 'development') {
      const appId = process.env.VUE_APP_APPLICATION_ID;
      return this.getApplicationSites.find((s) => s.id === appId) as Site;
    }

    const hostname = window.location.hostname;
    return this.sites.find((s) => s.portalUrl.includes(hostname)) as Site;
  }

  @Getter()
  public get getPredefinedMediaProperties() {
    return this.predefinedMediaProperties;
  }

  @Getter()
  public get isAudios() {
    return _isAvailable(this.settings, 'isAudios');
  }

  @Getter()
  public get isDisplayUserChannels() {
    return _isAvailable(this.settings, 'isDisplayUserChannels');
  }

  @Getter()
  public get isPhotos() {
    return _isAvailable(this.settings, 'isPhotos');
  }

  @Getter()
  public get isVideos() {
    return _isAvailable(this.settings, 'isVideos');
  }

  @Getter()
  public get isDocuments() {
    return _isAvailable(this.settings, 'isDocuments');
  }

  @Getter()
  public get isCollections() {
    return _isAvailable(this.settings, 'isCollections');
  }

  @Getter()
  public get isGroups() {
    return _isAvailable(this.settings, 'isGroups');
  }

  @Getter()
  public get isSmComments() {
    return _isAvailable(this.settings, 'isSmComments');
  }

  @Getter()
  public get isSmRating() {
    return _isAvailable(this.settings, 'isSmRating');
  }

  @Getter()
  public get isSmRelated() {
    return _isAvailable(this.settings, 'isSmRelated');
  }

  @Getter()
  public get isSmStatistics() {
    return _isAvailable(this.settings, 'isSmStatistics');
  }

  @Getter()
  public get isSubscriptions() {
    return _isAvailable(this.settings, 'isSubscriptions');
  }

  @Getter()
  public get isSignoutEnabled() {
    return _isAvailable(this.settings, 'isSignoutEnabled');
  }

  @Getter()
  public get isLiveStreaming() {
    return _isAvailable(this.settings, 'isLiveStreaming');
  }

  @Getter()
  public get isCategories() {
    return _isAvailable(this.settings, 'isCategories');
  }

  @Getter()
  public get isScreenCasterEnabled() {
    return _isAvailable(this.settings, 'isScreenCasterEnabled');
  }

  @Getter()
  public get isUploads() {
    return _isAvailable(this.settings, 'isUploads');
  }

  @Getter()
  public get isAdvancedSearch() {
    return _isAvailable(this.settings, 'isAdvancedSearch');
  }

  @Getter()
  public get isFaq() {
    return _isAvailable(this.settings, 'isFaq');
  }

  @Getter()
  public get isSupport() {
    return _isAvailable(this.settings, 'isSupport');
  }

  @Getter()
  public get isPrivacyPolicy() {
    return _isAvailable(this.settings, 'isPrivacyPolicy');
  }

  @Getter()
  public get isTermSandConditions() {
    return _isAvailable(this.settings, 'isTermSandConditions');
  }

  @Getter()
  public get isContactInfo() {
    return _isAvailable(this.settings, 'isContactInfo');
  }

  @Getter()
  public get isFeaturedVideos() {
    return _isAvailable(this.settings, 'isFeaturedVideos');
  }

  @Getter()
  public get isMediaLikes() {
    return _isAvailable(this.settings, 'isMediaLikes');
  }

  @Getter()
  public get isFeaturedCarousels() {
    return _isAvailable(this.settings, 'isFeaturedCarousels');
  }

  @Getter()
  public get isEmailDomainManagement() {
    return this.settings?.isEmailDomainManagement ?? false;
  }

  @Mutation()
  public changeCategories(categories: CategoryNode[]) {
    this.categories = categories;
  }

  @Mutation()
  public changeSites(sites: Site[]) {
    this.sites = sites.map((s) => {
      const portalUrl = s.portalUrl ? `${s.portalUrl}/` : '';
      const adminUrl = s.adminUrl ? `${s.adminUrl}/` : '';
      return { ...s, adminUrl, portalUrl };
    });
  }

  @Mutation()
  public changePortalSettings(settings: PortalSettings) {
    this.settings = settings;
  }

  @Mutation()
  public changeDefaultMediaProperties(props: PredefinedMediaProperties) {
    this.predefinedMediaProperties = props;
  }

  @Mutation()
  public changeLanguages(languages: Locale[]) {
    this.languages = languages;
  }

  @Mutation()
  setPublicDomain(domain: string) {
    this.publicDomain = domain;
  }

  @Mutation()
  public setPublicValues(error: PortalAuthError) {
    this.publicDomain = error.publicDomain + '/';
    this.logoPath = error.logoPath;
    this.defaultLanguageId = error.defaultLanguageId || '';
    this.settings = error?.portalFeatures || null;
  }

  @Mutation()
  public setFooterSettings(settings: FooterSettings) {
    this.footerSettings = settings;
  }

  @Mutation()
  public setAnalyticsSettings(settings: AnalyticsSettings) {
    this.analyticsSettings = settings;
  }
}

function _isAvailable(settings: Nullable<PortalSettings>, key: keyof PortalSettings): boolean {
  return settings ? settings[key] : false;
}

function _toOption({ id, name }: Site): StandardOption {
  return { id, label: name };
}
