import { Logger } from '@ravnur/logger';
import keyBy, { keyById } from '@ravnur/nanoutils/keyBy';
import { computed, ref } from 'vue';
import { Job$Response, Job$Status, Job$Type } from '@ravnur/shared/types/Job';

import ProcessingRepository from '../processing-repository';
import { ProcessingInfo, ProcessingInfo$Job } from '../ProcessingInfo';

const BLOCKING_JOBS: Job$Type[] = [Job$Type.AUDIO_TRANSCODING, Job$Type.VIDEO_TRANSCODING];
const HIGH_PRIORITY_TIMEOUT = 5000;
const LOW_PRIORITY_TIMEOUT = 30000;
const repository = new ProcessingRepository();
const keyByMediaId: (list: ProcessingInfo[]) => Dictionary<ProcessingInfo> = keyBy('mediaId');
const logger = new Logger('processing');

export const IGNORED_JOBS: Job$Status[] = [
  Job$Status.CANCELED,
  Job$Status.CANCELING,
  Job$Status.SUCCESS,
];

export const processingItems = ref<ProcessingInfo[]>([]);
const externalItems = ref<ProcessingInfo[]>([]);
let pingId: Nullable<number> = null;

const blockingList = computed<ProcessingInfo[]>(() =>
  allItems.value.filter(({ jobs }) => jobs.some(isBlockingJob))
);

export const allItems = computed<ProcessingInfo[]>(() => [
  ...externalItems.value,
  ...processingItems.value,
]);

const jobsOnly = computed<ProcessingInfo$Job[]>(() => {
  return allItems.value.reduce((memo, i) => [...memo, ...i.jobs], [] as ProcessingInfo$Job[]);
});

export const blockingByMediaId = computed(() => keyByMediaId(blockingList.value));
export const byMediaId = computed(() => keyByMediaId(allItems.value));
export const jobById = computed(() => keyById(jobsOnly.value));

export function subscribe() {
  refreshDelayed();
}

export function unsubscribe() {
  clearTimeoutIfNeeded();
}

function refreshDelayed() {
  clearTimeoutIfNeeded();
  const time = processingItems.value.length ? HIGH_PRIORITY_TIMEOUT : LOW_PRIORITY_TIMEOUT;
  pingId = window.setTimeout(refresh, time);
}

function clearTimeoutIfNeeded() {
  if (pingId) {
    clearTimeout(pingId);
  }
  pingId = null;
}

export async function refresh() {
  clearTimeoutIfNeeded();
  try {
    const response = await repository.load();
    processingItems.value = formatResponse(response);
  } catch (e) {
    logger.error(e);
  }
  refreshDelayed();
}

function isBlockingJob({ type }: ProcessingInfo$Job) {
  return BLOCKING_JOBS.some((j) => j === type);
}

function toJob(job: Job$Response): ProcessingInfo$Job {
  return {
    id: job.id,
    type: job.type,
    status: job.status,
    progress: job.progress,
  };
}

function formatResponse(jobs: Job$Response[]) {
  const formatted: ProcessingInfo[] = [];

  jobs.map((job) => {
    const mediaId = job.media.id;
    const mediaTitle = job.media.title;

    const existing = formatted.find((i: ProcessingInfo) => i.mediaId === mediaId);

    if (existing) {
      existing.jobs = [...existing.jobs, toJob(job)];
    } else {
      formatted.push({
        id: mediaId,
        mediaId,
        mediaTitle,
        jobs: [toJob(job)],
      });
    }
  });

  return formatted;
}
