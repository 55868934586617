import {
  Media,
  Media$Types,
  PlaylistEntity,
  SinglePlayableMedia,
  SingleMediaEntity,
  VideoEntity,
  DocumentEntity,
  SearchableMedia,
} from '@/types/Media';

export const REAL_MEDIA_TYPES: Media$Types[] = [
  Media$Types.VIDEO,
  Media$Types.AUDIO,
  Media$Types.DOCUMENT,
  Media$Types.AUDIO_PLAYLIST,
  Media$Types.VIDEO_PLAYLIST,
];

export const PLAYLIST_TYPES: Media$Types[] = [
  Media$Types.AUDIO_PLAYLIST,
  Media$Types.VIDEO_PLAYLIST,
];

export const SINGLE_TYPES: Media$Types[] = [
  Media$Types.AUDIO,
  Media$Types.VIDEO,
  Media$Types.DOCUMENT,
];

export const PLAYABLE_TYPES: Media$Types[] = [Media$Types.AUDIO, Media$Types.VIDEO];

export function isMediaEntity(e: Entity): e is Media {
  const type = (e as any).type;
  return type ? REAL_MEDIA_TYPES.some((t) => t === type) : false;
}

export function isPlaylist(m: Media): m is PlaylistEntity {
  return PLAYLIST_TYPES.some((t) => t === m.type);
}

export function isPlayable(m: Media): m is SinglePlayableMedia {
  return PLAYABLE_TYPES.some((t) => t === m.type);
}

export function isSingleMedia(m: Media): m is SingleMediaEntity {
  return SINGLE_TYPES.some((t) => t === m.type);
}

export function isVideo(m: Media): m is VideoEntity {
  return m.type === Media$Types.VIDEO;
}

export function isDocument(m: Media): m is DocumentEntity {
  return m.type === Media$Types.DOCUMENT;
}

export function isSearchableMedia(m: Media): m is SearchableMedia {
  return !!(m as any).captionsMatches?.length;
}
