import store from '../store';

import { MediaOption } from '@/config/media-types';
import { Media$Types } from '@/types/Media';

const TYPES: MediaOption[] = [
  {
    type: Media$Types.VIDEO,
    tkey: 'media_types__video',
    isSingle: true,
    getterKey: 'application/isVideos',
  },
  {
    type: Media$Types.AUDIO,
    tkey: 'media_types__audio',
    isSingle: true,
    getterKey: 'application/isAudios',
  },
  {
    type: Media$Types.DOCUMENT,
    tkey: 'media_types__document',
    isSingle: true,
    getterKey: 'application/isDocuments',
  },
  {
    type: Media$Types.PLAYLIST,
    tkey: 'media_types__playlist',
    isSingle: false,
  },
];

export default TYPES;

export function getAvailableTypes({ isSingle }: { isSingle?: boolean } = {}): MediaOption[] {
  const predicate =
    typeof isSingle === 'boolean' ? (o: MediaOption) => o.isSingle === isSingle : () => true;
  return TYPES.filter((t) => {
    const key = t.getterKey;
    const res = key ? store.getters[key] : true;
    return res && predicate(t);
  });
}
