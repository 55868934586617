<template>
  <NotFound />
</template>

<script>
import NotFound from '@ravnur/shared/components/not-found.vue';

export default {
   name: 'NotFoundComponent',
   components: {
     NotFound,
   }
};
</script>