import ellipsis from '@ravnur/core/filters/ellipsis';
import { computed, ref } from 'vue';

const useEllipsis = (value: string, size: number) => {
  const isCollapsed = ref(true);

  const prepared = computed(() => (isCollapsed.value ? ellipsis(value, size) : value));

  const isCollapsable = computed(() => value.length > size);

  const toggle = () => (isCollapsed.value = !isCollapsed.value);

  return {
    isCollapsable,
    value: prepared,
    toggle,
    isCollapsed,
  };
};

export default useEllipsis;
