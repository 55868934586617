import WithModel from '@ravnur/core/WithModel';
import $t from '@ravnur/l10n/$t';
import { ErrorObject } from '@vuelidate/core';
import { defineComponent, PropType, ref, computed } from 'vue';

import { Translations } from '../../translations';

import NakedToggler from './naked-toggler';

import './password-field.scss';

const CN = 'password-field';

const PasswordField = defineComponent({
  props: {
    value: { type: String, required: true },
    tkey: { type: String as PropType<keyof Translations>, default: null },
    label: { type: String, default: '' },
    placeholder: { type: String as PropType<keyof Translations>, default: null },
    errors: { type: Array as PropType<ErrorObject[]>, default: [] },
  },
  emits: {
    'update:value': (val: string) => {
      return typeof val === 'string';
    },
  },
  setup(props, ctx) {
    const fieldLabel = props.tkey ? $t('login', props.tkey) : props.label;
    const placeholderText = props.placeholder ? $t('login', props.placeholder) : '';

    const isNaked = ref(false);

    const password = computed({
      get: () => props.value,
      set: (password: string) => ctx.emit('update:value', password),
    });

    const type = computed(() => (isNaked.value ? 'text' : 'password'));

    const renderToggler = () => (
      <NakedToggler
        class={`${CN}__toggler`}
        password={password.value}
        vModel={[isNaked.value, 'value']}
      />
    );

    const slots = {
      actions: renderToggler,
    };

    return () => {
      return (
        <text-field
          class={CN}
          errors={props.errors}
          icon="lock"
          label={fieldLabel}
          maxLength={20}
          placeholder={placeholderText}
          type={type.value}
          v-slots={slots}
          vModel={[password.value, 'value']}
        />
      );
    };
  },
});

// FIXME: Use correct type
export default WithModel(PasswordField, 'value') as any;
