import { Vue, prop } from 'vue-class-component';

import { DefaultTagType } from '../../types/DefaultTagType';

import './tag.scss';

type Variant = 'success' | 'secondary' | 'warning' | 'default';

class Props {
  info: DefaultTagType;
  variant = prop({ type: String as () => Variant, default: () => 'default' });
  closable = prop({ type: Boolean, default: () => false });
  size = prop({ type: String, default: () => 'lg' });
  highlighted = prop({ type: Boolean, default: () => false });

  onClose: () => void;
}

const CN = 'tag';

export default class Tag extends Vue.with(Props) {
  private get label(): string {
    const { info } = this;
    if (typeof info === 'string') {
      return info;
    }
    return info.label;
  }

  private get canClose(): boolean {
    if (!this.closable) {
      return false;
    }

    if (typeof this.info === 'object') {
      return this.info.id !== '';
    }

    return true;
  }

  render() {
    const cn = {
      [CN]: true,
      [`${CN}--${this.variant}`]: true,
      [`${CN}--${this.size}`]: this.size,
      [`${CN}--closable`]: this.closable,
      [`${CN}--highlighted`]: this.highlighted,
    };
    return (
      <div class={cn} {...this.$attrs}>
        {this.renderIcon()}
        {this.label}
        {this.renderCloseButton()}
      </div>
    );
  }

  private renderIcon() {
    if (typeof this.info === 'object' && this.info.iconType) {
      return <icon class={`${CN}__icon`} type={this.info.iconType} />;
    }
  }

  private renderCloseButton() {
    if (this.canClose) {
      return (
        <r-button
          aria-label="Remove tag"
          class="tag__close"
          color="black"
          data-testid="remove-tag-button"
          icon="close"
          mode="frameless"
          onclick={this.handleClose}
          size="sm"
        />
      );
    }
  }

  private handleClose(e: Event) {
    e.stopImmediatePropagation();
    this.onClose();
  }
}
