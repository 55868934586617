import { $Props } from '@ravnur/core/typings/tsx';
import { Vue } from 'vue-class-component';

import './info.scss';
const CN = 'info';

class Props {
  message: string;
}

export type Info$Props = $Props<Props>;

export default class Info extends Vue.with(Props) {
  render() {
    return (
      <div class={CN}>
        <icon class={`${CN}__icon`} type="info" />
        {this.message}
      </div>
    );
  }
}
