import { defineComponent } from 'vue';
import './confirm.scss';

const CN = 'confirm';
const ICON_CN = `${CN}__icon`;

const MODAL_OPTIONS = {
  mode: 'fixed',
  showFooter: true,
  confirmText: 'Confirm',
};

export default defineComponent({
  props: {
    message: String,
    modalClasses: { type: String, default: '' },
    icon: { type: String, default: '' },
    confirmText: { type: String, default: 'Confirm' },
    cancelText: { type: String, default: 'Cancel' },
    showFooter: { type: Boolean, default: true },
    rejectedValue: { type: Function, default: () => false },
    resolvedValue: { type: Function, default: () => true },
    header: { type: String, default: '' },
  },
  setup(props) {
    const {
      message,
      icon: iconType,
      confirmText,
      cancelText,
      header,
      showFooter,
      modalClasses,
    } = props;

    const options = { ...MODAL_OPTIONS, confirmText, cancelText, header, showFooter, modalClasses };

    return () => (
      <modal ref="modal" class={CN} options={options}>
        {iconType ? <icon class={ICON_CN} type={iconType} /> : null}
        {message}
      </modal>
    );
  },
});
