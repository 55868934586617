import { Prop } from '@ravnur/decorators';
import { Vue } from 'vue-class-component';
import sanitize from '@ravnur/helpers/dom/sanitizer';

import './info-modal.scss';

const CN = 'info-modal';

export default class InfoModal extends Vue {
  @Prop({ type: String, default: '' }) value: string;
  @Prop({ type: String, default: '' }) header: string;

  get modalOptions() {
    return {
      mode: 'fixed',
      header: `${this.header}:`,
    };
  }

  render() {
    return (
      <modal ref="modal" class={CN} options={this.modalOptions}>
        <div v-html={sanitize(this.value)} />
      </modal>
    );
  }
}
