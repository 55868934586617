import { Watch } from '@ravnur/decorators';
import { Options, prop, Vue } from 'vue-class-component';

import ResizeObserverComponent from '../../components/resize-observer/resize-observer';
import { COMPONENT_CTX_PROVIDER_KEY } from '../../constants';
import { $Props } from '../../typings/tsx';

import SliderDot from './slider-dot';
import './slider.scss';

const CN = 'slider';

class Props<Value> {
  value = prop<Value>({ default: 0 as any });
  min = prop({ default: 0 });
  max = prop({ default: 100 });
  interval = prop({ default: 1 });
  disabled = prop({ default: false });
  dragOnClick = prop({ default: false });
  lazy = prop({ default: false });
  order = prop({ default: true });
}

type Emits<Value> = {
  onChange: (value: Value) => void;
  onChangeDot: (value: number) => void;
};

export type Slider$Props<Value = number> = $Props<Props<Value>, Emits<Value>, 'value'>;

@Options({
  emits: ['change', 'update:value', 'change-dot'],
  inject: {
    context: COMPONENT_CTX_PROVIDER_KEY,
  },
})
export default class Slider extends Vue.with(Props) {
  declare $el: HTMLDivElement;
  private readonly context!: Nullable<ComponentContext>;

  private scale = 1;
  private focusDotIndex = 0;
  private dotsPos: number[] = [];
  private isDragging = false;

  private get containerClasses() {
    return {
      [CN]: true,
      [`${CN}--ltr`]: true,
      [`${CN}--disabled`]: this.disabled,
    };
  }

  private get processes(): Array<[number, number]> {
    const { dotsPos } = this;
    if (dotsPos.length === 1) {
      return [[0, dotsPos[0]]];
    } else if (dotsPos.length > 1) {
      return [[Math.min(...dotsPos), Math.max(...dotsPos)]];
    }

    return [];
  }

  private get dragRange(): [number, number] {
    const prevDotPos = this.dotsPos[this.focusDotIndex - 1];
    const nextDotPos = this.dotsPos[this.focusDotIndex + 1];
    return [prevDotPos ? prevDotPos : -Infinity, nextDotPos ? nextDotPos : Infinity];
  }

  private get isDisabled(): boolean {
    const { context, disabled } = this;
    return disabled || context?.disabled || false;
  }

  private get dotsValue(): number[] {
    const { scale, dotsPos, base } = this;
    return dotsPos.map((pos) => (base * pos) / scale / base);
  }

  private get base() {
    const length = (`${this.interval}`.split('.')[1] || '').length;
    return Math.pow(10, length);
  }

  private get period(): number[] {
    const { value } = this;
    return value instanceof Array ? value : [value];
  }

  mounted() {
    this.bindEvent();
    this.calcScale();
  }

  beforeUnmount() {
    this.unbindEvent();
  }

  @Watch('value')
  @Watch('max')
  @Watch('min')
  protected calcScale() {
    this.scale = this.$el.offsetWidth / (this.max - this.min);
    this.handleValueChanged();
  }

  protected handleValueChanged() {
    this.dotsPos = this.period.map((val) => val * this.scale);
  }

  render() {
    return (
      <div
        class={this.containerClasses}
        onClick={this.clickHandle}
        onMousedown={this.dragStartOnProcess}
        onTouchstart={this.dragStartOnProcess}
      >
        <ResizeObserverComponent onWidthChanged={this.calcScale} />

        <div class={`${CN}__rail`}>
          {this.renderProcesses()}
          {this.renderDots()}
        </div>
      </div>
    );
  }

  private renderProcesses() {
    return this.processes.map(([left, right], index) => {
      const width = right - left;
      return (
        <div
          key={`process-${index}`}
          class={`${CN}__process`}
          style={{ left: `${left}px`, width: `${width}px` }}
        />
      );
    });
  }

  private renderDots() {
    return this.dotsPos.map((pos, idx) => (
      <SliderDot
        key={`dot-${idx}`}
        disabled={this.isDisabled}
        focus={idx === this.focusDotIndex}
        position={pos}
        onDragStart={() => this.dragStart(idx)}
      />
    ));
  }

  private bindEvent() {
    document.addEventListener('touchmove', this.dragMove, { passive: false });
    document.addEventListener('touchend', this.dragEnd, { passive: false });
    document.addEventListener('mousemove', this.dragMove);
    document.addEventListener('mouseup', this.dragEnd);
    document.addEventListener('mouseleave', this.dragEnd);
  }

  private unbindEvent() {
    document.removeEventListener('touchmove', this.dragMove);
    document.removeEventListener('touchend', this.dragEnd);
    document.removeEventListener('mousemove', this.dragMove);
    document.removeEventListener('mouseup', this.dragEnd);
    document.removeEventListener('mouseleave', this.dragEnd);
  }

  private syncValueByPos() {
    const { dotsValue: values } = this;
    if (isDiff(values, this.period)) {
      const arr = values.length === 1 ? values[0] : [...values];
      this.$emit('change', arr);
      this.$emit('update:value', arr);
    }
  }

  private dragStartOnProcess(e: MouseEvent | TouchEvent) {
    if (this.dragOnClick) {
      const pos = this.getPosByEvent(e);
      const index = this.getRecentDot(pos);
      this.dragStart(index);
      this.focusDotIndex = index;
      if (!this.lazy) {
        this.syncValueByPos();
      }
    }
  }

  private dragStart(index: number) {
    if (this.isDisabled) {
      return;
    }
    this.focusDotIndex = index;
    this.isDragging = true;
    this.$emit('drag-start');
  }

  private dragMove(e: MouseEvent | TouchEvent) {
    if (!this.isDragging) {
      return false;
    }
    e.preventDefault();
    const pos = this.getPosByEvent(e);
    this.isCrossDot(pos);
    this.setDotPosition(pos);
    if (!this.lazy) {
      this.syncValueByPos();
    }
  }

  private setDotPosition(pos: number, index: number = this.focusDotIndex) {
    this.dotsPos[index] = pos;
  }

  // If the component is sorted, then when the slider crosses, toggle the currently selected slider index
  private isCrossDot(pos: number) {
    const curIndex = this.focusDotIndex;
    let curPos = pos;
    if (curPos > this.dragRange[1]) {
      curPos = this.dragRange[1];
      this.focusDotIndex++;
    } else if (curPos < this.dragRange[0]) {
      curPos = this.dragRange[0];
      this.focusDotIndex--;
    }
    if (curIndex !== this.focusDotIndex) {
      this.setDotPosition(curPos, curIndex);
    }
  }

  private dragEnd(e: MouseEvent | TouchEvent) {
    if (!this.isDragging) {
      return false;
    }

    const pos = this.getPosByEvent(e);
    const index = this.getRecentDot(pos);

    setTimeout(() => {
      if (this.lazy) {
        this.syncValueByPos();
      }
      this.isDragging = false;
      this.$emit('change-dot', index);
      this.$emit('drag-end');
    });
  }

  private clickHandle(e: MouseEvent | TouchEvent) {
    if (this.isDisabled) {
      return false;
    }
    if (this.isDragging) {
      return;
    }
    const pos = this.getPosByEvent(e);
    const index = this.getRecentDot(pos);
    this.focusDotIndex = index;
    this.setDotPosition(pos, index);
    this.syncValueByPos();
    this.$emit('change-dot', index);
  }

  private getPosByEvent(e: MouseEvent | TouchEvent): number {
    const max = this.$el.offsetWidth;
    const pos = Math.min(max, getPos(e, this.$el).x);
    return Math.max(pos, 0);
  }

  private getRecentDot(pos: number): number {
    const arr = this.dotsPos.map((dotPos) => Math.abs(dotPos - pos));
    return arr.indexOf(Math.min(...arr));
  }
}

function isDiff(value1: number[], value2: number[]) {
  return value1.length !== value2.length || value1.some((val, index) => val !== value2[index]);
}

function getPos(e: MouseEvent | TouchEvent, elem: HTMLDivElement): { x: number; y: number } {
  const event = 'targetTouches' in e ? e.targetTouches[0] ?? e : e;
  const offset = getOffset(elem);

  return {
    x: event.pageX - offset.x,
    y: event.pageY - offset.y,
  };
}

function getOffset(elem: HTMLDivElement): { x: number; y: number } {
  const doc = document.documentElement as HTMLElement;
  const body = document.body as HTMLElement;
  const rect = elem.getBoundingClientRect();
  return {
    y: rect.top + (window.pageYOffset || doc.scrollTop) - (doc.clientTop || body.clientTop || 0),
    x:
      rect.left + (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || body.clientLeft || 0),
  };
}
