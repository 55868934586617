import { Options, Vue, prop } from 'vue-class-component';

import { FORM_CTX_PROVIDER_KEY } from '../../constants';

import { Translations } from '../../translations';
import { FormContext } from '../../types/FormContext';

const CN = 'auth-submit-btn';

class Props {
  label: keyof Translations;
  disabled = prop({ default: false });
}

@Options({
  name: 'auth-submit-btn',
  inject: {
    formContext: FORM_CTX_PROVIDER_KEY,
  },
})
export default class AuthSubmitBtn extends Vue.with(Props) {
  private formContext!: FormContext;

  private get processing() {
    return this.formContext.submitting;
  }

  public click() {
    if (this.disabled) {
      return;
    }
    this.formContext.submit();
  }

  render() {
    return (
      <r-button
        class={CN}
        color="primary"
        disabled={this.disabled}
        loading={this.processing}
        mode="standard"
        onclick={this.click}
        type="button"
      >
        <l10n group="login" tkey={this.label} />
      </r-button>
    );
  }
}
