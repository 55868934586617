import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx, _cache) {
  const _component_l10n = _resolveComponent("l10n")
  const _component_chosen = _resolveComponent("chosen")
  const _component_match_builder = _resolveComponent("match-builder")
  const _component_query_builder = _resolveComponent("query-builder", true)

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["query-builder", `query-builder--l${Math.min(5,_ctx.level)}`])
  }, [
    (_ctx.rules.length > 2)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_l10n, {
            class: "query-builder__match-label",
            group: "common",
            tkey: "query_builder__match"
          }),
          _createVNode(_component_chosen, {
            class: "query-builder__match",
            options: _ctx.matches,
            value: _ctx.match,
            onInput: _ctx.onChangeMatch,
            "key-for-label": "tkey",
            size: "sm"
          }, {
            option: _withCtx((props) => [
              _createVNode(_component_l10n, {
                group: "common",
                tkey: props.item.tkey
              }, null, 8, ["tkey"])
            ]),
            _: 1
          }, 8, ["options", "value", "onInput"]),
          _createVNode(_component_l10n, {
            class: "query-builder__match-label",
            group: "common",
            tkey: "query_builder__of_rules"
          })
        ], 64))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules, (d, idx) => {
      return (_openBlock(), _createElementBlock("div", { key: idx }, [
        (_openBlock(), _createBlock(_component_match_builder, {
          type: d.type,
          cond: d.cond,
          value: d.value,
          "second-value": d.secondValue,
          "hide-nested-btn": d.nested != null,
          key: d._id,
          "show-settings": (_ctx.rules.length === 1 && _ctx.level > 0) || d.type != null,
          "opener-position": d.openerPosition,
          onInput: $event => (_ctx.onChangeBuilder(idx, $event)),
          onRemove: $event => (_ctx.removeBuilder(idx)),
          onNested: $event => (_ctx.addNested(d))
        }, null, 8, ["type", "cond", "value", "second-value", "hide-nested-btn", "show-settings", "opener-position", "onInput", "onRemove", "onNested"])),
        (d.nested)
          ? (_openBlock(), _createBlock(_component_query_builder, {
              key: 0,
              match: d.nested.match,
              rules: d.nested.rules,
              level: _ctx.level + 1,
              onInput: $event => (_ctx.onChangeNested(idx, $event)),
              onRemove: $event => (_ctx.onChangeNested(idx, null))
            }, null, 8, ["match", "rules", "level", "onInput", "onRemove"]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ], 2))
}