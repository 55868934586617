import curry2, { CurriedFunction1 } from './curry2';

type Value = string | number;
type D<T> = { [idx in Value]: T[] };

interface GroupByFnc {
  <P extends string, V extends Value | null, T extends { [key in P]: V }>(
    prop: P
  ): CurriedFunction1<T[], D<T>>;
  <P extends string, V extends Value | null, T extends { [key in P]: V }>(prop: P, arr: T[]): D<T>;
}

const groupBy: GroupByFnc = curry2(_groupBy as any);
export default groupBy;

export const groupById = groupBy('id');

function _groupBy<P extends string, V extends string, T extends { [key in P]: V }>(
  prop: P,
  arr: T[]
): D<T> {
  return arr.reduce((memo, entity) => {
    const val = entity[prop];
    memo[val] = memo[val] || [];
    memo[val].push(entity);
    return memo;
  }, {} as D<T>);
}
