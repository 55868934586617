import { Inject } from '@ravnur/decorators';
import { Vue } from 'vue-class-component';

import { PortalSettings } from '../../types/Settings';
import { FooterSettings } from '@ravnur/shared/types/FooterSettings';
import ModalService from '@ravnur/modal';
import InfoModal from '@ravnur/shared/components/info-modal/info-modal';
import $t from '@ravnur/l10n/$t';

import './application-footer.scss';

type Link = {
  tkey: string;
  value: string;
  allowed: boolean;
  isText: boolean;
};

const CN = 'application-footer';

export default class ApplicationFooter extends Vue {
  @Inject('footer-settings') footerSettings?: FooterSettings;
  @Inject('portal-settings') portalSettings?: PortalSettings;

  get footerLinks(): Link[] {
    return [
      {
        tkey: 'links__condition',
        value: this.footerSettings?.['termsAndConditions'] || '',
        isText: false,
        allowed:
          !!this.portalSettings?.['isTermSandConditions'] &&
          !!this.footerSettings?.['termsAndConditions'],
      },
      {
        tkey: 'links__policy',
        isText: false,
        value: this.footerSettings?.['privacyPolicy'] || '',
        allowed:
          !!this.portalSettings?.['isPrivacyPolicy'] && !!this.footerSettings?.['privacyPolicy'],
      },
      {
        tkey: 'links__knowledge',
        isText: false,
        value: this.footerSettings?.['knowledgeBase'] || '',
        allowed: !!this.portalSettings?.['isFaq'] && !!this.footerSettings?.['knowledgeBase'],
      },
      {
        tkey: 'links__contact',
        isText: true,
        value: this.footerSettings?.['contactUs'] || '',
        allowed: !!this.portalSettings?.['isContactInfo'] && !!this.footerSettings?.['contactUs'],
      },
    ];
  }

  render() {
    const d = new Date();
    return (
      <footer class={CN}>
        <ul class={`${CN}__links`}>
          {this.footerLinks.map(this.renderLink)}
          <li class={`${CN}__link-wrapper`}>© {d.getFullYear()} Ravnur Inc.</li>
        </ul>
      </footer>
    );
  }

  private renderLink(link: Link) {
    const { value, tkey, allowed, isText } = link;

    if (!allowed) {
      return null;
    }

    return (
      <li class={`${CN}__link-wrapper`}>
        {isText ? (
          <r-button mode="frameless" onclick={() => this.openModal(value, $t('footer', tkey))}>
            <l10n group="footer" tkey={tkey} />
          </r-button>
        ) : (
          <a href={value} rel="noreferrer" target="_blank">
            <l10n group="footer" tkey={tkey} />
          </a>
        )}
      </li>
    );
  }

  private async openModal(text: string, header: string) {
    await ModalService.showModal(InfoModal, {
      header: header,
      value: text,
    }).catch(() => null);
  }
}
