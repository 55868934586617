import groupBy from '@ravnur/nanoutils/groupBy';

import { Category, CategoryNode } from '../types/Category';

const groupByParentId = groupBy('parentId');

interface CategoryGroupCache {
  [key: string]: Category[];
  null: Category[];
}

export function toNodes(all: Category[]): CategoryNode[] {
  const cache = groupByParentId(all) as CategoryGroupCache;
  const walker = _createConverter(cache);
  return (cache.null || []).map(walker);
}

export function toTree(root: Category, all: Category[]): CategoryNode {
  const cache = groupByParentId(all) as CategoryGroupCache;
  const walker = _createConverter(cache);
  return walker(root);
}

function _createConverter(cache: CategoryGroupCache) {
  return function _converter(category: Category): CategoryNode {
    const node: CategoryNode = { ...category };
    const children = cache[category.id];
    if (children) {
      node.children = children.map(_converter);
    }
    return node;
  };
}
