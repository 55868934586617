import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal__header" }
const _hoisted_2 = { class: "modal__body" }
const _hoisted_3 = {
  key: 0,
  class: "modal__footer"
}

export function render(_ctx, _cache) {
  const _component_body_scroll_locker = _resolveComponent("body-scroll-locker")
  const _component_r_button = _resolveComponent("r-button")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", [{ 'modal--fixed': _ctx.modalOptions.mode === 'fixed' }, _ctx.modalOptions.modalClasses]]),
    onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => (_ctx.onBackdropClick && _ctx.onBackdropClick(...args)), ["self","stop"]))
  }, [
    _createVNode(_component_body_scroll_locker),
    _createElementVNode("fieldset", {
      class: _normalizeClass(["modal__container", { 'modal__container--wf': _ctx.modalOptions.showFooter }]),
      tabindex: "-1"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "header", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.modalOptions.header), 1)
        ]),
        _createVNode(_component_r_button, {
          class: "modal__close-btn",
          icon: "close",
          mode: "frameless",
          onClick: _withModifiers(_ctx.handleCloseEvent, ["stop"]),
          color: "black",
          size: "sm"
        }, null, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.modalOptions.message), 1)
        ])
      ]),
      (_ctx.modalOptions.showFooter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_ctx.modalOptions.cancelText)
              ? (_openBlock(), _createBlock(_component_r_button, {
                  key: 0,
                  class: "modal__cancel-btn",
                  mode: "borderless",
                  color: "black",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.handleCloseEvent(), ["stop"]))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.modalOptions.cancelText), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_r_button, {
              class: "modal__confirm-btn",
              color: "accent",
              loading: _ctx.modalOptions.processing,
              disabled: _ctx.modalOptions.disabledConfirm,
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => _ctx.handleConfirmEvent(), ["stop"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modalOptions.confirmText), 1)
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}