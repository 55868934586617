import { defineComponent, provide, renderSlot, onMounted, onBeforeUnmount } from 'vue';

const EM = parseFloat(getComputedStyle(document.body).fontSize);

const TABLET = 40 * EM;
const LAPTOP = 64 * EM;
const DESKTOP = 100 * EM;

class Layout {
  width = 0;
  height = 0;

  get isPhoneResolution() {
    return this.width < TABLET;
  }

  get isTabletResolution() {
    return this.width >= TABLET && this.width < LAPTOP;
  }
  get isLaptopResolution() {
    return this.width >= LAPTOP && this.width < DESKTOP;
  }
  get isDesktopResolution() {
    return this.width >= DESKTOP;
  }
}

const layout = new Layout();

export default defineComponent({
  setup(props, { slots }) {
    onMounted(() => {
      handleLayoutChanged();
      window.addEventListener('resize', handleLayoutChanged);
    });
    onBeforeUnmount(() => window.removeEventListener('resize', handleLayoutChanged));
    provide('layout', layout);
    return () => renderSlot(slots, 'default');
  },
});

function handleLayoutChanged() {
  layout.width = window.innerWidth;
  layout.height = window.innerHeight;
}
