const MODAL_OPTIONS = {
  mode: 'fixed',
  showFooter: true,
  confirmText: 'Save',
};

export default {
  name: 'SaveQueryModal',
  props: {
    isNew: { type: Boolean, required: true },
  },
  data() {
    return {
      modalOptions: {
        ...MODAL_OPTIONS,
        canConfirmChecker: () => this.canConfirmChecker,
      },
      name: '',
      option: 'create',
    };
  },
  watch: {
    option() {
      this.name = '';
    },
  },
  computed: {
    isSelectedCreate() {
      return this.option === 'create';
    },
    resolvedValue() {
      const { name, option } = this;
      return { name, option };
    },
    rejectedValue() {
      return false;
    },
    canConfirmChecker() {
      return true;
    },
  },
  created() {
    this.option = this.isNew ? 'create' : 'update';
  },
};
