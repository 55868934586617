import keyBy from '@ravnur/nanoutils/keyBy';
import { PortalConfigurations } from '@ravnur/shared/types/Configurations';
import { State, Getter, Mutation, Action } from 'vuex-simple';

import { factory } from '@/helpers/resumable';
import safeGetter from '@/helpers/safe-getter';
import Resumable, { ResumableFile } from '@/libs/Resumable';
import ExtMediaRepository from '@/repositories/ext-media-repository';
import { IUploadStore, UploadInfo, UploadProgressEvent } from '@/types/Upload';
import { UploadStatus } from '@ravnur/shared/types/Upload';

const keyByMediaId: (list: UploadInfo[]) => Dictionary<UploadInfo> = keyBy('mediaId');
const keyByUid: (list: UploadInfo[]) => Dictionary<UploadInfo> = keyBy('uid');
const repository = new ExtMediaRepository();

export class UploadModule implements IUploadStore {
  @State()
  public list: UploadInfo[] = [];

  @State()
  public videoFormats = '';

  @State()
  public audioFormats = '';

  private resumable = factory(this);

  @Getter()
  public get uploadingItems() {
    return this.list;
  }

  @Getter()
  public get cacheByMediaId() {
    return keyByMediaId(this.list);
  }

  @Getter()
  public get cacheByUid() {
    return keyByUid(this.list);
  }

  @Getter()
  public get allowedVideoFormats() {
    return this.videoFormats.split(',').map((f: string) => f.replace('.', '')) || [];
  }

  @Getter()
  public get allowedAudioFormats() {
    return this.audioFormats.split(',').map((f: string) => f.replace('.', '')) || [];
  }

  @Getter()
  public get isProcessing() {
    return this.list.some((item) =>
      [UploadStatus.PROCESSING, UploadStatus.INITIALIZED].includes(item.status)
    );
  }

  @Getter()
  public get allAllowedMediaFormats() {
    let allFormats: string[] = [];

    allFormats = [...this.videoFormats.split(',').map((f: string) => f.replace('.', ''))];

    this.audioFormats
      .split(',')
      .map((f: string) => f.replace('.', ''))
      .forEach((f: string) => !allFormats.includes(f) && allFormats.push(f));

    return allFormats;
  }

  public canPreventUploading() {
    return this.resumable.support;
  }

  public canUploadMultifiles() {
    return this.resumable.support;
  }

  public getFileName(uid: string) {
    return getFileInfo(this.resumable, uid, 'fileName', '');
  }

  public getFileSize(uid: string) {
    return getFileInfo(this.resumable, uid, 'size', 0);
  }

  public add(
    file: File,
    payload: { mediaId: string; mediaTitle: string; keepSubResources: boolean }
  ) {
    this.resumable.addFile(file, payload);
  }

  public assignDrop(el: HTMLElement) {
    this.resumable.assignDrop(el);
  }

  public assignBrowse(el: HTMLElement) {
    this.resumable.assignBrowse(el, false);
  }

  public unassign(el: HTMLElement) {
    this.resumable.unAssignDrop(el);
  }

  public pause({ uid }: UploadInfo) {
    const file = this.resumable.getFromUniqueIdentifier(uid);
    if (file) {
      file.pause();
    }
  }

  public resume({ uid }: UploadInfo) {
    const file = this.resumable.getFromUniqueIdentifier(uid);
    if (file) {
      file.pause(false);
      file.upload();
    }
  }

  public retry({ uid }: UploadInfo) {
    const file = this.resumable.getFromUniqueIdentifier(uid);
    if (file) {
      file.retry();
    }
  }

  public addAllowedFormats(formats: string[]) {
    this.resumable.opts.fileType = formats;
  }

  @Action()
  public async cancel(info: UploadInfo) {
    const file = this.resumable.getFromUniqueIdentifier(info.uid);
    if (file) {
      file.cancel();
    }
    if (info.isRefreshExistingSource) {
      this.stop(info);
      repository.undoUploading(info.mediaId, { identifier: info.uid });
    } else {
      await repository.remove(info.mediaId);
      this.stop(info);
    }
  }

  @Mutation()
  public start(info: UploadInfo) {
    this.list.unshift(info);
  }

  @Mutation()
  public addMediaId({ uid, mediaId }: { uid: string; mediaId: string }) {
    const index = this.list.findIndex((info: UploadInfo) => info.uid === uid);

    if (index === -1) return;

    this.list[index].mediaId = mediaId;
  }

  @Mutation()
  public handleProgressEvent({ uid, progress, isPaused, status }: UploadProgressEvent) {
    const [info] = this.list.filter((i) => i.uid === uid);

    if (!info) return;

    info.progress = progress;
    info.status = status;
    info.isPaused = isPaused;
  }

  @Mutation()
  public stop({ uid }: UploadInfo) {
    this.list = this.list.filter((i) => i.uid !== uid);
  }

  @Mutation()
  public submit(info: UploadInfo) {
    info.isSubmitted = true;
  }

  @Mutation()
  public logout() {
    this.list = [];
  }

  @Mutation()
  public changeVideoFormats(configurations: PortalConfigurations) {
    this.videoFormats = configurations.media.video.videoSettings.allowedFormats;
  }

  @Mutation()
  public changeAudioFormats(configurations: PortalConfigurations) {
    this.audioFormats = configurations.media.audio.audioSettings.allowedFormats;
  }
}

function getFileInfo<K extends keyof ResumableFile>(
  resumable: Resumable,
  uid: string,
  prop: K,
  def: ResumableFile[K]
) {
  return safeGetter(resumable.getFromUniqueIdentifier(uid), prop, def);
}
