import { prop, Vue, Options } from 'vue-class-component';

import { COMPONENT_CTX_PROVIDER_KEY } from '../../constants';
import { $Props } from '../../typings/tsx';
import './radiobutton.scss';

const CN = 'radiobutton';

class Props<T> {
  value!: T;
  selected = prop<T>({ default: null });
  label = prop({ default: '' });
  disabled = prop({ default: false });
}

type Emits<T> = {
  onChange: (value: T) => void;
};

export type RadioButton$Props<T> = $Props<Props<T>, Emits<T>, 'selected'>;

@Options({
  name: 'radiobutton',
  inject: {
    context: COMPONENT_CTX_PROVIDER_KEY,
  },
  emits: ['change', 'update:selected'],
})
export default class RadioButton extends Vue.with(Props) {
  private readonly context!: Nullable<ComponentContext>;

  private labelId = Math.random().toString(36).substring(2);

  private get isSelected(): boolean {
    return this.value === this.selected;
  }

  private get isDisabled(): boolean {
    return this.disabled || (this.context?.disabled ?? false);
  }

  render() {
    const cn = {
      [CN]: true,
      [`${CN}--checked`]: this.isSelected,
      [`${CN}--disabled`]: this.isDisabled,
    };
    return (
      <span
        aria-checked={this.isSelected ? 'true' : 'false'}
        aria-disabled={this.isDisabled ? 'true' : 'false'}
        aria-labelledby={this.labelId}
        class={cn}
        role="radio"
        onClick={this.select}
      >
        <icon
          class={`${CN}__icon`}
          type={this.isSelected ? 'radiobutton-checked' : 'radiobutton-unchecked'}
        />
        <label class={`${CN}__label`} id={this.labelId}>
          {this.label || this.$slots.default?.()}
        </label>
      </span>
    );
  }

  private select() {
    if (this.isDisabled) {
      return;
    }
    this.$emit('change', this.value);
    this.$emit('update:selected', this.value);
  }
}
