import curry2, { CurriedFunction1 } from './curry2';

interface SomeFnc {
  <T>(iteratees: Predicate<T>): CurriedFunction1<T[], boolean>;
  <T>(iteratees: Predicate<T>, arr: T[]): boolean;
}

const some: SomeFnc = curry2(_some);
export default some;

function _some<T>(iteratees: Predicate<T>, arr: T[]): boolean {
  return arr.some(iteratees);
}
