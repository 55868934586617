import debounce from 'lodash/debounce';
import { createDecorator, VueDecorator } from 'vue-class-component';

export default function Debounce(duration: number): VueDecorator {
  return createDecorator(function _decorator(options, key) {
    const { methods } = options;
    const origin = methods[key];
    methods[key] = debounce(function _notDebounced<T>(...args: T[]) {
      // eslint-disable-next-line
      // @ts-ignore
      const self: unknown = this; // eslint-disable-line
      origin.apply(self, args);
    }, duration);
  });
}
