import { encode } from '@ravnur/core/transformers/base64';
import $t from '@ravnur/l10n/$t';
import ModalService from '@ravnur/modal';
import { Vue, Options } from 'vue-class-component';

import SaveQueryModal from '../save-query-modal/save-query-modal.vue';

import QueryBuilder from '@/components/common/query-builder/query-builder.vue';
import Logger from '@/logger';
import createEmptyRule from '@/transformers/advanced-search/createEmptyRule';
import toClientFormat from '@/transformers/advanced-search/toClientFormat';
import toServerFormat from '@/transformers/advanced-search/toServerFormat';
import {
  AdvancedSearch$Query,
  AdvancedSearch$MatchState,
  AdvancedQuery,
} from '@/types/AdvancedSearch';

type SaveQueryModalResp = Nullable<{
  name: string;
  option: string;
}>;

const logger = new Logger('AdvancedSearch');

// FIXME via BaseChosenComponent
@Options({
  components: { QueryBuilder },
  watch: {
    isOpen: 'onOpen',
  },
})
export default class AdvancedSearch extends Vue {
  private current: AdvancedSearch$Query = _createEmptyQuery();

  get state(): AdvancedSearch$MatchState {
    return this.current.state as AdvancedSearch$MatchState;
  }

  get isFilled(): boolean {
    return this.state.rules.length > 1;
  }

  get queries(): AdvancedQuery[] {
    return this.store.advancedQueries.list;
  }

  public async remove(query: AdvancedQuery) {
    const message = $t('common', 'advanced_search__remove_confirm', {
      queryName: query.name,
    });
    const res = await ModalService.confirm(message);
    if (res) {
      try {
        await this.store.advancedQueries.remove(query);
        if (query.id === this.current.id) {
          this.clear();
        }
      } catch (e) {
        logger.error(e);
      }
    }
  }

  public changeCurrentState(state: AdvancedSearch$MatchState) {
    const walker = _createWalker();
    walker(state);
    this.current.state = state;
  }

  public clear() {
    this.current = _createEmptyQuery();
  }

  public search() {
    const query = toServerFormat(this.state);
    const sq = encode(JSON.stringify(query));
    this.$router.push({ name: 'Search', query: { sq } });
    // FIXME
    // this.deactivate();
  }

  public async searchAndSave() {
    try {
      const resp: SaveQueryModalResp = await ModalService.showModal(SaveQueryModal, {
        isNew: !this.current.id,
      });

      if (!resp) {
        return;
      }
      const { name, option } = resp;

      const query: any = option === 'create' ? _createEmptyQuery() : this.current;
      query.state = toServerFormat(this.current.state as any);
      query.name = name || query.title;

      const { id } = await this.store.advancedQueries.save(query);
      const [stored] = this.queries.filter((q) => q.id === id);
      if (stored) {
        this.setCurrent(stored);
      }
      this.$nextTick(this.search);
    } catch (e) {
      logger.error(e);
    }
  }

  public onOpen() {
    // FIXME
    // if (this.isOpen) {
    //   this.store.advancedQueries.load();
    // }
  }

  public setCurrent(query: AdvancedQuery) {
    const state = toClientFormat(query.state);
    const walker = _createWalker();
    walker(state);
    this.current = { id: query.id as string, title: query.name, state };
  }

  public $t(
    group: string,
    key: string,
    params: Record<string, unknown> = {},
    pluralCount?: number
  ): string {
    return $t(group, key, params, pluralCount);
  }

  created() {
    this.clear();
    this.store.metadataFields.load();
  }
}

function _createWalker() {
  let openerWithBottomCount = 8;

  return function _walker(state: AdvancedSearch$MatchState) {
    state.rules.forEach((r) => {
      openerWithBottomCount = openerWithBottomCount && --openerWithBottomCount;
      r.openerPosition = openerWithBottomCount ? 'bottom' : 'top';

      const nested = r.nested as Nullable<AdvancedSearch$MatchState>;
      if (nested) {
        _walker(nested);
      }
    });
  };
}

function _createEmptyQuery(): AdvancedSearch$Query {
  return { id: null, title: '', state: _createState() };
}

function _createState(): AdvancedSearch$MatchState {
  return { match: 0, rules: [createEmptyRule()] };
}
