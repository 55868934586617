import { PortalSettings } from '@ravnur/shared/types/PortalSettings';
import useTypedStore from '@/helpers/hooks/useTypedStore';

const usePortalSettings = (): Nullable<PortalSettings> => {
  const store = useTypedStore();

  return store.application.settings;
};

export default usePortalSettings;
