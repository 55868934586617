function not(val: boolean): boolean;
function not<T>(val: Predicate<T>): Predicate<T>;
function not<T>(val: boolean | Predicate<T>): unknown {
  if (typeof val === 'boolean') {
    return !val;
  }
  return function _not(e: T): boolean {
    return !val(e);
  };
}
export default not;
