import { Prop } from '@ravnur/decorators';
import { Vue, Options } from 'vue-class-component';

import MatchBuilder from '../match-builder/match-builder';

import { MATCHES } from '@/config/advanced-search';
import createEmptyRule from '@/transformers/advanced-search/createEmptyRule';
import {
  AdvancedSearch$MatchState,
  AdvancedSearch$MatchKey,
  AdvancedSearch$MatchRule,
  AdvancedSearch$MatchOption,
} from '@/types/AdvancedSearch';

@Options({
  components: { MatchBuilder },
})
export default class QueryBuilder extends Vue {
  @Prop({ type: Number, default: 0 }) match: AdvancedSearch$MatchKey;
  @Prop({ type: Array, default: () => [createEmptyRule()] })
  rules: Array<AdvancedSearch$MatchRule>;
  @Prop({ type: Number, default: 0 }) level: number;

  get state(): AdvancedSearch$MatchState {
    return { match: this.match, rules: this.rules };
  }

  get matches(): Array<AdvancedSearch$MatchOption> {
    return MATCHES;
  }

  onChangeBuilder(idx: number, source: AdvancedSearch$MatchRule) {
    this.$logger.debug('onChangeBuilder. idx: ', idx, ' source: ', source);
    const data = this.rules[idx];
    const fields: Array<keyof AdvancedSearch$MatchRule> = ['type', 'value', 'secondValue', 'cond'];
    fields.forEach((key) => (data[key] = source[key]));

    if (this.rules.length === idx + 1) {
      this.rules.push(createEmptyRule());
    }
    this.$emit('input', this.state);
  }

  onChangeMatch(match: AdvancedSearch$MatchKey) {
    this.$emit('input', { ...this.state, match });
  }

  onChangeNested(idx: number, nested: AdvancedSearch$MatchState | null) {
    const data: AdvancedSearch$MatchRule = this.rules[idx];
    data.nested = nested;
    this.$emit('input', this.state);
  }

  removeBuilder(idx: number) {
    const count = 1;
    this.rules.splice(idx, count);
    if (!this.rules.length) {
      this.$emit('remove');
    }
  }

  addNested(rule: AdvancedSearch$MatchRule) {
    if (rule) {
      rule.nested = _createGroup();
      this.$emit('input', this.state);
    }
  }
}

function _createGroup(): AdvancedSearch$MatchState {
  return { match: 0, rules: [createEmptyRule()] };
}
