import { Vue } from 'vue-class-component';

import './placeholder.scss';

const CN = 'placeholder';

export default class Placeholder extends Vue {
  render() {
    return <div class={CN}>{this.$slots.default?.()}</div>;
  }
}
