import MockAdapter from 'axios-mock-adapter/types';
import { faker } from '@faker-js/faker';
import toBackendFormat from '../transformers/http/toBackendFormat';
import { Featured } from '@/types/Featured';
import { _generateMockList, _generateUser, _generateThumbnail } from './helper-fakers';

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/featured').reply(_load);
}

function _load() {
  const total = +faker.random.numeric(1);
  const data = toBackendFormat(_generateMockList<Featured>(total, _generateFeatured));
  return [200, data];
}

export function _generateFeatured() {
  return {
    id: `${faker.datatype.number()}`,
    title: faker.lorem.lines(2),
    createdDate: faker.date.past().toISOString(),
    owner: _generateUser(),
    thumbnail: _generateThumbnail(),
  };
}
