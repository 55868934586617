const ZERO_FORM: Plural$Form = {
  predicate: (num) => !num,
  label: 'is 0',
  description: '0',
};

export const RULES: Plural$Rule[] = [
  {
    families: 'Asian (Chinese, Japanese, Korean), Persian, Turkic/Altaic (Turkish), Thai, Lao',
    forms: [
      ZERO_FORM,
      {
        label: 'everything else',
        description:
          '1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, …',
        predicate: () => true,
      },
    ],
  },
  {
    families:
      'Germanic (Danish, Dutch, English, Faroese, Frisian, German, Norwegian, Swedish), Finno-Ugric (Estonian, Finnish, Hungarian), Language isolate (Basque), Latin/Greek (Greek), Semitic (Hebrew), Romanic (Italian, Portuguese, Spanish, Catalan), Vietnamese',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'everything else',
        description:
          '2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Romanic (French, Brazilian Portuguese)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'everything else',
        description:
          '2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Baltic (Latvian, Latgalian)',
    forms: [
      ZERO_FORM,
      {
        label: 'ends in 0, excluding 0',
        description: '10, 20, 30, 40, 50, 60, 70, …',
        predicate: (num) => !(num % 10),
      },
      {
        label: 'ends in 1, excluding 11',
        description:
          '1, 21, 31, 41, 51, 61, 71, 81, 91, 101, 121, 131, 141, 151, 161, 171, 181, 191, 201, 221, 231, 241, 251, 261, 271, 281, 291, …',
        predicate: (num) => {
          const rest = num % 100;
          return num % 10 === 1 && rest !== 11;
        },
      },
      {
        label: 'everything else',
        description:
          '2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 22, 23, 24, 25, 26, 27, 28, 29, 32, 33, 34, 35, 36, 37, 38, 39, 42, 43, 44, 45, 46, 47, 48, 49, 52, 53, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Celtic (Scottish Gaelic)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1 or 11',
        description: '1, 11',
        predicate: (num) => num === 1 || num === 11,
      },
      {
        label: 'is 2 or 12',
        description: '2, 12',
        predicate: (num) => num === 2 || num === 12,
      },
      {
        label: 'is 3-10 or 13-19',
        description: '3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19',
        predicate: (num) => num < 20,
      },
      {
        label: 'everything else',
        description:
          '20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Romanic (Romanian)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'is ends in 01-19, excluding 1',
        description:
          '2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, …',
        predicate: (num) => !(num % 10) && num % 100 < 20,
      },
      {
        label: 'everything else',
        description:
          '20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Baltic (Lithuanian)',
    forms: [
      ZERO_FORM,
      {
        label: 'ends in 1, excluding 11',
        description:
          '1, 21, 31, 41, 51, 61, 71, 81, 91, 101, 121, 131, 141, 151, 161, 171, 181, 191, 201, 221, 231, 241, 251, 261, 271, 281, 291, …',
        predicate: (num) => {
          const rest = num % 100;
          return num % 10 === 1 && rest !== 11;
        },
      },
      {
        label: 'ends in 0 or ends in 11-19, excluding 0',
        description:
          '10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, …',
        predicate: (num) => !(num % 10) || num % 100 < 20,
      },
      {
        label: 'everything else',
        description:
          '2, 3, 4, 5, 6, 7, 8, 9, 22, 23, 24, 25, 26, 27, 28, 29, 32, 33, 34, 35, 36, 37, 38, 39, 42, 43, 44, 45, 46, 47, 48, 49, 52, 53, 54, 55, 56, 57, 58, 59, 62, 63, 64, 65, 66, 67, 68, 69, 72, 73, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Slavic (Belarusian, Bosnian, Croatian, Serbian, Russian, Ukrainian)',
    forms: [
      ZERO_FORM,
      {
        label: 'ends in 1, excluding 11',
        description:
          '1, 21, 31, 41, 51, 61, 71, 81, 91, 101, 121, 131, 141, 151, 161, 171, 181, 191, 201, 221, 231, 241, 251, 261, 271, 281, 291, …',
        predicate: (num) => {
          const rest = num % 100;
          return num % 10 === 1 && rest !== 11;
        },
      },
      {
        label: 'ends in 2-4, excluding 12-14',
        description:
          '2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54, 62, 63, 64, 72, 73, 74, 82, 83, 84, 92, 93, 94, 102, 103, 104, 122, 123, 124, 132, 133, 134, 142, 143, 144, 152, 153, 154, 162, 163, 164, 172, 173, 174, 182, 183, …',
        predicate: (num) => {
          const rest100 = num % 100;
          const rest10 = num % 10;
          return rest10 > 1 && rest10 < 5 && rest100 !== 12 && rest100 !== 13 && rest100 !== 14;
        },
      },
      {
        label: 'everything else',
        description:
          '5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 26, 27, 28, 29, 30, 35, 36, 37, 38, 39, 40, 45, 46, 47, 48, 49, 50, 55, 56, 57, 58, 59, 60, 65, 66, 67, 68, 69, 70, 75, 76, 77, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Slavic (Slovak, Czech)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'is 2-4',
        description: '2, 3, 4',
        predicate: (num) => num === 2 || num === 3 || num === 4,
      },
      {
        label: 'everything else',
        description:
          '5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Slavic (Polish)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'ends in 2-4, excluding 12-14',
        description:
          '2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54, 62, 63, 64, 72, 73, 74, 82, 83, 84, 92, 93, 94, 102, 103, 104, 122, 123, 124, 132, 133, 134, 142, 143, 144, 152, 153, 154, 162, 163, 164, 172, 173, 174, 182, 183, …',
        predicate: (num) => {
          const rest100 = num % 100;
          const rest10 = num % 10;
          return rest10 > 1 && rest10 < 5 && rest100 !== 12 && rest100 !== 13 && rest100 !== 14;
        },
      },
      {
        label: 'everything else',
        description:
          '5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 25, 26, 27, 28, 29, 30, 31, 35, 36, 37, 38, 39, 40, 41, 45, 46, 47, 48, 49, 50, 51, 55, 56, 57, 58, 59, 60, 61, 65, 66, 67, 68, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Slavic (Slovenian, Sorbian)',
    forms: [
      ZERO_FORM,
      {
        label: 'ends in 01',
        description: '1, 101, 201, …',
        predicate: (num) => num % 100 === 1,
      },
      {
        label: 'ends in 02',
        description: '2, 102, 202, …',
        predicate: (num) => num % 100 === 2,
      },
      {
        label: 'ends in 03-04',
        description: '3, 4, 103, 104, 203, 204, …',
        predicate: (num) => {
          const rest = num % 100;
          return rest === 3 || rest === 4;
        },
      },
      {
        label: 'everything else',
        description:
          '5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Celtic (Irish Gaelic)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'is 2',
        description: '2',
        predicate: (num) => num === 2,
      },
      {
        label: 'is 3-6',
        description: '3, 4, 5, 6',
        predicate: (num) => num > 2 && num < 7,
      },
      {
        label: 'is 7-10',
        description: '7, 8, 9, 10',
        predicate: (num) => num > 6 && num < 11,
      },
      {
        label: 'everything else',
        description:
          ' 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Semitic (Arabic)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'is 2',
        description: '2',
        predicate: (num) => num === 2,
      },
      {
        label: 'ends in 03-10',
        description:
          '3, 4, 5, 6, 7, 8, 9, 10, 103, 104, 105, 106, 107, 108, 109, 110, 203, 204, 205, 206, 207, 208, 209, 210, …',
        predicate: (num) => {
          const rest = num % 100;
          return rest > 2 && rest < 11;
        },
      },
      {
        label: 'ends in 00-02, excluding 0-2',
        description: '100, 101, 102, 200, 201, 202, …',
        predicate: (num) => num % 100 < 3,
      },
      {
        label: 'everything else',
        description:
          '11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Semitic (Maltese)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'is ends in 01-10, excluding 1',
        description:
          '2, 3, 4, 5, 6, 7, 8, 9, 10, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, …',
        predicate: (num) => {
          const rest = num % 100;
          return !!rest && rest < 11;
        },
      },
      {
        label: 'is ends in 11-19',
        description:
          '11, 12, 13, 14, 15, 16, 17, 18, 19, 111, 112, 113, 114, 115, 116, 117, 118, 119, 211, 212, 213, 214, 215, 216, 217, 218, 219, …',
        predicate: (num) => {
          const rest = num % 100;
          return !!rest && rest < 20;
        },
      },
      {
        label: 'everything else',
        description:
          '20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'unused',
    forms: [
      ZERO_FORM,
      {
        label: 'ends in 1',
        description:
          '1, 11, 21, 31, 41, 51, 61, 71, 81, 91, 101, 111, 121, 131, 141, 151, 161, 171, 181, 191, 201, 211, 221, 231, 241, 251, 261, 271, 281, 291, …',
        predicate: (num) => num % 10 === 1,
      },
      {
        label: 'ends in 2',
        description:
          '2, 12, 22, 32, 42, 52, 62, 72, 82, 92, 102, 112, 122, 132, 142, 152, 162, 172, 182, 192, 202, 212, 222, 232, 242, 252, 262, 272, 282, 292, …',
        predicate: (num) => num % 10 === 2,
      },
      {
        label: 'everything else',
        description:
          '3, 4, 5, 6, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 20, 23, 24, 25, 26, 27, 28, 29, 30, 33, 34, 35, 36, 37, 38, 39, 40, 43, 44, 45, 46, 47, 48, 49, 50, 53, 54, 55, 56, 57, 58, 59, 60, 63, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Icelandic, Macedonian',
    forms: [
      ZERO_FORM,
      {
        label: 'ends in 1, excluding 11',
        description:
          '1, 21, 31, 41, 51, 61, 71, 81, 91, 101, 111, 121, 131, 141, 151, 161, 171, 181, 191, 201, 211, 221, 231, 241, 251, 261, 271, 281, 291, …',
        predicate: (num) => {
          const rest = num % 100;
          return num % 10 === 1 && rest !== 11;
        },
      },
      {
        label: 'everything else',
        description:
          '2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 34, 35, 36, 37, 38, 39, 40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 52, 53, 54, …',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Celtic (Breton)',
    forms: [
      ZERO_FORM,
      {
        label: 'is 1',
        description: '1',
        predicate: (num) => num === 1,
      },
      {
        label: 'ends in 1, excluding 1, 11, 71, 91',
        description:
          '21, 31, 41, 51, 61, 81, 101, 121, 131, 141, 151, 161, 181, 201, 221, 231, 241, 251, 261, 281, ...',
        predicate: (num) => {
          const rest = num % 100;
          return num % 10 === 1 && rest !== 1 && rest !== 11 && rest !== 71 && rest !== 91;
        },
      },
      {
        label: 'ends in 2, excluding 12, 72, 92',
        description:
          '2, 22, 32, 42, 52, 62, 82, 102, 122, 132, 142, 152, 162, 182, 202, 222, 232, 242, 252, 262, 282, ...',
        predicate: (num) => {
          const rest = num % 100;
          return num % 10 === 2 && rest !== 12 && rest !== 72 && rest !== 92;
        },
      },
      {
        label: 'ends in 3, 4 or 9 excluding 13, 14, 19, 73, 74, 79, 93, 94, 99',
        description: '3, 4, 9, 23, 24, 29, 33, 34, 39, 43, 44, 49, 53, 54, 59, ...',
        predicate: (num) => {
          const rest100 = num % 100;
          const rest10 = num % 10;
          return (
            (rest10 === 3 || rest10 === 4 || rest10 === 9) &&
            rest100 !== 13 &&
            rest100 !== 14 &&
            rest100 !== 19 &&
            rest100 !== 73 &&
            rest100 !== 74 &&
            rest100 !== 79 &&
            rest100 !== 93 &&
            rest100 !== 94 &&
            rest100 !== 99
          );
        },
      },
      {
        label: 'ends in 1000000',
        description:
          '1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000, 10000000, ...',
        predicate: (num) => !(num % 1000000),
      },
      {
        label: 'everything else',
        description:
          '5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 26, 27, 28, 30, 35, 36, 37, 38, 40, ...',
        predicate: () => true,
      },
    ],
  },
  {
    families: 'Ecuador indigenous languages (Shuar)',
    forms: [
      ZERO_FORM,
      {
        label: 'everything else',
        description:
          '1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, …',
        predicate: () => true,
      },
    ],
  },
];
