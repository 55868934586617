import mitt from "mitt";
import { reactive } from "vue";
import { VueConstructor, Vue } from "vue-class-component";

interface Emits<R> {
  close?: () => void;
  confirm?: (payload: R) => void;
}

export type ModalConstructor<R, P extends Emits<R> = Emits<R>> = VueConstructor<
  Vue & { $props: P }
>;

type Props<MC extends ModalConstructor<any>> = Omit<
  InstanceType<MC>["$props"],
  keyof Emits<any>
>;

export interface IModalManager {
  showModal<R, MC extends ModalConstructor<R>>(
    Modal: MC,
    props: Props<MC>,
    slots?: Record<string, () => unknown>
  ): Promise<R>;

  hasModalActive: boolean;
}

class ModalService {
  private manager: Nullable<IModalManager> = null;
  bus = mitt();

  registry(manager: IModalManager) {
    this.manager = manager;
  }

  // TODO use showModal<R, MC extends ModalConstructor<R>>(Modal: MC, props: Props<MC>): Promise<R> {
  showModal<R>(
    Modal: any,
    props: Props<any>,
    slots: Record<string, () => unknown> = {}
  ): Promise<R> {
    return this.manager?.showModal(Modal, props, slots) || Promise.reject();
  }

  async confirm(
    message: string | JSX.Element,
    opts: Partial<{
      confirmText?: string;
      cancelText?: string;
      showFooter?: boolean;
      header?: string;
    }> = {}
  ): Promise<boolean> {
    // eslint-disable-next-line
    const Modal = require('@ravnur/core/ui-kit/confirm/confirm').default;
    return this.showModal(Modal, { ...opts, message });
  }

  get hasModalActive() {
    return this.manager?.hasModalActive ?? false;
  }
}

export default reactive(new ModalService());
