export enum ApplicationNames {
  PORTAL = 'portal',
  MY_ACCOUNT = 'my-account',
}

export enum ApplicationTypes {
  BASIC = 'Basic',
  ROOT = 'Root',
  MY_ACCOUNT = 'MyAccount',
  PUBLIC = 'Public',
  EXTERNAL = 'External',
}
