import { isOperation } from '@ravnur/http';
import $t from '@ravnur/l10n/$t';

export function convertFromError(e: any): string[] {
  const msg = e instanceof Error ? e.message : null;
  if (isOperation(e) && e.errorReason && e.errorReason.length) {
    return e.errorReason;
  }
  const text = msg || $t('common', 'notifications__some_error');
  return [text];
}
