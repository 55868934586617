import MockAdapter from 'axios-mock-adapter/types';

import { LiveChannel } from '@/types/Broadcast';

export default function mocker(adapter: MockAdapter) {
  adapter.onGet(/\/api\/v1\.0\/live\/channel\/channel-path/).reply(_get);
}

function _event(): LiveChannel {
  return {
    id: '1',
    title: 'title',
    description: 'description',
    activeEventId: '1',
    path: 'path',
    splashPath: 'splashPath',
    enableQA: true,
    allowEmbedding: true,
    streamingUrl: null,
    playlistId: null,
  };
}

function _get() {
  return [200, _event()];
}
