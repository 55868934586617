import { AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter/types';
import toBackendFormat from '@/transformers/http/toBackendFormat';
import { Group } from '@/types/Group';
import { Participant } from '@/types/Participant';
import { User } from '@/types/User';
import { _generateGroup, _generateMockList, _generateUser } from './helper-fakers';
import { faker } from '@faker-js/faker';

const groups: Group[] = _generateMockList(+faker.random.numeric(2), _generateGroup);
const users: User[] = _generateMockList(+faker.random.numeric(2), _generateUser);

const _usersAsParticipant: Array<Participant> = users.map((u: User) => {
  return { id: u.id, email: u.email, label: u.displayName, type: 'user' };
});

const _groupsAsParticipant: Array<Participant> = groups.map((g: Group) => {
  return { id: g.id, label: g.name, type: 'group' };
});

const data = [..._usersAsParticipant, ..._groupsAsParticipant];

type Media$Params = {
  Offset?: number;
  Count?: number;
  Q?: string;
};

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/participant').reply(_load);
}

function _load(config: AxiosRequestConfig) {
  const params: Media$Params = config.params;
  const q = params.Q;

  let res: Array<Participant> = data;
  if (q) {
    res = res.filter((c) => c.label.indexOf(q) > -1);
  }

  const count = params.Count;

  if (count) {
    const offset = params.Offset || 0;
    res = res.slice(offset, offset + count);
  }

  return [200, toBackendFormat(res)];
}
