import { Validation } from '@vuelidate/core';
import { Options, Vue, prop } from 'vue-class-component';

import { FORM_CTX_PROVIDER_KEY } from '../../constants';
import { FormContext } from '../../types/FormContext';

import './base-auth-form.scss';

class Props {
  prevalidateOnSubmit = prop({ default: true });
  v!: Validation<any>;
  submit: () => Promise<unknown>;
}

const CN = 'base-auth-form';

@Options({
  name: 'base-auth-form',
  emits: ['submit'],
  provide(this: BaseAuthForm) {
    return {
      [FORM_CTX_PROVIDER_KEY]: this.context,
    };
  },
})
export default class BaseAuthForm extends Vue.with(Props) {
  private context: FormContext = {
    disabled: false,
    submitting: false,
    submit: () => this.submitIfPossible(),
  };

  mounted() {
    this.$nextTick(this.focusAtFirstInput);
  }

  render() {
    return (
      <form class={CN} onKeyup={this.handleSubmitEvent} onSubmit={prevent}>
        {this.$slots.default?.()}
      </form>
    );
  }

  private handleSubmitEvent({ target, which }: KeyboardEvent) {
    if (target instanceof HTMLButtonElement || which !== 13) {
      return;
    }
    this.submitIfPossible();
  }

  private async submitIfPossible() {
    if (this.prevalidateOnSubmit) {
      if (!(await this.v.$validate?.())) {
        return;
      }
    }

    this.context.submitting = true;
    this.submit().then(() => {
      this.context.submitting = false;
    });
  }

  private focusAtFirstInput() {
    const [input] = this.$el.getElementsByTagName('input');
    if (input instanceof HTMLInputElement) {
      input.focus();
    }
  }
}

function prevent(e: Event) {
  e.preventDefault();
}
