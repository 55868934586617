import MockAdapter from 'axios-mock-adapter/types';

import { integer, oneOf } from './casual';
import { startOperation } from './operation';

import toBackendFormat from '@/transformers/http/toBackendFormat';
import { Job, Job$Status, Job$Type } from '@ravnur/shared/types/Job';

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/job/current').reply(_generate);
  mock.onDelete(/\/api\/v1\.0\/job\/\d+$/).reply(_removeJob);
}

function _removeJob() {
  const operation = startOperation(`${Date.now()}`);
  return [200, toBackendFormat(operation)];
}

function _generate() {
  return new Array(integer(4, 1)).fill('').map(_createFakeProcessing);
}

function _createFakeProcessing(): Job {
  return {
    id: '12346',
    mediaId: '1644819977020',
    state: oneOf([/*Job$Status.SUCCESS, Job$Status.ERROR,*/ Job$Status.RUNNING]),
    type: Job$Type.VIDEO_TRANSCODING,
    createdBy: '42',
    progress: integer(100),
  };
}
