import { DATE_FORMAT } from '@ravnur/core/config';
import date from '@ravnur/core/filters/date';
import { Vue, Options, prop } from 'vue-class-component';

import { MediaOption } from '@/config/media-types';
import { getAvailableTypes } from '@/helpers/media';
import {
  Fork$SupportedComponent,
  Fork$Value,
  Component$OpenerPosition,
  Component$Size,
} from '@/types/Component';
import { Language } from '@/types/Language';
import { Site } from '@/types/Site';

import './fork.scss';

const CN = 'fork';

const CNAME_2_LOOKUP_TYPE: { [key in Fork$SupportedComponent]?: string } = {
  'lookup-categories': 'category',
  'lookup-tags': 'tag',
  'lookup-users': 'user',
  'lookup-groups': 'group',
};

const FORMATTER_4_COMPONENT: { [key in Fork$SupportedComponent]?: RegExp } = {
  'str-input': /./,
  'int-input': /^-?\d*$/,
  'float-input': /^-?(\d+\.?\d*)?$/,
};

const LENGTH_4_COMPONENT: { [key in Fork$SupportedComponent]?: number } = {
  'str-input': 1000,
  'int-input': 9,
  'float-input': 15,
};

class Props {
  name: Fork$SupportedComponent;
  value: Fork$Value;
  openerPosition = prop<Component$OpenerPosition>({ default: 'bottom' });
  size = prop<Component$Size>({ default: 'sm' });
  label = prop({ default: '' });
  placeholder = prop({ default: '' });

  onInput?: (val: string) => void;
}

@Options({
  emits: ['input', 'update:value'],
  watch: {
    name: 'reloadThesaurusIfNeeded',
    value: { handler: 'setStrIfNeeded', immediate: true },
    strValue: 'handleInputEvent',
  },
})
export default class Fork extends Vue.with(Props) {
  declare $refs: {
    origin: { focus?: () => void } | undefined;
  };

  private strValue = '';

  private get sites(): Array<Site> {
    return this.store.application.getApplicationSites;
  }

  private get languages(): Array<Language> {
    return this.store.languages.list;
  }

  private get mediaTypes(): Array<MediaOption> {
    return getAvailableTypes();
  }

  private get renderLabel(): boolean {
    return !!this.label;
  }

  private get lookupType(): Nullable<string> {
    return CNAME_2_LOOKUP_TYPE[this.name] || null;
  }

  private get isLookup(): boolean {
    return !!CNAME_2_LOOKUP_TYPE[this.name];
  }

  private get isInput(): boolean {
    return !!FORMATTER_4_COMPONENT[this.name];
  }

  private get formatter(): RegExp {
    return FORMATTER_4_COMPONENT[this.name] || /./;
  }

  private get length(): number {
    return LENGTH_4_COMPONENT[this.name] || 1000;
  }

  private handleInputEvent() {
    this.onChange(this.strValue);
  }

  private onChange(value: Fork$Value) {
    if (value instanceof Date) {
      const v = date(value, DATE_FORMAT, false);
      this.$emit('input', v);
      this.$emit('update:value', v);
    } else {
      this.$emit('input', value);
      this.$emit('update:value', value);
    }
  }

  private reloadThesaurusIfNeeded() {
    if (this.name === 'selector-languages') {
      this.store.languages.load();
    }
  }

  private setStrIfNeeded() {
    this.strValue = '';
    if (this.isInput) {
      if (typeof this.value === 'string') {
        this.strValue = this.value;
      }
      if (typeof this.value === 'number') {
        this.strValue = `${this.value}`;
      }
    }
  }

  // use as public method in metadata-list
  focus() {
    const { origin } = this.$refs;
    if (origin && typeof origin.focus === 'function') {
      origin.focus();
    }
  }

  created() {
    this.reloadThesaurusIfNeeded();
    this.setStrIfNeeded();
  }

  render() {
    if (this.name === 'datepicker') {
      return (
        <div class={CN}>
          <datepicker
            ref="origin"
            class={`${CN}__component`}
            label={this.label}
            openerPosition={this.openerPosition}
            placeholder={this.placeholder}
            size={this.size}
            value={this.value as string}
            onInput={this.onChange}
          />
        </div>
      );
    }

    if (this.isInput) {
      return (
        <div class={CN}>
          <text-field
            ref="origin"
            class={`${CN}__component`}
            formatter={this.formatter}
            label={this.label}
            maxLength={this.length}
            placeholder={this.placeholder}
            vModel={[this.strValue, 'value']}
          />
        </div>
      );
    }

    if (this.name === 'lookup-sites') {
      return (
        <div class={CN}>
          <chosen
            ref="origin"
            class={`${CN}__component`}
            keyForLabel="name"
            label={this.label}
            openerPosition={this.openerPosition}
            options={this.sites}
            placeholder={this.placeholder}
            size={this.size}
            value={this.value}
            onInput={this.onChange}
          />
        </div>
      );
    }

    if (this.name === 'selector-languages') {
      return (
        <div class={CN}>
          <chosen
            ref="origin"
            class={`${CN}__component`}
            keyForLabel="localName"
            label={this.label}
            openerPosition={this.openerPosition}
            options={this.languages}
            placeholder={this.placeholder}
            size={this.size}
            value={this.value}
            onInput={this.onChange}
          />
        </div>
      );
    }

    if (this.name === 'selector-content-type') {
      return (
        <div class={CN}>
          <chosen
            ref="origin"
            class={`${CN}__component`}
            keyForLabel="tkey"
            label={this.label}
            openerPosition={this.openerPosition}
            options={this.mediaTypes}
            placeholder={this.placeholder}
            size={this.size}
            value={this.value}
            onInput={this.onChange}
          />
        </div>
      );
    }

    if (this.isLookup) {
      return (
        <div class={CN}>
          <lookup
            ref="origin"
            class={`${CN}__component`}
            label={this.label}
            openerPosition={this.openerPosition}
            placeholder={this.placeholder}
            size={this.size}
            type={this.lookupType}
            value={this.value}
            onInput={this.onChange}
          />
        </div>
      );
    }

    return <span class={CN}>{this.name}</span>;
  }
}
