import { TranslationType as Translation } from '@ravnur/l10n';

export class Translations {
  public readonly paginator__mobile_desc: string = 'paginator__mobile_desc';

  public readonly LoginFailed: string =
    'You are not authorized to access this application. Please contact the administrator.';
  public readonly unknown_user: string =
    'You are not authorized to access this application. Please contact the administrator.';
  public readonly access_denied =
    'Your login has failed because you have not agreed to allow this application to access your Microsoft/Google account profile. Try again and click on Yes to login.';
  public readonly invalid_request: string =
    'An error has occurred during processing your request. Please contact the administrator.';
  public readonly server_error: string =
    'An error has occurred during processing your request. Please try again later.';
  public readonly temporarily_unavailable: string =
    'Service temporarily unavailable. Please try again later.';
  public readonly invalid_status: string =
    'You are not authorized to access this application. Please contact the administrator.';
  public readonly ms_access_denied: string =
    'Your login has failed because you have not agreed to allow this application to access your Microsoft Account profile. Try again and click on Yes to login.';

  public readonly success_logout: string = 'You have successfully signed out';

  public readonly title: string = 'Sign In';
  public readonly login: string = 'Sign In';
  public readonly username: string = 'Username';
  public readonly firstName: string = 'First name';
  public readonly lastName: string = 'Last name';
  public readonly password: string = 'Password';
  public readonly email: string = 'Email address';
  public readonly register: string = 'Register';
  public readonly forgotPassword: string = 'Forgot password?';
  public readonly resetPassword: string = 'Send password reset instructions';
  public readonly resetDescription: string = 'Enter your email to receive reset instructions';
  public readonly resetMessage: string =
    'If we find {email} in our system, we will send you an email with a link to reset your password.';

  public readonly webLoginDesc: string = 'or';
  public readonly webSignInTab: string = 'Sign in';
  public readonly registerTab: string = 'Register';
  public readonly forgotTab: string = 'Forgot Password';

  public readonly openWebForm: string = 'Open registration and sign in web form';
  public readonly closeWebForm: string = 'Close registration and sign in web form';

  public readonly registrationSuccess: string =
    'Thank you for registering.\nIf a valid e-mail address was entered, you will receive an activation link after admin approval.';

  public readonly save: string = 'Save';
  public readonly cancel: string = 'Cancel';
  public readonly toLoginPage: string = 'Take me to the sign in page';

  public readonly changePasswordTitle: string = 'Change Password';
  public readonly changePasswordIncorrectToken: string = 'Invalid Token';
  public readonly enterNewPassword: string = 'Enter new password';
  public readonly repeatNewPassword: string = 'Repeat new password';
  public readonly strongPasswordText: string =
    'Strong passwords keep you safe! Longer passwords are safer passwords. Your password must contain:';
  public readonly strongPasswordRules: string =
    'between 8 and 20 characters\nat least one lowercase letter (a-z)\nat least one uppercase letter (A-Z)\nat least one number (0-9)\none of these special characters: !@_#$%^&*?';
  public readonly generateHelpText: string =
    "We understand this can be tricky, so we'll generate one for you if you click the button below.";
  public readonly generateBtnText: string = 'Generate strong password';
  public readonly successStrongPasswordMessage: string = 'Success! You have a new strong password.';

  public readonly hidePassword: string = 'Hide password';
  public readonly showPassword: string = 'Show password';

  public readonly activationTitle: string = 'Account activation';
  public readonly verify: string = 'Verify Identity';
  public readonly createPassword: string = 'Create password';
  public readonly continue: string = 'Continue';
  public readonly nameLabel: string = 'Your user name is:';
  public readonly tempPassword: string = 'Enter your temporary password:';
  public readonly successActivation: string =
    'Welcome! You have successfully activated your account. Click the button below to log in with your email address and password.';
}

const translations = new Translations();

export const AS_LIST: Translation[] = (Object.keys(translations) as Array<keyof Translations>).map(
  (key) => {
    return {
      group: 'login',
      key,
      value: translations[key],
      isPlural: false,
      isDefault: true,
    };
  }
);

export const VALIDATIONS: Translation[] = [
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'required',
    value: 'The field is required',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'minLength',
    value: 'Please enter at least {min} characters',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'numeric',
    value: 'The value should be a number',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'numericIf',
    value: 'The value should be a number',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'requiredIf',
    value: 'The field is required',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'maxLength',
    value: 'The maximum length of field is {max} characters',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'email',
    value: 'Invalid email address',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'channelUrl',
    value: 'Invalid URL',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'strongPassword',
    value:
      'Your password doesn\'t meet the strong password criteria. Click on "Generate strong password" and we\'ll do this for you.',
  },
  {
    isPlural: false,
    isDefault: true,
    group: 'validation',
    key: 'sameAsPassword',
    value:
      'Your passwords don’t match so we don’t know which one you want. Please re-enter your password',
  },
];
