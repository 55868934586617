import { computed, defineComponent, PropType } from 'vue';

import { ProcessingInfo, ProcessingInfo$Job } from '../ProcessingInfo';

import ProcessingJob from './processing-job';

import './media-processing-item.scss';
import { IGNORED_JOBS } from './service';

const CN = 'media-processing-item';

export default defineComponent({
  props: {
    item: { type: Object as PropType<ProcessingInfo>, default: true },
    mediaId: { type: String, required: true },
  },
  setup(props) {
    const renderJob = (job: ProcessingInfo$Job) => <ProcessingJob key={job.id} job={job} />;

    const filteredJobs = computed(() =>
      props.item.jobs.filter((j) => !IGNORED_JOBS.includes(j.status))
    );

    return () => (
      <li class={CN}>
        <div class={`${CN}__title`}>{props.item.mediaTitle}</div>
        {filteredJobs.value.map(renderJob)}
      </li>
    );
  },
});
