import $t from '@ravnur/l10n/$t';
import { Job$Status, Job$Type } from '@ravnur/shared/types/Job';
import { computed, defineComponent, PropType } from 'vue';

import { ProcessingInfo$Job } from '../ProcessingInfo';

const CN = 'media-processing-item';
const PROGRESS_DIGITS = 2;

const LABEL_KEYS: { [key in Job$Type]: string } = {
  [Job$Type.AUDIO_TRANSCODING]: 'processing__progress-processing',
  [Job$Type.VIDEO_TRANSCODING]: 'processing__progress-processing',
  [Job$Type.AUDIO_INDEXING]: 'processing__progress-cc',
};

export default defineComponent({
  props: {
    job: { type: Object as PropType<ProcessingInfo$Job>, required: true },
  },
  setup(props) {
    const isFailed = computed(() => props.job.status === Job$Status.ERROR);

    const label4Job = ({ progress, type }: ProcessingInfo$Job, isFailed: boolean): string => {
      const progressLabel = `${progress.toFixed(PROGRESS_DIGITS)}%`;
      const failedLabel = $t('common', 'content-processing__failed');

      return $t('common', LABEL_KEYS[type], { progress: isFailed ? failedLabel : progressLabel });
    };

    return () => (
      <div
        class={[`${CN}__progressbar-wrapper`, isFailed.value ? `${CN}__progressbar--error` : '']}
      >
        <div class={`${CN}__progressbar-title`}>{label4Job(props.job, isFailed.value)}</div>
        <progressbar size="sm" value={props.job.progress} />
      </div>
    );
  },
});
