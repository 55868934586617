type ByTypes<E> = keyof SubType<E, string>;
type ByFnc<E> = (e: E) => string;
type ValueTaker<E> = ByTypes<E> | ByFnc<E>;

export function finder<E extends Record<string, unknown>>(q: string, props: Array<ValueTaker<E>>) {
  const r = new RegExp(q.toLowerCase(), 'ig');
  const getters = props.map(toGetters);
  return function iterator(obj: E) {
    return getters.some((getter) => {
      r.lastIndex = 0;
      const val = getter(obj);
      return r.test(val);
    });
  };
}

export function createFinder<E extends Record<string, unknown>>(props: Array<ValueTaker<E>>) {
  return (q: string) => finder(q, props);
}

function toGetters<E>(it: ValueTaker<E>): ByFnc<E> {
  if (it instanceof Function) {
    return it;
  }
  return (e: E) => e[it] as any;
}
