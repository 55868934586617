import { $Props } from '@ravnur/core/typings/tsx';
import { Button$Color } from '@ravnur/core/ui-kit/button/button';
import ModalService from '@ravnur/modal';
import { Vue, Options, prop } from 'vue-class-component';

type Mode = 'standard' | 'secondary' | 'frameless' | 'flat' | 'borderless';
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

class Props {
  confirmer = prop({ default: '' });
  confirmText = prop({ default: '' });
  disabled = prop({ default: false });

  size = prop<Size>({ default: 'md' });
  color = prop<Button$Color>({ default: 'accent' });
  mode = prop<Mode>({ default: 'standard' });
  icon = prop({ default: '' });

  process!: () => unknown;
}

export type ActionButton$Props = $Props<Props>;

@Options({
  inheritAttrs: true,
})
export default class ActionButton extends Vue.with(Props) {
  public processing = false;

  public render() {
    return (
      <r-button
        color={this.color}
        disabled={this.disabled}
        icon={this.icon}
        loading={this.processing}
        mode={this.mode}
        onclick={this.handleClickEvent}
        size={this.size}
      >
        {this.$slots.default?.()}
      </r-button>
    );
  }

  public async handleClickEvent() {
    const { confirmer, confirmText } = this;
    if (confirmer) {
      const res = await ModalService.confirm(confirmer, confirmText ? { confirmText } : {});
      if (!res) {
        return;
      }
    }
    this.processing = true;
    await this.process();
    this.processing = false;
  }
}
