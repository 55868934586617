import { defineComponent } from 'vue';

import ApplicationsMenu from './applications-menu/applications-menu';
import UserMenu from './user-menu/user-menu';

import './application-header.scss';

const CN = 'application-header';

export default defineComponent({
  setup(props, ctx) {
    return () => (
      <header class={CN}>
        <div class={`${CN}__wrapper`}>
          <ApplicationsMenu />
          {ctx.slots.default?.()}
          <UserMenu />
        </div>
      </header>
    );
  },
});
