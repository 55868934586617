import getScrollableParent, { mainScrollingElement } from './getScrollableParent';
import rAF from './rAF';

const ANIMATION_DURATION = 250;

const HEADER_TOP = 100;

export default function scrollIntoView(
  el: Element,
  useAnimation = false,
  duration: number = ANIMATION_DURATION
) {
  const parent = getScrollableParent(el);

  if (!parent) {
    return;
  }

  const { top } = el.getBoundingClientRect();
  const rect = parent.getBoundingClientRect();

  const startPosition = parent.scrollTop;
  const delta = Math.floor(top - rect.top);
  const destination =
    parent === mainScrollingElement() ? delta - HEADER_TOP : startPosition + delta;

  if (useAnimation) {
    _animateMe(function _scrollStep(easing: number) {
      _scrollTo(parent, startPosition + (destination - startPosition) * easing);
    }, duration);
  } else {
    _scrollTo(parent, destination);
  }
}

function _scrollTo(el: Element, top: number): void {
  el.scrollTop = top;
}

function _animateMe(
  callback: (val: number) => void,
  duration = ANIMATION_DURATION,
  easingFn = _quadratic
) {
  const startTime = _now();
  const destTime = startTime + duration;

  function _step() {
    const now = _now();

    if (now >= destTime) {
      const lastStep = 1;
      callback(lastStep);
      return;
    }

    const easing = easingFn((now - startTime) / duration);
    callback(easing);
    rAF(_step);
  }

  _step();
}

function _now(): number {
  return performance && performance.now ? performance.now() : Date.now();
}

function _quadratic(k: number) {
  return k * (2 - k);
}
