export default {
  TRANSLATION: '/translation',
  MEDIA: '/media',
  FAVORITES: '/favorites',
  EXT_MEDIA: '/ext-media',
  SOURCE: '/source',
  FEATURED: '/featured',
  THUMBNAIL: '/thumbnail',
  METADATA: '/metadata',
  CATEGORY: '/category',
  SEARCH: '/search',
  LANGUAGES: '/language',
  TAGS: '/tag',
  USERS: '/user',
  JOB: '/job',
  GROUPS: '/group',
  ADVANCED_QUERIES: '/advanced_query',
  PARTICIPANT: '/participant',
  SHARE: '/share',
  PLAYLIST: '/playlist',
  VIEWING_HISTORY: '/viewing-history',
  METADATA_FIELDS: '/metadata-field',
  METADATA_TEMPLATES: '/metadata-template',
  FOLDERS: '/folder',
  MEMBERS: '/member',
  COMMENTS: '/comment',

  TIMEDATA: '/timedata',
  SEGMENT: '/segment',
  CC: '/cc',
  CHAPTERS: '/chapters',

  RELATED: '/related',

  UNAPPROVED_MEDIA: '/unapproved-media',

  QRCODE: '/qrcode',
  DOWNLOAD: '/download',

  REPORTS: '/report',

  LOOKUP_TAGS: '/lookup/tag',
  LOOKUP_USERS: '/lookup/user',
  LOOKUP_GROUPS: '/lookup/group',
  LOOKUP_CATEGORIES: '/lookup/category',

  FOLLOWED: '/followed',
};
