import BodyScrollLocker from '@ravnur/core/components/body-scroll-locker/body-scroll-locker';
import $t from '@ravnur/l10n/$t';
import last from '@ravnur/nanoutils/last';
import { setup, Vue } from 'vue-class-component';

import GenericMobileMenu from './generic-mobile-menu/generic-mobile-menu';

import { CategoryNode } from '@/types/Category';
import { MobileMenuItemInfo } from '@/types/MobileMenu';

import { Inject } from '@ravnur/decorators';
import ModalService from '@ravnur/modal';
import InfoModal from '@ravnur/shared/components/info-modal/info-modal';
import usePortalSettings from '@/helpers/hooks/usePortalSettings';
import { LiveEvent$Status } from '@ravnur/shared/types/Live';

import './mobile-menu.scss';

const CN = 'mobile-menu';

interface MobileMenu$Step {
  label: string;
  items: MobileMenuItemInfo[][];
}

export default class MobileMenu extends Vue {
  @Inject('to-my-account') private toMyAccount: () => void;
  @Inject('to-admin-panel') private toAdminPanel: () => void;
  @Inject('is-admin') private isAdmin: boolean;

  private portalSettings = setup(() => usePortalSettings());

  public isOpen = false;
  public stack: MobileMenu$Step[] = [];

  private get mainStep(): MobileMenu$Step {
    return {
      label: '',
      items: [this.mainItems],
    };
  }

  private get currentStep(): MobileMenu$Step | undefined {
    return last(this.stack);
  }

  private get isMainStep() {
    return !this.currentStep || this.currentStep?.label === 'Home';
  }

  private get user() {
    return this.store.user.current;
  }

  get supportChildren() {
    const items: MobileMenuItemInfo[] = [];

    if (this.hasFAQ) {
      items.push({
        id: 'Knowledge-base',
        label: $t('header', 'user-menu__faq'),
        action: this.toFAQ,
      });
    }

    if (this.hasContactUs) {
      items.push({
        id: 'Contact-us',
        label: $t('header', 'user-menu__contact-us'),
        action: () => this.openContactUsModal(),
      });
    }

    return items;
  }

  get mainItems(): MobileMenuItemInfo[] {
    const { isCategories, isGroups, isLiveStreaming } = this.store.application;

    const res: MobileMenuItemInfo[] = [
      {
        id: 'Home',
        label: $t('header', 'menu__home'),
        to: { name: 'Home' },
      },
      {
        id: 'Browse',
        label: $t('header', 'menu__browse'),
        to: { name: 'Browse' },
      },
      {
        id: 'My-Profile',
        label: $t('header', 'user-menu__my-account'),
        action: this.toMyAccount,
      },
    ];

    if (this.isAdmin) {
      res.push({
        id: 'Admin-Panel',
        label: $t('header', 'user-menu__admin-portal'),
        action: this.toAdminPanel,
      });
    }

    if (isCategories && this.applicationCategories.map(_category2menu).length > 0) {
      res.push({
        id: 'Categories',
        label: $t('header', 'menu__categories'),
        children: [this.applicationCategories.map(_category2menu)],
      });
    }

    if (isGroups) {
      res.push({
        id: 'Groups',
        label: $t('header', 'community__groups'),
        to: { name: 'Groups' },
      });
    }

    if (isLiveStreaming) {
      res.push({
        id: 'LiveEvents',
        label: $t('header', 'menu__live'),
        to: { name: 'LiveEvents' },
        count: this.liveStreams,
      });
    }

    if (this.canUploadContent) {
      res.push({
        id: 'Upload',
        label: $t('header', 'menu__upload'),
        to: { name: 'Upload' },
      });
    }

    if (this.hasContactUs || this.hasFAQ) {
      res.push({
        id: 'Support',
        label: $t('header', 'user-menu__support'),
        children: [this.supportChildren],
      });
    }

    res.push({
      id: 'LogOut',
      label: $t('header', 'user-menu__sign-out'),
      action: this.store.logout,
    });

    return res;
  }

  get footerSettings() {
    return this.store.application.footerSettings;
  }

  get hasFAQ() {
    return this.portalSettings?.isFaq && !!this.footerSettings?.knowledgeBase;
  }

  get hasContactUs() {
    return this.portalSettings?.isContactInfo && !!this.footerSettings?.contactUs;
  }

  get applicationCategories() {
    return this.store.application.getApplicationCategories;
  }

  get canUploadContent() {
    return this.store.user.canUploadContent;
  }

  get liveStreams() {
    return this.store.live.all.filter((e) => e.status === LiveEvent$Status.ON_AIR).length;
  }

  public create() {
    this.stack.push(this.mainStep);
  }

  public render() {
    const cn = [CN, this.isOpen ? `${CN}--push` : ''];
    return (
      <div class={cn} tabindex={-1}>
        <BodyScrollLocker isLocked={this.isOpen} />
        {this.renderPlaceholder()}

        <r-button
          class={`${CN}__btn`}
          color="black"
          icon="menu"
          mode="frameless"
          onclick={this.showMenu}
        />

        <div class={`${CN}__container`}>
          {this.renderHeader()}
          <div class={[`${CN}__scroller`, this.currentStep ? 'no-border' : '']}>
            {this.mainStep.items.map((items, idx) => {
              return <GenericMobileMenu key={`${idx}`} items={items} onMore={this.moveTo} />;
            })}
          </div>
        </div>
        <div
          class={[
            `${CN}__scroller-step`,
            this.currentStep && this.currentStep.items[0].length > 0 ? 'active' : '',
          ]}
        >
          {this.renderHeader()}
          <div class={[`${CN}__scroller-step-title`]} v-show={this.currentStep}>
            {this.currentStep?.label}
          </div>
          {this.currentStep &&
            this.currentStep.items.map((items, idx) => {
              return <GenericMobileMenu key={`${idx}`} items={items} onMore={this.moveTo} />;
            })}
        </div>
      </div>
    );
  }

  private renderPlaceholder() {
    if (this.isOpen) {
      return <div class={`${CN}__placeholder`} onClick={this.hideMenu} onTouchmove={_prevent} />;
    }
  }

  private renderHeaderLogo() {
    return (
      <img
        class={`${CN}__header-logo`}
        src={this.store.application.getCurrentSite && this.store.application.getCurrentSite.logoUrl}
      />
    );
  }

  private renderHeader() {
    return (
      <div class={`${CN}__header`}>
        {this.isMainStep && this.user && this.renderHeaderLogo()}

        <r-button
          class={`${CN}__close-btn`}
          color="black"
          icon="close"
          mode="frameless"
          onclick={this.isMainStep ? this.hideMenu : this.back}
          size="lg"
        />
      </div>
    );
  }

  private showMenu() {
    this.isOpen = true;
  }

  private hideMenu() {
    this.isOpen = false;
    this.stack = [];
  }

  private back() {
    this.stack.pop();
  }

  private moveTo({ label, children, to, action }: MobileMenuItemInfo) {
    if (action) {
      action?.();
      return;
    }

    if (!children && to) {
      this.hideMenu();
      this.$router.push(to);
    } else {
      this.stack.push({ label, items: children || [[]] });
    }
  }

  private toFAQ() {
    if (!this.store.application.footerSettings) {
      return;
    }

    const { knowledgeBase } = this.store.application.footerSettings;

    if (knowledgeBase) {
      window.open(knowledgeBase, '_blank', 'noreferrer');
    }
  }

  private async openContactUsModal() {
    this.hideMenu();

    await ModalService.showModal(InfoModal, {
      header: $t('header', 'faq-menu__contact'),
      value: this.store.application.footerSettings?.contactUs,
    }).catch(() => null);
  }
}

function _prevent(e: Event) {
  e.preventDefault();
}

function _category2menu({ id, name: label, children }: CategoryNode): MobileMenuItemInfo {
  return {
    id,
    label,
    children: children ? [children.map(_category2menu)] : null,
    to: { name: 'MediaCategories', params: { cid: id } },
  };
}
