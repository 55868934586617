class ApplicationScroll {
  get scrollTop(): number {
    const eViewport = document.scrollingElement;
    if (eViewport) {
      return eViewport.scrollTop;
    }
    return document.documentElement.scrollTop || document.body.scrollTop;
  }

  set scrollTop(offset: number) {
    document.documentElement.scrollTop = offset;
    document.body.scrollTop = offset;
  }

  get hasScroll(): boolean {
    const body = document.body;
    return body.scrollHeight > window.innerHeight;
  }
}

const applicationScroll = new ApplicationScroll();

export default applicationScroll;
