import find from 'lodash/find';
import has from 'lodash/has';

const RAF_KEY = (function _rafKeyDetector() {
  const keys = [
    'requestAnimationFrame',
    'msRequestAnimationFrame',
    'webkitRequestAnimationFrame',
    'mozRequestAnimationFrame',
    'oRequestAnimationFrame',
  ];

  return find(keys, (k: string) => has(window, k));
})();

export default function rAF(callback: () => unknown) {
  if (RAF_KEY) {
    (window as any)[RAF_KEY](callback); // eslint-disable-line
  } else {
    setTimeout(callback, 1000 / 60);
  }
}
