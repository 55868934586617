import { Watch } from '@ravnur/decorators';
import scrollIntoViewIfNeeded from '@ravnur/helpers/dom/scrollIntoViewIfNeeded';
import findIndex from '@ravnur/nanoutils/findIndex';
import { Options, Vue } from 'vue-class-component';

import { CommonProps, WithModel } from '../../typings/tsx';
import Scrollable from '../../ui-kit/scrollable/scrollable';
import OptionSnippet from '../option-snippet/option-snippet';

import './selectable-list.scss';

const CN = 'selectable-list';

type Props<E> = {
  autoHighlightingFirstItem?: boolean;
  current?: Nullable<E>;
  items: E[];
  propName?: keyof E;
  isSelectable?: Predicate<E>;
  isScrollable?: boolean;
};

type Emits<E> = {
  onEmpty?: () => void;
  onSelect?: (entity: E) => void;
};

@Options({
  name: 'selectable-list',
  emits: ['select', 'empty'],
  props: {
    autoHighlightingFirstItem: { default: true },
    current: { default: null },
    items: { required: true },
    propName: { default: 'id' },
    isSelectable: { default: () => () => true },
    isScrollable: { default: true },
  },
})
export default class SelectableList<E> extends Vue {
  declare readonly $props: WithModel<Props<E>, 'current'> & Emits<E> & CommonProps;

  declare $refs: {
    scrollable: Scrollable | undefined;
  };

  public readonly autoHighlightingFirstItem!: boolean;
  public readonly current!: Nullable<E>;
  public readonly items!: E[];
  public readonly propName!: keyof SubType<E, string>;
  public readonly isSelectable!: Predicate<E>;
  public readonly isScrollable!: boolean;

  private pointedIndex = 0;

  get pointed(): Nullable<E> {
    return this.items[this.pointedIndex] || null;
  }

  public up() {
    this.pointedIndex = Math.max(0, this.pointedIndex - 1);
  }

  public down() {
    if (this.items.length) {
      this.pointedIndex = Math.min(this.items.length - 1, this.pointedIndex + 1);
    } else {
      this.pointedIndex = 0;
    }
  }

  public selectPointed() {
    const { pointed } = this;
    if (pointed) {
      this.select(pointed);
    } else {
      this.$emit('empty');
    }
  }

  @Watch('items', { immediate: true })
  protected handleItemsChanged() {
    const { autoHighlightingFirstItem, current, items } = this;
    if (autoHighlightingFirstItem) {
      this.pointedIndex = findIndex((e: E) => current === e, items) || 0;
    } else {
      this.pointedIndex = -1;
    }
  }

  mounted() {
    setTimeout(this.scrollToCurrent);
  }

  render() {
    if (!this.isScrollable) {
      return this.renderContent();
    }
    return <scrollable ref="scrollable" class={CN} v-slots={{ default: this.renderContent }} />;
  }

  private renderContent() {
    return (
      <ul class={`${CN}__items`} data-testid="selectable-list">
        {this.items.map(this.renderItem)}
      </ul>
    );
  }

  private keyForEntity(entity: unknown) {
    return (entity as any)?.id || JSON.stringify(entity);
  }

  private renderItem(entity: E, idx: number) {
    const { pointedIndex, current } = this;
    const cn = {
      [`${CN}__item`]: true,
      [`${CN}__item--pointer`]: pointedIndex === idx,
      [`${CN}__item--selected`]: current === entity,
      [`${CN}__item--disabled`]: !this.isSelectable(entity),
    };
    const key = this.keyForEntity(entity);
    return (
      <li
        key={key}
        ref={`id-${key}`}
        class={cn}
        role="button"
        onClick={() => this.select(entity)}
        onMousemove={() => this.changePointedIndex(idx)}
      >
        {this.renderEntityData(entity)}
      </li>
    );
  }

  private renderEntityData(entity: E) {
    const { propName, current, $slots } = this;

    const slots = {
      default: $slots.option,
    };

    const check = current === entity && (
      <icon class={`${CN}__current-check`} size="md" type="check" />
    );

    return (
      <>
        <OptionSnippet item={entity} propName={propName} v-slots={slots} />
        {check}
      </>
    );
  }

  private select(entity: E) {
    if (this.isSelectable(entity)) {
      this.$emit('select', entity);
    }
  }

  private changePointedIndex(idx: number) {
    this.pointedIndex = idx;
  }

  private scrollToIndex(idx: number) {
    if (idx < 0) {
      return;
    }
    const key = this.keyForEntity(this.items[idx]);
    const el = (this.$refs as any)[`id-${key}`];
    if (el instanceof HTMLElement) {
      scrollIntoViewIfNeeded(el);
    }
  }

  private scrollToCurrent() {
    if (this.current) {
      const idx = this.items.indexOf(this.current);
      this.scrollToIndex(idx);
    }
  }
  //
  // private scrollToPointedIndex() {
  //   this.scrollToIndex(this.pointedIndex);
  // }
}
