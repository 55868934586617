import throttle from 'lodash/throttle';
import { createDecorator, VueDecorator } from 'vue-class-component';

export default function Throttle(duration: number): VueDecorator {
  return createDecorator(function _decorator(options, key) {
    const methods = options.methods;
    const origin = methods[key];
    methods[key] = throttle(function _notThrottled<T>(...args: T[]) {
      // eslint-disable-next-line
      // @ts-ignore
      const self: unknown = this; // eslint-disable-line
      origin.apply(self, args);
    }, duration);
    options.methods = methods;
  });
}
