import { Inject, Watch } from '@ravnur/decorators';
import { Vue } from 'vue-class-component';

import { CategoryNode } from '@/types/Category';

import './application-subcategories.scss';

const MAX_SCROLL_COUNT = 5;
const CN = 'application-subcategories';

class Props {
  category: CategoryNode;

  onBack: () => void;
}

export default class ApplicationSubcategories extends Vue.with(Props) {
  declare $refs: {
    items: HTMLUListElement;
  };
  @Inject('layout') private layout: LayoutContext;

  private canScrollUp = false;
  private canScrollDown = false;
  private offset = 0;

  // watcher
  protected get layoutWidth(): number {
    return this.layout.width;
  }

  private detect() {
    const items = this.$refs.items.children;
    const lastItem = items.item(items.length - 1);
    const firstItem = items.item(0);

    if (!firstItem || !lastItem) {
      return;
    }

    const elRect = this.$el.getBoundingClientRect();
    const lastElementRect = lastItem.getBoundingClientRect();
    const firstElementRect = firstItem.getBoundingClientRect();

    this.canScrollUp = elRect.top > firstElementRect.top;
    this.canScrollDown = elRect.bottom < lastElementRect.bottom;
  }

  private up() {
    const items = this.$refs.items.children;
    const firstItem = items.item(0);
    if (!firstItem) {
      return;
    }
    const firstElementRect = firstItem.getBoundingClientRect();
    const height = firstElementRect.bottom - firstElementRect.top;
    this.offset = Math.min(this.offset + MAX_SCROLL_COUNT * height, 0);
    this.$nextTick(this.detect);
  }

  private down() {
    const items = this.$refs.items.children;
    const lastItem = items.item(items.length - 1);

    if (!lastItem) {
      return;
    }

    const elRect = this.$el.getBoundingClientRect();
    const lastElementRect = lastItem.getBoundingClientRect();

    const height = lastElementRect.bottom - lastElementRect.top;

    const available = (lastElementRect.bottom - elRect.bottom) / height;
    this.offset = this.offset - Math.min(MAX_SCROLL_COUNT, available) * height;

    this.$nextTick(this.detect);
  }

  @Watch('layoutWidth')
  protected onResize() {
    this.offset = 0;
    this.$nextTick(this.detect);
  }

  mounted() {
    this.detect();
  }

  render() {
    return (
      <div class={CN}>
        <r-button
          class={`${CN}__back`}
          color="black"
          icon="arrow-back"
          mode="frameless"
          onclick={this.onBack}
          title={this.category.name}
        >
          {this.category.name}
        </r-button>
        <ul ref="items" class={`${CN}__list`} style={{ transform: `translateY(${this.offset}px)` }}>
          {this.category.children?.map(this.renderItem)}
        </ul>
        {this.renderScrollUpButton()}
        {this.renderScrollDownButton()}
      </div>
    );
  }

  private renderScrollUpButton() {
    if (this.canScrollUp) {
      return (
        <r-button
          class={`${CN}__scroll-up`}
          color="black"
          icon="arrow-up"
          mode="frameless"
          onclick={this.up}
        />
      );
    }
  }

  private renderScrollDownButton() {
    if (this.canScrollDown) {
      return (
        <r-button
          class={`${CN}__scroll-down`}
          color="black"
          icon="arrow-down"
          mode="frameless"
          onclick={this.down}
        />
      );
    }
  }

  private renderItem(item: CategoryNode) {
    return (
      <li key={item.id} class={`${CN}__item`}>
        <router-link
          class={`${CN}__item-name`}
          title={item.name}
          to={{ name: 'MediaCategories', params: { cid: `${item.id}` } }}
        >
          {item.name}
        </router-link>
      </li>
    );
  }
}
