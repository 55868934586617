import { Vue, prop } from 'vue-class-component';

import './tooltip.scss';

const CN = 'tooltip';

class Props {
  text = prop<string>({ default: '' });
}

export default class Tooltip extends Vue.with(Props) {
  render() {
    return (
      <div class={CN}>
        {this.text && (
          <>
            <div class={`${CN}__text`}>{this.text}</div>
            <div class={`${CN}__text-arrow`} />
          </>
        )}
        {this.$slots.default?.()}
      </div>
    );
  }
}
