import { Options, prop, Vue } from 'vue-class-component';

import { $Props } from '../../typings/tsx';

import './button.scss';

const CN = 'button';

type Button$Mode = 'standard' | 'secondary' | 'frameless' | 'flat' | 'borderless';

export type Button$Color = 'accent' | 'primary' | 'grey' | 'white' | 'black' | 'danger';

export class Props {
  size = prop<Component$Size>({ default: 'md' });
  hover = prop({ default: false });
  icon = prop<Nullable<string>>({ default: null });
  iconSize = prop<Component$Size>({ default: 'md' });
  mode = prop<Button$Mode>({ default: 'standard' });
  color = prop<Button$Color>({ default: 'accent' });
  loading = prop({ default: false });
  disabled = prop({ default: false });
  inlineSpinner = prop({ default: false });
}

export type RButton$Props = $Props<Props> & Partial<HTMLButtonElement>;

@Options({
  name: 'r-button',
  inheritAttrs: false,
})
export default class Button extends Vue.with(Props) {
  render() {
    const { disabled, loading, $attrs } = this;
    const cn = {
      [CN]: true,
      [`${CN}--${this.size}`]: this.size,
      [`${CN}--${this.mode}`]: this.mode,
      [`${CN}--${this.color}`]: this.color,
      [`${CN}--loading`]: this.loading,
      [`${CN}--hovering`]: this.hover,
    };

    return (
      <button class={cn} disabled={disabled || loading} type="button" {...$attrs}>
        {this._icon()}
        {this._text()}
        {this._spinner()}
      </button>
    );
  }

  private _icon() {
    const { icon: iconType, iconSize, size } = this;
    return iconType && <icon size={iconSize || size} type={iconType} />;
  }

  private _text() {
    const content = this.$slots.default?.();
    return content && <span class={`${CN}__text`}>{content}</span>;
  }

  private _spinner() {
    const { inlineSpinner, loading, size } = this;
    if (!loading) {
      return null;
    }
    const cn = {
      [`${CN}__spinner`]: true,
      [`${CN}__spinner--inline`]: inlineSpinner,
    };

    return <spinner class={cn} isPrimary={true} size={size} think={true} />;
  }
}
