import { prop, Vue, Options } from 'vue-class-component';

import { COMPONENT_CTX_PROVIDER_KEY } from '../../constants';
import { $Props } from '../../typings/tsx';

import './checkbox.scss';

type Checkbox$Mode = 'standard' | 'invert';

class Props {
  value!: boolean;
  label = prop({ default: '' });
  disabled = prop({ default: false });
  mode = prop<Checkbox$Mode>({ default: 'standard' });
}

type Emits = {
  onInput: (value: boolean) => void;
};

export type Checkbox$Props = $Props<Props, Emits, 'value'> & Partial<HTMLDivElement>;

const CN = 'checkbox';

@Options({
  name: 'checkbox',
  inject: {
    context: COMPONENT_CTX_PROVIDER_KEY,
  },
  emits: ['input', 'update:value'],
})
export default class Checkbox extends Vue.with(Props) {
  private readonly context!: Nullable<ComponentContext>;

  private get iconType(): string {
    if (this.value) {
      return this.mode === 'invert' ? 'checkbox-invert' : 'checkbox';
    }
    return 'checkbox-outline';
  }

  private get isDisabled(): boolean {
    return this.disabled || (this.context?.disabled ?? false);
  }

  render() {
    const cn = {
      [CN]: true,
      'checkbox--checked': this.value,
      'checkbox--disabled': this.isDisabled,
    };

    const content = this.label || this.$slots.default?.();

    return (
      <span
        aria-checked={this.value}
        aria-disabled={this.isDisabled}
        aria-label={this.label}
        class={cn}
        role="checkbox"
        onClick={this.toggle}
      >
        <icon aria-hidden={true} class={`${CN}__icon`} type={this.iconType} />
        <span class={`${CN}__label`}>{content}</span>
        {this.$slots.action?.()}
      </span>
    );
  }

  private toggle() {
    if (this.isDisabled) {
      return;
    }
    this.$emit('update:value', !this.value);
    this.$emit('input', !this.value);
  }
}
