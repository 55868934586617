import { defineComponent, PropType } from 'vue';

import { Site } from '@/types/Site';

const CN = 'application-item';

export default defineComponent({
  props: { site: { type: Object as PropType<Nullable<Site>> } },
  setup(props) {
    return () => {
      const { site } = props;
      if (site) {
        return (
          <span class={CN}>
            <img alt={site.name} src={site.logoUrl} />
          </span>
        );
      }
    };
  },
});
