import { $Props } from '@ravnur/core/typings/tsx';
import upperFirst from '@ravnur/nanoutils/string/upperFirst';
import { Vue, prop } from 'vue-class-component';

import './stats.scss';

const CN = 'stats';

type Stats$Type = 'ratings' | 'views' | 'likes' | 'comments' | 'count' | 'group' | 'subscriptions';
type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

class Props {
  count: string | number;
  iconSize = prop<Size>({ default: 'sm' });
  statType: Stats$Type;
}
export type Stats$Props = $Props<Props>;

const ICONS: { [key in Stats$Type]: string } = {
  ratings: 'star',
  views: 'eye',
  likes: 'thumb-up',
  comments: 'chat',
  count: 'collections-bookmark',
  group: 'group',
  subscriptions: 'subscriptions',
};

export default class Stats extends Vue.with(Props) {
  render() {
    return (
      <span class={CN} {...this.$attrs} data-testid="stats">
        <icon
          class={[`${CN}__icon`, `${CN}__icon--${this.statType}`]}
          size={this.iconSize}
          type={ICONS[this.statType]}
        />
        <span aria-label={upperFirst(this.statType)} class={`${CN}__count`}>
          {_label(this.statType, this.count)}
        </span>
      </span>
    );
  }
}

function _label(type: Stats$Type, count: string | number): string {
  if (type === 'ratings') {
    return `${count}/5`;
  }
  return `${count}`;
}
