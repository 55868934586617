import { renderSlot } from 'vue';
import { Options, Vue, prop } from 'vue-class-component';

import BaseChosen from '../../components/base-chosen/base-chosen';
import Opener, { OpenerSlotProps } from '../../components/opener/opener';
import SelectableList from '../../components/selectable-list/selectable-list';

import { $Props } from '../../typings/tsx';
import { Button$Color } from '../button/button';

import './more-actions.scss';

const CN = 'more-actions';

class Props<T> {
  options = prop<T[]>({ default: [] });
  icon = prop({ default: 'more' });
  size = prop<Component$Size>({ default: 'md' });
  color = prop<Button$Color>({ default: 'black' });
  align = prop<'right' | 'left' | 'center'>({ default: 'right' });
  openerPosition = prop<Component$OpenerPosition>({ default: 'bottom' });
  tooltip = prop({ default: '' });
  isSelectable = prop<Predicate<T>>({ default: () => () => true });
  text = prop<string>({ default: '' });
}

type Emits<T> = {
  onClick?: (e: T) => void;
};

export type MoreActions$Props<T> = $Props<Props<T>, Emits<T>>;

@Options({
  name: 'more-actions',
  emits: ['click'],
})
export default class MoreActions<T extends Entity> extends Vue.with(Props) {
  public declare readonly options: T[];

  declare $refs: {
    options: SelectableList<T>;
    opener: Opener;
  };

  render() {
    const { align, size, openerPosition, options, $slots } = this;
    if (!options.length) {
      return;
    }

    const cn = {
      [CN]: true,
      [`${CN}--${size}`]: true,
      [`${CN}--top`]: openerPosition === 'top',
      [`${CN}--${align}`]: align,
    };

    const slots = {
      button: this.renderButton,
      ...$slots,
    };

    return (
      <BaseChosen
        ref="opener"
        class={cn}
        current={null}
        isScrollable={false}
        isSelectable={this.isSelectable}
        openerPosition={this.openerPosition}
        options={options}
        v-slots={slots}
        onInput={this.select}
      />
    );
  }

  private renderButton({ visible, toggle }: OpenerSlotProps) {
    const { size, options, icon: iconType, tooltip, $slots } = this;

    if (!options.length) {
      return;
    }

    const color = visible ? 'primary' : this.color;
    const content = renderSlot($slots, 'default', {}, () => [
      <icon key="icon" size={size} type={iconType} />,
      <span key="text">{this.text}</span>,
    ]);

    return (
      <r-button
        class={`${CN}__toggler`}
        color={color}
        mode="frameless"
        onclick={toggle}
        title={tooltip}
      >
        {content}
      </r-button>
    );
  }

  private select(option: T) {
    this.$emit('click', option);
  }
}
