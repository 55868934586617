import is403 from '@ravnur/core/transformers/error/is403';
import is404 from '@ravnur/core/transformers/error/is404';
import { convertFromError } from '@ravnur/notifications/convertFromError';
import { showErrorNotification } from '@ravnur/notifications/service';
import { useRouter } from 'vue-router';

export function $processException(e: unknown) {
  convertFromError(e).forEach(showErrorNotification);
}

export function $processPageException(e: unknown) {
  if (is404(e)) {
    useRouter().replace({ name: '404' });
  } else if (is403(e)) {
    useRouter().replace({ name: '403' });
  } else {
    $processException(e);
  }
}
