import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { watch } from 'vue';
import { PortalSettings } from '@ravnur/shared/types/PortalSettings';

interface RouteRestrictionsGuardParams<P> {
  settings?: Array<keyof PortalSettings>;
  permissions?: Array<keyof P>;
  getSettingsStore: () => Nullable<PortalSettings>;
  getPermissionsStore: () => Nullable<P>;
  getStoreInitializedField?: () => boolean;
}

interface RedirectIfNeededParams<P>
  extends Omit<RouteRestrictionsGuardParams<P>, 'getStoreInitializedField'> {
  next: NavigationGuardNext;
}

const SETTINGS_REDIRECT_ROUTE_NAME = '404';
const PERMISSIONS_REDIRECT_ROUTE_NAME = '403';

function redirectIfNeeded<P = string[]>({
  settings = [],
  permissions = [],
  getSettingsStore,
  getPermissionsStore,
  next,
}: RedirectIfNeededParams<P>) {
  const settingsStore = getSettingsStore();
  const permissionsStore = getPermissionsStore();
  if (!settings?.every((item) => !!settingsStore?.[item]) ?? true) {
    return next({ name: SETTINGS_REDIRECT_ROUTE_NAME });
  }

  if (!permissions?.every((item) => !!permissionsStore?.[item]) ?? true) {
    return next({ name: PERMISSIONS_REDIRECT_ROUTE_NAME });
  }

  return next();
}

function routeGuard<P = string[]>({
  settings = [],
  permissions = [],
  getSettingsStore,
  getPermissionsStore,
  getStoreInitializedField,
}: RouteRestrictionsGuardParams<P>) {
  return async function (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<ReturnType<NavigationGuardNext>> {
    if (getStoreInitializedField && !getStoreInitializedField?.()) {
      return new Promise((resolve) => {
        const unwatch = watch(getStoreInitializedField, () => {
          unwatch();
          return resolve(
            redirectIfNeeded<P>({
              settings,
              permissions,
              getSettingsStore,
              getPermissionsStore,
              next,
            })
          );
        });
      });
    }

    return redirectIfNeeded<P>({
      settings,
      permissions,
      getSettingsStore,
      getPermissionsStore,
      next,
    });
  };
}

export default routeGuard;
