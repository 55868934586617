export enum UserPermissions {
  downloadContent = 'downloadContent',
  rate = 'rate',
  comment = 'comment',
  tag = 'tag',
  categoriesAdmin = 'categoriesAdmin',
  rolesAdmin = 'rolesAdmin',
  createGroup = 'createGroup',
  contentAdmin = 'contentAdmin',
  reviewContent = 'reviewContent',
  manageUsers = 'manageUsers',
  groupsAdmin = 'groupsAdmin',
  upload = 'upload',
  accessToAdministration = 'accessToAdministration',
  tagsAdmin = 'tagsAdmin',
  shareLink = 'shareLink',
  shareEmbedCode = 'shareEmbedCode',
  editComments = 'editComments',
  runAudioIndexing = 'runAudioIndexing',
  enablePublicPage = 'enablePublicPage',
  purgeItems = 'purgeItems',
  purgeUsers = 'purgeUsers',
}
