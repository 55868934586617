import { Watch } from '@ravnur/decorators';
import always from '@ravnur/nanoutils/always';
import { prop, Vue } from 'vue-class-component';

import { CAROUSEL_AUTOREFRESH_INTERVAL } from '@/config/constants';

import './carousel.scss';

const CN = 'carousel';
const DEFAULT_INDEX = 0;
const DEFAULT_ITEMS_COUNT_PER_LINE = 1;

class Props {
  list = prop<unknown[]>({ required: true });
  dotTitleGen = prop<(item: unknown) => string | undefined>({ default: always('') });
  autoScroll = prop<boolean>({ default: true });
  showDotes = prop<boolean>({ default: true });
  widthControl = prop<boolean>({ default: true });
  arrowStyle = prop<string>({
    default: 'frameless',
    validator: (value: string) => ['frameless', 'flat'].includes(value),
  });
}

export default class Carousel extends Vue.with(Props) {
  declare $refs: {
    items: HTMLUListElement | undefined;
  };

  index = DEFAULT_INDEX;
  timerId: Nullable<number> = null;
  width = 0;
  isDisabledAnimation = false;
  itemsCountPerLine = DEFAULT_ITEMS_COUNT_PER_LINE;
  count = 0;
  initialX = 0;
  initialY = 0;

  get isFirst(): boolean {
    return this.index === DEFAULT_INDEX;
  }

  get isLast(): boolean {
    return this.index === this.count - 1;
  }

  get totalWidth(): number {
    return this.count * this.width;
  }

  get isReady(): boolean {
    return !!this.width;
  }

  get offset(): number {
    return this.index * this.width;
  }

  render() {
    return (
      <div
        class={[CN, { 'carousel--no-animation': this.isDisabledAnimation }]}
        onMouseenter={this.stopAutoRefreshing}
        onMouseleave={this.startAutoRefreshing}
        onTouchmove={this.handleTouchMove}
        onTouchstart={this.handleTouchStart}
      >
        <ul
          ref="items"
          class={`${CN}__list`}
          style={`width: ${this.totalWidth}px; transform: translateX(-${this.offset}px)`}
        >
          {this.list.map((item, idx) => (
            <li
              key={idx}
              class={[`${CN}__item`, { 'carousel__item--visible': this.isVisible(idx) }]}
              data-testid={this.isVisible(idx) ? 'carousel-item-visible' : ''}
              style={this.widthControl ? `width: ${this.width}px` : ''}
            >
              {this.$slots.item?.({ item, index: idx })}
            </li>
          ))}
        </ul>
        <r-button
          class={[
            `${CN}__arrow`,
            `${CN}__arrow--left`,
            { 'carousel__arrow--flat': this.arrowStyle === 'flat' },
          ]}
          color={this.arrowStyle === 'flat' ? 'black' : 'grey'}
          data-testid="carousel-arrow-left"
          disabled={this.isFirst}
          icon="arrow-left"
          mode="frameless"
          onclick={this.prev}
          size="lg"
          v-show={this.count > 1}
        />
        <r-button
          class={[
            `${CN}__arrow ${CN}__arrow--right`,
            { 'carousel__arrow--flat': this.arrowStyle === 'flat' },
          ]}
          color={this.arrowStyle === 'flat' ? 'black' : 'grey'}
          data-testid="carousel-arrow-right"
          disabled={this.isLast}
          icon="arrow-right"
          mode="frameless"
          onclick={this.next}
          size="lg"
          v-show={this.count > 1}
        />
        {this.showDotes && this.count > 1 && (
          <div class={`${CN}__dotes`}>
            {this.list.map((item: unknown, idx: number) => (
              <r-button
                key={idx}
                class={[`${CN}__dote`, { 'carousel__dote--current': this.index === idx }]}
                color="white"
                icon="dot"
                mode="frameless"
                onclick={() => this.goto(idx)}
                size="xs"
                title={this.dotTitleGen(item)}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  handleTouchStart(event: TouchEvent) {
    this.initialX = event.touches[0].clientX;
    this.initialY = event.touches[0].clientY;
  }

  handleTouchMove(event: TouchEvent) {
    if (this.initialX === 0) return;
    if (this.initialY === 0) return;

    const currentX = event.touches[0].clientX;
    const currentY = event.touches[0].clientY;

    const diffX = this.initialX - currentX;
    const diffY = this.initialY - currentY;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      // sliding horizontally
      if (diffX > 0) {
        this.next();
        event.preventDefault();
      } else {
        this.prev();
        event.preventDefault();
      }
    }

    this.initialX = 0;
    this.initialY = 0;
  }

  isVisible(idx: number) {
    const from = this.index * this.itemsCountPerLine;
    const to = from + this.itemsCountPerLine;

    return idx >= from && idx < to;
  }

  prev() {
    if (this.index === 0) return;
    --this.index;
  }

  next() {
    if (this.isLast) return;
    ++this.index;
  }

  goto(idx: number) {
    this.index = idx;
  }

  carouselTick() {
    if (this.isLast) {
      this.goto(DEFAULT_INDEX);
    } else {
      this.next();
    }
  }

  disabledAnimation() {
    this.isDisabledAnimation = true;
  }

  enabledAnimation() {
    this.isDisabledAnimation = false;
  }

  @Watch('list')
  onChangeLayoutWidth() {
    this.disabledAnimation();
    this.$nextTick(() => {
      this.index = DEFAULT_INDEX;
      const count = this.list.length;
      this.width = this.$el.clientWidth;
      this.count = count;
      this.$nextTick(this._afterRepaint);
    });
  }

  _afterRepaint() {
    const ul = this.$refs.items;
    if (!ul) {
      return;
    }
    const items = ul.children;
    const count = this.count;
    const li = items.item(0);
    if (li && li instanceof HTMLElement) {
      this.itemsCountPerLine = Math.round(this.width / li.clientWidth);
      this.count = Math.ceil(count / this.itemsCountPerLine);
    }
    this.$nextTick(this.enabledAnimation);
  }

  stopAutoRefreshing() {
    const timerId = this.timerId;
    if (timerId) {
      clearInterval(timerId);
      this.timerId = null;
    }
  }

  startAutoRefreshing() {
    this.stopAutoRefreshing();
    if (this.autoScroll) {
      this.timerId = window.setInterval(this.carouselTick, CAROUSEL_AUTOREFRESH_INTERVAL);
    }
  }

  @Watch('autoScroll')
  autoScrollIfNeeded() {
    if (this.autoScroll) {
      this.startAutoRefreshing();
    } else {
      this.stopAutoRefreshing();
    }
  }

  created() {
    this.autoScrollIfNeeded();
  }

  mounted() {
    this.onChangeLayoutWidth();
    window.addEventListener('resize', this.onChangeLayoutWidth);
  }

  destroyed() {
    this.stopAutoRefreshing();
  }
}
