import { Vue, Options, prop } from 'vue-class-component';
import Debounce from '../../../../decorators/src/debounce';
const CN = 'resize-observer';

class Props {
  onResize = prop<() => void>({ default: () => () => void 0 });
  onWidthChanged = prop<() => void>({ default: () => () => void 0 });
  onHeightChanged = prop<() => void>({ default: () => () => void 0 });
}

@Options({
  name: 'resize-observer-component',
  emits: ['notify'],
})
export default class ResizeObserverComponent extends Vue.with(Props) {
  protected observer: Nullable<ResizeObserver> = null;

  protected width = 0;
  protected height = 0;

  mounted() {
    if ('ResizeObserver' in window) {
      this.observer = new window.ResizeObserver(this.listener);
      this.observer.observe(this.$el);
    }
  }

  beforeUnmount() {
    this.observer?.disconnect();
  }

  render() {
    return <div class={CN}>{this.$slots.default?.()}</div>;
  }

  @Debounce(100)
  private listener(entries: ResizeObserverEntry[]) {
    const entry = entries.find((e) => e.target === this.$el);
    if (!entry) {
      return;
    }
    this.onResize();
    if (this.width !== entry.contentRect.width) {
      this.onWidthChanged();
    }
    if (this.height !== entry.contentRect.height) {
      this.onHeightChanged();
    }
    this.width = entry.contentRect.width;
    this.height = entry.contentRect.height;
  }
}
