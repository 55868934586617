const loader = () => import('sanitize-html').then((m) => m.default);

let transformer = (html: string) => {
  return html;
};

loader().then((san: (txt: string) => string) => {
  transformer = san;
});

export default function sanitize(html: string) {
  return transformer(html);
}
