import { computed, defineComponent, PropType } from 'vue';

import DisablingRouterLink from '@/components/common/disabling-router-link/disabling-router-link';
import { MobileMenuItemInfo } from '@/types/MobileMenu';

import './mobile-menu-item.scss';

export const CN = 'mobile-menu-item';

export default defineComponent({
  props: {
    info: { type: Object as PropType<MobileMenuItemInfo>, required: true },
    onMore: { type: Function as PropType<(info: MobileMenuItemInfo) => void>, required: true },
  },
  setup(props) {
    const hasCounts = computed(() => typeof props.info.count === 'number' && props.info.count > 0);

    const onMore = () => {
      props.onMore(props.info);
    };

    return () => {
      return (
        <li key={props.info.label} class={`${CN}`} onClick={onMore}>
          <DisablingRouterLink class={`${CN}__link`}>
            <span class={`${CN}__label`}>{props.info.label}</span>
            <span class={`${CN}__counter`} v-show={hasCounts.value}>
              {props.info.count}
            </span>
          </DisablingRouterLink>

          <r-button
            class={`${CN}__more`}
            color="black"
            icon="arrow-right"
            mode="frameless"
            v-show={props.info.children}
          />
        </li>
      );
    };
  },
});
