import { Message } from '../types/Message';

const UNAUTHORIZED_CODE = 401;

export function messageFromError(e: Record<string, any>): Message {
  if (e.status === UNAUTHORIZED_CODE) {
    return {
      text: 'There is an error in the user name, email address or password you entered',
      type: 'error',
    };
  }

  return {
    text: 'Sorry, something went wrong: please try again later',
    type: 'error',
  };
}
