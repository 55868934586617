/* eslint-disable */
import { Throttle } from '@ravnur/decorators';
import { Options, Vue } from 'vue-class-component';
import ResizeObserverComponent from '../../components/resize-observer/resize-observer';

import Z from './dom/Z';
import isUglyScroll from '@ravnur/helpers/featured.detect/uglyScroll';

import baron from './baron';

const THROTTLE_TIMEOUT = 100;
const ZERO = 0;

const OPTIONS = {
  scroller: '.scrollable__container',
  bar: '.scrollable__bar',
  track: '.scrollable__track',
  barOnCls: 'scrollable--active',
  cssGuru: true,
  direction: 'v',

  // Local copy of jQuery-like utility
  $: Z,

  // Event manager
  event: handleListener,
};

@Options({
  emits: ['scroll'],
  components: { ResizeObserverComponent },
})
export default class Scrollable extends Vue {
  baronInstance = null;
  force = false;

  get useNativeScroll() {
    return !isUglyScroll;
  }

  getClientHeight() {
    const container = this.$refs.container;
    if (container && container instanceof HTMLElement) {
      return this.$el.clientHeight;
    }
    return ZERO;
  }

  getScrollHeight() {
    const container = this.$refs.container;
    if (container && container instanceof HTMLElement) {
      return Math.max(container.scrollHeight, container.clientHeight);
    }
    return ZERO;
  }

  getScrollTop() {
    const container = this.$refs.container;
    if (container && container instanceof HTMLElement) {
      return container.scrollTop;
    }
    return ZERO;
  }

  setScrollTop(st) {
    const container = this.$refs.container;
    if (container && container instanceof HTMLElement) {
      container.scrollTop = st;
    }
  }

  control() {
    const container = this.$refs.container;
    if (container) {
      const clientHeight = this.getClientHeight();
      const innerHeight = this.getScrollHeight();

      const height = innerHeight > clientHeight ? `${clientHeight}px` : '';
      this.$el.style.height = height;
      this.force = !!height;

      this.$nextTick(() => this.baronInstance.update());
    }
  }

  checkAfterRefreshed() {
    if (isUglyScroll) {
      this.$nextTick(this.control);
    }
  }

  @Throttle(THROTTLE_TIMEOUT)
  onScroll() {
    this.$emit('scroll');
  }

  mounted() {
    if (isUglyScroll) {
      this.baronInstance = baron({ root: this.$el, ...OPTIONS });
      this.checkAfterRefreshed();

      this.$nextTick(this.checkAfterRefreshed);
    }
  }
}

function handleListener(elem, event, func, mode) {
  event.split(' ').forEach((event) => {
    if (mode === 'on') {
      elem.addEventListener(event, func);
    } else {
      elem.removeEventListener(event, func);
    }
  });
}
