import { $Props } from '@ravnur/core/typings/tsx';
import { Options, Vue, prop } from 'vue-class-component';

import { LabeledComponentDescription } from '../../types/LabeledComponentDescription';
import AuthContainersSwitcher from '../auth-containers-switcher/auth-containers-switcher';

import './auth-tabs.scss';

const CN = 'auth-tabs';

class Props {
  tabs!: LabeledComponentDescription[];
  forceShow = prop({ default: false });
}

type Emits = {
  onMessage: (message: null) => void;
};

@Options({
  name: 'auth-tabs',
  emits: ['message'],
  watch: {
    index: {
      handler(this: AuthTabs) {
        this.handleTabChanged();
      },
    },
  },
})
export default class AuthTabs extends Vue.with(Props) {
  declare $props: $Props<Props, Emits>;
  private index = 0;

  private get isSelectable() {
    return this.tabs.length > 1 || this.forceShow;
  }

  private get keys() {
    return this.tabs.map(({ key }) => key);
  }

  protected handleTabChanged() {
    this.$emit('message', null);
  }

  render() {
    return (
      <div class={CN}>
        {this.isSelectable ? this.renderTabsSelector() : null}
        <AuthContainersSwitcher
          index={this.index}
          keys={this.keys}
          v-slots={this.$slots}
          onInput={this.setIndex}
        />
      </div>
    );
  }

  private renderTabsSelector() {
    const { tabs } = this;
    return <ul class={`${CN}__selector`}>{tabs.map(this.renderTabSelector)}</ul>;
  }

  private renderTabSelector(desc: LabeledComponentDescription, idx: number) {
    const isCurrent = idx === this.index;
    const color = isCurrent ? 'black' : 'grey';
    const cn = {
      [`${CN}__tab`]: true,
      [`${CN}__tab--current`]: isCurrent,
    };
    return (
      <li key={desc.key} class={cn}>
        <r-button color={color} mode="frameless" onclick={() => (this.index = idx)}>
          <l10n group="login" tkey={desc.label} />
        </r-button>
      </li>
    );
  }

  private setIndex(index: number) {
    this.index = index;
  }
}
