import moment from 'moment';

export default function time(t: number, isForcingApperarHours = false): string {
  const template = isForcingApperarHours ? 'HH:mm:ss' : 'mm:ss';
  return convert(t, template, 'seconds');
}

export function convert(
  t: number,
  format = 'HH:mm:ss',
  precision: moment.unitOfTime.DurationConstructor = 'milliseconds'
): string {
  // eslint-disable-next-line import/no-named-as-default-member
  return (moment.duration(t, precision) as any).format(format, { trim: false });
}
