import { Prop } from '@ravnur/decorators';
import { Vue } from 'vue-class-component';

const HUNDRED_PERCENT = 100;
const MAX_RATING = 5;
const DEFAULT_RATING = 0;
const INCREMENT = 0.1;
const ONE = 1.0;
const INVARIANT_INCREMENT = ONE / INCREMENT;
const NUMBERS_AFTER_POINT = 1;

export default class Rating extends Vue {
  @Prop({ type: Number, default: () => DEFAULT_RATING }) rating: number;
  @Prop({ type: String, default: () => 'md' }) size: string;

  hoveredRating: Nullable<number> = null;

  get formattedRating(): string {
    return this.rating.toFixed(NUMBERS_AFTER_POINT);
  }

  get currentRating(): number {
    return this.hoveredRating || this.rating;
  }

  get fillLevel(): Array<number> {
    const result = [];
    const rating = Math.min(
      MAX_RATING,
      Math.ceil(this.currentRating * INVARIANT_INCREMENT) / INVARIANT_INCREMENT
    );
    for (let i = 0; i < MAX_RATING; i++) {
      let level = 0;
      if (rating > i) {
        level = Math.min((rating - i) * HUNDRED_PERCENT, HUNDRED_PERCENT);
      }
      result.push(Math.round(level));
    }
    return result;
  }

  selected() {
    if (!this.hoveredRating) {
      return;
    }

    this.$emit('rating-selected', this.hoveredRating);
  }

  mouseMove(starIndex: number) {
    this.hoveredRating = Math.min(MAX_RATING, starIndex + ONE);
  }

  mouseLeave() {
    this.hoveredRating = null;
  }
}
