import { $Props } from '@ravnur/core/typings/tsx';
import { Options, Vue } from 'vue-class-component';

import { AuthRepository } from '../../types/AuthService';
import { LabeledComponentDescription } from '../../types/LabeledComponentDescription';
import { Message } from '../../types/Message';
import AuthTabs from '../auth-tabs/auth-tabs';
import RegistrationForm from '../registration-form/registration-form';
import WebLoginForm from '../web-login-form/web-login-form';

class Props<T> {
  repository: AuthRepository<T>;
  isRegistrationAvailable: boolean;
}

type Emits<T> = {
  onAuth: (data: T) => void;
  onForgot: () => void;
  onMessage: (message: Message) => void;
};

const CN = 'web-login-tabs';
const FORM_KEY = 'form';
const REGISTER_KEY = 'register';

@Options({
  name: 'web-login-tabs',
  emits: ['auth', 'forgot', 'message'],
})
export default class WebLoginTabs<T> extends Vue.with(Props) {
  declare $props: $Props<Props<T>, Emits<T>>;

  private get tabs() {
    const { isRegistrationAvailable } = this;
    const tabs: LabeledComponentDescription[] = [{ key: FORM_KEY, label: 'webSignInTab' }];
    if (isRegistrationAvailable) {
      tabs.push({ key: REGISTER_KEY, label: 'registerTab' });
    }
    return tabs;
  }

  render() {
    const slots = {
      [FORM_KEY]: this.renderLoginForm,
      [REGISTER_KEY]: this.renderRegistrationForm,
    };
    return <AuthTabs class={CN} tabs={this.tabs} v-slots={slots} onMessage={this.handleMessage} />;
  }

  private renderRegistrationForm() {
    return <RegistrationForm repository={this.repository} onMessage={this.handleMessage} />;
  }

  private renderLoginForm() {
    const slots = {
      actions: this.renderForgotButton,
    };
    return (
      <WebLoginForm
        key="login"
        repository={this.repository}
        v-slots={slots}
        onAuth={this.handleAuthEvent}
        onMessage={this.handleMessage}
      />
    );
  }

  private renderForgotButton() {
    return (
      <r-button color="primary" mode="borderless" onclick={this.toForgotPassword}>
        <l10n group="login" tkey="forgotPassword" />
      </r-button>
    );
  }

  private handleMessage(message: Nullable<Message>) {
    this.$emit('message', message);
  }

  private handleAuthEvent(data: unknown) {
    this.$emit('auth', data);
  }

  private toForgotPassword() {
    this.$emit('forgot');
  }
}
