import Opener, { OpenerSlotProps } from '@ravnur/core/components/opener/opener';
import { Inject, Watch } from '@ravnur/decorators';
import $t from '@ravnur/l10n/$t';
import { Vue } from 'vue-class-component';

import './user-menu.scss';
import { ApplicationNames } from '@ravnur/shared/types/Application';

const CN = 'user-menu';

export default class ApplicationsMenu extends Vue {
  declare $refs: {
    opener: Opener;
  };

  @Inject('current-user') private user: User$Details;
  @Inject('application-name') private applicationName: ApplicationNames;
  @Inject('is-admin') private isAdmin: boolean;
  @Inject('logout') private emitLogoutEvent: () => void;
  @Inject('to-my-account') private toMyAccount: () => void;
  @Inject('to-admin-panel') private toAdminPanel: () => void;

  private get slots() {
    return {
      summary: this.renderDashboardTitle,
      details: this.renderDashboardOptions,
    };
  }

  @Watch('$route.query')
  @Watch('$route.name')
  protected handleRouteChanged() {
    this.$refs.opener.hide();
  }

  render() {
    return <Opener ref="opener" class={CN} v-slots={this.slots} />;
  }

  private renderDashboardTitle({ toggle, visible }: OpenerSlotProps) {
    const { user } = this;

    return (
      <div class={[`${CN}__dropdown`, visible ? 'active' : '']} tabindex="0" onClick={toggle}>
        <div class={`${CN}__dropdown-title`}>{$t('header', 'user-menu__my-account')}</div>
        <avatar class={`${CN}__avatar`} title={user.displayName} user={user} />
      </div>
    );
  }

  private renderDashboardOptions() {
    return (
      <paper class={`${CN}__dropdown-menu`}>
        {this.applicationName !== ApplicationNames.MY_ACCOUNT && (
          <div class={`${CN}__dropdown-menu-item`} onClick={this.toMyAccount}>
            <l10n group="header" tkey="user-menu__my-account" />
          </div>
        )}
        {this.isAdmin && (
          <div class={`${CN}__dropdown-menu-item`} onClick={this.toAdminPanel}>
            <l10n group="header" tkey="user-menu__admin-portal" />
          </div>
        )}
        <div class={`${CN}__dropdown-menu-item`} onClick={this.logout}>
          <l10n group="header" tkey="user-menu__sign-out" />
        </div>
      </paper>
    );
  }

  private logout(e: Event) {
    e.preventDefault();
    this.emitLogoutEvent();
  }
}
