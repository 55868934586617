import { CurriedFunction1, CurriedFunction2 } from './curry2';
import curry3 from './curry3';

type Reducer<T, R> = (memo: R, item: T, idx?: number, arr?: T[]) => R;

interface ReducerFnc {
  <T, R>(iteratees: Reducer<T, R>): CurriedFunction2<T[], R, R>;
  <T, R>(iteratees: Reducer<T, R>, arr: T[]): CurriedFunction1<R, R>;
  <T, R>(iteratees: Reducer<T, R>, arr: T[], initial: R): R;
}

const reduce: ReducerFnc = curry3(_reduce);
export default reduce;

function _reduce<T, R>(iteratees: Reducer<T, R>, arr: T[], initial: R): R {
  return arr.reduce(iteratees, initial);
}
