import { Prop as PropOptions } from '@vue/runtime-core';
import { createDecorator, Vue } from 'vue-class-component';

/**
 * decorator of a prop
 * @param  options the options for the prop
 * @return PropertyDecorator | void
 */
export default function Prop<T, D>(options: PropOptions<T, D>) {
  return (target: Vue<unknown>, key: string): void => {
    createDecorator((componentOptions, k) => {
      componentOptions.props = componentOptions.props || {};
      componentOptions.props[k] = options;
    })(target, key);
  };
}
