import { $Props } from '@ravnur/core/typings/tsx';
import { renderSlot, isVNode } from 'vue';
import { Vue, Options } from 'vue-class-component';

class Props {
  keys!: string[];
  index!: number;
}

type Emits = {
  onInput: (idx: number) => void;
  onSuccess: () => void;
};

@Options({
  name: 'auth-container-switcher',
  emits: ['input', 'success'],
  watch: {
    keys: {
      immediate: true,
      handler(this: AuthContainersSwitcher) {
        this.goto(0);
      },
    },
  },
})
export default class AuthContainersSwitcher extends Vue.with(Props) {
  declare $props: $Props<Props, Emits>;

  private get current() {
    return this.keys[this.index];
  }

  render() {
    const { current, $slots } = this;
    const slot = renderSlot($slots, current);
    if (slot.children instanceof Array) {
      slot.children.forEach((node) => {
        if (isVNode(node)) {
          node.props = node.props || {};
          node.props.onSuccess = this.toNextComponent;
        }
      });
    }
    return slot;
  }

  private toNextComponent() {
    if (this.index === Math.max(this.keys.length - 1, 0)) {
      this.$emit('success');
    } else {
      this.goto(this.index + 1);
    }
  }

  private goto(idx: number) {
    this.$emit('input', idx);
  }
}
