import { Vue } from 'vue-class-component';

import { $Props } from '../../typings/tsx';
import './overlay.scss';

const CN = 'overlay';

class Props {
  enable!: boolean;
}

export type Overlay$Props = $Props<Props>;

export default class Overlay extends Vue.with(Props) {
  render() {
    const cn = {
      [CN]: true,
      [`${CN}--active`]: this.enable,
    };
    return (
      <div class={cn} {...this.$attrs}>
        {this.$slots.default?.()}
      </div>
    );
  }
}
