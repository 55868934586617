import Tooltip from '@ravnur/core/components/tooltip/tooltip';
import { Prop } from '@ravnur/decorators';
import { Vue, Options } from 'vue-class-component';

import { DefaultTagType } from '../../types/DefaultTagType';
import TagComponent from './tag';

import './tags.scss';

const from = 0;

export interface Tags$Props {
  value: DefaultTagType[];
  variant?: string;
  closable?: boolean | Predicate<Entity>;
  size?: string;
  limit?: number;
  duplicate?: Nullable<DefaultTagType>;

  onRemove?: (tag: DefaultTagType) => void;
}

@Options({
  emits: ['input', 'remove', 'update:value', 'click'],
  components: {
    Tooltip,
  },
})
export default class Tags extends Vue {
  @Prop({ type: Array, required: true }) private value!: DefaultTagType[];
  @Prop({ type: Array, default: [] }) private newTags!: { name: string }[];
  @Prop({ type: String, default: () => 'default' }) private variant!: string;
  @Prop({ default: () => false }) private closable!: boolean | Predicate<Entity>;
  @Prop({ type: String, default: () => 'lg' }) private size!: string;
  @Prop({ type: Number, default: Number.MAX_VALUE }) private limit!: number;
  @Prop({ type: Object, default: () => null }) private duplicate: Nullable<DefaultTagType>;
  @Prop({ type: String, default: 'name' }) private keyForLabel: Nullable<DefaultTagType>;

  private get filtered(): DefaultTagType[] {
    return this.value?.slice(from, this.limit) || [];
  }

  private get hasMore(): boolean {
    return this.limit < this.value.length;
  }

  private get hiddenCount(): number {
    return this.value.length - this.limit;
  }

  private remove(tag: DefaultTagType): void {
    this.$emit('remove', tag);
    this.emitUpdate(this.value.filter((t) => t !== tag));
  }

  private emitUpdate(tags: DefaultTagType[]) {
    this.$emit('input', tags);
    this.$emit('update:value', tags);
  }

  private isHighlighted(tag: DefaultTagType) {
    const { duplicate } = this;
    if (!duplicate || !tag) {
      return false;
    }

    if (typeof duplicate === 'string' || typeof tag === 'string') {
      return this.duplicate === tag;
    }

    return duplicate.id === tag.id;
  }

  isClosable(tag: DefaultTagType): boolean {
    const { closable } = this;

    if (typeof closable === 'boolean') {
      return closable;
    }

    if (typeof tag === 'object') {
      return closable(tag) || this.newTags.some((t) => t.name === tag.label);
    }

    return true;
  }

  render() {
    return (
      <ul class="tags" data-testid="tags">
        {this.filtered.map(this.renderTag)}
        {this.renderMoreButton()}
      </ul>
    );
  }

  private renderMoreButton() {
    if (this.hasMore) {
      return (
        <li class="tags__item">
          <l10n
            class="tags__more"
            group="common"
            params={{ count: this.hiddenCount }}
            tkey="tags__more"
          />
        </li>
      );
    }
  }

  private renderTag(tag: DefaultTagType) {
    const isDuplicated = this.isHighlighted(tag);
    let toolTipText = '';

    if (typeof tag === 'object' && tag.tooltip) {
      toolTipText = tag.tooltip;
    }

    return (
      <li class="tags__item" onClick={() => this.$emit('click', tag)}>
        <Tooltip text={toolTipText}>
          <TagComponent
            closable={this.isClosable(tag)}
            data-testid={isDuplicated ? 'duplicated-tag' : ''}
            highlighted={isDuplicated}
            info={tag}
            size={this.size}
            variant={this.variant}
            onClose={() => this.remove(tag)}
          />
        </Tooltip>
      </li>
    );
  }
}
