import Storage from '@ravnur/helpers/storage';
import axios from 'axios';

import generate from './helpers/url.generator';
import { FAILED, IN_PROGRESS, SUCCESS } from './statuses';
import toFrontendFormat from './transformers/toFrontendFormat';

const OPERATION_HEARTBEAT_TIMEOUT = 200;

export default async function wait<D extends Record<string, unknown>>(
  operation: Operation<D>
): Promise<Operation<D>> {
  return _process(operation);
}

function _path(operation: Operation<Entity>): string {
  return generate(`/operation/${operation.operationId || ''}`);
}

function waiter<D extends Record<string, unknown>>(path: string): Promise<Operation<D>> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const headers = { Authorization: Storage.get<string>('guard', 'auth') };

    const resp = await axios.get(path, { headers });

    if (resp.status === 200) {
      const d = resp.data;
      if (!(d instanceof Object)) {
        reject(new Error('Incorrect server response'));
        return;
      }
      const answer = toFrontendFormat(d) as Operation<D>;
      try {
        const od = await _process(answer);
        resolve(od);
      } catch (e) {
        reject(e);
      }
    } else {
      reject(new Error('Network'));
    }
  });
}

function _process<D extends Record<string, unknown>>(answer: Operation<D>): Promise<Operation<D>> {
  return new Promise((resolve, reject) => {
    if (answer.state === IN_PROGRESS) {
      setTimeout(async () => {
        try {
          const path = _path(answer as any);
          const resp = await waiter(path);
          const data = { ...answer.data, ...resp.data };
          resolve({ ...resp, data } as Operation<D>);
        } catch (e) {
          reject(e);
        }
      }, OPERATION_HEARTBEAT_TIMEOUT);
    } else if (answer.state === SUCCESS) {
      resolve(answer);
    } else if (answer.state === FAILED) {
      reject(answer);
    }
  });
}
