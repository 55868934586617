import axios, { AxiosRequestConfig } from 'axios';

import generate from './helpers/url.generator';

class HttpService {
  public get<T>(path: string, options: AxiosRequestConfig = {}): Promise<T> {
    path = generate(path);
    return axios.get(path, options).then((r) => r.data);
  }

  public delete<T>(path: string, options: AxiosRequestConfig = {}): Promise<T> {
    path = generate(path);
    return axios.delete(path, options).then((r) => r.data);
  }

  public post<T, E>(path: string, body: E | E[], options: AxiosRequestConfig = {}): Promise<T> {
    path = generate(path);
    return axios.post(path, body, options).then((r) => r.data);
  }

  public put<T, E>(path: string, body: E, options: AxiosRequestConfig = {}): Promise<T> {
    path = generate(path);
    return axios.put(path, body, options).then((r) => r.data);
  }
}

export default new HttpService();
