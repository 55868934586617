import MockAdapter from 'axios-mock-adapter/types';
import toBackendFormat from '../transformers/http/toBackendFormat';
import { faker } from '@faker-js/faker';
import { _generateMedia, _generateMockList } from './helper-fakers';
import { Media$Details } from '@/types/Media';

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/search').reply(_get);
}

function _get() {
  const total = +faker.random.numeric(2);
  const data = _generateMockList<Media$Details>(total, _generateMedia);

  return [200, toBackendFormat({ items: data, total })];
}
