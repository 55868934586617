import { defineComponent } from 'vue';

import './hint-icon.scss';

const CN = 'hint-icon';

export default defineComponent({
  props: {
    hint: { type: String, default: '' },
  },
  setup(props) {
    return () => {
      if (props.hint) {
        return <icon aria-title={props.hint} class={CN} title={props.hint} type="info" />;
      }
    };
  },
});
