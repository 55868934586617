import http from './http';
import wait from './operation';

type EntityIdentifier = Partial<Entity> | string | null | Record<string, unknown>;

interface Prefix {
  path: string;
  entity: EntityIdentifier;
}

interface PathOptions {
  action?: string;
  prefixes?: Prefix[];
}

export class CrudAPI<T extends Partial<Entity>> {
  constructor(private path: string, private pathOptions: PathOptions = {}) {}

  public load<R = T[]>(params: Record<string, unknown> = {}): Promise<R> {
    const path = this._preparePathForEntity(null);
    return http.get(path, { params });
  }

  public get(id: string, params: Record<string, unknown> = {}): Promise<T> {
    const path = this._preparePathForEntity(id, true);
    return http.get(path, { params });
  }

  public async save(entity: Partial<T>, forceReload = false): Promise<T> {
    const path = this._preparePathForEntity(entity);

    const promise: Promise<Operation> = entity.id
      ? http.put(path, entity)
      : http.post(path, entity);

    const op: Operation = await promise;

    try {
      const operation: Operation = await wait(op);
      if (forceReload && operation.entityId) {
        return this.get(operation.entityId);
      }
      return { ...entity, id: operation.entityId } as T;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async remove(entity: T | string): Promise<any> {
    const id = typeof entity === 'string' ? entity : entity.id;

    if (!id) {
      return Promise.resolve({});
    }

    const path = this._preparePathForEntity(entity);
    const operation: Operation = await http.delete(path);

    return wait(operation);
  }

  public async undo(entity: T): Promise<any> {
    const path = this._preparePathForEntity(entity);
    const operation: Operation = await http.delete(path);

    try {
      await wait(operation);
      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async hold(entity: Partial<T>): Promise<any> {
    const path = this._preparePathForEntity(entity);
    const operation: Operation = await http.post(path, entity);
    try {
      const op = await wait(operation);
      return op.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async postCollection(collection: any[]): Promise<any> {
    const path = this._preparePathForEntity(null);
    const operation: Operation = await http.post(path, collection);
    return wait(operation);
  }

  public async removeCollection(collection: any[]): Promise<any> {
    const path = this._preparePathForEntity(null);

    const ids = collection.map((e) => e.id);
    const operation: Operation = await http.delete(path, { params: { ids } });

    return wait(operation);
  }

  private _preparePathForEntity(
    entity: Partial<Entity> | string | null,
    withoutAction = false
  ): string {
    const { prefixes, action } = this.pathOptions;

    const pathes = (prefixes || []).concat({ path: this.path, entity });
    const path = _pathFromPrefixes(pathes);

    if (action && !withoutAction) {
      return `${path}/${action}`;
    }
    return path;
  }
}

function _pathFromPrefixes(prefixes: Prefix[]) {
  return prefixes.reduce((memo, prefix) => {
    if (prefix.path) {
      memo += prefix.path;
    }

    const entity = prefix.entity;
    if (entity) {
      if (typeof entity === 'string') {
        memo += `/${entity}`;
      } else {
        const id = (entity as Entity).id;
        if (id) {
          memo += `/${id}`;
        }
      }
    }
    return memo;
  }, '');
}
