import registryCoreKit from '@ravnur/core/ui-kit';
import TextField from '@ravnur/core/ui-kit/text-field/text-field';
import Alert from '@ravnur/shared/components/alert/alert';
import Tag from '@ravnur/shared/components/tags/tag';
import Tags from '@ravnur/shared/components/tags/tags';
import ListCard from '@ravnur/shared/components/list-card/list-card';
import Lookup from '@ravnur/shared/components/lookup/lookup';
import { App } from 'vue';

import Accordion from './accordion/accordion';
import ActionButton from './action-button/action-button';
import Carousel from './carousel/carousel';
import Ellipsis from './ellipsis/ellipsis';
import GridCard from './grid-card/grid-card';
import Info from './info/info';
import Messages from './messages/messages';
import NamedBlock from './named-block/named-block';
import Paper from './paper/paper';
import Placeholder from './placeholder/placeholder';
import Rating from './rating/rating.vue';
import SearchInput from '@ravnur/shared/components/search-input/search-input';
import StatsButton from './stats-btn/stats-btn';
import Stats from './stats/stats';
import Thumbnail from './thumbnail/thumbnail';

export default function registry(app: App) {
  registryCoreKit(app);
  app.component('accordion', Accordion);
  app.component('action-button', ActionButton);
  app.component('portal-alert', Alert);
  app.component('carousel', Carousel);
  app.component('ellipsis', Ellipsis);
  app.component('grid-card', GridCard);
  app.component('list-card', ListCard);
  app.component('lookup', Lookup);
  app.component('messages', Messages);
  app.component('paper', Paper);
  app.component('placeholder', Placeholder);
  app.component('info', Info);
  app.component('rating', Rating);
  app.component('search-input', SearchInput);
  app.component('stats', Stats);
  app.component('stats-btn', StatsButton);
  app.component('tag', Tag);
  app.component('tags', Tags);
  app.component('thumbnail', Thumbnail);
  app.component('named-block', NamedBlock);
  app.component('text-field', TextField);
}
