import { createDecorator } from 'vue-class-component';
// import { InjectKey } from 'vue';

type InjectKey = string | symbol;
export type InjectOptions = { from?: InjectKey; default?: any };

export default function Inject(options?: InjectOptions | InjectKey) {
  return createDecorator((componentOptions: Record<string, any>, key) => {
    if (typeof componentOptions.inject === 'undefined') {
      componentOptions.inject = {};
    }
    if (!Array.isArray(componentOptions.inject)) {
      componentOptions.inject[key] = options || key;
    }
  });
}
