/* eslint-disable */

import each from 'lodash/each';
import isString from 'lodash/isString';
import first from 'lodash/first';
import camelCase from 'lodash/camelCase';

import _addClasses from './addClasses';
import _removeClass from './removeClasses';
import _containsClass from './containsClass';

const addClasses = _functionalify(_addClasses);
const removeClasses = _functionalify(_removeClass);
const containsClass = _functionalify(_containsClass);

function Z(elements) {
  [...elements].forEach((e) => this.push(e));
}

Z.prototype = [];

Z.prototype.addClass = function addClass(className) {
  this.forEach(addClasses(className));
  return this;
};

Z.prototype.removeClass = function removeClass(className) {
  this.forEach(removeClasses(className));
  return this;
};

Z.prototype.hasClass = function hasClass(className) {
  return this.some(containsClass(className));
};

Z.prototype.css = function css(props, value) {
  if (isString(props) && !value) {
    const el = first(this);
    const styled = window.getComputedStyle(el);
    return styled[camelCase(props)];
  }

  if (typeof props === 'string') {
    props = {
      [props]: value,
    };
  }

  this.forEach((el) => {
    each(props, (val, prop) => {
      el.style[camelCase(prop)] = val;
    });
  });

  return this;
};

export default function $wrapper(selector, context) {
  if (typeof selector === 'string') {
    context = context || window.document;
    const elems = context.querySelectorAll(selector);
    return new Z([...elems]);
  }
  if (selector instanceof HTMLElement) {
    const res = new Z([selector]);
    return res;
  }
  if (selector instanceof Array) {
    return new Z(selector);
  }
  return new Z([]);
}

function _functionalify(fnc) {
  return (second) => (el) => fnc(el, second);
}
