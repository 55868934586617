import { register } from '@ravnur/core/ui-kit/icon/icon-storage';

register('input', {
  width: 24,
  height: 24,
  paths: [
    {
      d: 'M21 3.01H3c-1.1 0-2 .9-2 2V9h2V4.99h18v14.03H3V15H1v4.01c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88 2-1.98v-14c0-1.11-.9-2-2-2zM11 16l4-4-4-4v3H1v2h10v3z',
    },
  ],
});

register('ms', {
  width: 21,
  height: 21,
  rects: [
    { x: '1', y: '1', width: '9', height: '9', fill: '#f25022' },
    { x: '1', y: '11', width: '9', height: '9', fill: '#00a4ef' },
    { x: '11', y: '1', width: '9', height: '9', fill: '#7fba00' },
    { x: '11', y: '11', width: '9', height: '9', fill: '#ffb900' },
  ],
});

register('google', {
  width: 512,
  height: 512,
  paths: [
    {
      style: 'fill:#FBBB00;',
      d: 'M113.47,309.408L95.648,375.94l-65.139,1.378C11.042,341.211,0,299.9,0,256 c0-42.451,10.324-82.483,28.624-117.732h0.014l57.992,10.632l25.404,57.644c-5.317,15.501-8.215,32.141-8.215,49.456 C103.821,274.792,107.225,292.797,113.47,309.408z',
    },
    {
      style: 'fill:#518EF8;',
      d: 'M507.527,208.176C510.467,223.662,512,239.655,512,256c0,18.328-1.927,36.206-5.598,53.451 c-12.462,58.683-45.025,109.925-90.134,146.187l-0.014-0.014l-73.044-3.727l-10.338-64.535 c29.932-17.554,53.324-45.025,65.646-77.911h-136.89V208.176h138.887L507.527,208.176L507.527,208.176z',
    },
    {
      style: 'fill:#28B446;',
      d: 'M416.253,455.624l0.014,0.014C372.396,490.901,316.666,512,256,512 c-97.491,0-182.252-54.491-225.491-134.681l82.961-67.91c21.619,57.698,77.278,98.771,142.53,98.771 c28.047,0,54.323-7.582,76.87-20.818L416.253,455.624z',
    },
    {
      style: 'fill:#F14336;',
      d: 'M419.404,58.936l-82.933,67.896c-23.335-14.586-50.919-23.012-80.471-23.012 c-66.729,0-123.429,42.957-143.965,102.724l-83.397-68.276h-0.014C71.23,56.123,157.06,0,256,0 C318.115,0,375.068,22.126,419.404,58.936z',
    },
  ],
});

register('arrow-down', {
  width: 24,
  height: 24,
  paths: [
    {
      d: 'M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z',
    },
  ],
});

register('arrow-right', {
  width: 24,
  height: 24,
  paths: [
    {
      d: 'M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z',
    },
  ],
});

register('visibility-off', {
  width: 24,
  height: 24,
  paths: [
    {
      d: 'M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z',
    },
  ],
});
