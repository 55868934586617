import moment, { Moment, weekdaysShort } from 'moment';
import { withModifiers } from 'vue';
import { Vue, prop, Options } from 'vue-class-component';

import { $Props } from '../../typings/tsx';

import './date-holder.scss';

const CN = 'date-holder';

const WEEKS_COUNT = 6;
const DAYS_COUNT = 7;
const DATE_FORMAT = 'YYYY-MM-DD';

class Props {
  value = prop<Nullable<Date | string>>({
    type: [Date, String],
    default: null,
  });
  maxDate = prop<Date | string | null>({ type: [Date, String], default: null });
  minDate = prop<Date | string | null>({ type: [Date, String], default: null });
  highlightChecker = prop<Predicate<Date>>({ default: () => () => false });
  range = prop<[Nullable<Date>, Nullable<Date>]>({ default: () => [null, null] });
  markToday = prop<boolean>({ default: true });
}

type Emits = {
  onInput: (val: Date) => void;
  onDouble: (val: Date) => void;
};

export type DateHolder$Props = $Props<Props, Emits, 'value'>;

@Options({
  watch: {
    value: {
      immediate: true,
      handler(this: DateHolder) {
        this.onChangedValue();
      },
    },
    currentMonth: {
      immediate: true,
      handler(this: DateHolder) {
        this.refreshMatrix();
      },
    },
    highlightRange: {
      immediate: true,
      handler(this: DateHolder) {
        this.refreshMatrix();
      },
    },
  },
  emits: ['input', 'update:value'],
})
export default class DateHolder extends Vue.with(Props) {
  private today: Moment = moment();
  private days: string[] = weekdaysShort().map((d) => d.charAt(0));
  private matrix: Moment[][] = [];
  private base: Nullable<Moment> = null;
  private currentMonth: Date | string = new Date();

  private get cm(): Moment {
    return moment(this.currentMonth).date(1);
  }

  private get ct(): Nullable<Moment> {
    return this.value ? moment(this.value) : null;
  }

  private get title(): string {
    return this.cm.format('MMM YYYY');
  }

  private get min(): Nullable<Moment> {
    const { minDate } = this;
    if (!minDate) {
      return null;
    }

    if (typeof minDate === 'string') {
      return moment(minDate, DATE_FORMAT);
    }
    return moment(minDate);
  }

  private get max(): Nullable<Moment> {
    const { maxDate } = this;
    if (!maxDate) {
      return null;
    }

    if (typeof maxDate === 'string') {
      return moment(maxDate, DATE_FORMAT);
    }
    return moment(maxDate);
  }

  protected onChangedValue() {
    this.currentMonth = this.value || new Date();
  }

  protected refreshMatrix() {
    const first = moment(this.currentMonth).date(1).startOf('day');
    this.base = moment(first).day(0);
    const detector = _detector(this.base);
    this.matrix = _times(WEEKS_COUNT, (w: number) => _times(DAYS_COUNT, detector(w)));
  }

  render() {
    const { title, days, matrix } = this;
    return (
      <div class={CN}>
        <div class={`${CN}__monthes`}>
          <r-button
            aria-label="Previous month"
            class={[`${CN}__month-btn`, `${CN}__month-btn--prev`]}
            color="black"
            icon="arrow-left"
            mode="borderless"
            onclick={this.prevMonth}
          />

          {title}

          <r-button
            aria-label="Next month"
            class={[`${CN}__month-btn`, `${CN}__month-btn--next`]}
            color="black"
            icon="arrow-right"
            mode="borderless"
            onclick={this.nextMonth}
          />
        </div>

        <table class={`${CN}__calendar`}>
          <thead>
            <tr>
              {days.map((d) => (
                <th key={d} class={`${CN}__header`}>
                  {d}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{matrix.map(this.renderWeek)}</tbody>
        </table>

        {this.$slots.footer?.()}
      </div>
    );
  }

  private renderWeek(week: Moment[], idx: number) {
    return <tr key={idx}>{week.map(this.renderDay)}</tr>;
  }

  private renderDay(d: Moment) {
    const cn = {
      [`${CN}__day-btn`]: true,
      [`${CN}__day-btn--today`]: this.isToday(d) && this.markToday,
      [`${CN}__day-btn--selected`]: this.isSelected(d) || this.isLastInRange(d),
      [`${CN}__day-btn--inactive`]: this.isPrevMonth(d) || this.isNextMonth(d),
      [`${CN}__day-btn--highlight`]: this.highlightChecker(d.toDate()),
    };

    const tdCn = {
      [`${CN}__day`]: true,
      [`${CN}__day--in-range`]: this.isInRange(d),
      [`${CN}__day--inactive`]: this.isPrevMonth(d) || this.isNextMonth(d),
    };

    return (
      <td key={d.toString()} class={tdCn}>
        <button
          class={cn}
          disabled={!this.isActive(d)}
          onClick={withModifiers(() => this.select(d), ['prevent', 'stop'])}
          onDblclick={withModifiers(() => this.doubleSelect(d), ['prevent', 'stop'])}
        >
          {' '}
          {d.date()}{' '}
        </button>
      </td>
    );
  }

  private isInRange(d: Moment): boolean {
    const [start, end] = this.range;

    if (!start || !end || start === end) {
      return false;
    }

    return moment(start).isSameOrBefore(d) && moment(end).isSameOrAfter(d);
  }

  private isLastInRange(d: Moment): boolean {
    return moment(this.range[1]).isSame(d);
  }

  private isActive(d: Moment) {
    const { max, min } = this;

    const beforeThanMax = max ? d.isSameOrBefore(max) : true;
    const afterThanMin = min ? d.isSameOrAfter(min) : true;

    return beforeThanMax && afterThanMin;
  }

  private isToday(d: Moment): boolean {
    return _isSame(d, this.today);
  }

  private isSelected(d: Moment): boolean {
    const { ct } = this;
    return ct ? _isSame(d, ct) : false;
  }

  private isPrevMonth(d: Moment) {
    return d.month() < this.cm.month() || d.year() < this.cm.year();
  }

  private isNextMonth(d: Moment) {
    return d.month() > this.cm.month() || d.year() > this.cm.year();
  }

  private select(d: Moment) {
    const { cm } = this;
    if (d.month() !== cm.month() || d.year() !== cm.year()) {
      this.currentMonth = d.toDate();
    }
    const value = d.toDate();
    this.$emit('input', value);
    this.$emit('update:value', value);
  }

  private doubleSelect(d: Moment) {
    const { cm } = this;

    if (d.month() !== cm.month() || d.year() !== cm.year()) {
      this.currentMonth = d.toDate();
    }

    const value = d.toDate();

    this.$emit('double', value);
  }

  private prevMonth() {
    this.currentMonth = moment(this.cm).add(-1, 'month').toDate();
  }

  private nextMonth() {
    this.currentMonth = moment(this.cm).add(1, 'month').toDate();
  }
}

function _detector(base: Moment): (w: number) => (d: number) => Moment {
  return (w: number) => {
    return (d: number) => moment(base).add(w, 'week').add(d, 'day');
  };
}

function _times<T>(n: number, cb: (n: number) => T): T[] {
  const res = [];
  for (let i = 0; i < n; i++) {
    res.push(cb(i));
  }
  return res;
}

function _isSame(d1: Moment, d2: Nullable<Moment>) {
  if (!d1 || !d2) {
    return false;
  }

  return d1.month() === d2.month() && d1.date() === d2.date() && d1.year() === d2.year();
}
