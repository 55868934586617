import Tooltip from '@ravnur/core/components/tooltip/tooltip';
import { Watch } from '@ravnur/decorators';
import $t from '@ravnur/l10n/$t';

import { Vue, Options } from 'vue-class-component';

import AdvancedSearch from '../advanced-search/advanced-search.vue';
import './application-search.scss';

const CN = 'application-search';

@Options({
  components: { AdvancedSearch },
})
export default class ApplicationSearch extends Vue {
  protected search = '';
  protected isCollapsed = false;
  protected isTooltipHidden = false;

  get isAdvancedSearch(): boolean {
    return this.store.application.isAdvancedSearch;
  }

  @Watch('$route.name')
  onRouteChange() {
    if (this.$route.name !== 'Search') {
      this.search = '';
    }
  }

  render() {
    return (
      <div class={[CN, this.isCollapsed ? `${CN}--collapsed` : '']}>
        <div class={`${CN}__search-open`} onClick={() => (this.isCollapsed = true)}>
          <icon type="search" />
        </div>
        <Tooltip
          class={[`${CN}__input-tooltip`, this.isTooltipHidden ? `${CN}__input-tooltip--hide` : '']}
          text={$t('header', 'search__tooltip')}
        >
          <div class={`${CN}__input-wrapper`}>
            <input
              ref="input"
              class={`${CN}__input`}
              placeholder={$t('header', 'search__placeholder', {
                name: this.store.application.getCurrentSite
                  ? this.store.application.getCurrentSite.name
                  : '',
              })}
              type="search"
              v-model={this.search}
              onBlur={this.unhideTooltip}
              onFocus={this.forceHideTooltip}
              onKeypress={this.handleEnterPressedEvent}
            />
            <div
              class={`${CN}__search-clear`}
              v-show={!!this.search}
              onClick={() => (this.search = '')}
            >
              <icon type="close" />
            </div>
          </div>
        </Tooltip>
        <div class={`${CN}__search-close`} onClick={this.onClose}>
          <icon type="close" />
        </div>
        <icon class={`${CN}__search-icon`} type="search" />
      </div>
    );
  }

  private forceHideTooltip() {
    this.isTooltipHidden = true;
  }

  private unhideTooltip() {
    this.isTooltipHidden = false;
  }

  private onClose() {
    this.isCollapsed = false;
    this.search = '';
  }

  private handleEnterPressedEvent(e: KeyboardEvent) {
    if (e.keyCode === 13) {
      this.gotoSearch();
    }
  }

  public onBlur() {
    const field = this.$refs.input;
    if (field instanceof HTMLElement) {
      field.blur();
    }
  }

  public gotoSearch() {
    const search = this.search.trim();
    if (search) {
      let query = { s: search };

      if (this.$route.name === 'Search') {
        query = { ...(this.$route.query || {}), ...query };
      }

      this.isCollapsed = false;
      this.$router.push({ name: 'Search', query });
    }
    this.onBlur();
  }
}
