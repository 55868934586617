import Opener, { OpenerSlotProps } from '@ravnur/core/components/opener/opener';
import { Inject } from '@ravnur/decorators';
import $t from '@ravnur/l10n/$t';
import { Vue } from 'vue-class-component';

import ApplicationItem from './application-item/application-item';
import ApplicationsGroup from './applications-group';

import { Site } from '@/types/Site';
import { ApplicationNames } from '@ravnur/shared/types/Application';

import './applications-menu.scss';

const CN = 'applications-menu';

export default class ApplicationsMenu extends Vue {
  @Inject('sites') private sites: Site[];
  @Inject('current-site') private currentSite: Nullable<Site>;
  // @Inject('is-admin') private isAdmin: boolean;
  @Inject('application-name') private applicationName: ApplicationNames;

  private get slots() {
    return {
      summary: this.renderSummary,
      details: this.renderApplications,
    };
  }

  private get applications() {
    return this.sites.filter((s) => !s.isRoot);
  }

  private get isAvailable() {
    return !this.currentSite || this.applications.length > 1;
  }

  render() {
    return <Opener class={CN} v-slots={this.slots} />;
  }

  private renderSummary({ toggle }: OpenerSlotProps) {
    return (
      <>
        <r-button
          class={`${CN}__toggler`}
          color="black"
          icon="apps"
          mode="frameless"
          onclick={toggle}
          title={$t('header', 'applications__tooltip')}
          v-show={this.isAvailable}
        />
        <ApplicationItem
          class={`${CN}__item--current`}
          site={this.currentSite}
          v-show={this.currentSite}
          onClick={this.handleRedirect}
        />
      </>
    );
  }

  private renderApplications() {
    return (
      <div class={`${CN}__sites`}>
        <ApplicationsGroup
          sites={this.sites.filter((s) => !s.isRoot)}
          title="Switch to"
          urlProperty="portalUrl"
        />
      </div>
    );
  }

  private handleRedirect() {
    if (this.applicationName === ApplicationNames.MY_ACCOUNT) {
      const url = this.sites.find((s) => !s.isRoot && s.portalUrl)?.portalUrl ?? '/';
      window.location.href = url;
      return;
    }

    if (this.currentSite?.startPage) {
      this.$router.push({ path: this.currentSite.startPage });
    } else {
      this.$router.push('/');
    }
  }
}
