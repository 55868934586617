// tslint:disable:one-variable-per-declaration
// tslint:disable:no-bitwise
// tslint:disable:triple-equals
export default function uidGenerator(template = 'rav_xxxxxxxxxxxx_xxxx') {
  return template.replace(/[xy]/g, function _repl(c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
