import { BaseRepository } from '@ravnur/http';

import entities from '../config/pathes';

import { ExpandedMedia } from '../types/Media';

export default class ExtMediaRepository extends BaseRepository<ExpandedMedia> {
  constructor() {
    super(entities.EXT_MEDIA);
  }

  public process(mediaId: string) {
    return this.action(`${mediaId}/process`);
  }

  public async undoUploading(mediaId: string, options: Dictionary<unknown> = {}) {
    return this.undo('upload', options, mediaId);
  }
}
