import { $Props } from '@ravnur/core/typings/tsx';
import { Notification$Type, RavnurNotification } from '@ravnur/notifications/typings';
import { Vue, Options, prop } from 'vue-class-component';

import './alert.scss';

const CN = 'alert';

// TODO: remove duplicates

const ICONS: { [key in Notification$Type]: string } = {
  info: 'info',
  error: 'warning',
  done: 'check',
};

class Props {
  value = prop<string[]>({ default: [] });
  type = prop<Notification$Type>({ default: 'error' });
  tag = prop<any>({ default: 'div' });

  onHide = prop<() => void>({ default: () => () => void 0 });
}

export type Alert$Props = $Props<Props>;

// TODO use defineComponent
@Options({
  emits: ['hide', 'update:value'],
})
export default class Alert extends Vue.with(Props) {
  get notification(): Nullable<RavnurNotification> {
    const { value: reasons, type } = this;
    if (!reasons || !reasons.length) {
      return null;
    }
    return { message: reasons.join('\n'), type, timestamp: Date.now() };
  }

  render() {
    const { tag: Tag, notification } = this;
    if (!notification) {
      return;
    }
    const type = ICONS[notification.type];
    return (
      <Tag class={[`${CN}`, `${CN}--${type}`]} role="alert">
        <icon class={`${CN}__icon`} type={type} />
        <ul class={`${CN}__message`}>{notification.message.split('\n').map(this.renderText)}</ul>
        <r-button
          class={`${CN}__close-btn`}
          color="white"
          icon="close"
          mode="frameless"
          onclick={this.hide}
          type={type}
        />
      </Tag>
    );
  }

  public renderText(message: string) {
    return <li class={`${CN}__text`}>{message}</li>;
  }

  private hide() {
    this.onHide();
    this.$emit('hide', []);
    this.$emit('update:value', []);
  }
}
