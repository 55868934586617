import reduce from './reduce';

/* tslint:disable:max-line-length */
interface Flow {
  <A1, R1, R2>(f1: (a1: A1) => R1, f2: (a: R1) => R2): (a1: A1) => R2;
  <A1, R1, R2, R3>(f1: (a1: A1) => R1, f2: (a: R1) => R2, f3: (a: R2) => R3): (a1: A1) => R3;
  <A1, R1, R2, R3, R4>(
    f1: (a1: A1) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4
  ): (a1: A1) => R4;
  <A1, R1, R2, R3, R4, R5>(
    f1: (a1: A1) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5
  ): (a1: A1) => R5;
  <A1, R1, R2, R3, R4, R5, R6>(
    f1: (a1: A1) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6
  ): (a1: A1) => R6;
  <A1, R1, R2, R3, R4, R5, R6, R7>(
    f1: (a1: A1) => R1,
    f2: (a: R1) => R2,
    f3: (a: R2) => R3,
    f4: (a: R3) => R4,
    f5: (a: R4) => R5,
    f6: (a: R5) => R6,
    f7: (a: R6) => R7
  ): (a1: A1) => R7;
}

const flow: Flow = function _flow<R>(...fnc: Array<(val: unknown) => R>) {
  return reduce((val, cb) => cb(val), fnc);
};

export default flow;
