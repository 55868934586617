import curry2 from './curry2';

export default curry2(isEqual);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEqual(a: any, b: any, circulars?: any[]): boolean {
  const circ = (circulars || []).concat([a, b]);
  if (a === b) {
    return true;
  }
  if (a == null || b == null) {
    return a === b;
  }
  const typeA = typeof a;
  const typeB = typeof b;
  if (typeA !== typeB) {
    return false;
  }
  if (a instanceof Date) {
    return a.getTime() === b.getTime();
  }
  if (a instanceof RegExp) {
    return a.toString() === b.toString();
  }
  if (typeA === 'object' && typeA === typeB) {
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) {
      return false;
    }

    // eslint-disable-next-line
    for (var i = 0; i < keys.length; i++) {
      // eslint-disable-next-line
      for (var k = 0; k < circ.length; k++) {
        if (circ[k] === a[keys[i]]) {
          break;
        }
      }
      // tslint:disable-next-line:align
      if (k < circ.length) {
        if (a[keys[i]] !== b[keys[i]]) {
          return false;
        }
      } else if (!isEqual(a[keys[i]], b[keys[i]], circ)) {
        return false;
      }
    }
    return true;
  }
  return false;
}
