import { ErrorObject } from '@vuelidate/core';
import { withModifiers } from 'vue';
import { prop, Vue, Options } from 'vue-class-component';

import Opener, { OpenerSlotProps } from '../../components/opener/opener';
import { DATE_FORMAT } from '../../config';
import toString from '../../filters/date';

import { $Props } from '../../typings/tsx';

import './datepicker.scss';

class Props {
  value: Nullable<string>;
  label = prop({ default: '' });
  placeholder = prop({ default: '' });
  size = prop<Component$Size>({ default: 'md' });
  errors = prop<ErrorObject[]>({ default: [] });
  maxDate = prop<Date | string | null>({ default: null });
  minDate = prop<Date | string | null>({ default: null });
  disabled = prop({ default: false });
  isUtcTime = prop({ default: true });
  clearable = prop({ default: false });
  openerPosition = prop<Component$OpenerPosition>({ default: 'bottom' });
  withActions = prop({ default: false });
  withIcon = prop({ default: true });
  markToday = prop({ default: true });
  requiredAsterisk = prop({ default: false });
}

type Emits = {
  onInput: (val: Nullable<Date>) => void;
};

export type DatePicker$Props = $Props<Props, Emits, 'value'>;

const CN = 'datepicker';

@Options({
  emits: ['input', 'update:value'],
})
export default class DatePicker extends Vue.with(Props) {
  declare readonly $refs: {
    opener: Opener;
  };

  localValue: Nullable<string> = null;

  private get dateAsString(): string {
    const { value, isUtcTime } = this;
    return value ? toString(value, 'l', isUtcTime) : '';
  }

  private get classes() {
    return {
      [CN]: true,
      [`${CN}--sm`]: this.size === 'sm',
      [`${CN}--top`]: this.openerPosition === 'top',
    };
  }

  private get openerSlots() {
    return {
      summary: this.renderInput,
      details: this.renderDateHolder,
    };
  }

  render() {
    return (
      <div class={this.classes}>
        <Opener ref="opener" class={`${CN}__wrapper`} v-slots={this.openerSlots} />
      </div>
    );
  }

  private renderInput({ toggle }: OpenerSlotProps) {
    return (
      <>
        <text-field
          class={`${CN}__input`}
          disabled={this.disabled}
          errors={this.errors}
          icon={this.withIcon ? 'date-range' : ''}
          label={this.label}
          onclick={withModifiers(toggle, ['self'])}
          placeholder={this.placeholder}
          readOnly={true}
          requiredAsterisk={this.requiredAsterisk}
          value={this.dateAsString}
        />
        {this.renderClearButton()}
        {this.$slots.default?.()}
      </>
    );
  }

  private renderClearButton() {
    if (!this.clearable || !this.value || this.disabled) {
      return;
    }
    return <r-button class={`${CN}__clear`} icon="cancel" mode="frameless" onclick={this.clear} />;
  }

  private renderDateHolder() {
    return (
      <date-holder
        class={`${CN}__date-holder`}
        markToday={this.markToday}
        maxDate={this.maxDate}
        minDate={this.minDate}
        v-slots={{ footer: this.renderActions }}
        value={this.localValue}
        onInput={this.onChange}
      />
    );
  }

  renderActions() {
    if (!this.withActions) return;

    return (
      <div class={`${CN}__actions`}>
        <r-button
          class={[`${CN}__btn`, `${CN}__btn--clear`]}
          color="primary"
          mode="secondary"
          onclick={this.clear}
        >
          <l10n group="common" tkey="action__clear" />
        </r-button>

        <r-button
          class={[`${CN}__btn`, `${CN}__btn--apply`]}
          color="primary"
          disabled={!this.localValue}
          onclick={this.apply}
        >
          <l10n group="common" tkey="action__apply" />
        </r-button>
      </div>
    );
  }

  private clear() {
    this.onChange(null);
    this.apply();
  }

  private onChange(date: Nullable<Date>) {
    this.localValue = date ? toString(date, DATE_FORMAT, this.isUtcTime) : null;

    if (!this.withActions) {
      this.apply();
    }
  }

  private apply() {
    this.$emit('input', this.localValue);
    this.$emit('update:value', this.localValue);
    this.$refs.opener.hide();
  }

  mounted() {
    this.localValue = this.value;
  }
}
