import createEmptyRule from './createEmptyRule';

import {
  AdvancedSearch$MatchState,
  AdvancedSearch$MatchRule,
  AdvancedSearch$ServerRule,
  AdvancedSearch$ServerState,
} from '@/types/AdvancedSearch';

export default function toClientFormat(
  state: AdvancedSearch$ServerState
): AdvancedSearch$MatchState {
  const rules = state.P.map(_encodeRule);
  rules.push(createEmptyRule());
  return {
    match: state.M,
    rules,
  };
}

function _encodeRule(rule: AdvancedSearch$ServerRule): AdvancedSearch$MatchRule {
  const res: AdvancedSearch$MatchRule = createEmptyRule();
  res.type = rule.F;
  res.value = rule.FV;
  res.cond = rule.O;
  res.secondValue = rule.SV || null;
  res.nested = (rule.N ? toClientFormat(rule.N) : null) as any;
  return res;
}
