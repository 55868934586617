import { prop, Vue } from 'vue-class-component';

import { $Props } from '../../typings/tsx';

import { getIcon } from './icon-storage';

import './icon.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */

class Props {
  type!: string;
  title = prop({ default: '' });
  size = prop<Component$Size>({ default: 'sm' });
}

export type Icon$Props = $Props<Props>;

const CN = 'icon';

export default class Icon extends Vue.with(Props) {
  render() {
    const { type, title: iconTitle, size } = this;
    const icon = getIcon(type);
    if (!icon) {
      return;
    }

    const { width, height } = icon;

    const viewBox = `0 0 ${width} ${height}`;
    const attrs = { version: '1.1', width, height, viewBox };

    const cn = {
      [CN]: true,
      [`${CN}--${type}`]: type,
      [`${CN}--${size}`]: size,
    };

    const rects = (icon.rects || []).map(this._createTagRenderer('rect'));
    const circles = (icon.circles || []).map(this._createTagRenderer('circle'));
    const paths = (icon.paths || []).map(this._createTagRenderer('path'));
    const polygons = (icon.polygons || []).map(this._createTagRenderer('polygon'));

    const childs = [...circles, ...rects, ...paths, ...polygons];
    const Rect: any = 'rect';
    const Svg: any = 'svg';

    if (iconTitle) {
      const titleClass = `${CN}__title`;
      childs.push(
        <Rect class={titleClass} height={height} width={width} x={0} y={0}>
          <title>{iconTitle}</title>
        </Rect>
      );
    }
    return (
      <Svg class={cn} {...attrs} aria-hidden="true">
        {childs}
      </Svg>
    );
  }

  private _createTagRenderer(Tag: any) {
    return function renderer(attrs: any) {
      return <Tag {...attrs} />;
    };
  }
}
