import isEqual from '@ravnur/nanoutils/isEqual';
import { Vue, Options, prop } from 'vue-class-component';

import { $Props } from '../../typings/tsx';
import './tabs.scss';

const CN = 'tabs';
type MODE = 'sm';

class Props<T extends Entity = Entity> {
  value = prop<Nullable<T>>({ default: null });
  tabs!: T[];
  mode = prop<Nullable<MODE>>({ default: null });
}

type Emits<T> = {
  onInput?: (value: T) => void;
};

export type Tabs$Props<T extends Entity> = $Props<Props<T>, Emits<T>, 'value'>;

@Options({
  emits: ['input', 'update:value'],
})
export default class Tabs<T extends Entity = Entity> extends Vue.with(Props) {
  declare readonly $props: Tabs$Props<T>;
  declare readonly tabs: T[];

  render() {
    const cn = {
      [CN]: true,
      [`${CN}--${this.mode}`]: this.mode,
    };

    const current = this.value?.id;

    return (
      <div class={cn}>
        <div class={`${CN}__wrapper`}>
          <ul class={`${CN}__items`}>{this.tabs.map(this.renderTabButton)}</ul>
        </div>

        <div class={`${CN}__content`}>
          {this.$slots.default?.()}
          {current && this.$slots[current]?.()}
        </div>
      </div>
    );
  }

  private renderTabButton(tab: T, idx: number) {
    const cn = {
      [`${CN}__tab`]: true,
      [`${CN}__tab--current`]: isEqual(tab, this.value),
    };
    return (
      <li key={idx} class={cn}>
        <button class={`${CN}__tab-title`} onClick={() => this.select(tab)}>
          {this.$slots.tab?.({ tab })}
        </button>
      </li>
    );
  }

  private select(tab: T) {
    this.$emit('input', tab);
    this.$emit('update:value', tab);
  }
}
