import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import { DATE_FORMAT } from '../config';
// eslint-disable-next-line
momentDurationFormatSetup(moment as any);

export default function date(val: string | Date, outputFormat = 'll', fromUTC = true): string {
  if (fromUTC) {
    // eslint-disable-next-line import/no-named-as-default-member
    return moment.utc(val, DATE_FORMAT).local().format(outputFormat);
  }
  return moment(val, DATE_FORMAT).format(outputFormat);
}

export function factory(defaultFormat: string) {
  return function d(val: string, outputFormat: string = defaultFormat): string {
    // eslint-disable-next-line import/no-named-as-default-member
    return moment.utc(val, DATE_FORMAT).local().format(outputFormat);
  };
}

export function toMS(val: string): number {
  // eslint-disable-next-line import/no-named-as-default-member
  return moment.utc(val, DATE_FORMAT).local().valueOf();
}
