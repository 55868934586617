import { BaseRepository } from '@ravnur/http';

import pathes from '@/config/pathes';
import { FeaturedCarousel } from '@/types/Media';

export default class FeaturedCarouselRepository extends BaseRepository<FeaturedCarousel> {
  constructor() {
    super(pathes.FEATURED_CAROUSEL);
  }
}
