import {
  ComponentOptionsMixin,
  ComponentPropsOptions,
  ComputedOptions,
  DefineComponent,
  EmitsOptions,
  ExtractPropTypes,
  MethodOptions,
} from 'vue';

export default function WithModel<
  PropsOptions extends Readonly<ComponentPropsOptions>,
  PropTypes extends ExtractPropTypes<PropsOptions>,
  K extends keyof PropTypes,
  RawBindings,
  D,
  // eslint-disable-next-line
  C extends ComputedOptions = {},
  // eslint-disable-next-line
  M extends MethodOptions = {},
  Mixin extends ComponentOptionsMixin = ComponentOptionsMixin,
  Extends extends ComponentOptionsMixin = ComponentOptionsMixin,
  E extends EmitsOptions = Record<string, any>,
  EE extends string = string,
  ModifiedProps = Omit<PropTypes, K> & { vModel: [PropTypes[K], string] }
>(
  component: DefineComponent<PropsOptions, RawBindings, D, C, M, Mixin, Extends, E, EE>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  prop: K
): DefineComponent<ModifiedProps, RawBindings, D, C, M, Mixin, Extends, Record<string, any>, EE> {
  const modifiedProps = component.props as any; // Convert to 'any' temporarily

  modifiedProps[prop] = {
    ...modifiedProps[prop],
    type: [modifiedProps[prop].type, Function as any], // Use 'Function' instead of tuple type
  };

  return component as any;
}
