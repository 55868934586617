import { BaseRepository, PagerListResponse, BasePaginatedParams, HttpService } from '@ravnur/http';

import pathes from '@/config/pathes';
import { BroadcastEvent } from '@/types/Broadcast';
import { LiveEvent } from '@/types/Live';
import { LiveEvent$Privacy, LiveEvent$Status } from '@ravnur/shared/types/Live';

export type LiveEventsQueryParams = {
  status: LiveEvent$Status[];
  channelIds?: string[];
  privacy?: LiveEvent$Privacy[];
} & BasePaginatedParams;

export enum Thesaurus {
  LIVE_CHANNELS = '',
}

export default class LiveEventsRepository extends BaseRepository<
  LiveEvent,
  LiveEventsQueryParams,
  PagerListResponse<LiveEvent>
> {
  constructor() {
    super(`${pathes.LIVE}/event`);
  }

  public async broadcast(id: string, accessCode?: string): Promise<BroadcastEvent> {
    const body = accessCode ? { accessCode } : {};

    return HttpService.post(`${this._resource}/${id}/broadcast`, body);
  }
}
