import { prop, Vue } from 'vue-class-component';

import { $Props } from '../../typings/tsx';
import './error-messages.scss';

const CN = 'error-messages';

type ErrorValue = string | string[] | null;

class Props {
  value = prop<ErrorValue>({ default: null });
}

export type ErrorMessages$Props = $Props<Props>;

export default class ErrorMessages extends Vue.with(Props) {
  render() {
    const { value } = this;
    if (!value || !value.length) {
      return;
    }

    const messages = Array.isArray(value) ? value : [value];

    return (
      <ul class={CN}>
        {messages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    );
  }
}
