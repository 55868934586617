import moment from 'moment';

const DATE_HELPERS: Dictionary<number> = {
  quarters: 4,
  months: 12,
  weeks: 6,
  days: 14,
  hours: 24,
  minutes: 60,
  seconds: 60,
  milliseconds: 1000,
};

function random() {
  return Math.random();
}

export function coinFlip() {
  return random() < 0.5;
}

export function integer(to = 1000, from = 0) {
  return Math.round(from + (to - from) * random());
}

export function date() {
  let m = moment();
  const keys = Object.keys(DATE_HELPERS);
  for (let i = 0; i < 3; i++) {
    const period = keys[integer(keys.length - 1)];
    const n: number = DATE_HELPERS[period] as number;
    m = m.subtract(integer(n) as any, period);
  }
  return m.toDate().toUTCString();
}

export function oneOf<T>(arr: T[]): T {
  let len = arr.length;
  const idx = integer(--len);
  return arr[idx];
}
