import logger from './logger';

import { COMPONENT_FOR_TYPE } from '@/config/advanced-search';
import { AdvancedSearch$Field } from '@/types/AdvancedSearch';
import { Fork$SupportedComponent } from '@/types/Component';
import { Metadata$Field } from '@/types/Metadata';

/* eslint no-useless-escape: 0 */
const TAG_NAME_REGEXP = /([a-z\-])+/;

type Field = AdvancedSearch$Field | Metadata$Field;

export function componentForFirstValue(field: Field): Fork$SupportedComponent {
  logger.debug('getComponentForFirstField. Name: ', field.name);
  const fv = (field as any).firstValue;
  if (fv) {
    const [tag] = fv.match(TAG_NAME_REGEXP);
    logger.debug('getComponentForFirstField. Use custom tag: ', tag);
    return tag.toLowerCase();
  }

  const operatorsType = field.type;
  logger.debug('getComponentForFirstField. Use default for type: ', operatorsType);
  return COMPONENT_FOR_TYPE[operatorsType];
}

export function componentForSecondValue(field: Field): Fork$SupportedComponent | null {
  logger.debug('getComponentForSecondField. Name: ', field.name);
  const sv = (field as any).secondValue;
  if (sv) {
    let [tag] = sv.match(TAG_NAME_REGEXP);
    logger.debug('getComponentForSecondField. Use custom tag: ', tag);
    tag = tag.toLowerCase();

    return tag === 'input' ? 'str-input' : tag;
  }

  logger.debug('getComponentForSecondField. Without any second component.');
  return null;
}
