import { RouteRecordRaw } from 'vue-router';

import Forbidden from './components/forbidden.vue';
import Home from './components/home/home';
import NotFound from './components/not-found.vue';
import routeGuard from '@/route-guard/route-guard';

export const ROUTES: RouteRecordRaw[] = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { translations: ['search_page', 'home'] },
  },
  {
    path: '/browse',
    name: 'Browse',
    component: () => import('./components/browse/browse'),
    meta: { translations: ['search_page', 'browse'] },
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('./components/search/search'),
    meta: { translations: ['search_page', 'search'] },
  },
  {
    path: '/media-categories/:cid',
    name: 'MediaCategories',
    component: () => import('./components/media-categories/media-categories.vue'),
    meta: { translations: ['search_page', 'mediacategories'] },
  },
  {
    path: '/groups',
    name: 'Groups',
    component: () => import('./components/groups/groups'),
    meta: {
      translations: ['search_page', 'group'],
    },
    beforeEnter: routeGuard(['isGroups']),
  },
  {
    path: '/groups/:id',
    name: 'Group',
    component: () => import('./components/group/group'),
    meta: { translations: ['search_page', 'comment', 'my-media', 'group'] },
    beforeEnter: routeGuard(['isGroups']),
  },
  {
    path: '/media/:id',
    name: 'Media',
    component: () => import('./components/media-details/media-details'),
    meta: { translations: ['comment', 'media'] },
  },
  {
    path: '/user/:id',
    name: 'User',
    component: () => import('./components/user-channel/user-channel'),
    meta: { translations: ['user_channel', 'search_page'] },
    beforeEnter: routeGuard(['isDisplayUserChannels']),
  },

  {
    path: '/upload',
    name: 'Upload',
    component: () => import('./components/upload/upload'),
    meta: { translations: ['upload'] },
    beforeEnter: routeGuard([], ['canUploadContent']),
  },
  {
    path: '/live',
    name: 'LiveEvents',
    component: () => import('./components/live/live'),
    beforeEnter: routeGuard(['isLiveStreaming']),
    meta: {
      translations: ['search_page', 'live'],
    },
  },
  {
    path: '/live/:path/:eventId?',
    name: 'LiveChannel',
    component: () => import('./components/live/live-channel/live-channel'),
    meta: {
      translations: ['livechannel'],
    },
  },

  {
    path: '/',
    name: 'Root',
    redirect: { name: 'Home' },
  },

  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: { translations: ['error-page'] },
  },
  {
    path: '/403',
    name: '403',
    component: Forbidden,
    meta: { translations: ['error-page'] },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '*',
    redirect: { name: '404' },
  },
];
