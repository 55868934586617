import moment, { unitOfTime, Moment } from 'moment';

export const FORMAT = 'YYYYMMDD';

export interface TimeFilterOption {
  id: string;
  tkey: string;
  custom?: boolean;
  bdate: Nullable<string>;
  edate: Nullable<string>;
}

export default [
  { id: '1', tkey: 'time_select__all', bdate: null, edate: null },
  {
    id: '2',
    tkey: 'time_select__year',
    bdate: startOf('year'),
    edate: endOf('year'),
  },
  {
    id: '3',
    tkey: 'time_select__month',
    bdate: startOf('month'),
    edate: endOf('month'),
  },
  {
    id: '4',
    tkey: 'time_select__week',
    bdate: startOf('week'),
    edate: endOf('week'),
  },
  { id: '5', tkey: 'time_select__today', bdate: today(), edate: today() },
];

function startOf(period: unitOfTime.StartOf): string {
  return toString(moment().startOf(period));
}

function endOf(period: unitOfTime.StartOf): string {
  return toString(moment().endOf(period));
}

export function today(): string {
  return toString(moment());
}

export function toString(date: Date | Moment | string, format: string = FORMAT): string {
  date = date instanceof Date || typeof date === 'string' ? moment(date) : date;
  return date.format(format);
}

export function toDate(str: string): Date {
  return moment(str, FORMAT).toDate();
}
