import { $Props } from '@ravnur/core/typings/tsx';
import { renderSlot } from 'vue';
import { Vue, Options } from 'vue-class-component';

class Props<T> {
  fetch: () => Promise<T>;
}

type Emits<T> = {
  onSuccess: (data: T) => void;
  onError: <E>(error: E) => void;
};

@Options({
  emits: ['success', 'error'],
})
export default class StateFetcher<T> extends Vue.with(Props) {
  declare $props: $Props<Props<T>, Emits<T>>;

  loading = true;

  created() {
    this.load();
  }

  render() {
    const { loading, $slots } = this;
    return <div>{!loading && renderSlot($slots, 'default')}</div>;
  }

  private async load() {
    try {
      const data = await this.fetch();
      this.$emit('success', data);
    } catch (e) {
      this.$emit('error', e);
    }
    this.loading = false;
  }
}
