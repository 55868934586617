import fromNow from '@ravnur/core/filters/fromNow';
import time from '@ravnur/core/filters/time';
import $t from '@ravnur/l10n/$t';

import { Vue, prop } from 'vue-class-component';

import { isSingleMedia, isPlayable, isPlaylist, isRestrictedForUser } from '../../typeguards/media';
import { Media } from '../../types/Media';
import UserName from '@ravnur/core/ui-kit/user-name/user-name';
import './media-card.scss';

const CN = 'media-card';
const SECONDS_IN_HOUR = 3600;

class Props {
  media = prop<Media>({ required: true });
  showComments = prop<boolean>({ default: false });
  showLikes = prop<boolean>({ default: false });
  showType = prop<boolean>({ default: false });
  showOverlay = prop<boolean>({ default: false });
  showCCBadge = prop<boolean>({ default: false });
  goToUser?: () => void;
  goToMedia?: () => void;
}

export default class MediaCard extends Vue.with(Props) {
  get createDate() {
    return fromNow(this.media.createdDate);
  }

  get isRestrictedForUser() {
    return isRestrictedForUser(this.media);
  }

  render() {
    return (
      <div class={CN}>
        {this.isRestrictedForUser ? this.renderRestrictedMediaCard() : this.renderMediaCard()}
      </div>
    );
  }

  renderRestrictedMediaCard() {
    return (
      <div class={`${CN}__restricted`}>
        <div class={`${CN}__thumbnail`}>
          <thumbnail entity={this.media} showType={false} showTypeLabel={false}>
            <div class={`${CN}__restricted-icon-wrapper`}>
              <div class={`${CN}__restricted-icon`}>!</div>
            </div>
          </thumbnail>
        </div>
        <div class={`${CN}__body`}>
          <l10n group="common" tkey="restricted__media" />
        </div>
      </div>
    );
  }

  renderMediaCard() {
    let likes;
    if (isSingleMedia(this.media) && this.showLikes) {
      likes = <stats count={this.media.likes} statType="likes" />;
    }

    let comments;
    if (isSingleMedia(this.media) && this.showComments) {
      comments = <stats count={this.media.commentsCount} statType="comments" />;
    }

    return (
      <>
        <div class={`${CN}__thumbnail`} onClick={this.goToMedia}>
          <thumbnail
            entity={this.media}
            showType={false}
            showTypeLabel={this.showType}
            title={this.media.title}
          >
            {this.renderDuration()}
            {this.renderCC()}
            {this.$slots.thumbnail?.()}
            <div class={`${CN}__thumbnail-overlay`} v-show={this.showOverlay}>
              <icon
                type="play-circle-outline"
                v-show={isPlayable(this.media) || isPlaylist(this.media)}
              />
            </div>
          </thumbnail>
        </div>
        <div class={`${CN}__body`}>
          <avatar
            class={`${CN}__avatar`}
            onclick={this.goToUser}
            title={this.media.owner.displayName}
            user={this.media.owner}
          />
          <div class={`${CN}__info`}>
            <div class={`${CN}__info-title`} title={this.media.title} onClick={this.goToMedia}>
              {this.media.title.length > 55
                ? `${this.media.title.substring(0, 55)}...`
                : this.media.title}
            </div>
            <UserName
              class={`${CN}__info-author`}
              onclick={this.goToUser}
              title={this.media.owner.displayName}
            >
              {this.media.owner.displayName}
            </UserName>
            <div class={`${CN}__info-meta`}>
              <div class={`${CN}__info-meta-item`}>{this.createDate}</div>
              <div class={`${CN}__info-meta-item`}>
                {$t('common', 'media__views-count', { count: this.media.views }, this.media.views)}
              </div>
              {likes && <div class={`${CN}__info-meta-item`}>{likes}</div>}
              {comments && <div class={`${CN}__info-meta-item`}>{comments}</div>}
            </div>
          </div>
        </div>
      </>
    );
  }

  private renderDuration() {
    const { media } = this;
    if (isPlayable(media)) {
      const showHours = media.duration ? media.duration > SECONDS_IN_HOUR : false;
      const duration = time(media.duration, showHours);
      return (
        <span ref="duration" class={`${CN}__duration`}>
          {duration}
        </span>
      );
    }
    return null;
  }

  private renderCC() {
    const { media } = this;

    if (isPlayable(media) && media.hasCC && this.showCCBadge) {
      return <span class={`${CN}__cc`}>{$t('media', 'cc')}</span>;
    }
    return null;
  }
}
