import { $Props } from '@ravnur/core/typings/tsx';
import $t from '@ravnur/l10n/$t';
import { Vue } from 'vue-class-component';

import './messages.scss';

const CN = 'messages';
const TEXT_CN = `${CN}__text`;

class Props {
  value: string;
}

export type Messages$Props = $Props<Props>;

export default class Messages extends Vue.with(Props) {
  render() {
    const messages = (this.value || $t('common', 'filter-list__empty')).split('\n');
    return (
      <div class={CN} data-testid="messages">
        {messages.map((text) => (
          <p key={text} class={TEXT_CN}>
            {text}
          </p>
        ))}
      </div>
    );
  }
}
