import { faker } from '@faker-js/faker';
import MockAdapter from 'axios-mock-adapter/types';
import toBackendFormat from '@/transformers/http/toBackendFormat';
import { Group, Group$Member, Group$MemberRole } from '@/types/Group';
import { _generateFolder, _generateGroup, _generateMockList } from './helper-fakers';

const total = +faker.random.numeric(2);
const data = _generateMockList<Group>(total, _generateGroup as any);

export default function mocker(mock: MockAdapter) {
  mock.onGet(/\/api\/v1\.0\/group\/\d+$/).reply(_get);
  mock.onGet(/\/api\/v1\.0\/group\/\d+\/folder/).reply(_loadGroupFolders);
  mock.onGet(/\/api\/v1\.0\/group\/\d+\/member/).reply(_loadGroupMembers);
  mock.onGet('/api/v1.0/group').reply(_load);
  mock.onGet('/api/v1.0/group/thumbnail/stock').reply(_loadStockImages);
}

function _load() {
  return [200, toBackendFormat({ items: data, total })];
}

function _get() {
  const randomGroup = faker.helpers.arrayElement(data);
  return [200, toBackendFormat(randomGroup)];
}

function _loadStockImages() {
  const data = _generateMockList(8, _generateStockImage);
  return [200, toBackendFormat(data)];
}
function _loadGroupFolders() {
  const total = +faker.random.numeric(1);
  const data = _generateMockList(total, _generateFolder);
  return [200, toBackendFormat(data)];
}

function _loadGroupMembers() {
  const total = +faker.random.numeric(1);
  const data = _generateMockList(total, _generateGroupMember);
  return [200, toBackendFormat({ total, items: data })];
}

function _generateStockImage() {
  return {
    name: faker.lorem.word(),
    url: faker.image.imageUrl(),
  };
}

function _generateGroupMember(): Group$Member {
  return {
    id: `${faker.datatype.number()}`,
    createdDate: faker.date.past().toISOString(),
    displayName: faker.internet.userName(),
    userName: faker.internet.userName(),
    userpic: faker.internet.avatar(),
    role: faker.helpers.arrayElement([
      Group$MemberRole.ADMIN,
      Group$MemberRole.INVITED,
      Group$MemberRole.MEMBER,
      Group$MemberRole.OWNER,
      Group$MemberRole.PENDING,
    ]),
  };
}
