import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { getAuthToken } from './get-auth-token';

export function createAuthenticationInterseptors(header = 'Authorization') {
  return {
    request(config: AxiosRequestConfig) {
      const token = getAuthToken();
      if (token) {
        config.headers = {
          ...config.headers,
          [header]: token,
        };
      }
      return config;
    },
    response(resp: AxiosResponse) {
      // const token = (resp.headers || {})[header.toLowerCase()];
      // if (token) {
      //    refreshAuthToken(token);
      // }
      return resp;
    },
  };
}
