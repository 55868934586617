import {
  WebAuthData,
  RegistrationData,
  RestorePasswordInfo,
  ChangePasswordData,
} from '@ravnur/auth/types/AuthService';
import { HttpService, wait } from '@ravnur/http';

import { Auth$Response } from '@/types/Auth';
import { AuthSettings } from '@ravnur/auth/types/AuthSettings';

export default class AuthRepository {
  public check(): Promise<Auth$Response> {
    return HttpService.get<Auth$Response>('/auth').catch((e) =>
      Promise.reject({ ...e.response.data, status: e.response.status })
    );
  }

  public async auth(data: WebAuthData): Promise<Auth$Response> {
    return HttpService.post<Auth$Response, WebAuthData>('/auth', data).catch((e) =>
      Promise.reject({ ...e.response.data, status: e.response.status })
    );
  }

  public logout(): Promise<Auth$Response> {
    return HttpService.delete<Auth$Response>('/auth').catch((e) => Promise.reject(e.response.data));
  }

  public registration(data: RegistrationData): Promise<unknown> {
    return this._post('/registration', data);
  }

  public restorePassword(email: string): Promise<unknown> {
    return this._post('/restore-password', { email });
  }

  public checkRestoreToken(token: string): Promise<RestorePasswordInfo> {
    return HttpService.get<RestorePasswordInfo>('/check-token', { params: { token } }).catch((e) =>
      Promise.reject(e.response.data)
    );
  }

  public changePassword(data: ChangePasswordData): Promise<unknown> {
    return this._post('/change-password', data);
  }

  public verify(data: WebAuthData): Promise<{ token: string }> {
    return HttpService.post('/verify', data)
      .then((op: any) => op.data)
      .catch((e) => Promise.reject(e.response.data) as any);
  }

  public activationInfo(): Promise<{ logoPath: string }> {
    return HttpService.get<{ logoPath: string }>('/activation-info').catch((e) =>
      Promise.reject(e.response.data)
    );
  }

  public getAuthSettings(): Promise<AuthSettings> {
    return HttpService.get<AuthSettings>('/auth/settings').catch((e) =>
      Promise.reject(e.response.data)
    );
  }

  private async _post(path: string, data: Record<string, unknown> = {}): Promise<Operation> {
    const promise: Promise<Operation> = HttpService.post(path, data);
    const op: Operation = await promise;

    try {
      const operation: Operation = await wait(op);
      return operation;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
