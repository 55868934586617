import curry2, { CurriedFunction1 } from './curry2';

interface EveryFnc {
  <T>(iteratees: Predicate<T>): CurriedFunction1<T[], boolean>;
  <T>(iteratees: Predicate<T>, arr: T[]): boolean;
}

const every: EveryFnc = curry2(_every);
export default every;

function _every<T>(iteratees: Predicate<T>, arr: T[]): boolean {
  return arr.every(iteratees);
}
