import {
  BaseRepository,
  PagerListResponse,
  BasePaginatedParams,
  BooleanAsString,
  HttpService,
} from '@ravnur/http';

import pathes from '@/config/pathes';
import { FacetGroup } from '@/types/Facet';
import { Media, Media$Types, Media$Privacies, Media$Details } from '@/types/Media';
import { MediaAnalyzedFields } from '@ravnur/shared/types/Media';

export type MediaRequestParams = {
  types: Media$Types[];
  title: string;
  hasCC: BooleanAsString;
  privacy: Media$Privacies;
  isExpired: BooleanAsString;
  isReviewed: BooleanAsString;
  isPublished: BooleanAsString;
  isFacets: BooleanAsString;
  isLiveRecording: BooleanAsString;
  owner: string;
  analyzedFields: MediaAnalyzedFields[];
  groupId: string;
  exceptGroup: BooleanAsString;
  categoryId: string;
  folderId: string;
  edate: string;
  bdate: string;
  fg: string;
} & BasePaginatedParams;

export type MediaListResponse = {
  facets: FacetGroup[];
} & PagerListResponse<Media>;

export default class MediaRepository extends BaseRepository<
  Media$Details,
  MediaRequestParams,
  MediaListResponse
> {
  constructor() {
    super(pathes.MEDIA);
  }

  public addToFavorite(media: Media | Media[]) {
    if (media instanceof Array) {
      return this.action('favorite', media);
    }
    return this.action('favorite', {}, media.id);
  }

  public removeFromFavorite(media: Media) {
    return this.undo('favorite', {}, media.id);
  }

  public like(media: Media) {
    return this.action('like', {}, media.id);
  }

  public unlike(media: Media) {
    return this.undo('like', {}, media.id);
  }

  public view(mediaId: string) {
    return this.action('views', {}, mediaId);
  }

  public rating(media: Media, rating: number) {
    return this.action('rating', { rating }, media.id);
  }

  public report(mediaId: string, report: Record<string, unknown>) {
    return this.action('report', report, mediaId);
  }

  public cancelMediaJobs(mediaId: string) {
    return HttpService.delete(`${this._resource}/${mediaId}/job`);
  }
}
