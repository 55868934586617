import { renderSlot } from 'vue';
import { prop, Vue } from 'vue-class-component';

import { $Props } from '../../typings/tsx';
import './alert.scss';

const CN = 'alert';

type Mode = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info';

class Props {
  mode = prop<Mode>({ default: 'danger' });
}

export type Alert$Props = $Props<Props>;

export default class Alert extends Vue.with(Props) {
  render() {
    return (
      <div class={[CN, `${CN}--${this.mode}`]} role="alert" {...this.$attrs}>
        {renderSlot(this.$slots, 'default')}
      </div>
    );
  }
}
