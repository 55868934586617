import { renderSlot, isVNode } from 'vue';
import { Vue, prop, Options } from 'vue-class-component';

import { $Props } from '../../typings/tsx';

class Props {
  enable = prop({ type: Boolean, default: false });
}

type Emits = {
  onIntersected?: () => void;
};

@Options({
  name: 'intersection-observer',
  emits: ['intersected'],
  watch: {
    enable: {
      immediate: true,
      handler(this: IntersectionObserverWrapper) {
        this.handleEnableChanged();
      },
    },
  },
})
export default class IntersectionObserverWrapper extends Vue.with(Props) {
  declare $props: $Props<Props, Emits>;
  private observer: Nullable<IntersectionObserver> = null;

  public observe() {
    if (!('IntersectionObserver' in window)) {
      this.emitIntersectedEvent();
      return;
    }
    const observer = new IntersectionObserver(this.handleObserveEvent);
    observer.observe(this.$el);
    this.observer = observer;
  }

  public disconnectIfNeeded() {
    this.observer?.disconnect();
    this.observer = null;
  }

  protected handleEnableChanged() {
    if (this.enable) {
      setTimeout(this.observe, 0);
    } else {
      this.disconnectIfNeeded();
    }
  }

  render() {
    const children = renderSlot(this.$slots, 'default').children;
    if (Array.isArray(children) && children.length === 1) {
      const first = children[0];
      if (isVNode(first)) {
        return first;
      }
    }

    throw new Error('IntersectionObsrver should contain one children');
  }

  private handleObserveEvent(entries: IntersectionObserverEntry[]) {
    const isIntersecting: boolean = entries.some((entry) => entry.isIntersecting);
    if (isIntersecting) {
      this.emitIntersectedEvent();
    }
  }

  private emitIntersectedEvent() {
    this.$nextTick(() => {
      this.$emit('intersected');
    });
  }
}
