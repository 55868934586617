import $t from '@ravnur/l10n/$t';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { Options, setup, Vue } from 'vue-class-component';

import { messageFromError } from '../../transformers/message-from-error';

import { AuthRepository } from '../../types/AuthService';
import { Message } from '../../types/Message';
import AuthMessage from '../auth-message/auth-message';
import AuthSubmitBtn from '../auth-submit-btn/auth-submit-btn';
import BaseAuthForm from '../base-auth-form/base-auth-form';

import './forgot-password-form.scss';

class Props {
  repository!: AuthRepository<unknown>;
  onMessage: (message: Message) => void;
}

const CN = 'forgot-password-form';

@Options({
  name: 'forgot-password-form',
  emits: ['message', 'success'],
  validations: {
    email: { required, email },
  },
  validationConfig: {
    $autoDirty: true,
  },
})
export default class ForgotPasswordForm extends Vue.with(Props) {
  private email = '';
  private isSuccess = false;

  private v$ = setup(() => useVuelidate());

  private get isEmpty() {
    return !this.email;
  }

  render() {
    if (this.isSuccess) {
      return (
        <AuthMessage
          message={{
            text: $t('login', 'resetMessage', { email: this.email }),
            type: 'info',
          }}
        />
      );
    }

    return (
      <BaseAuthForm class={CN} submit={this.resetPassword} v={this.v$}>
        <l10n class={`${CN}__desc`} group="login" tkey="resetDescription" />

        <text-field
          errors={this.v$.email.$errors}
          placeholder={$t('login', 'email')}
          vModel={[this.email, 'value']}
        />

        <AuthSubmitBtn class={`${CN}__btn`} disabled={this.isEmpty} label="resetPassword" />
      </BaseAuthForm>
    );
  }

  private async resetPassword() {
    this.handleMessage(null);
    try {
      await this.repository.restorePassword(this.email);
      this.isSuccess = true;
    } catch (e: any) {
      this.handleMessage(messageFromError(e));
    }
  }

  private handleMessage(message: Nullable<Message>) {
    this.$emit('message', message);
  }
}
