import { Vue, Options } from 'vue-class-component';

import Opener, { OpenerSlotProps } from '../../components/opener/opener';
import SelectableList from '../../components/selectable-list/selectable-list';
import { WithModel, CommonProps } from '../../typings/tsx';

const CN = 'base-chosen';

import './base-chosen.scss';

export { OpenerSlotProps };

type Props<T> = Partial<
  Pick<
    BaseChosen<T>,
    | 'current'
    | 'disabled'
    | 'options'
    | 'closeOnSelect'
    | 'keyForLabel'
    | 'openerPosition'
    | 'isSelectable'
    | 'isScrollable'
  >
>;

type Emits<T> = {
  onInput?: (val: T) => void;
};

@Options({
  name: 'base-chosen',
  emits: ['input', 'update:current'],
  props: {
    current: { default: null },
    disabled: { default: false },
    options: { default: [] },
    closeOnSelect: { default: true },
    keyForLabel: { default: 'label' },
    openerPosition: { default: 'bottom' },
    isSelectable: { default: () => () => true },
    isScrollable: { default: true },
  },
})
export default class BaseChosen<T> extends Vue {
  declare $props: WithModel<Props<T>, 'current'> & Emits<T> & CommonProps;
  public readonly current: Nullable<T>;
  public readonly options!: T[];
  public readonly size!: Component$Size;
  public readonly closeOnSelect!: boolean;
  public readonly keyForLabel!: T extends Record<string, unknown> ? keyof T : never;
  public readonly openerPosition!: Component$OpenerPosition;
  public readonly isSelectable!: Predicate<T>;
  public readonly isScrollable!: boolean;
  public readonly disabled!: boolean;

  declare readonly $refs: {
    opener: Opener;
  };

  protected select(option: T) {
    this.$emit('input', option);
    this.$emit('update:current', option);
    if (this.closeOnSelect) {
      this.$refs.opener.hide();
    }
  }

  render() {
    const { disabled } = this;

    const slots = {
      summary: this.$slots.button,
      details: this.renderOptions,
    };

    return <Opener ref="opener" class={`${CN}__wrapper`} disabled={disabled} v-slots={slots} />;
  }

  private renderOptions() {
    if (!this.options.length) {
      return;
    }
    return (
      <div class={`${CN}__items-wrapper`}>
        {/* Only for mobile devices, hidden by default */}
        <div class={`${CN}__items-header`}>{this.$slots.header?.()}</div>
        <SelectableList<T>
          class={`${CN}__items`}
          current={this.current}
          isScrollable={this.isScrollable}
          isSelectable={this.isSelectable}
          items={this.options}
          propName={this.keyForLabel}
          v-slots={this.$slots}
          onSelect={this.select}
        />
      </div>
    );
  }

  hide() {
    this.$refs.opener.hide();
  }

  open() {
    this.$refs.opener.open();
  }
}
