<template>
  <Forbidden />
</template>

<script>
import Forbidden from '@ravnur/shared/components/forbidden.vue';

export default {
   name: 'ForbiddenComponent',
   components: {
     Forbidden,
   }
};
</script>