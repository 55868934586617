import dateToString from '@ravnur/core/filters/date';
import { Prop } from '@ravnur/decorators';
import $t from '@ravnur/l10n/$t';
import { Vue, Options } from 'vue-class-component';

import Fork from '../fork/fork';

import { CONDITION_BETWEEN } from '@/config/advanced-search';
import { FORMAT } from '@/config/time-filters';
import {
  conditions,
  componentForFirstValue,
  componentForSecondValue,
} from '@/transformers/advanced-search';

import { isEntity } from '@/typeguards/entity';
import type {
  AdvancedSearch$Field,
  AdvancedSearch$FieldType,
  AdvancedSearch$FieldOperator,
  AdvancedSearch$MatchRule,
  AdvancedSearch$ConditionOption,
} from '@/types/AdvancedSearch';

import type { Fork$SupportedComponent, Component$OpenerPosition } from '@/types/Component';

import type { Metadata$Field } from '@/types/Metadata';

const META_DATA_TYPE = 'fake';

@Options({
  components: { Fork },
})
export default class MatchBuilder extends Vue {
  @Prop({ type: String }) type: AdvancedSearch$FieldType;
  @Prop({ type: Number }) cond: AdvancedSearch$FieldOperator;
  @Prop({ type: String, default: () => 'bottom' })
  openerPosition: Component$OpenerPosition;
  @Prop({}) value: any;
  @Prop({}) secondValue: any;

  @Prop({ type: Boolean, default: false }) hideNestedBtn: boolean;
  @Prop({ type: Boolean, default: false }) showSettings: boolean;

  firstType = null;
  metaType = null;

  get fields(): Array<AdvancedSearch$Field> {
    return this.store.advancedSearchFields.list;
  }

  get commonFields(): Array<AdvancedSearch$Field> {
    const res = this.fields.filter((f) => !f.isMetadata);
    res.push({
      name: META_DATA_TYPE,
      displayName: this.metaLabel,
      type: 'System.String',
      id: META_DATA_TYPE,
    });
    return res;
  }

  get metaLabel(): string {
    return $t('common', 'match_builder__metadata_field');
  }

  get metadataFields(): Metadata$Field[] {
    return this.store.metadataFields.list;
  }

  get state(): AdvancedSearch$MatchRule {
    const { type, value, secondValue, cond, openerPosition } = this;
    return { type, value, secondValue, cond, openerPosition, nested: null };
  }

  get _isBetweenCond(): boolean {
    return this.cond === CONDITION_BETWEEN;
  }

  get showSecondValue(): boolean {
    return !!this.secondValueComponent;
  }

  get currentType(): AdvancedSearch$Field | Metadata$Field | null {
    const [asf] = this.fields.filter((t) => t.name === this.type);
    if (asf) {
      return asf;
    }

    const [mf] = this.metadataFields.filter((t) => t.id === this.type);

    return mf;
  }

  get commonType(): string {
    return this.isMetaDataField ? META_DATA_TYPE : this.type;
  }

  get conditions(): Array<AdvancedSearch$ConditionOption> | null {
    const currentType = this.currentType;
    return currentType ? conditions(currentType) : null;
  }

  get valueComponent(): Fork$SupportedComponent | null {
    const field = this.currentType;
    return field ? componentForFirstValue(field) : null;
  }

  get secondValueComponent(): Fork$SupportedComponent | null {
    const currentType = this.currentType;
    if (!currentType) {
      return null;
    }

    if (this._isBetweenCond) {
      return this.valueComponent;
    }

    return componentForSecondValue(currentType);
  }

  get isMetaDataField(): boolean {
    if (!this.type) {
      return false;
    }
    const [field] = this.fields.filter((f) => f.name === this.type);
    return !field;
  }

  onChangeType(type: string) {
    if (type === META_DATA_TYPE) {
      const [first] = this.metadataFields;
      type = first ? first.id : '';
    }
    this.$logger.debug('changeType', type);
    if (type) {
      this._refreshRule({ type });
    }
  }

  onChangeValue(val: unknown) {
    const value = _encodeValue(val);
    this.$logger.debug('changeValue', value);
    this._refreshRule({ value });
  }

  onChangeCond(cond: AdvancedSearch$FieldOperator) {
    this.$logger.debug('changeCond', cond);
    this._refreshRule({ cond });
  }

  onChangeSecondValue(value: unknown) {
    const secondValue = _encodeValue(value);
    this.$logger.debug('changeValue', secondValue);
    this._refreshRule({ secondValue });
  }

  _refreshRule(state: Partial<AdvancedSearch$MatchRule>) {
    this.$emit('input', { ...this.state, ...state });
  }

  created() {
    this.store.advancedSearchFields.load();
  }
}

function _encodeValue(value: unknown): Nullable<string> {
  if (value instanceof Date) {
    return dateToString(value, FORMAT);
  }

  if (isEntity(value)) {
    return value.id;
  }

  return typeof value === 'string' ? value : null;
}
