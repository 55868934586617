import { watch, computed } from 'vue';

const usePrompt = (enabled: () => boolean) => {
  const enablePrompt = computed(enabled);

  watch(
    () => enablePrompt.value,
    () => {
      if (enablePrompt.value) {
        window.onbeforeunload = () => true;
      }
      if (!enablePrompt.value) {
        window.onbeforeunload = null;
      }
    }
  );
};

export default usePrompt;
