import { $Props } from '@ravnur/core/typings/tsx';
import { Vue } from 'vue-class-component';

import MobileMenuItem from '../mobile-menu-item/mobile-menu-item';

import { MobileMenuItemInfo } from '@/types/MobileMenu';

import './generic-mobile-menu.scss';

class Props {
  items: MobileMenuItemInfo[];
}

type Emits = {
  onMore: (item: MobileMenuItemInfo) => void;
};

export const CN = 'generic-mobile-menu';

export default class GenericMobileMenu extends Vue.with(Props) {
  declare $props: $Props<Props, Emits>;

  render() {
    return (
      <ul class={CN}>
        {this.items.map((info) => (
          <MobileMenuItem key={info.id} info={info} onMore={this.more} />
        ))}
      </ul>
    );
  }

  private more(item: MobileMenuItemInfo) {
    this.$emit('more', item);
  }
}
