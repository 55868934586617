/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

const mock = new MockAdapter(axios, { delayResponse: 750 });

[
  require('./auth').default,
  require('./media').default,
  require('./source').default,
  require('./log').default,
  require('./featured').default,
  require('./featured-carousel').default,
  require('./search').default,
  require('./languages').default,
  require('./groups').default,
  require('./lookups').default,
  require('./participant').default,
  require('./processing').default,
  require('./live-channels').default,
  require('./live-events').default,
  require('./broadcast-channels').default,
  require('./operation').default,
  require('./jobs').default,
].forEach((fnc) => fnc(mock));
