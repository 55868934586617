import curry2, { CurriedFunction1 } from './curry2';

type Iterator<T, R> = (item: T) => R;

interface MapFnc {
  <T, R>(iteratees: Iterator<T, R>): CurriedFunction1<T[], R[]>;
  <T, R>(iteratees: Iterator<T, R>, arr: T[]): R[];
}

const map: MapFnc = curry2(_map);
export default map;

function _map<T, R>(iteratees: Iterator<T, R>, arr: T[]): R[] {
  return arr.map(iteratees);
}
