import { SetupContext } from '@vue/runtime-core';
import { defineComponent, h, TransitionGroup } from 'vue';

import { useNotifications } from './service';

export default defineComponent({
  setup(props: unknown, ctx: SetupContext) {
    return () => {
      const { notifications, hide } = useNotifications();
      return h(TransitionGroup, { name: 'notifications', tag: 'ul' }, () =>
        notifications.value.map((notification) => {
          return h('li', [ctx.slots.notification?.({ notification, hide })]);
        })
      );
    };
  },
});
