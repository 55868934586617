import Login from '@ravnur/auth/pages/login-page/login-page';
import { AuthService } from '@ravnur/auth/types/AuthService';
import { COMPONENT_CTX_PROVIDER_KEY } from '@ravnur/core/constants';
import { Prop, Provide } from '@ravnur/decorators';
import { wait } from '@ravnur/http';
import { PortalApplicationPage as PortalLayout } from '@ravnur/layout';
import { Vue } from 'vue-class-component';

import PortalHeader from '@/components/application-header/portal-header';
import { DEFAULT_PAGE_SIZE } from '@/config/constants';
import AuthRepository from '@/repositories/auth-repository';
import { Auth$Response } from '@/types/Auth';
import { ApplicationNames } from '@ravnur/shared/types/Application';
import MatomoAnalytics from '@/components/matomo-analytics';
import usePrompt from '@ravnur/hooks/usePrompt';
import matomo from '@/config/matomo';

const BASE_API_PREFIX = process.env.VUE_APP_API_URL || '';
const authService: AuthService<Auth$Response> = {
  repository: new AuthRepository(),
  operationWaiter,
  providerPathGenerator,
};

export default class PortalApplicationPage extends Vue {
  @Provide('PAGINATED_REPOSITORY_PAGE_SIZE')
  protected pageSize: number = DEFAULT_PAGE_SIZE;
  @Provide(COMPONENT_CTX_PROVIDER_KEY) protected context: ComponentContext = {
    disabled: false,
  };

  @Provide('application-name')
  protected applicationName = ApplicationNames.PORTAL;

  @Prop({ type: Function, default: () => ({}) }) registerGA: (gaId: string) => void;
  @Prop({ type: Function, default: () => ({}) }) registerGTM: (gtmId: string) => void;
  @Prop({ type: Function, default: () => ({}) }) registerAppInsights: (id: string) => void;
  @Prop({ type: Function, default: () => ({}) }) registerMatomo: (
    config: Record<string, any>
  ) => void;

  isMatomoEnabled = false;

  private get isAuth() {
    return this.store.user.isAuth;
  }

  private get slots() {
    return {
      header: this.renderHeaderMenu,
      matomo: this.renderMatomo,
    };
  }

  created() {
    usePrompt(() => this.store.upload.isProcessing);
  }

  render() {
    if (this.isAuth) {
      return (
        <PortalLayout
          currentSite={this.store.application.getCurrentSite}
          footerSettings={this.store.application.footerSettings}
          isAdmin={this.store.user.hasAccessToAdmin}
          logout={this.store.logout}
          portalSettings={this.store.application.settings}
          sites={this.store.application.sites}
          toAdminPanel={this.toAdminPanel}
          toMyAccount={this.toMyAccount}
          user={this.store.user.current as User$Details}
          v-slots={this.slots}
        />
      );
    }

    return <Login service={authService} onAuth={this.handleAuthEvent} onError={this.handleError} />;
  }

  private renderHeaderMenu() {
    return <PortalHeader />;
  }

  private renderMatomo() {
    return this.isMatomoEnabled && <MatomoAnalytics />;
  }

  private handleError(e: Record<string, any>) {
    if (e.status !== 401) {
      this.$processException(e);
    }
  }

  private handleAuthEvent(resp: Auth$Response) {
    this.store.handleAuthResponse(resp);
    this.store.jobs.pulling();
    this.store.live.pulling();
    this.store.languages.load();

    const { analytics } = resp.portalConfiguration;
    const currentSite = this.store.application.getCurrentSite;

    document.title = currentSite?.name || 'Media Portal';

    this.isMatomoEnabled = analytics.matomoAnalyticsEnabled;
    if (this.isMatomoEnabled) {
      this.registerMatomo({
        ...matomo,
        host: analytics.matomoHost,
        siteId: analytics.matomoSiteId,
        trackerUrl: analytics.matomoHost + `/matomo.php`,
      });
    }

    if (analytics.gaId) {
      this.registerGA(analytics.gaId);
    }

    if (analytics.gtmID) {
      this.registerGTM(analytics.gtmID);
    }

    // if (analytics.appInsightId) {
    //   this.registerAppInsights(analytics.appInsightId);
    // }

    // Temporary hardcode
    this.registerAppInsights(
      'InstrumentationKey=317ff99b-5d2e-4367-8de5-161188a18530;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/'
    );

    if (
      currentSite.startPage &&
      (window.location.hash === '#/home' || window.location.hash === '')
    ) {
      this.$router.push({ path: currentSite.startPage });
    }
  }

  private toMyAccount() {
    const site = this.store.application.sites.find((s) => s.isRoot && s.portalUrl);
    if (site && site.portalUrl) {
      window.location.href = `${site.portalUrl}#/account/videos`;
    }
  }

  private toAdminPanel() {
    const adminUrl = this.store.application.getCurrentSite?.adminUrl;
    if (adminUrl) {
      window.location.href = adminUrl;
    }
  }
}

function providerPathGenerator(loginEndpointPath: string): string {
  const url = encodeURIComponent(window.location.href);

  return `${BASE_API_PREFIX}/${loginEndpointPath}?url=${url}`;
}

async function operationWaiter(operationId: string): Promise<void> {
  await wait({
    entityId: null,
    errorReason: [],
    operationId,
    data: {},
    state: 0,
  });
}
