import { Vue } from 'vue-class-component';
import { Media$Types } from '@ravnur/shared/types/Media';
import { Inject } from '@ravnur/decorators';

let MA: any;

const customPlayer: any = (node: Record<string, any>, mediaType: string) => {
  if (node.hasPlayerInstance) {
    // prevent creating multiple trackers for the same media
    // when scanning for media multiple times
    return;
  }

  node.hasPlayerInstance = true;

  const actualResource = MA.element.getAttribute(node, 'src');

  // "data-matomo-resource" attribute has priority over "src" attribute
  const resource = MA.element.getMediaResource(node, actualResource);

  const tracker = new MA.MediaTracker('rmp', mediaType, resource);

  tracker.setWidth(node.clientWidth);
  tracker.setHeight(node.clientHeight);
  tracker.setFullscreen(MA.element.isFullscreen(node));

  const title = MA.element.getMediaTitle(node);
  tracker.setMediaTitle(title);

  tracker.setMediaTotalLengthInSeconds(node.duration);

  const useCapture = true;

  node.addEventListener(
    'play',
    () => {
      tracker.play();
    },
    useCapture
  );

  node.addEventListener(
    'pause',
    () => {
      tracker.pause();
    },
    useCapture
  );

  node.addEventListener(
    'ended',
    () => {
      tracker.finish();
    },
    useCapture
  );

  node.addEventListener(
    'timeupdate',
    () => {
      tracker.setMediaProgressInSeconds(node.currentTime);
      tracker.setMediaTotalLengthInSeconds(node.duration);
      tracker.update();
    },
    useCapture
  );

  node.addEventListener(
    'seeking',
    () => {
      tracker.seekStart();
    },
    true
  );

  node.addEventListener(
    'seeked',
    () => {
      tracker.setMediaProgressInSeconds(node.currentTime);
      tracker.setMediaTotalLengthInSeconds(node.duration);
      tracker.seekFinish();
    },
    useCapture
  );

  window.addEventListener(
    'resize',
    () => {
      tracker.setWidth(node.clientWidth);
      tracker.setHeight(node.clientHeight);
      tracker.setFullscreen(MA.element.isFullscreen(node));
    },
    useCapture
  );

  tracker.trackUpdate();
};

export default class MatomoAnalytics extends Vue {
  @Inject('current-user') private user: User$Details;

  matomoPullingTimeout: Nullable<NodeJS.Timeout> = null;

  mounted() {
    this.startMatomoPulling();
  }

  // Matomo loads asynchronously
  // See https://github.com/AmazingDreams/vue-matomo/issues/41
  // and https://github.com/AmazingDreams/vue-matomo/issues/3
  startMatomoPulling() {
    if (this.matomoPullingTimeout) {
      clearTimeout(this.matomoPullingTimeout);
    }

    if ((window as any).Matomo) {
      this.init();
    } else {
      this.matomoPullingTimeout = setTimeout(this.startMatomoPulling, 10);
    }
  }

  init() {
    MA = (window as any).Matomo.MediaAnalytics;
    this.pushRouteAnalytics();
    this.$router.afterEach(this.pushRouteAnalytics);
    this.initCustomMediaAnalytics();
  }

  pushRouteAnalytics() {
    const _paq = (window as any)._paq || [];

    _paq.push(['setCustomUrl', this.$route.fullPath]);
    _paq.push(['setDocumentTitle', this.$route.name]);
    _paq.push(['trackPageView']);
  }

  initCustomMediaAnalytics() {
    customPlayer.scanForMedia = () => {
      const videos = document.getElementsByTagName('video');
      const audios = document.getElementsByTagName('audio');
      const mediaElements = [...videos, ...audios];

      for (let i = 0; i < mediaElements.length; i++) {
        if (MA.element.isMediaIgnored(mediaElements[i])) {
          return;
        }

        if (mediaElements[i].getAttribute('data-matomo-type') === Media$Types.AUDIO) {
          customPlayer(mediaElements[i], MA.mediaType.AUDIO);
        } else {
          customPlayer(mediaElements[i], MA.mediaType.VIDEO);
        }
      }
    };

    // adding the newly created player to the Media Analytics tracker
    MA.addPlayer('rmp', customPlayer);
  }
}
