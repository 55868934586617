import logger from './logger';

import { CONDITIONS_FOR_TYPE, filterConditionsByKeys } from '@/config/advanced-search';

import { AdvancedSearch$Field, AdvancedSearch$ConditionOption } from '@/types/AdvancedSearch';
import { Metadata$Field } from '@/types/Metadata';

type Field = AdvancedSearch$Field | Metadata$Field;

export default function getConditionsFor(field: Field): Array<AdvancedSearch$ConditionOption> {
  logger.debug('detectConditions. Name: ', field.name);
  const operators = (field as any).operators;
  if (operators) {
    logger.debug('detectConditions. Use custom conditions: ', operators);
    return filterConditionsByKeys(operators);
  }
  const operatorsType = field.type;
  logger.debug('detectConditions. Use default for type: ', operatorsType);
  return CONDITIONS_FOR_TYPE[operatorsType];
}
