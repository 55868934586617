import curry2, { CurriedFunction1 } from './curry2';

interface HasFnc {
  (key: string): CurriedFunction1<Record<string, unknown>, boolean>;
  (key: string, o: Record<string, unknown>): boolean;
}

const has: HasFnc = curry2(_has);
export default has;

function _has<P extends string, T extends { [k in P]: unknown }>(key: P, o: T): boolean {
  return Object.prototype.hasOwnProperty.call(o, key);
}
