import { COMPONENT_CTX_PROVIDER_KEY } from '@ravnur/core/constants';
import { FooterSettings } from '@ravnur/shared/types/FooterSettings';
import { computed, defineComponent, PropType, provide } from 'vue';
import { PortalSettings } from '@ravnur/shared/types/PortalSettings';

import { Site } from '../types/Site';

import AppContent from './app-content';

import './portal-application-page.scss';

export default defineComponent({
  props: {
    user: { type: Object as PropType<User>, required: true },
    sites: { type: Array as PropType<Site[]>, required: true },
    currentSite: { type: Object as PropType<Site>, default: null },
    footerSettings: { type: Object as PropType<Nullable<FooterSettings>> },
    portalSettings: { type: Object as PropType<Nullable<Partial<PortalSettings>>> },
    logout: { type: Function as PropType<() => unknown>, required: true },
    isAdmin: { type: Boolean, required: true },
    sidebarOpen: { type: Boolean, default: false },
    toMyAccount: { type: Function as PropType<() => void>, required: true },
    toAdminPanel: { type: Function as PropType<() => void>, required: true },
  },
  setup(props, ctx) {
    provide('PAGINATED_REPOSITORY_PAGE_SIZE', 24);
    provide(COMPONENT_CTX_PROVIDER_KEY, { disabled: false });
    provide('sites', props.sites);
    provide('is-admin', props.isAdmin);
    provide('current-site', props.currentSite);
    provide('logout', props.logout);
    provide('footer-settings', props.footerSettings);
    provide('portal-settings', props.portalSettings);
    provide('is-admin', props.isAdmin);
    provide('to-my-account', props.toMyAccount);
    provide('to-admin-panel', props.toAdminPanel);

    provide(
      'sidebar-open',
      computed(() => props.sidebarOpen)
    );

    provide(
      'current-user',
      computed(() => props.user)
    );

    return () => <AppContent v-slots={ctx.slots} />;
  },
});
