import { defineComponent, PropType } from 'vue';
import { RouteLocationRaw, RouterLink } from 'vue-router';

export default defineComponent({
  props: {
    to: { type: Object as PropType<RouteLocationRaw> },
    disabled: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    return () => {
      const isDisabled = !props.to || props.disabled;
      const Tag: any = isDisabled ? 'span' : RouterLink;
      const cn = { 'router-link-active': isDisabled };
      return (
        <Tag class={cn} event={props.to ? 'click' : ''} style={{ cursor: 'pointer' }} to={props.to}>
          {ctx.slots.default?.()}
        </Tag>
      );
    };
  },
});
