import isiOS from '@ravnur/helpers/featured.detect/isiOS';
import normalizeClasses from '@ravnur/helpers/normalize-classes';
import omit from '@ravnur/nanoutils/omit';
import { ErrorObject } from '@vuelidate/core';
import { prop, Options, Vue } from 'vue-class-component';

import ValidationErrors from '../../components/validation-errors/validation-errors';
import { $Props } from '../../typings/tsx';

import './text-field.scss';

const CN = 'text-field';

class Props {
  value!: string;
  label = prop({ default: '' });
  size = prop<Component$Size>({ default: 'md' });
  errors = prop<ErrorObject[]>({ default: [] });
  formatter = prop({ default: /./ });
  icon = prop<Nullable<string>>({ default: null });
  isFocused = prop({ default: false });
  requiredAsterisk = prop({ default: false });
  maxLength = prop({ default: 100 });
  clearable = prop({ default: false });
  isFakePassword = prop({ default: false });
  onBlur?: () => void;
  onFocus?: () => void;
  onInput?: (e: Event) => void;
  onKeypress?: (e: KeyboardEvent) => void;
}

export type TextField$Props = $Props<Props, unknown, 'value'> & Partial<HTMLInputElement>;

const withoutClass = omit(['class', 'style']);

@Options({
  // should be false otherwise masked-input will be broken
  inheritAttrs: false,
  name: 'text-field',
  emits: ['update:value', 'input', 'blur', 'focus', 'keypress'],
})
export default class TextField extends Vue.with(Props) {
  declare $refs: {
    field: HTMLInputElement;
  };

  focus() {
    this.$refs.field.focus();
  }

  render() {
    const cn = normalizeClasses(
      {
        [CN]: true,
        [`${CN}--${this.size}`]: true,
        [`${CN}--error`]: !!this.errors.length,
        [`${CN}--low`]: !this.label,
        [`${CN}--disabled`]: this.$attrs.disabled,
        [`${CN}--focused`]: this.isFocused,
      },
      this.$attrs.class
    );

    return (
      <label class={cn}>
        <span class={`${CN}__wrapper`}>
          {this._input()}
          {this._icon()}
          <span class={`${CN}__label`}>
            {this.label}
            <b class={`${CN}__label--asterisk`}>{this.requiredAsterisk && '*'}</b>
          </span>
          {this.$slots.actions?.()}
          {this.$slots.default?.()}
        </span>
        <ValidationErrors errors={this.errors} />
      </label>
    );
  }

  private _icon() {
    const { icon: type } = this;
    if (!type) {
      return null;
    }
    return (
      <div class={`${CN}__icon-placeholder`}>
        <icon class={`${CN}__icon`} type={type} />
      </div>
    );
  }

  private _input() {
    const cn = `${CN}__input`;
    const attrs = withoutClass(this.$attrs as Record<string, unknown>);
    return (
      <input
        ref="field"
        class={[cn, this.icon ? `${cn}--icon` : '']}
        maxlength={this.maxLength}
        type="text"
        value={this.value}
        onBlur={() => this.$emit('blur')}
        onFocus={() => this.$emit('focus')}
        onInput={this.handleInputEvent}
        onKeypress={(e: KeyboardEvent) => this.$emit('keypress', e)}
        {...attrs}
      />
    );
  }

  private _clearButton() {
    const { clearable, value } = this;

    if (!clearable || !value) {
      return null;
    }

    return (
      <div class={`${CN}__clear-button`} onClick={this.handleClearButtonClick}>
        <icon size="md" type="cancel" />
      </div>
    );
  }

  private handleClearButtonClick() {
    this.$emit('update:value', '');
  }

  private handleInputEvent(e: Event) {
    const { target } = e;
    if (!(target instanceof HTMLInputElement)) {
      return;
    }

    const { formatter, value } = this;
    const val = target.value;
    if (val && !(formatter || /./).test(val)) {
      e.preventDefault();
      e.stopPropagation();
      target.value = value;
      return;
    }
    if (isiOS) {
      (this.$attrs as any).oninput?.(e);
      (this.$attrs as any).onInput?.(e);
    }

    this.$emit('update:value', target.value);
    this.$emit('input', { ...e, target });
  }

  preventCopyOnFakePasswordFields() {
    if (!this.isFakePassword) {
      return;
    }

    this.$refs.field.addEventListener('copy', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }

  mounted() {
    this.preventCopyOnFakePasswordFields();
  }
}
