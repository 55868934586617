import curry2, { CurriedFunction1 } from './curry2';
import isNil from './isNil';

interface FindFnc {
  <T>(iteratees: Predicate<T>): CurriedFunction1<T[], null | number>;
  <T>(iteratees: Predicate<T>, arr: T[]): null | number;
}

const findIndex: FindFnc = curry2(_findIndex);
export default findIndex;

function _findIndex<T>(iteratees: Predicate<T>, arr: T[]): null | number {
  return arr.reduce((res: null | number, e: T, idx: number) => {
    return isNil(res) && iteratees(e) ? idx : res;
  }, null);
}
