import BaseChosen, { OpenerSlotProps } from '@ravnur/core/components/base-chosen/base-chosen';
import TextField from '@ravnur/core/ui-kit/text-field/text-field';
import { Watch, Debounce } from '@ravnur/decorators';
import { CrudAPI } from '@ravnur/http';
import { Logger } from '@ravnur/logger';
import { ErrorObject } from '@vuelidate/core';
import { Options, prop, Vue } from 'vue-class-component';

import { SEARCH_DEBOUNCE_TIME } from '../../config/constants';
import entities from '../../config/pathes';
import { LookupItem } from '../../types/Media';

import './lookup.scss';

const CN = 'lookup';

const LOOKUP_COUNT = 25;
const logger = new Logger('Lookup');

type Option = Entity & Record<string, unknown>;
type Label = string;
type Key = string;

export type Lookup$Type = 'user' | 'category' | 'tag' | 'group' | 'participant';

class Props {
  label = prop({ default: '' });
  placeholder = prop({ default: '' });
  value?: Key;
  type?: Lookup$Type;
  size?: Component$Size;
  searchApi?: CrudAPI<any>;
  autoSelect = prop({ default: true });
  canCreate = prop({ default: false });
  clearOnBlur = prop({ default: true });
  renderLabel = prop({ default: true });
  hasClear = prop({ default: false });
  keyForLabel = prop({ default: 'name' });
  props = prop<Record<string, unknown>>({ default: _empty });
  maxlength?: number;
  optionTagName = prop({ default: 'button' });
  errors = prop<ErrorObject[]>({ default: [] });

  onSelect: (item: Nullable<LookupItem>) => void;
}

@Options({
  emits: ['input', 'select', 'blur'],
})
export default class Lookup extends Vue.with(Props) {
  declare $refs: {
    field: TextField;
    chosen: BaseChosen<Option> | undefined;
  };

  private options: Array<Option> = [];
  private loading = false;
  private filter = '';

  private get selected(): Option | undefined {
    return this.options.find(this.isCurrent);
  }

  private get api(): CrudAPI<any> {
    if (this.searchApi) {
      return this.searchApi;
    }

    if (this.type === 'tag') {
      return new CrudAPI(entities.LOOKUP_TAGS);
    } else if (this.type === 'user') {
      return new CrudAPI(entities.LOOKUP_USERS);
    } else if (this.type === 'group') {
      return new CrudAPI(entities.LOOKUP_GROUPS);
    } else if (this.type === 'category') {
      return new CrudAPI(entities.LOOKUP_CATEGORIES);
    } else if (this.type === 'participant') {
      return new CrudAPI(entities.PARTICIPANT);
    }

    throw new Error(`Unsupported type ${this.type}`);
  }

  private get chosenSlots() {
    return {
      button: this.renderInput,
      option: this.$slots.option,
    };
  }

  @Watch('type')
  protected onTypeChanged() {
    this.onChangeFilter('');
    this.options.length = 0;
  }

  private select(option: Option) {
    this.filter = this.label4option(option);
    this.$emit('input', this.key4option(option));
    this.onSelect?.(option as any);
    if (this.clearOnBlur) {
      this.filter = '';
    }

    this.options = [];

    this.$nextTick(this.deactivate);
  }

  private deactivate() {
    this.$refs.chosen?.hide();
  }

  private key4option(option: Option): Key {
    return option.id;
  }

  private label4option(option: Option): Label {
    return (option as any)[this.keyForLabel];
  }

  private isCurrent(option: Option): boolean {
    return this.value === this.key4option(option);
  }

  private onChangeFilter(q: string) {
    const { selected } = this;
    this.filter = q;
    this.$emit('input', null);
    if (this.autoSelect && selected && this.label4option(selected) !== q) {
      this.onSelect?.(null);
    }
    this.$nextTick(() => {
      if (q && !this.value) {
        this.$refs.chosen?.open();
        this.loadOptions();
      } else {
        this.options = [];
      }
    });
  }

  @Debounce(SEARCH_DEBOUNCE_TIME)
  private async loadOptions() {
    const q = this.filter;
    if (this.loading || !q) {
      return;
    }

    if (this.value && this.autoSelect) {
      return;
    }

    this.loading = true;
    try {
      const { items } = (await this.api.load({
        ...this.props,
        q,
        count: LOOKUP_COUNT,
      })) as any;
      this.options = items;
      this.$refs.chosen?.open();
    } catch (e) {
      logger.error(e);
    }
    this.loading = false;
  }

  private async loadByValue() {
    const { value } = this;
    if (!value) {
      return;
    }

    this.loading = true;
    try {
      const selected = await this.api.get(value);
      this.select(selected);
    } catch (e) {
      logger.error(e);
    }
    this.loading = false;
  }

  focus() {
    this.$refs.field.focus();
  }

  clear() {
    this.filter = '';
    this.options = [];
  }

  created() {
    this.loadByValue();
  }

  render() {
    return (
      <BaseChosen
        ref="chosen"
        class="lookup"
        current={null}
        keyForLabel={this.keyForLabel}
        options={this.options}
        v-slots={this.chosenSlots}
        onInput={this.select}
      />
    );
  }

  private renderInput({ toggle }: OpenerSlotProps) {
    const $attrs = { ...this.$attrs };
    delete $attrs.class;
    delete $attrs.style;
    return (
      <>
        <text-field
          ref="field"
          class={`${CN}__field`}
          errors={this.errors}
          label={this.label}
          maxLength={this.maxlength}
          placeholder={this.placeholder}
          vModel={[this.filter, 'value']}
          {...$attrs}
          size={'sm' as unknown as undefined}
          onBlur={this.handleBlurEvent}
          onFocus={toggle}
          onInput={(ev: Event) => this.onChangeFilter((ev.target as HTMLInputElement).value)}
          onKeypress={this.handleEnterPressedEvent}
        >
          <>
            <span class={`${CN}__label`}>{this.$slots.label?.()}</span>
            {this.filter && this.hasClear && (
              <span class={`${CN}__clear`}>
                <r-button color="white" icon="close" mode="borderless" onclick={this.clear} />
              </span>
            )}
          </>
        </text-field>
        {this.loading ? <spinner class={`${CN}__spinner`} size="sm" think={true} /> : null}
      </>
    );
  }

  private handleEnterPressedEvent(e: KeyboardEvent) {
    if (this.canCreate && e.keyCode === 13) {
      this.createNewEntity();
      this.$nextTick(() => this.clear());
    }
  }

  private createNewEntity() {
    if (!this.filter.trim()) {
      return;
    }

    this.onSelect?.({ id: null, name: this.filter } as any);
    this.$nextTick(() => {
      if (this.errors.length) {
        return;
      }
      this.options.length = 0;
    });
  }

  private handleBlurEvent() {
    if (this.clearOnBlur || !this.canCreate) {
      return;
    }
    setTimeout(() => {
      if (!this.value) {
        this.createNewEntity();
      }
    }, 400);
  }
}

function _empty() {
  return {};
}
