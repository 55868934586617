import Opener, { OpenerSlotProps } from '@ravnur/core/components/opener/opener';
import { Watch } from '@ravnur/decorators';
import isMobile from '@ravnur/helpers/featured.detect/isMobile';
import { renderSlot } from 'vue';
import { Vue } from 'vue-class-component';

import './application-submenu.scss';

class Props {
  options: Array<Record<string, unknown>>;
  isMobile: boolean;
}

const CN = 'application-submenu';

export default class ApplicationSubmenu extends Vue.with(Props) {
  declare $refs: {
    opener: Opener;
  };

  private get slots() {
    return {
      summary: this.renderSummaryButton,
      details: this.renderItems,
    };
  }

  render() {
    return <Opener ref="opener" class={CN} v-slots={this.slots} />;
  }

  @Watch('$route.query')
  @Watch('$route.name')
  protected handleRouteChanged() {
    this.$refs.opener.hide();
  }

  private renderSummaryButton({ toggle }: OpenerSlotProps) {
    return (
      <button class={`${CN}__toggler`} type="button" onClick={toggle}>
        <span class={`${CN}__value`}>{this.$slots.title?.()}</span>
      </button>
    );
  }

  private renderItems() {
    const cn = {
      [`${CN}__scrollable`]: true,
      [`${CN}__scrollable--mobile`]: isMobile,
    };
    return (
      <>
        <r-button class={`${CN}__mobile-title`} color="grey" icon="arrow-back" mode="frameless">
          {renderSlot(this.$slots, 'mobile-title')}
        </r-button>
        <scrollable class={cn}>
          <ul class={`${CN}__items`}>{this.options.map(this.renderItem)}</ul>
        </scrollable>
      </>
    );
  }

  private renderItem(item: Record<string, unknown>, idx: number) {
    return (
      <li key={idx} class={`${CN}__item`}>
        {renderSlot(this.$slots, 'item', { item })}
      </li>
    );
  }
}
