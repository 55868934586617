import MockAdapter from 'axios-mock-adapter/types';
import toBackendFormat from '../transformers/http/toBackendFormat';
import { _generateChannel } from './helper-fakers';

export default function mocker(mock: MockAdapter) {
  mock.onGet(/\/api\/v1\.0\/live\/channel/).reply(_get);
}

function _get() {
  return [200, toBackendFormat(_generateChannel())];
}
