import last from '@ravnur/nanoutils/last';
import { AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter/types';

import toBackendFormat from '../transformers/http/toBackendFormat';

import { integer } from './casual';

const data: Dictionary<string> = {};

export default function mocker(mock: MockAdapter) {
  mock.onGet(/\/operation\/\d+/).reply(_get);
}

function _get(config: AxiosRequestConfig) {
  const url = config.url || '';
  const operationId = last(url.split('/')) || '';
  const entityId = data[operationId];

  if (entityId) {
    const state = integer(2) as 0 | 1 | 2;
    if (state !== 0) {
      delete data[operationId];
    }
    const operation: Operation = {
      entityId,
      state,
      operationId,
      errorReason: [],
      data: {},
    };

    operation.errorReason = [
      'Metadata field "decimal" with value "1.23456789012346E+17" isn\'t valid. Please change the value.',
      'Metadata field "decimal" with value "1.23456789012346E+17" isn\'t valid. Please change the value.',
    ];

    return [200, toBackendFormat(operation)];
  }

  return [404];
}

export function startOperation(entityId: string): Operation {
  const operationId = `${Date.now()}`;
  data[operationId] = entityId;
  return {
    entityId: null,
    state: 0,
    operationId,
    errorReason: [],
    data: {},
  };
}
