import Opener, { OpenerSlotProps } from '@ravnur/core/components/opener/opener';
import { Watch } from '@ravnur/decorators';
import { Vue } from 'vue-class-component';

import ApplicationSubcategories from './application-subcategories/application-subcategories';

import toMatrix from '@/transformers/toMatrix';
import { CategoryNode } from '@/types/Category';

import './application-categories.scss';

const CN = 'application-categories';
const MAX_COLUMN_LENGTH = 12;
const MAX_COLUMN_COUNT = 4;

class Props {
  isMobile: boolean;
}

export default class ApplicationCategories extends Vue.with(Props) {
  declare $refs: {
    opener: Opener;
  };

  private opened: Nullable<CategoryNode> = null;

  private get categories(): Array<CategoryNode> {
    return this.store.application.getApplicationCategories;
  }

  private get columns(): Array<Array<CategoryNode>> {
    return toMatrix(this.categories, MAX_COLUMN_LENGTH);
  }

  private get maxColumnsCount(): number {
    return Math.min(MAX_COLUMN_COUNT, this.columns.length);
  }

  private get maxRowsCount(): number {
    const [column] = this.columns;
    const opened = this.opened;
    const subRowsCount = opened ? (opened.children || []).length : +'0';
    return Math.max(column.length, Math.min(subRowsCount, MAX_COLUMN_LENGTH));
  }

  private get slots() {
    return {
      summary: this.renderSummary,
      details: this.renderMatrix,
    };
  }

  @Watch('$route.query')
  @Watch('$route.name')
  protected onRefreshRouter() {
    this.closeSubCategories();
    this.$refs.opener.hide();
  }

  render() {
    return <Opener ref="opener" class={CN} v-slots={this.slots} />;
  }

  private renderSummary({ toggle }: OpenerSlotProps) {
    return (
      <button class={`${CN}__toggler`} type="button" onClick={toggle}>
        <l10n class={`${CN}__value`} group="header" tkey="menu__categories" />
      </button>
    );
  }

  private renderMatrix() {
    const cn = {
      [`${CN}__opener`]: true,
      [`${CN}__opener--c${this.maxColumnsCount}`]: this.maxColumnsCount,
      [`${CN}__opener--r${this.maxRowsCount}`]: this.maxRowsCount,
      [`${CN}__opener--showing-subcategories`]: this.opened,
    };
    return (
      <>
        <r-button class={`${CN}__mobile-title`} color="grey" icon="arrow-back" mode="frameless">
          <l10n class={`${CN}__value`} group="header" tkey="menu__categories" />
        </r-button>
        <div class={cn}>{this.columns.map(this.renderColumn)}</div>
      </>
    );
  }

  private renderColumn(nodes: CategoryNode[], idx: number) {
    return (
      <ul key={idx} class={`${CN}__items`}>
        {nodes.map(this.renderItem)}
      </ul>
    );
  }

  private renderItem(node: CategoryNode) {
    const showChildren = () => this.showChildrenFor(node);
    return (
      <li key={node.id} class={`${CN}__item`}>
        <router-link
          class={`${CN}__item-name`}
          title={node.name}
          to={{ name: 'MediaCategories', params: { cid: `${node.id}` } }}
        >
          {node.name}
        </router-link>
        <r-button
          class={`${CN}__show-children`}
          color="black"
          icon="arrow-forward"
          mode="frameless"
          onclick={showChildren}
          v-show={node.children?.length}
        />
        {this.renderSubCategories(node)}
      </li>
    );
  }

  private renderSubCategories(node: CategoryNode) {
    if (this.opened?.id === node.id) {
      return <ApplicationSubcategories category={node} onBack={this.handleBackEvent} />;
    }
  }

  private handleBackEvent() {
    this.opened = null;
  }

  private showChildrenFor(node: CategoryNode) {
    this.opened = node;
  }

  private closeSubCategories() {
    this.opened = null;
  }
}
