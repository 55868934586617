import { defineComponent, onMounted, ref } from 'vue';
import { FeaturedCarouselItem } from '@/components/home/featured-carousel/featured-carousel-item';
import FeaturedCarouselRepository from '@/repositories/featured-carousel-repository';
import { FeaturedCarousel } from '@/types/Media';
import { $processException } from '@/helpers/error-handler';

const carouselRepository = new FeaturedCarouselRepository();

const FeaturedCarouselList = defineComponent({
  setup() {
    const carousels = ref<FeaturedCarousel[]>([]);

    const fetchCarousel = async () => {
      try {
        carousels.value = await carouselRepository.load();
        carousels.value = carousels.value.filter(
          (carousel: FeaturedCarousel) => carousel.isEnabled
        );
        carousels.value.sort((a: FeaturedCarousel, b: FeaturedCarousel) => a.order - b.order);
      } catch (e) {
        $processException(e);
      }
    };

    onMounted(fetchCarousel);

    return () =>
      carousels.value.map((carousel: FeaturedCarousel) => (
        <FeaturedCarouselItem
          key={carousel.id}
          hasControls
          carousel={carousel}
          mediaItems={carousel.mediaItems}
        />
      ));
  },
});

export default FeaturedCarouselList;
