import { Options, prop, Vue } from 'vue-class-component';

import { $Props } from '../../typings/tsx';

import './badge.scss';

const CN = 'badge';

export type Badge$Mode =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'grey';
export type Badge$Variant = 'standard' | 'outlined';

export class Props {
  mode = prop<Badge$Mode>({ default: 'danger' });
  variant = prop<Badge$Variant>({ default: 'standard' });
  tag = prop<string>({ default: 'span' });
}

export type Badge$Props = $Props<Props>;

@Options({
  inheritAttrs: false,
})
export default class Badge extends Vue.with(Props) {
  render() {
    const cn = {
      [CN]: true,
      [`${CN}--${this.mode}`]: this.mode,
      [`${CN}--${this.variant}`]: this.variant,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Tag: any = this.tag;
    return (
      <Tag class={cn} {...this.$attrs}>
        {this.$slots.default?.()}
      </Tag>
    );
  }
}
