import { $Props } from '@ravnur/core/typings/tsx';
import $t from '@ravnur/l10n/$t';
import { useVuelidate } from '@vuelidate/core';

import { required, email } from '@vuelidate/validators';
import { Options, setup, Vue } from 'vue-class-component';

import { messageFromError } from '../../transformers/message-from-error';
import { AuthRepository } from '../../types/AuthService';
import { Message } from '../../types/Message';
import AuthMessage from '../auth-message/auth-message';
import AuthSubmitBtn from '../auth-submit-btn/auth-submit-btn';
import BaseAuthForm from '../base-auth-form/base-auth-form';

import './registration-form.scss';

class Props {
  repository: AuthRepository<unknown>;
}

type Emits = {
  onMessage: (message: Message) => void;
};

const CN = 'registration-form';

@Options({
  name: 'registration-form',
  emits: ['message'],
  validations: {
    firstName: { required },
    lastName: { required },
    email: { required, email },
  },
  validationConfig: {
    $autoDirty: true,
  },
})
export default class RegistrationForm extends Vue.with(Props) {
  declare $props: $Props<Props, Emits>;

  private firstName = '';
  private lastName = '';
  private email = '';

  private successMessage: Nullable<Message> = null;

  private v$ = setup(() => useVuelidate());

  private get isFilled() {
    const { firstName, lastName, email: e } = this;
    return !!firstName.trim() && !!lastName.trim() && !!e;
  }

  render() {
    if (this.successMessage) {
      return (
        <div class={CN}>
          <AuthMessage message={this.successMessage} />
        </div>
      );
    }

    return (
      <BaseAuthForm class={CN} submit={this.registration} v={this.v$}>
        <text-field
          errors={this.v$.firstName.$errors}
          maxLength={50}
          placeholder={$t('login', 'firstName')}
          vModel={[this.firstName, 'value']}
        />
        <text-field
          errors={this.v$.lastName.$errors}
          maxLength={50}
          placeholder={$t('login', 'lastName')}
          vModel={[this.lastName, 'value']}
        />
        <text-field
          errors={this.v$.email.$errors}
          placeholder={$t('login', 'email')}
          vModel={[this.email, 'value']}
        />

        <AuthSubmitBtn class={`${CN}__btn`} disabled={!this.isFilled} label="register" />
      </BaseAuthForm>
    );
  }

  private async registration() {
    const { firstName, lastName } = this;
    try {
      await this.repository.registration({ firstName, lastName, email: this.email });
      this.handleMessage(null);
      this.successMessage = {
        type: 'info',
        text: $t('login', 'registrationSuccess'),
      };
      this.resetForm();
    } catch (e: any) {
      this.handleMessage(messageFromError(e));
    }
  }

  private handleMessage(message: Nullable<Message>) {
    this.$emit('message', message);
  }

  private resetForm() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.v$.$reset();
  }
}
