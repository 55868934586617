import { Vue, Options, prop } from 'vue-class-component';

import ApplicationCategories from '../application-categories/application-categories';

import ApplicationSubmenu from '../application-submenu/application-submenu';

import { MediaOption } from '@/config/media-types';
import { getAvailableTypes } from '@/helpers/media';
import { LiveEvent$Status } from '@ravnur/shared/types/Live';

const COMMUNITY_OPTIONS = [{ tkey: 'community__groups', to: { name: 'Groups' } }];

class Props {
  isMobile = prop({ default: false });
}

@Options({
  components: { ApplicationSubmenu, ApplicationCategories },
})
export default class ApplicationNav extends Vue.with(Props) {
  get configuration() {
    return this.store.application;
  }

  get mediaTypes(): MediaOption[] {
    return getAvailableTypes();
  }

  get communityOptions() {
    return COMMUNITY_OPTIONS;
  }

  get liveStreams() {
    return this.store.live.all.filter((e) => e.status === LiveEvent$Status.ON_AIR).length;
  }

  get hasCategories(): boolean {
    return this.configuration.isCategories && this.configuration.hasCategories;
  }

  get isLiveStreaming(): boolean {
    return this.configuration.isLiveStreaming;
  }

  get isGroups(): boolean {
    return this.configuration.isGroups;
  }

  isActive(routeNames: string[]) {
    return routeNames.includes(this.$route.name as string);
  }
}
