import dateFormatter from '@ravnur/core/filters/date';

import {
  AdvancedSearch$MatchOption,
  AdvancedSearch$ConditionOption,
  AdvancedSearch$FieldOperator,
  AdvancedSearch$FieldType,
  AdvancedSearch$Value,
} from '@/types/AdvancedSearch';

import { Fork$SupportedComponent } from '@/types/Component';

export const MATCHES: AdvancedSearch$MatchOption[] = [
  { id: 0, tkey: 'query_builder__match_all' },
  { id: 1, tkey: 'query_builder__match_any' },
];

export const CONDITION_EQ: AdvancedSearch$FieldOperator = 0;
export const CONDITION_GT: AdvancedSearch$FieldOperator = 1;
export const CONDITION_GTE: AdvancedSearch$FieldOperator = 2;
export const CONDITION_LT: AdvancedSearch$FieldOperator = 3;
export const CONDITION_LTE: AdvancedSearch$FieldOperator = 4;
export const CONDITION_CONT: AdvancedSearch$FieldOperator = 5;
export const CONDITION_NOT_CONT: AdvancedSearch$FieldOperator = 6;
export const CONDITION_BETWEEN: AdvancedSearch$FieldOperator = 7;
export const CONDITION_NOT_EQ: AdvancedSearch$FieldOperator = 8;
export const CONDITION_AFTER: AdvancedSearch$FieldOperator = 9;
export const CONDITION_ON_AFTER: AdvancedSearch$FieldOperator = 10;
export const CONDITION_BEFORE: AdvancedSearch$FieldOperator = 11;
export const CONDITION_ON_BEFORE: AdvancedSearch$FieldOperator = 12;
export const CONDITION_ON: AdvancedSearch$FieldOperator = 13;

const CONDITIONS: AdvancedSearch$ConditionOption[] = [
  { id: CONDITION_EQ, tkey: 'query_builder__cond_equal' },
  { id: CONDITION_GT, tkey: 'query_builder__cond_greater' },
  { id: CONDITION_GTE, tkey: 'query_builder__cond_greater_eq' },
  { id: CONDITION_LT, tkey: 'query_builder__cond_less' },
  { id: CONDITION_LTE, tkey: 'query_builder__cond_less_eq' },
  { id: CONDITION_CONT, tkey: 'query_builder__cond_contains' },
  { id: CONDITION_NOT_CONT, tkey: 'query_builder__cond_not_contains' },
  { id: CONDITION_BETWEEN, tkey: 'query_builder__cond_between' },
  { id: CONDITION_NOT_EQ, tkey: 'query_builder__cond_not_eq' },
  { id: CONDITION_AFTER, tkey: 'query_builder__cond_after' },
  { id: CONDITION_ON_AFTER, tkey: 'query_builder__cond_on_after' },
  { id: CONDITION_BEFORE, tkey: 'query_builder__cond_before' },
  { id: CONDITION_ON_BEFORE, tkey: 'query_builder__cond_on_before' },
  { id: CONDITION_ON, tkey: 'query_builder__cond_on' },
];

type CacheType = {
  [key in AdvancedSearch$FieldOperator]: AdvancedSearch$ConditionOption;
};
const cache: CacheType = CONDITIONS.reduce((memo, option) => {
  memo[option.id] = option;
  return memo;
}, {} as CacheType);

const INT: AdvancedSearch$ConditionOption[] = filterConditionsByKeys([
  CONDITION_EQ,
  CONDITION_GT,
  CONDITION_GTE,
  CONDITION_LT,
  CONDITION_LTE,
  CONDITION_BETWEEN,
]);

const DATE: AdvancedSearch$ConditionOption[] = filterConditionsByKeys([
  CONDITION_GT,
  CONDITION_AFTER,
  CONDITION_ON_AFTER,
  CONDITION_BEFORE,
  CONDITION_ON_BEFORE,
  CONDITION_BETWEEN,
]);

const STRING: AdvancedSearch$ConditionOption[] = filterConditionsByKeys([
  CONDITION_EQ,
  CONDITION_CONT,
  CONDITION_NOT_CONT,
]);

export const CONDITIONS_FOR_TYPE: {
  [key in AdvancedSearch$FieldType]: AdvancedSearch$ConditionOption[];
} = {
  'System.String': STRING,
  'System.Int32': INT,
  'System.Double': INT,
  'System.DateTime': DATE,
};

export const COMPONENT_FOR_TYPE: {
  [key in AdvancedSearch$FieldType]: Fork$SupportedComponent;
} = {
  'System.String': 'str-input',
  'System.Int32': 'int-input',
  'System.Double': 'float-input',
  'System.DateTime': 'datepicker',
};

type FormatterType = {
  [key in AdvancedSearch$FieldType]: (val: AdvancedSearch$Value) => string;
};
export const FORMATTER_FOR_TYPE: FormatterType = {
  'System.String': identity,
  'System.Int32': identity,
  'System.Double': identity,
  'System.DateTime': dateFormatter as (val: AdvancedSearch$Value) => string,
};

export const TYPES: Array<{ id: AdvancedSearch$FieldType; tkey: string }> = [
  { id: 'System.String', tkey: 'metadata__type-string' },
  { id: 'System.Int32', tkey: 'metadata__type-number' },
  { id: 'System.Double', tkey: 'metadata__type-decimal' },
  { id: 'System.DateTime', tkey: 'metadata__type-date' },
];

// TODO move to transformers
export function filterConditionsByKeys(
  keys: AdvancedSearch$FieldOperator[]
): AdvancedSearch$ConditionOption[] {
  return keys.map((o) => cache[o]);
}

function identity(str: AdvancedSearch$Value): string {
  return `${str}`;
}
