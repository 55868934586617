import { $Props } from '@ravnur/core/typings/tsx';
import { Watch } from '@ravnur/decorators';
import { Vue, Options, prop } from 'vue-class-component';

import './search-input.scss';

const CN = 'search-input';

type Mode = 'fly' | 'manual';

type Type = 'primary' | 'secondary';

class Props {
  value = prop({ default: '' });
  placeholder = prop({ default: 'search' });
  showClear = prop({ default: true });
  loading = prop({ default: false });
  mode = prop<Mode>({ default: 'fly' });
  type = prop({ type: String as () => Type, default: 'primary' });
}

type Emits = {
  onInput: (value: string) => void;
};

export type SearchInput$Props = $Props<Props, Emits, 'value'> & Partial<HTMLInputElement>;

@Options({
  emits: ['input', 'update:value'],
})
export default class SearchInput extends Vue.with(Props) {
  declare $props: SearchInput$Props;
  declare $refs: {
    field: HTMLInputElement;
  };

  public q = '';

  mounted() {
    if (this.$attrs.autofocus) {
      this.$refs.field.focus();
    }
  }

  private get isManualMode() {
    return this.mode === 'manual';
  }

  @Watch('value', { immediate: true })
  protected handleValueChanged() {
    this.q = this.value;
  }

  render() {
    const { loading, isManualMode } = this;
    const bmode = this.q && this.isManualMode ? 'standard' : 'frameless';
    const bcolor = this.q && this.isManualMode ? 'primary' : 'black';
    return (
      <div class={[CN, `${CN}--${this.type}`]}>
        <input
          ref="field"
          class={`${CN}__field`}
          data-testid="field"
          type="search"
          value={this.q}
          {...this.$attrs}
          placeholder={this.placeholder}
          onInput={this.isManualMode ? this.setQ : this.emitSearchEvent}
          onKeypress={this.isManualMode ? this.handleKeypressEvent : noop}
          // Blocking native search event for some browsers
          // see https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/search_event
          {...{ onsearch: (e: Event) => e.stopPropagation() }}
        />

        <r-button
          aria-label="Search"
          class={`${CN}__search-btn`}
          color={bcolor}
          data-testid="search-button"
          icon="search"
          mode={bmode}
          onclick={isManualMode ? this.handleSearchClick : noop}
        />

        {loading ? (
          <spinner class={`${CN}__spinner`} data-testid="spinner" size="md" think={true} />
        ) : (
          this.renderClearButton()
        )}
      </div>
    );
  }

  private renderClearButton() {
    const show = this.showClear && this.q;

    return (
      <r-button
        aria-label="Clear search field"
        /* Hide the element instead of not rendering it bc the outside listener gets invoked after the element
        is removed and consider a click on it to be outside the parent element since it no longer exists whatsoever */
        class={`${CN}__clear ${!show && 'hidden'}`}
        color="primary"
        data-testid="clear-button"
        icon="cancel"
        mode="frameless"
        onclick={this.emitClearEvent}
        size="lg"
      />
    );
  }

  private handleSearchClick() {
    if (this.isManualMode) {
      this.emitChangeValue(this.q);
    }
  }

  private handleKeypressEvent(ev: KeyboardEvent) {
    if (ev.which === 13 || ev.keyCode === 13) {
      this.emitSearchEvent(ev);
    }
  }

  // https://stackoverflow.com/questions/50617865/vue-v-model-input-change-mobile-chrome-not-work
  private setQ({ target }: Event) {
    if (target instanceof HTMLInputElement) {
      this.q = target.value;
    }
  }

  private emitSearchEvent(ev: Event) {
    this.setQ(ev);
    this.emitChangeValue(this.q);
  }

  private emitClearEvent() {
    if (this.value) {
      this.emitChangeValue('');
    } else {
      this.q = '';
    }
  }

  private emitChangeValue(q: string) {
    this.$emit('input', q);
    this.$emit('update:value', q);
  }
}

function noop() {
  //
}
