import ModalService from '@ravnur/modal';
import BaseNotifications from '@ravnur/notifications/notifications';
import { RavnurNotification } from '@ravnur/notifications/typings';
import { defineComponent } from 'vue';

import PortalAlert from '../alert/alert';

import './notifications.scss';

type ItemData = {
  notification: RavnurNotification;
  hide: (notification: RavnurNotification) => void;
};

const CN = 'notifications';

export default defineComponent({
  setup() {
    const renderNotification = (data: ItemData) => {
      return (
        <PortalAlert
          key={data.notification.timestamp}
          class={`${CN}__item`}
          tag="li"
          type={data.notification.type}
          value={[data.notification.message]}
          onHide={() => data.hide(data.notification)}
        />
      );
    };

    return () => {
      const cn = {
        [CN]: true,
        [`${CN}--modal`]: ModalService.hasModalActive,
      };
      const BN: any = BaseNotifications;

      return <BN class={cn} v-slots={{ notification: renderNotification }} />;
    };
  },
});
