import $t from '@ravnur/l10n/$t';

import { ErrorObject } from '@vuelidate/core';
import { defineComponent, PropType } from 'vue';

const CN = 'validation-errors';

export default defineComponent({
  props: {
    errors: { type: Array as PropType<ErrorObject[]>, default: [] },
  },
  setup(props) {
    return () => {
      if (props.errors.length === 0) {
        return;
      }

      const messages = props.errors.map((e) => $t('validation', e.$validator));
      return <error-messages class={CN} value={messages} />;
    };
  },
});
