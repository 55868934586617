import { computed, defineComponent } from 'vue';

import { ProcessingInfo } from '../ProcessingInfo';

import MediaProcessingItem from './media-processing-item';
import { allItems, IGNORED_JOBS, subscribe } from './service';

import './processing-content.scss';

const CN = 'processing-content';

export default defineComponent({
  setup() {
    subscribe();

    const renderItem = (item: ProcessingInfo) => {
      const hasValidJob = item.jobs.some((j) => !IGNORED_JOBS.includes(j.status));

      if (!hasValidJob) {
        return;
      }

      return (
        <MediaProcessingItem
          key={item.mediaId}
          class={`${CN}__item`}
          item={item}
          mediaId={item.mediaId}
        />
      );
    };

    const renderList = () => <ul class={`${CN}__list`}>{allItems.value.map(renderItem)}</ul>;
    const renderTitle = () => <l10n group="common" tkey="processing__title" />;

    const totalItemsCount = computed(() =>
      allItems.value.reduce((memo, i) => {
        if (i.jobs.every((j) => IGNORED_JOBS.includes(j.status))) {
          return memo;
        }

        return memo + 1 + i.jobs.length;
      }, 0)
    );

    const accordionSlots = {
      summary: renderTitle,
    };

    const scrollableSlots = {
      default: renderList,
    };

    return () => {
      if (totalItemsCount.value > 0) {
        return (
          <accordion class={CN} titleColor="accent" titleMode="standard" v-slots={accordionSlots}>
            <scrollable class={`${CN}__content`} v-slots={scrollableSlots} />
          </accordion>
        );
      }
    };
  },
});
