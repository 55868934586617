import { renderSlot, isVNode } from 'vue';
import { prop, Options, Vue } from 'vue-class-component';

import { $Props } from '../../typings/tsx';

class Props {
  enable = prop({ default: true });
  capturePhase = prop({ default: true });
}

type Emits = {
  onClick: () => void;
};

@Options({
  name: 'outside-click-listener',
  watch: {
    enable: {
      immediate: true,
      handler(this: OutsideClickListener) {
        this.handleEnableChanged();
      },
    },
  },
  emits: ['click'],
})
export default class OutsideClickListener extends Vue.with(Props) {
  declare $props: $Props<Props, Emits>;

  protected handleEnableChanged() {
    if (this.enable) {
      setTimeout(this.addClickListener, 0);
    } else {
      this.removeClickListener();
    }
  }

  beforeUnmount() {
    this.removeClickListener();
  }

  render() {
    const content = renderSlot(this.$slots, 'default');
    if (content.children instanceof Array) {
      content.children.forEach((node) => {
        if (isVNode(node)) {
          return node;
        }
      });
    }
    return <div>{content}</div>;
  }

  private addClickListener() {
    window.addEventListener('click', this.handleClickListener, this.capturePhase);
  }

  private removeClickListener() {
    window.removeEventListener('click', this.handleClickListener, this.capturePhase);
  }

  private handleClickListener(ev: Event) {
    const { target } = ev;
    if (!(target instanceof Element)) {
      return;
    }
    const $el = getRootElement(this.$el);
    if (!$el || $el === target || $el.contains(target)) {
      return;
    }
    this.$emit('click');
  }
}

function getRootElement($el: unknown): Nullable<HTMLElement> {
  if ($el instanceof HTMLElement) {
    return $el;
  }
  if ($el instanceof Node) {
    return $el.parentElement;
  }
  return null;
}
