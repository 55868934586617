import { UploadPermission } from '@ravnur/shared/types/Upload';
import { State, Getter, Mutation } from 'vuex-simple';

import { UserPermissions } from '@/config/user-permissions';
import { Permissions } from '@/types/Auth';
import { User$Details } from '@/types/User';

export class UserModule {
  @State()
  public current: Nullable<User$Details> = null;

  @State()
  public permissions: Nullable<Permissions> = null;

  @Getter()
  public get getCurrentUser() {
    return this.current;
  }

  @Getter()
  public get isAuth(): boolean {
    return !!this.current;
  }

  @Getter()
  public get canUploadContentWithoutReview(): boolean {
    return _hasPermissionsFor(
      this.permissions,
      UserPermissions.upload,
      (val: string) => val === UploadPermission.WITHOUT_REVIEW
    );
  }

  @Getter()
  public get canUploadContent(): boolean {
    return _hasPermissionsFor(
      this.permissions,
      UserPermissions.upload,
      (val: string) => val !== UploadPermission.NONE
    );
  }

  @Getter()
  public get canRunAudioIndexing(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.runAudioIndexing);
  }

  @Getter()
  public get canShareEmailAndLink(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.shareLink);
  }

  @Getter()
  public get canMediaExternalSharing(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.enablePublicPage);
  }

  @Getter()
  public get canShareEmbedCode(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.shareEmbedCode);
  }

  @Getter()
  public get canDownloadContent(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.downloadContent);
  }

  @Getter()
  public get canEditTags(): boolean {
    return (
      _hasPermissionsFor(this.permissions, UserPermissions.accessToAdministration) &&
      _hasPermissionsFor(this.permissions, UserPermissions.tagsAdmin)
    );
  }

  @Getter()
  public get canAddTags(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.tag);
  }

  @Getter()
  public get canEditForeignComments(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.editComments);
  }

  @Getter()
  public get hasAccessToAdmin(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.accessToAdministration);
  }

  @Getter()
  public get canLikeMedia(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.rate);
  }

  @Getter()
  public get canAddComment(): boolean {
    return _hasPermissionsFor(this.permissions, UserPermissions.comment);
  }

  @Mutation()
  public changeUserPermissions(permissions: Permissions) {
    this.permissions = permissions;
  }

  @Mutation()
  public changeCurrentUser(user: Nullable<User$Details>) {
    this.current = user;
  }
}

type PermissionsConverter = (value: string) => boolean;

function _hasPermissionsFor(
  permissions: Nullable<Permissions>,
  name: keyof Permissions,
  converter: PermissionsConverter = _boolConverter
) {
  return permissions ? converter(permissions[name]) : false;
}

function _boolConverter(value: string): boolean {
  return value === 'True';
}
