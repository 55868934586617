import { Watch } from '@ravnur/decorators';
import { Vue, Options, prop } from 'vue-class-component';

import OutsideClickListener from '../outside-click-listener/outside-click-listener';

class Props {
  disabled = prop<boolean>({ default: false });

  onHide?: () => void;
  onOpen?: () => void;
}

export type OpenerSlotProps = {
  visible: boolean;
  toggle: () => void;
};

@Options({})
export default class Opener extends Vue.with(Props) {
  private visible = false;

  hide() {
    // wait till 'select' emits
    this.$nextTick(() => {
      this.visible = false;
    });
  }

  open() {
    this.visible = true;
  }

  render() {
    const { visible } = this;
    return (
      <OutsideClickListener enable={visible} onClick={this.hide}>
        {this.$slots.summary?.({ visible, toggle: this.toggle })}
        {visible && this.$slots.details?.()}
      </OutsideClickListener>
    );
  }

  @Watch('visible')
  protected handleVisibleChanged() {
    if (this.visible) {
      this.onOpen?.();
    } else {
      this.onHide?.();
    }
  }

  private toggle() {
    if (this.disabled) {
      return;
    }
    this.visible = !this.visible;
  }
}
