import { PortalSettings } from '@ravnur/shared/types/PortalSettings';
import genericRouteGuard from '@ravnur/shared/route-guard/route-guard';
import { instance as storeInstance } from '@/store';
import { UserModule } from '@/store/modules/user';

function routeGuard(settings?: Array<keyof PortalSettings>, permissions?: Array<keyof UserModule>) {
  return genericRouteGuard({
    settings,
    permissions,
    getSettingsStore: () => storeInstance.application.settings,
    getPermissionsStore: () => storeInstance.user,
    getStoreInitializedField: () => storeInstance.user.isAuth,
  });
}

export default routeGuard;
