import { parse, stringify } from 'qs';

type Resp<K extends string> = { [key in K]: Nullable<string> };

interface UrlCleanerData<K extends string> {
  url: string;
  removed: Resp<K>;
}

export function cleanLocation<K extends string>(...keys: K[]): Resp<K> {
  const { hash, search } = window.location;
  if (hash) {
    const [h, query] = hash.split('?');
    const { removed, url } = detector(keys, query, h);
    window.location.href = url;
    return removed;
  } else {
    const { url, removed } = detector(keys, search.substr(1));
    window.history.replaceState(url, '', url);
    return removed;
  }
}

function detector<K extends string>(keys: K[], search: string, hash = ''): UrlCleanerData<K> {
  const { host, protocol, pathname } = window.location;
  const params = parse(search);
  const removed = keys.reduce((memo, key) => {
    const val = params[key];
    memo[key] = typeof val === 'string' ? val : null;
    delete params[key];
    return memo;
  }, {} as Resp<K>);
  const q = stringify(params);
  const u = `${protocol}//${host}${pathname}${hash}`;
  const url = q ? `${u}?${q}` : u;
  return { url, removed };
}
