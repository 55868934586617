import { prop, Vue, Options } from 'vue-class-component';

import './slider-dot.scss';

const CN = 'slider-dot';

class Props {
  position = prop({ default: 0 });
  focus = prop({ default: false });
  disabled = prop({ default: false });
  onDragStart = prop<() => void>({ default: () => () => void 0 });
}

@Options({
  name: 'slider-dot',
})
export default class SliderDot extends Vue.with(Props) {
  private get dotClasses() {
    return {
      [CN]: true,
      [`${CN}--disabled`]: this.disabled,
      [`${CN}--focus`]: this.focus,
    };
  }

  private get handleClasses() {
    const cn = `${CN}__handle`;
    return {
      [cn]: true,
      [`${cn}--disabled`]: this.disabled,
      [`${cn}--focus`]: this.focus,
    };
  }

  render() {
    return (
      <div
        class={this.dotClasses}
        style={{ left: `${this.position}px` }}
        onMousedown={this.dragStart}
        onTouchstart={this.dragStart}
      >
        <div class={this.handleClasses} />
      </div>
    );
  }

  private dragStart() {
    if (this.disabled) {
      return false;
    }
    this.onDragStart();
  }
}
