const KEYS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

export async function toFile(url: string, filename: string, mimeType: string): Promise<File> {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
}

/* tslint:disable:one-variable-per-declaration no-bitwise */

export function encode(input: string): string {
  input = escape(input);
  let output = '';
  let chr1,
    chr2,
    chr3 = null;
  let enc1,
    enc2,
    enc3,
    enc4 = null;
  let i = 0;

  do {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }

    output = output + KEYS.charAt(enc1) + KEYS.charAt(enc2) + KEYS.charAt(enc3) + KEYS.charAt(enc4);
    chr1 = chr2 = chr3 = '';
    enc1 = enc2 = enc3 = enc4 = '';
  } while (i < input.length);

  return output;
}

export function decode(input: string): string {
  let output = '';
  let chr1,
    chr2,
    chr3 = null;
  let enc1,
    enc2,
    enc3,
    enc4 = null;
  let i = 0;

  // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
  const base64test = /[^A-Za-z0-9\+\/\=]/g; // eslint-disable-line
  if (base64test.exec(input)) {
    throw new Error('There were invalid base64 string');
  }
  // eslint-disable-next-line
  input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

  do {
    enc1 = KEYS.indexOf(input.charAt(i++));
    enc2 = KEYS.indexOf(input.charAt(i++));
    enc3 = KEYS.indexOf(input.charAt(i++));
    enc4 = KEYS.indexOf(input.charAt(i++));

    chr1 = (enc1 << 2) | (enc2 >> 4);
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    chr3 = ((enc3 & 3) << 6) | enc4;

    output = output + String.fromCharCode(chr1);

    if (enc3 !== 64) {
      output = output + String.fromCharCode(chr2);
    }
    if (enc4 !== 64) {
      output = output + String.fromCharCode(chr3);
    }

    chr1 = chr2 = chr3 = '';
    enc1 = enc2 = enc3 = enc4 = '';
  } while (i < input.length);

  return unescape(output);
}
