import curry2 from '../curry2';

const FIRST = 0;
const SLICE_IDX = 1;

type M = (key: string) => string;

export default curry2(convertFirstLetter);

function convertFirstLetter(converter: M, str: string): string {
  const chr = str[FIRST];
  const trailing = str.slice(SLICE_IDX);
  return converter(chr) + trailing;
}
