import { Options, prop, Vue } from 'vue-class-component';

import { Message } from '../../types/Message';

import './auth-message.scss';

const CN = 'auth-message';

class Props {
  message = prop<Nullable<Message>>({ default: null });
}

@Options({
  name: 'auth-message',
})
export default class AuthMessage extends Vue.with(Props) {
  render() {
    const { message } = this;
    if (!message) {
      return;
    }
    const { text, type, align = 'left' } = message;
    const cn = {
      [CN]: true,
      [`${CN}--${type}`]: type,
      [`${CN}--${align}`]: align,
    };
    return (
      <div>
        {text.split('\n').map((t) => (
          <p key={t} class={cn}>
            {t}
          </p>
        ))}
      </div>
    );
  }
}
