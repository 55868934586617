import Opener, { OpenerSlotProps } from '@ravnur/core/components/opener/opener';
import { Watch } from '@ravnur/decorators';
import $t from '@ravnur/l10n/$t';
import { setup, Vue } from 'vue-class-component';

import useTypedStore from '@/helpers/hooks/useTypedStore';

import ApplicationNav from './application-nav/application-nav.vue';
import ApplicationSearch from './application-search/application-search';
import MobileMenu from './mobile-menu/mobile-menu';
import usePortalSettings from '@/helpers/hooks/usePortalSettings';
import ModalService from '@ravnur/modal';

import './portal-header.scss';
import InfoModal from '@ravnur/shared/components/info-modal/info-modal';

const CN = 'portal-header';

export default class PortalHeader extends Vue {
  declare $refs: {
    faqOpener: Opener;
  };

  private get faqSlots() {
    return {
      summary: this.renderFaqTitle,
      details: this.renderFaqOptions,
    };
  }

  private portalSettings = setup(() => usePortalSettings());

  get footerSettings() {
    return this.store.application.footerSettings;
  }

  get hasFAQ() {
    return this.portalSettings?.isFaq && !!this.footerSettings?.knowledgeBase;
  }

  get hasContactUs() {
    return this.portalSettings?.isContactInfo && !!this.footerSettings?.contactUs;
  }

  get showFaqBtn() {
    return this.hasContactUs || this.hasFAQ;
  }

  @Watch('$route.query')
  @Watch('$route.name')
  protected handleRouteChanged() {
    this.$refs.faqOpener.hide();
  }

  render() {
    const store = useTypedStore();

    return (
      <>
        <ApplicationNav />
        <MobileMenu />
        <ApplicationSearch />
        <Opener
          ref="faqOpener"
          class={`${CN}__faq-btn`}
          v-show={this.showFaqBtn}
          v-slots={this.faqSlots}
        />
        <div class={`${CN}__upload-btn`}>
          <r-button
            color="primary"
            icon="portal-upload"
            mode="secondary"
            onclick={this.toUploadPage}
            title={$t('header', 'menu__upload')}
            v-show={store.application.isUploads && store.user.canUploadContent}
          />
        </div>
      </>
    );
  }

  toUploadPage() {
    this.$router.push({ name: 'Upload' });
  }

  private renderFaqTitle({ toggle, visible }: OpenerSlotProps) {
    return (
      <r-button
        class={visible ? 'active' : ''}
        color="black"
        icon="question"
        mode="frameless"
        onclick={toggle}
      />
    );
  }

  private renderFaqOptions() {
    return (
      <paper class={`${CN}__dropdown-menu`}>
        {this.hasFAQ && (
          <a
            class={`${CN}__dropdown-menu-item`}
            href={this.footerSettings?.knowledgeBase || ''}
            rel="noreferrer"
            target="_blank"
          >
            <l10n group="header" tkey="faq-menu__faq" />
          </a>
        )}
        {this.hasContactUs && (
          <r-button
            class={`${CN}__dropdown-menu-item`}
            mode="frameless"
            onclick={this.openContactUsModal}
          >
            <l10n group="header" tkey="faq-menu__contact" />
          </r-button>
        )}
      </paper>
    );
  }

  private async openContactUsModal() {
    this.$refs.faqOpener.hide();

    await ModalService.showModal(InfoModal, {
      header: $t('header', 'faq-menu__contact'),
      value: this.store.application.footerSettings?.contactUs,
    }).catch(() => null);
  }
}
