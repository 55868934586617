export const SEARCH_DEBOUNCE_TIME = 600;
export const CAROUSEL_AUTOREFRESH_INTERVAL = 10000;
export const DEFAULT_PAGE_SIZE = 24;
export const DEFAULT_MY_MEDIA_PAGE_SIZE = 12;
export const MAX_FOLDER_DEPTH = 3;

export const OPERATION_HEARTBEAT_TIMEOUT = 200;

export const AVAILABLE_PAGE_SIZES = [12, 24, 36, 48, 60];

export const PUBLIC_PAGE_URL_PREFIX = 'public';
export const PORTAL_PAGE_URL_PREFIX = '#';
export const EMBED_PAGE_PATH = 'handlers/content/iframe.ashx';
export const EMBED_MEDIA_PAGE_PATH = 'embed/media/';
export const EMBED_CATEGORY_PAGE_PATH = 'embed/category';
