import curry2, { CurriedFunction1 } from './curry2';

type Value = string;
type D<T> = { [idx in Value]: T };

interface KeyByFnc {
  <P extends string, V extends Value, T extends { [key in P]: V }>(prop: P): CurriedFunction1<
    T[],
    D<T>
  >;
  <P extends string, V extends Value, T extends { [key in P]: V }>(prop: P, arr: T[]): D<T>;
}

const keyBy: KeyByFnc = curry2(_keyBy);
export default keyBy;

export const keyById = keyBy('id') as <E>(arr: E[]) => Record<string, E>;

function _keyBy<P extends string, V extends string, T extends { [key in P]: V }>(
  prop: P,
  arr: T[]
): D<T> {
  return arr.reduce((memo: Record<string, T>, entity) => {
    const val = entity[prop];
    memo[val] = entity;
    return memo;
  }, {});
}
