type StorageFormat = Dictionary<Dictionary<Translation>>;

export default function toStorageFormat(arr: Translation[]): StorageFormat {
  return arr.reduce(_reducer, {});
}

function _reducer(memo: StorageFormat, translation: Translation): StorageFormat {
  const { group, key } = translation;
  memo[group] = memo[group] || {};
  memo[group][key] = translation;
  return memo;
}
