export default function normalizeClasses(
  c1: unknown,
  c2: unknown,
  c3?: unknown
): Dictionary<unknown> {
  let res: Dictionary<unknown> = {};
  res = mergeClasses(res, c1);
  res = mergeClasses(res, c2);
  res = mergeClasses(res, c3);
  return res;
}

function mergeClasses(res: Dictionary<unknown>, classes: unknown): Dictionary<unknown> {
  if (!classes) {
    return res;
  }

  if (typeof classes === 'string') {
    res[classes] = true;
  }

  if (classes instanceof Array) {
    return classes.reduce((memo: Dictionary<unknown>, cn: unknown) => mergeClasses(memo, cn), res);
  }

  if (typeof classes === 'object') {
    return { ...res, ...classes };
  }
  return res;
}
