import { Options, Vue, prop } from 'vue-class-component';

import { COMPONENT_CTX_PROVIDER_KEY } from '../../constants';
import { $Props } from '../../typings/tsx';
import './r-fieldset.scss';

class Props {
  disabled = prop({ default: false });
}

export type RFieldset$Props = $Props<Props>;

const CN = 'fieldset';

@Options({
  name: 'r-fieldset',
  watch: {
    disabled: {
      immediate: true,
      handler(this: RFieldset) {
        this.handleDisabledChanged();
      },
    },
  },
  provide(this: RFieldset) {
    return {
      [COMPONENT_CTX_PROVIDER_KEY]: this.context,
    };
  },
})
export default class RFieldset extends Vue.with(Props) {
  private context: ComponentContext = {
    disabled: false,
  };

  mounted() {
    this.$nextTick(this.focusAtFirstInput);
  }

  protected handleDisabledChanged() {
    this.context.disabled = this.disabled;
  }

  render() {
    return (
      <fieldset class={CN} disabled={this.disabled}>
        {this.$slots.default?.()}
      </fieldset>
    );
  }

  private focusAtFirstInput() {
    const [input] = this.$el.getElementsByTagName('input');
    if (input instanceof HTMLInputElement) {
      input.focus();
    }
  }
}
