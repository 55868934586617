import MockAdapter from 'axios-mock-adapter/types';
import { faker } from '@faker-js/faker';
import toBackendFormat from '../transformers/http/toBackendFormat';
import { _generateMockList, _generateUser, _generateThumbnail } from './helper-fakers';
import {
  FeaturedCarousel,
  Media,
  Media$AvailabilityStatus,
  Media$ExternalPage,
  Media$Privacies,
  Media$Types,
} from '@/types/Media';

export default function mocker(adapter: MockAdapter) {
  adapter.onGet('/api/v1.0/carousel').reply(_load);
}

function _load() {
  const total = +faker.random.numeric(1);
  const items = _generateMockList<FeaturedCarousel>(total, _generateFeaturedCarousel);
  return [200, toBackendFormat(items)];
}

export function _generateFeaturedCarousel(): FeaturedCarousel {
  const total = +faker.random.numeric(1);
  const items = _generateMockList<Media>(total, _generateFeaturedCarouselMedia);

  return {
    id: `${faker.datatype.number()}`,
    createdDate: faker.date.past().toISOString(),
    displayName: faker.lorem.words(),
    isEnabled: faker.datatype.boolean(),
    keyword: faker.lorem.word(3),
    mediaItems: items,
    order: faker.datatype.number(10),
    query: '',
    type: '',
  };
}

function _generateFeaturedCarouselMedia(): Media {
  return {
    id: `${faker.datatype.number()}`,
    allowExternalPage: Media$ExternalPage.NONE,
    availability: Media$AvailabilityStatus.AVAILABLE,
    commentsCount: +faker.random.numeric(2),
    createdDate: faker.date.past().toISOString(),
    description: faker.lorem.sentences(),
    duration: +faker.random.numeric(2),
    expirationDate: faker.date.past().toISOString(),
    hasCC: faker.datatype.boolean(),
    hasMetadata: faker.datatype.boolean(),
    likes: +faker.random.numeric(2),
    owner: _generateUser(),
    privacy: Media$Privacies.PUBLIC,
    thumbnail: _generateThumbnail(),
    title: faker.lorem.sentence(),
    type: Media$Types.VIDEO,
    views: +faker.random.numeric(2),
    rating: +faker.random.numeric(1),
    isPublished: faker.datatype.boolean(),
  };
}
