import { State, Getter, Mutation, Action } from 'vuex-simple';

import JobsRepository from '@/repositories/jobs-repository';
import MediaRepository from '@/repositories/media-repository';
import { isRunningJob } from '@/types/Job';

import { RooStore } from '../store';
import { Job, Job$Response } from '@ravnur/shared/types/Job';

const repository = new JobsRepository();
const mediaRepository = new MediaRepository();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const keyByMediaId = (jobs: Job[], filter = (j: Job) => true) => {
  const grouped: Record<string, Job[]> = {};

  jobs?.filter(filter).forEach((j) => {
    grouped[j.mediaId] = grouped[j.mediaId] ? [...grouped[j.mediaId], j] : [j];
  });

  return grouped;
};

const runningByMediaId = (jobs: Job[]) => {
  return keyByMediaId(jobs, isRunningJob);
};

const JOBS_PING_TIMEOUT = 10000;

export class JobsModule {
  constructor(private root: RooStore) {}

  @State()
  public list: Job[] = [];

  @Getter()
  public get all() {
    return keyByMediaId(this.list);
  }

  @Getter()
  public get running() {
    return runningByMediaId(this.list);
  }

  @Mutation()
  public addToList(job: Job) {
    this.list.push(job);
  }

  @Mutation()
  public removeFromLost({ id }: Job) {
    this.list = this.list.filter((j) => j.id !== id);
  }

  @Mutation()
  public updateList(list: Job[]) {
    this.list = list;
  }

  @Action()
  public async add(jobId: string) {
    try {
      const job = await repository.get(jobId);
      this.addToList(job);
      return job;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @Action()
  async cancel({ job, mediaId }: { job: Job; mediaId: string }) {
    try {
      await mediaRepository.cancelMediaJobs(mediaId);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      this.removeFromLost(job);
    }
  }

  @Action()
  async refresh() {
    try {
      const jobs = await repository.monitor();

      const formatted: Job[] = jobs.map((job: Job$Response) => ({
        id: job.id,
        mediaId: job.media.id,
        state: job.status,
        createdBy: job.createdBy,
        progress: job.progress,
        type: job.type,
      }));

      this.updateList(formatted);

      return jobs;
    } catch (e) {
      console.log(e);
    }
  }

  @Action()
  async pulling() {
    try {
      await this.refresh();
    } catch (e) {
      console.log(e);
    }

    setTimeout(this.pulling, JOBS_PING_TIMEOUT);
  }

  @Action()
  update(mediaId: string, job: Partial<Job>) {
    const index = this.list.findIndex((j) => j.mediaId === mediaId);
    const newList = [...this.list];

    if (index !== -1) {
      newList[index] = {
        ...this.list[index],
        ...job,
      };
    }

    this.updateList(newList);
  }
}
