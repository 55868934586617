import is403 from '@ravnur/core/transformers/error/is403';
import is404 from '@ravnur/core/transformers/error/is404';
import $t from '@ravnur/l10n/$t';
import { convertFromError } from '@ravnur/notifications/convertFromError';
import { showErrorNotification } from '@ravnur/notifications/service';
import { Plugin } from 'vue';
import { useStore } from 'vuex-simple';

import Logger from './logger';
import store from './store';

export const BasePortalPlugin: Plugin = {
  install: function install(app) {
    app.mixin({
      beforeCreate() {
        this.$options.computed = this.$options.computed || {};
        this.$options.computed.store = () => useStore(store);
        this.$options.computed.$logger = () => new Logger(this.$options.name || 'Component');

        if (!this.$options.methods) {
          return;
        }

        // deprecated. using just inside template
        this.$options.methods.$t = $t;

        this.$options.methods.$processException = function $processException(e: unknown) {
          convertFromError(e).forEach(showErrorNotification);
        };

        this.$options.methods.$processPageException = function $processPageException(e: unknown) {
          if (is404(e)) {
            this.$router.replace({ name: '404' });
          } else if (is403(e)) {
            this.$router.replace({ name: '403' });
          } else {
            this.$processException(e);
          }
        };

        this.$options.methods.$matomoPush = function $matomoPush(event: [string, any]) {
          // if (process.env.VUE_APP_MATOMO_ENABLED !== 'yes') {
          //   return;
          // }

          const _paq = (window as any)._paq || [];
          _paq.push(event);
        };
      },
    });
  },
};
