import MockAdapter from 'axios-mock-adapter/types';

import toBackendFormat from '../transformers/http/toBackendFormat';

import { Language } from '@/types/Language';

// eslint-disable-next-line
const data: Language[] = require('./languages.data.json');

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/language').reply(_load);
}

function _load() {
  return [200, toBackendFormat(data)];
}
