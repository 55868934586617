import IntersectionObserverWrapper from '@ravnur/core/components/intersection-observer/intersection-observer';
import { ThumbnailableEntity } from '@ravnur/shared/types/Entity';
import { defineComponent, PropType, computed, ref, watch } from 'vue';

import { isLiveEventEntity } from '@/typeguards/live';
import { isMediaEntity } from '@/typeguards/media';

import './thumbnail.scss';

const CN = 'thumbnail';

export default defineComponent({
  props: {
    entity: { type: Object as PropType<ThumbnailableEntity>, required: true },
    showType: { type: Boolean, default: true },
    showTypeLabel: { type: Boolean, default: false },
    title: { type: String, default: '' },
  },
  setup(props, ctx) {
    const url = computed(() => {
      if (!isVisible.value) return '';

      return props.entity.thumbnail?.url || props.entity.thumbnailUrl || props.entity.poster || '';
    });

    watch(
      () => props.entity.thumbnail?.url ?? '',
      () => (isLoaded.value = false)
    );

    const isVisible = ref(false);
    const isLoaded = ref(false);

    const onload = () => (isLoaded.value = true);
    const setVisible = () => (isVisible.value = true);

    return () => {
      const cn = {
        [CN]: true,
        [`${CN}--default`]: !isLoaded.value,
        [`${CN}--untyped`]: !props.showType,
      };
      const iconType = getIconForEntity(props.entity);
      let image;
      if (url.value) {
        image = (
          <img
            alt="Thumbnail"
            class={`${CN}__img`}
            draggable={false}
            src={url.value}
            onLoad={onload}
          />
        );
      }
      return (
        <IntersectionObserverWrapper
          key={url.value}
          enable={!isVisible.value}
          title={props.title}
          onIntersected={setVisible}
        >
          <div class={cn} {...ctx.attrs}>
            {image}
            <div class={`${CN}__default-bg`} />
            <icon class={`${CN}__icon-preview`} type={iconType} />
            <div class={`${CN}__type-label`} v-show={props.showTypeLabel}>
              <l10n group="common" tkey={`media_types__${iconType}`} />
            </div>
            {ctx.slots.default?.()}
          </div>
        </IntersectionObserverWrapper>
      );
    };
  },
});

function getIconForEntity(entity: ThumbnailableEntity): string {
  if (isMediaEntity(entity)) {
    return entity.type.toLowerCase();
  }
  if (isLiveEventEntity(entity)) {
    return 'broadcast';
  }
  return 'group';
}
