import { Vue, prop, Options } from 'vue-class-component';

import BodyScrollLocker from '../../components/body-scroll-locker/body-scroll-locker';

const DEFAULT_OPTIONS = Object.freeze({
  message: '',
  header: '',

  confirmText: 'Select',
  cancelText: 'Cancel',

  mode: 'static',

  showFooter: false,
  showHeader: false,

  processing: false,
  disabledConfirm: false,

  closeOnBackdrop: true,
  canConfirmChecker: true,
  canCloseChecker: true,
});

class Props {
  options = prop({ default: {} });

  close: () => unknown;
  confirm: <R>(payload?: R) => unknown;
}

@Options({
  components: { BodyScrollLocker },
})
export default class Modal extends Vue.with(Props) {
  get modalOptions() {
    return { ...DEFAULT_OPTIONS, ...this.options };
  }

  onBackdropClick() {
    if (result(this.modalOptions.closeOnBackdrop)) {
      this.handleCloseEvent();
    }
  }
  handleConfirmEvent<R>(payload?: R) {
    if (result(this.modalOptions.canConfirmChecker)) {
      this.confirm(payload);
    }
  }
  handleCloseEvent() {
    if (result(this.modalOptions.canCloseChecker)) {
      this.close();
    }
  }
}

function result(checker: unknown) {
  if (checker === true) {
    return true;
  }
  if (typeof checker === 'function' && checker() === true) {
    return true;
  }

  return false;
}
