import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "application-nav" }
const _hoisted_2 = { class: "application-nav__list" }
const _hoisted_3 = {
  class: "application-nav__item",
  tabindex: "0"
}
const _hoisted_4 = {
  key: 0,
  class: "application-nav__item",
  tabindex: "0"
}
const _hoisted_5 = {
  key: 1,
  class: "application-nav__item",
  tabindex: "0"
}
const _hoisted_6 = {
  key: 0,
  class: "application-nav__badge"
}
const _hoisted_7 = ["textContent"]

export function render(_ctx, _cache) {
  const _component_l10n = _resolveComponent("l10n")
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: _normalizeClass(["application-nav__root-link", {'router-link-active': _ctx.isActive(['Browse'])}]),
          to: { name: 'Browse' }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_l10n, {
              group: "header",
              tkey: "menu__browse"
            })
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      (_ctx.isGroups)
        ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
            _createVNode(_component_router_link, {
              class: "application-nav__root-link",
              to: _ctx.communityOptions[0].to
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l10n, {
                  group: "header",
                  tkey: _ctx.communityOptions[0].tkey
                }, null, 8, ["tkey"])
              ]),
              _: 1
            }, 8, ["to"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.isLiveStreaming)
        ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
            (_ctx.liveStreams)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("span", {
                    textContent: _toDisplayString(_ctx.liveStreams)
                  }, null, 8, _hoisted_7)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_router_link, {
              class: "application-nav__root-link",
              to: { name: 'LiveEvents' }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l10n, {
                  group: "header",
                  tkey: "menu__live"
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}