import date from '@ravnur/core/filters/date';
import { $Props } from '@ravnur/core/typings/tsx';
import { Vue, prop } from 'vue-class-component';
import { withModifiers } from 'vue';

import Stats, { Stats$Props } from '../stats/stats';

import { StandardOption } from '../../types/Component';
import { DefaultTagType } from '../../types/DefaultTagType';
import { ThumbnailableEntity } from '../../types/Entity';
import { isRestrictedForUser } from '../../typeguards/media';
import { Media } from '../../types/Media';
import Tags from '..//tags/tags';
import htmlDescriptionCleaner from '@ravnur/helpers/dom/html-formatter/html-description-cleaner';

import './list-card.scss';

const CN = 'list-card';

type Mode = 'standard' | 'compact';

class Props<A extends string = string> {
  entity: ThumbnailableEntity;
  showType = prop({ default: true });
  showTypeLabel = prop({ default: false });
  showCheckbox = prop({ default: false });
  showTags = prop({ default: true });
  showStats = prop({ default: true });

  checked = prop({ default: false });
  disabled = prop({ default: false });

  title: string;
  description?: string;
  owner?: string;
  date = prop({ default: '' });
  actions = prop<StandardOption<A>[]>({ default: () => [] });
  stats = prop<Stats$Props[]>({ default: () => [] });
  tags = prop<DefaultTagType[]>({ default: () => [] });

  mode = prop<Mode>({ default: 'standard' });
  isSelectableAction = prop<(action: StandardOption) => boolean>({
    default: () => (action: StandardOption) => !action.disabled,
  });

  onChecked?: (checked: boolean) => void;
  doAction?: (action: A) => unknown;
}

export type ListCard$Props = $Props<Props> & Partial<HTMLDivElement>;

export default class ListCard extends Vue.with(Props) {
  get isRestrictedForUser() {
    return isRestrictedForUser(this.entity as Media);
  }

  get filteredDescription() {
    return htmlDescriptionCleaner(this.description);
  }

  render() {
    return this.isRestrictedForUser ? this.renderRestrictedListCard() : this.renderListCard();
  }

  private renderRestrictedListCard() {
    return (
      <div class={[CN, `${CN}__restricted`, `${CN}--${this.mode}`]} {...this.$attrs}>
        <span class={`${CN}__fake-checkbox`} v-show={this.showCheckbox} />
        <thumbnail
          class={`${CN}__thumb`}
          entity={this.entity}
          showType={false}
          showTypeLabel={false}
        >
          <div class={`${CN}__restricted-icon-wrapper`}>
            <div class={`${CN}__restricted-icon`}>!</div>
          </div>
        </thumbnail>
        <div class={`${CN}__body`}>
          <div class={`${CN}__row`}>
            <div class={`${CN}__title`}>
              <l10n group="common" tkey="restricted__media" />
            </div>
            <more-actions
              class={`${CN}__actions`}
              isSelectable={this.isSelectableAction}
              options={this.actions || []}
              v-slots={{ option: this.renderOption }}
              onClick={this.handleActionsClick}
            />
          </div>
          <div class={`${CN}__grow`} />
          {this.$slots.default?.()}
        </div>
      </div>
    );
  }

  private renderListCard() {
    return (
      <div class={[CN, `${CN}--${this.mode}`]} {...this.$attrs}>
        {this.renderCheckbox()}

        <thumbnail
          class={`${CN}__thumb`}
          entity={this.entity}
          showType={this.showType}
          showTypeLabel={this.showTypeLabel}
        >
          {this.$slots.thumbnail?.()}
        </thumbnail>
        <div class={`${CN}__body`}>
          <div class={`${CN}__row`}>
            <div class={`${CN}__title`} title={this.title}>
              {this.title}
            </div>
            <more-actions
              class={`${CN}__actions`}
              isSelectable={this.isSelectableAction}
              options={this.actions || []}
              v-slots={{ option: this.renderOption }}
              onClick={this.handleActionsClick}
            />
          </div>
          {this.$slots.bodyContent?.()}
          {this.renderDate()}
          {this.renderOwner()}
          <div
            class={[`${CN}__row`, `${CN}__row--desc`]}
            title={
              this.filteredDescription && this.filteredDescription.length > 200
                ? this.filteredDescription
                : ''
            }
          >
            {this.filteredDescription && this.filteredDescription.length > 200
              ? `${this.filteredDescription.slice(0, 200)}...`
              : this.filteredDescription}
          </div>
          <div class={`${CN}__grow`} />
          <div class={[`${CN}__row`, `${CN}__row--stats`]}>
            {this.$slots.action?.()}
            {this.renderTags()}
            {this.renderStatistics()}
          </div>

          {this.$slots.default?.()}
        </div>
      </div>
    );
  }

  private renderCheckbox() {
    if (this.showCheckbox) {
      return (
        <div class={`${CN}__checkbox`} onClick={withModifiers(() => void 0, ['prevent', 'stop'])}>
          <checkbox aria-label="Check" value={this.checked} onInput={this.onChecked} />
        </div>
      );
    }
  }

  private renderDate() {
    const dateStr = this.date ? date(this.date) : '';
    if (dateStr) {
      return (
        <div ref="date" class={[`${CN}__row`, `${CN}__row--date`]}>
          {dateStr}
        </div>
      );
    }
  }

  private renderOwner() {
    if (this.owner) {
      return (
        <div ref="owner" class={[`${CN}__row`, `${CN}__row--owner`]} title={this.owner}>
          {this.owner}
        </div>
      );
    }
  }

  private renderTags() {
    if (this.showTags) {
      return <Tags class={`${CN}__tags`} size="sm" value={this.tags || []} />;
    }
  }

  private renderStatistics() {
    if (this.showStats) {
      return this.stats.map(({ count, statType }) => {
        return <Stats key={statType} count={count} statType={statType} />;
      });
    }
  }

  private renderOption({ item }: { item: StandardOption }) {
    return <span class={`${CN}__action-item`}>{item.label}</span>;
  }

  private handleActionsClick({ id }: { id: string }) {
    this.doAction?.(id);
  }
}
