import curry2, { CurriedFunction1 } from './curry2';

interface OmitFnc {
  <P extends string, E extends Partial<{ [k in P]: unknown }>>(
    props: Array<keyof E>
  ): CurriedFunction1<E, Partial<E>>;
  <P extends string, E extends Partial<{ [k in P]: unknown }>>(
    props: Array<keyof E>,
    obj: Partial<E>
  ): Partial<E>;
}

const omit = curry2(_omit) as OmitFnc;
export default omit;

function _omit<P extends string, E extends { [k in P]: unknown }>(
  props: Array<keyof E>,
  obj: Partial<E>
): Partial<E> {
  const res: Partial<E> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && props.indexOf(key) === -1) {
      res[key] = obj[key];
    }
  }
  return res;
}
