import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "advanced-search" }
const _hoisted_2 = {
  key: 0,
  class: "advanced-search__stored-wrapper"
}
const _hoisted_3 = { class: "advanced-search__stored-list" }
const _hoisted_4 = { class: "advanced-search__current" }
const _hoisted_5 = { class: "advanced-search__actions" }

export function render(_ctx, _cache) {
  const _component_r_button = _resolveComponent("r-button")
  const _component_l10n = _resolveComponent("l10n")
  const _component_scrollable = _resolveComponent("scrollable")
  const _component_query_builder = _resolveComponent("query-builder")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_r_button, {
      class: "advanced-search__toggler",
      mode: "frameless",
      color: "grey",
      icon: "tune",
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.toggle()), ["prevent"]))
    }),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["advanced-search__opener", { 'advanced-search__opener--stored': _ctx.queries.length }])
        }, [
          (_ctx.queries.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_l10n, {
                  class: "advanced-search__title",
                  group: "common",
                  tkey: "advanced_search__stored_queries"
                }),
                _createVNode(_component_scrollable, {
                  class: "advanced-search__queries-scrollable",
                  bus: _ctx.queries
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("ul", _hoisted_3, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queries, (query) => {
                        return (_openBlock(), _createElementBlock("li", {
                          class: _normalizeClass(["advanced-search__stored-item", { 'advanced-search__stored-item--current': _ctx.current.id === query.id }]),
                          key: query.id
                        }, [
                          _createVNode(_component_r_button, {
                            class: "advanced-search__item-title",
                            mode: "frameless",
                            color: "black",
                            onClick: $event => (_ctx.setCurrent(query))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(query.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_r_button, {
                            class: "advanced-search__remove-item",
                            mode: "frameless",
                            color: "grey",
                            icon: "delete",
                            size: "sm",
                            title: _ctx.$t('common', 'advanced_search__remove_query'),
                            onClick: $event => (_ctx.remove(query))
                          }, null, 8, ["title", "onClick"])
                        ], 2))
                      }), 128))
                    ])
                  ]),
                  _: 1
                }, 8, ["bus"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_l10n, {
              class: "advanced-search__title advanced-search__title--current",
              group: "common",
              tkey: "advanced_search__current_title"
            }),
            _createVNode(_component_scrollable, {
              class: "advanced-search__query-scrollable",
              bus: _ctx.state
            }, {
              default: _withCtx(() => [
                _createVNode(_component_query_builder, {
                  class: "advanced-search__builder",
                  match: _ctx.state.match,
                  rules: _ctx.state.rules,
                  onInput: _ctx.changeCurrentState
                }, null, 8, ["match", "rules", "onInput"])
              ]),
              _: 1
            }, 8, ["bus"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_r_button, {
              class: "advanced-search__action",
              mode: "secondary",
              color: "accent",
              disabled: !_ctx.isFilled,
              onClick: _ctx.clear
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l10n, {
                  group: "common",
                  tkey: "advanced_search__clear"
                })
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]),
            _createVNode(_component_r_button, {
              class: "advanced-search__action",
              mode: "secondary",
              color: "accent",
              disabled: !_ctx.isFilled,
              onClick: _ctx.searchAndSave
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l10n, {
                  group: "common",
                  tkey: "advanced_search__save"
                })
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]),
            _createVNode(_component_r_button, {
              class: "advanced-search__action",
              mode: "standard",
              color: "primary",
              disabled: !_ctx.isFilled,
              onClick: _ctx.search
            }, {
              default: _withCtx(() => [
                _createVNode(_component_l10n, {
                  group: "common",
                  tkey: "advanced_search__search"
                })
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}