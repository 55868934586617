import { Watch } from '@ravnur/decorators';
import omit from '@ravnur/nanoutils/omit';
import { ErrorObject } from '@vuelidate/core';
import { Options, prop, Vue } from 'vue-class-component';

import ValidationErrors from '../../components/validation-errors/validation-errors';

import { $Props } from '../../typings/tsx';

import './multiline-field.scss';

const CN = 'multiline-field';
const withoutClass = omit(['class', 'style']);

class Props {
  value!: string;
  label = prop({ default: '' });
  formatter = prop({ default: /.?/ });
  errors = prop<ErrorObject[]>({ default: [] });
  autoHeight = prop({ default: false });
  isQuill = prop({ default: false });
}

type Emits = {
  onInput: (val: string) => void;
};

export type MultilineField$Props = $Props<Props, Emits, 'value'> & Partial<HTMLTextAreaElement>;

@Options({
  name: 'multiline-field',
  emits: ['input', 'update:value'],
})
export default class MultilineField extends Vue.with(Props) {
  declare $refs: {
    field: HTMLTextAreaElement;
    quill: HTMLDivElement & { setHTML: (html: string) => void };
  };

  private get maxLength() {
    const length = this.$attrs.maxLength;
    return typeof length === 'number' ? length : 0;
  }

  private get classes() {
    return {
      [CN]: true,
      [`${CN}--error`]: !!this.errors.length,
      [`${CN}--low`]: !this.label,
      [`${CN}--disabled`]: this.$attrs.disabled,
    };
  }

  mounted() {
    this.$nextTick(this.detectFieldHeight);
  }

  render() {
    return this.isQuill ? this.renderQuillTextarea() : this.renderRegularTextarea();
  }

  renderQuillTextarea() {
    return (
      <div class={[this.classes, `${CN}__quill`]}>
        <span class={`${CN}__label`}>{this.label}</span>
        {this._quillEditor()}
        <ValidationErrors errors={this.errors} />
        {this._counter()}
        {this.$slots.default?.()}
      </div>
    );
  }

  renderRegularTextarea() {
    return (
      <label class={this.classes}>
        {this._textarea()}
        <span class={`${CN}__label`}>{this.label}</span>
        <ValidationErrors errors={this.errors} />
        {this._counter()}
        {this.$slots.default?.()}
      </label>
    );
  }

  @Watch('value')
  private detectFieldHeight() {
    if (!this.autoHeight) {
      return;
    }
    const { field } = this.$refs;

    if (!field) {
      return;
    }

    field.style.overflow = 'auto';
    field.style.height = 'auto';
    field.style.height = `${field.scrollHeight}px`;
    field.style.overflow = 'hidden';
  }

  private handleInputEvent(e: Event) {
    const { maxLength, formatter } = this;
    const $textarea = e.target;
    if (!($textarea instanceof HTMLTextAreaElement)) {
      return;
    }
    const value = $textarea.value;

    if ((maxLength && value.length > maxLength) || (value && !formatter.test(value))) {
      e.preventDefault();
      $textarea.value = this.value;
      return false;
    }
    this.change(value);
  }

  private handleQuillInputEvent(content: string) {
    const { maxLength, formatter } = this;

    const value = content ? content.replace(/<[^>]*>/g, '') : content;

    if (!this.$refs.quill) {
      return;
    }

    if ((maxLength && value && value.length > maxLength) || (value && !formatter.test(value))) {
      this.$refs.quill.setHTML(this.value);

      return false;
    }

    this.change(content);
  }

  private change(value: string) {
    this.$emit('input', value);
    this.$emit('update:value', value);
  }

  _textarea() {
    return (
      <textarea
        ref="field"
        class={`${CN}__input`}
        data-testid="multiline-field"
        value={this.value}
        onFocus={this.detectFieldHeight}
        onInput={this.handleInputEvent}
        {...withoutClass(this.$attrs as Record<string, unknown>)}
      />
    );
  }

  _quillEditor() {
    return (
      <>
        <div id="my-toolbar">
          <span class="ql-header ql-picker">
            <span
              aria-hidden="true"
              class="ql-picker-options"
              id="ql-picker-options-0"
              tabindex="-1"
            >
              <span class="ql-picker-item" data-value="1" role="button" tabindex="0" />
              <span class="ql-picker-item" data-value="1" role="button" tabindex="1" />
              <span class="ql-picker-item" data-value="1" role="button" tabindex="2" />
              <span class="ql-picker-item" role="button" tabindex="0" />
            </span>
            <select class="ql-header">
              <option value="1" />
              <option value="2" />
              <option value="3" />
              <option selected />
            </select>
          </span>
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-link"></button>
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-clean"></button>

          {this.$slots.toolbar?.()}
        </div>
        <quill-editor
          ref="quill"
          content={this.value}
          contentType="html"
          options={{ bounds: `.${CN}__quill` }}
          theme="snow"
          toolbar="#my-toolbar"
          onUpdate:content={this.handleQuillInputEvent}
          {...withoutClass(this.$attrs as Record<string, unknown>)}
        />
      </>
    );
  }

  _counter() {
    const { maxLength, value } = this;

    const v = this.isQuill && value ? value.replace(/<[^>]*>/g, '') : value;

    const text = maxLength ? `${v.length}/${maxLength}` : '';
    return <span class={`${CN}__counter`}>{text}</span>;
  }
}
