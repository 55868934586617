const URL_BASE = _removeEndSlashes(process.env.VUE_APP_API_URL || '');

export default function generate(subpath: string): string {
  const path = _removeFirstSlashes(subpath);
  return `${URL_BASE}/${path}`;
}

// "http://url/"   => "http://url"
// "http://url"    => "http://url"
// "http://url//"  => "http://url"
function _removeEndSlashes(str: string): string {
  const idx = str.lastIndexOf('/');
  return idx === str.length - 1 ? _removeEndSlashes(str.slice(0, idx)) : str;
}

// "/path"     => "path"
// "path"      => "path"
// "///path"   => "path"
function _removeFirstSlashes(str: string): string {
  return str[0] === '/' ? _removeFirstSlashes(str.slice(1)) : str;
}
