import { Media$Types } from './Media';

export type IUploadStore = {
  cacheByUid: Dictionary<UploadInfo>;
  cacheByMediaId: Dictionary<UploadInfo>;
  allowedVideoFormats: string[];
  allowedAudioFormats: string[];
  allAllowedMediaFormats: string[];

  start: (info: UploadInfo) => void;
  addMediaId: (args: { uid: string; mediaId: string }) => void;
  handleProgressEvent: (event: UploadProgressEvent) => void;
};

export enum UploadStatus {
  INITIALIZED = 'initialized',
  PROCESSING = 'processing',
  FINISHED = 'finished',
  FAILED = 'failed',
}

export enum UploadPermission {
  WITHOUT_REVIEW = 'WithoutReview',
  REVIEW_REQUIRED = 'ReviewRequired',
  NONE = 'None',
}

export type UploadInfo = {
  uid: string;
  mediaId: string;
  mediaType: Media$Types;
  mediaTitle: string;
  progress: number;
  isPaused: boolean;
  isSubmitted: boolean;
  isRefreshExistingSource: boolean;
  keepSubResources?: boolean;
  autoStartProcess?: boolean;
  status: UploadStatus;
};

export type UploadProgressEvent = {
  uid: string;
  progress: number;
  isPaused: boolean;
  status: UploadStatus;
};
