import { detectCookieName } from '@ravnur/auth/helpers/detect-cookie-name';
import { defineComponent, PropType } from 'vue';

import ApplicationItem from './application-item/application-item';

import { Site } from '@/types/Site';

import './applications-group.scss';

const CN = 'applications-group';

export default defineComponent({
  props: {
    sites: { type: Array as PropType<Site[]>, required: true },
    title: { type: String, required: true },
    urlProperty: {
      type: String as PropType<keyof SubType<Site, Nullable<string>>>,
      required: true,
    },
  },
  setup(props) {
    const urlDetector = (site: Site) => site[props.urlProperty];

    const renderItem = (site: Site) => {
      const url = urlDetector(site);
      if (!url) {
        return;
      }

      return (
        <li key={`${props.urlProperty}-${site.id}`}>
          <a class={`${CN}__site`} href={detectHref(url)} title={site.name}>
            <ApplicationItem site={site} />
          </a>
        </li>
      );
    };

    return () => {
      if (props.sites.some(urlDetector)) {
        return (
          <div class={CN}>
            <div class={`${CN}__title`}>{props.title}</div>
            <ul class={`${CN}__sites`}>{props.sites.map(renderItem)}</ul>
          </div>
        );
      }
    };
  },
});

function detectHref(href: string) {
  return `${href}?cn=${detectCookieName()}`;
}
