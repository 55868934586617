import { renderSlot } from 'vue';
import { prop, Vue } from 'vue-class-component';

import { $Props } from '../../typings/tsx';
import './progressbar.scss';

const CN = 'progressbar';

class Props {
  value!: number;
  label = prop({ default: '' });
  size = prop<'md' | 'sm'>({ default: 'md' });
  loading = prop<boolean>({ default: false });
}

export type ProgressBar$Props = $Props<Props>;

export default class Progressbar extends Vue.with(Props) {
  render() {
    const { value, label, size, $slots } = this;
    const width = `${value.toFixed(2)}%`;
    return (
      <div class={[CN, `${CN}--${size}`]}>
        <div class={`${CN}__loading`} v-show={this.loading} />
        <div class={`${CN}__indicator`} style={{ width }} />
        <label class={`${CN}__label`}>{label}</label>
        {renderSlot($slots, 'default')}
      </div>
    );
  }
}
