import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

const localStorage = window.localStorage;

const cache: Record<string, Record<string, any>> = {};

class Storage {
  public set<T>(groupName: string, key: string, value: T): void {
    try {
      value = _safer(value);
      const group = _getGroup(groupName);
      const data = group[key];
      if (isObject(data) && isObject(value) && !isArray(data)) {
        group[key] = assign(data, value);
      } else {
        group[key] = value;
      }

      localStorage.setItem(groupName, JSON.stringify(group));
    } catch (e) {
      // logger.error('error on set data to localStorage', e);
    }
  }
  public get<T>(groupName: string, key: string): T {
    const res = _getGroup<T>(groupName)[key];
    return _safer(res);
  }

  public remove(groupName: string, key: string): void {
    this.set(groupName, key, void 0);
  }

  public clear(groupName: string): void {
    try {
      delete cache[groupName];
      localStorage.removeItem(groupName);
    } catch (e) {
      // logger.error('error on set data to localStorage', e);
    }
  }
}

export default new Storage();

function _getGroup<T>(name: string): Record<string, T> {
  if (has(cache, name)) {
    return cache[name];
  }

  const s = localStorage.getItem(name);
  let stored: Record<string, T> = {};
  if (s) {
    try {
      stored = JSON.parse(s);
    } catch (e) {
      stored = {};
    }
  }

  cache[name] = stored;

  return stored;
}

function _safer<T>(val: T): T {
  if (isObject(val) || isArray(val)) {
    return cloneDeep(val);
  }
  return val;
}
