/* eslint-disable */

import difference from 'lodash/difference';

import getClasses from './getClasses';

export default function removeClasses(el, ...classes) {
  classes = difference(getClasses(el), classes);
  el.setAttribute('class', classes.join(' '));
}
