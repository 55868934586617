import MockAdapter from 'axios-mock-adapter/types';

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/job/current').reply(_load);
  mock.onGet('/api/v1.0/processing').reply(_load);
}

function _load() {
  return [200, []];
}
