import { Vue } from 'vue-class-component';

import FeaturedCarouselList from '@/components/home/featured-carousel/featured-carousel-list';

import Featured from './featured/featured';

import './home.scss';

const CN = 'home';

export default class Home extends Vue {
  get isFeaturedVideos() {
    return this.store.application.isFeaturedVideos;
  }

  get isFeaturedCarousels() {
    return this.store.application.isFeaturedCarousels;
  }

  render() {
    return (
      <div class={CN}>
        {this.isFeaturedVideos ? <Featured /> : null}
        {this.isFeaturedCarousels ? (
          <div class={`${CN}__group-wrapper`}>
            <FeaturedCarouselList />
          </div>
        ) : null}
      </div>
    );
  }
}
