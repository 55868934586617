import { isVNode, defineComponent, PropType } from 'vue';

// import { IntrinsicElementAttributes } from '../../dom';

type T = any;
type P = T extends Record<string, unknown> ? keyof T : never;

export default defineComponent({
  props: {
    item: { type: Object as PropType<T> },
    propName: { type: String as PropType<P>, default: null },
    tag: { type: String }, // TODO: check in list of available tags
  },
  setup(props, ctx) {
    return () => {
      const { propName, item, tag: Tag } = props;
      const content = ctx.slots.default?.({ item }) || label(item, propName);
      if (Tag) {
        return <Tag>{content}</Tag>;
      }
      return isVNode(content) ? content : <span>{content}</span>;
    };
  },
});

function label(item: T, propName: P): string {
  if (typeof item === 'object' && item && Object.prototype.hasOwnProperty.call(item, propName)) {
    return `${item[propName]}`;
  }
  return typeof item === 'object' ? JSON.stringify(item) : `${item}`;
}
