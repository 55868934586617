export enum Job$Type {
  AUDIO_INDEXING = 'AudioIndexing',
  AUDIO_TRANSCODING = 'AudioTranscoding',
  VIDEO_TRANSCODING = 'VideoTranscoding',
}

export enum Job$Status {
  QUEUED = 'Queued',
  RUNNING = 'Processing',
  SUCCESS = 'Finished',
  ERROR = 'Error',
  CANCELING = 'Cancelling',
  CANCELED = 'Canceled',
}

export type Job = {
  mediaId: string;
  state: Job$Status;
  createdBy: string;
  progress: number;
  type: Job$Type;
} & Entity;

export type Job$Response = {
  media: {
    title: string;
  } & Entity;
  status: Job$Status;
  type: Job$Type;
  createdBy: string;
  progress: number;
} & Entity;

export function isRunningJob(job: Job): boolean {
  return job.state === Job$Status.RUNNING || job.state === Job$Status.QUEUED;
}

export function isTranscodingJob(job: Job): boolean {
  return job.type === Job$Type.AUDIO_TRANSCODING || job.type === Job$Type.VIDEO_TRANSCODING;
}

export function isCCJob(job: Job): boolean {
  return job.type === Job$Type.AUDIO_INDEXING;
}
