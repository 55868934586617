import { WatchOptions } from 'vue';
import { createDecorator, VueDecorator } from 'vue-class-component';

export default function Watch(path: string, options: WatchOptions = {}): VueDecorator {
  const { deep = false, immediate = false } = options;

  return createDecorator((componentOptions, handler) => {
    if (typeof componentOptions.watch !== 'object') {
      componentOptions.watch = Object.create(null);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const watch: any = componentOptions.watch ?? {};
    const watchers = watch[path];

    if (
      (typeof watchers === 'object' && !Array.isArray(watchers)) ||
      typeof watchers === 'function'
    ) {
      watch[path] = [watchers];
    } else if (typeof watch[path] === 'undefined') {
      watch[path] = [];
    }

    if (watch[path] instanceof Array) {
      watch[path].push({ handler, deep, immediate });
    }
  });
}
