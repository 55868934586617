import { Watch } from '@ravnur/decorators';
import applicationScroll from '@ravnur/helpers/dom/application-scroll.helper';
import { Vue, prop } from 'vue-class-component';

class Props {
  isLocked = prop({ default: true });
}

export default class BodyScrollLocker extends Vue.with(Props) {
  @Watch('isLocked', { immediate: true })
  protected handleIsLockedChanged() {
    if (this.isLocked) {
      lock();
    } else {
      unlock();
    }
  }

  beforeUnmount() {
    unlock();
  }

  render() {
    // noop
  }
}

function lock() {
  if (document.body.dataset.scrollLocked) {
    return;
  }
  const st = applicationScroll.scrollTop;
  document.body.style.overflowY = applicationScroll.hasScroll ? 'scroll' : '';
  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';
  document.body.style.top = `-${st}px`;
  document.body.dataset.scrollLocked = 'true';
}

function unlock() {
  if (!document.body.dataset.scrollLocked) {
    return;
  }
  const st = Math.abs(parseInt(document.body.style.top, 10)) || 0;
  document.body.style.position = '';
  document.body.style.overflow = '';
  document.body.style.top = '';
  document.body.style.overflowY = '';
  applicationScroll.scrollTop = st;
  document.body.removeAttribute('data-scroll-locked');
}
