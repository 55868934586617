import MediaCard from '@ravnur/shared/components/media-card/media-card';
import { MyMedia } from '@ravnur/shared/types/Media';
import { Vue, prop, setup } from 'vue-class-component';
import usePortalSettings from '@/helpers/hooks/usePortalSettings';

import './grid-card.scss';

const CN = 'grid-card';
class Props {
  entity: MyMedia;
  showType = prop({ default: false });
  showCCBadge = prop({ default: false });
}
export default class GridCard extends Vue.with(Props) {
  private portalSettings = setup(() => usePortalSettings());

  render() {
    return (
      <MediaCard
        class={`${CN}`}
        goToMedia={this.goToMedia}
        goToUser={this.portalSettings?.isDisplayUserChannels ? this.goToUser : undefined}
        media={this.entity}
        showCCBadge={this.showCCBadge}
        showType={this.showType}
        v-slots={this.$slots}
      />
    );
  }

  public goToMedia() {
    this.$router.push({ name: 'Media', params: { id: this.entity.id } });
  }

  public goToUser() {
    const id = this.entity.owner.id;
    if (id) {
      this.$router.push({ name: 'User', params: { id } });
    }
  }
}
