import curry2, { CurriedFunction1 } from './curry2';

interface PropFnc {
  <P extends string, T extends { [k in P]: unknown }>(key: P): CurriedFunction1<T, T[P]>;
  <P extends string, T extends { [k in P]: unknown }>(key: P, o: T): T[P];
}

const prop: PropFnc = curry2(_prop);
export default prop;

function _prop<P extends string, T extends { [k in P]: unknown }>(key: P, o: T): T[P] {
  return o[key];
}
