import { WebAuthData } from '@ravnur/auth/types/AuthService';
import { AxiosRequestConfig } from 'axios';
import MockAdapter from 'axios-mock-adapter/types/index';
import { startOperation } from './operation';
import toBackendFormat from '@/transformers/http/toBackendFormat';
import { Auth$Response, Permissions } from '@/types/Auth';
import { faker } from '@faker-js/faker';
import { PredefinedMediaProperties } from '@/types/Media';
import { Site } from '@/types/Site';
import { User$Details } from '@/types/User';
import { PortalSettings } from '@/types/Settings';
import { UploadPermission } from '@/types/Upload';
import { ApplicationTypes } from '@ravnur/shared/types/Application';

const applications: Site[] = [
  {
    adminUrl: faker.internet.url(),
    id: faker.datatype.uuid(),
    isRoot: false,
    logoUrl: faker.image.abstract(),
    name: faker.company.name(),
    portalUrl: faker.internet.url(),
    applicationType: ApplicationTypes.BASIC,
    startPage: null,
  },
  {
    adminUrl: faker.internet.url(),
    id: faker.datatype.uuid(),
    isRoot: false,
    logoUrl: faker.image.abstract(),
    name: faker.company.name(),
    portalUrl: faker.internet.url(),
    applicationType: ApplicationTypes.MY_ACCOUNT,
    startPage: null,
  },
];

const USERNAME = 'user';
const PASSWORD = 'password';

const portalSettings: PortalSettings = {
  isAudios: true,
  isDisplayUserChannels: true,
  isGroups: true,
  isFaq: true,
  isSupport: true,
  isPhotos: true,
  isSmComments: true,
  isSmRelated: true,
  isSmStatistics: true,
  isSubscriptions: true,
  isUploads: true,
  isVideos: true,
  isScreenCasterEnabled: true,
  isSignoutEnabled: true,
  isPrivacyPolicy: true,
  isTermSandConditions: true,
  isCategories: true,
  enableEmbededMediaCustomValidation: true,
  isDocuments: true,
  isCollections: true,
  isContactInfo: true,
  isAdvancedSearch: true,
  isUnlistedFeature: true,
  isExternalAccess: false,
  isSmRating: true,
  isLiveStreaming: true,
  isEmailDomainManagement: false,
  isMediaRating: true,
  isMediaLikes: false,
  isAutoCaptions: true,
  isEnableCcAutoTranslations: true,
  isLivePlaylistsEnabled: true,
  isFeaturedVideos: true,
  isFeaturedCarousels: true,
  isSettingsPageEnabled: true,
};

const permissions: Permissions = {
  accessToAdministration: 'True',
  categoriesAdmin: 'True',
  contentAdmin: 'True',
  createGroup: 'True',
  downloadContent: 'True',
  editComments: 'True',
  groupsAdmin: 'True',
  manageUsers: 'True',
  enablePublicPage: 'True',
  purgeItems: 'True',
  purgeUsers: 'True',
  rate: 'True',
  reviewContent: 'True',
  rolesAdmin: 'True',
  runAudioIndexing: 'True',
  shareEmbedCode: 'True',
  shareLink: 'True',
  tag: 'True',
  tagsAdmin: 'True',
  upload: UploadPermission.WITHOUT_REVIEW,
  comment: 'True',
};

const user = {
  id: faker.random.numeric(2),
  displayName: faker.internet.userName(),
  firstName: faker.name.firstName(),
  lastName: faker.name.lastName(),
  userpic: faker.internet.avatar(),
  email: faker.internet.email(),
  joinedDate: faker.date.past().toISOString(),
  lastLoginDate: faker.date.past().toISOString(),
  languageId: faker.random.numeric(1),
} as User$Details;

const predefinedMediaProperties: PredefinedMediaProperties = {
  isEnabledDownload: true,
  isEnabledEmbedding: true,
};

const BODY: Auth$Response = {
  user,
  portalFeatures: portalSettings,
  categories: [],
  applications,
  translationHash: '2',
  supportedLocales: [
    {
      abbreviation: 'en-US',
      localName: 'English',
      pluralRule: 1,
      id: '1',
    },
    {
      abbreviation: 'ge',
      localName: 'Germany',
      pluralRule: 1,
      id: '2',
    },
  ],
  predefinedMediaProperties,
  userPermissions: permissions,
  authToken: 'mock',
  cookieName: 'RT',

  publicDomain: 'https://public.ravnur.com',
  portalConfiguration: {
    media: {
      video: {
        attachments: [
          {
            kind: 'agenda',
            acceptTypes: 'application/pdf',
            isPlural: false,
            maxSize: 10,
          },
          {
            kind: 'minutes',
            acceptTypes: 'application/pdf',
            isPlural: false,
            maxSize: 10000,
          },
        ],
        videoSettings: {
          allowedFormats:
            '.3g2,.3gp,.asf,.avi,.flv,.mov,.mp4,.mpeg,.mpg,.rm,.vob,.wav,.webm,.wmv,.xesc,.m4v,.mkv,.mp2',
          maxSize: 7000,
          isAdaptiveStreaming: true,
        },
      },
      audio: {
        audioSettings: {
          bitrate: 128,
          allowedFormats: '.mp3,.wma,.mp4,.wmv,.mpeg,.mpg,.flv,.avi,.wav',
          maxSize: 20,
          parameters: '',
          samplingRate: 44100,
        },
      },
    },
    live: {
      event: {
        attachments: [
          {
            kind: 'agenda',
            acceptTypes: 'application/pdf',
            isPlural: true,
            maxSize: 10000,
          },
        ],
      },
      liveChannel: {
        logoImage: {
          allowedFormats: '.png,.jpg',
          acceptTypes: 'image/png,image/jpeg',
          maxSize: 200000,
        },
        bannerImage: {
          allowedFormats: '.png,.jpg',
          acceptTypes: 'image/png,image/jpeg',
          maxSize: 10000,
        },
      },
    },
    settings: {
      contactUs:
        '<h1>Testing html</h1><script>alert("booom");</script>You can contact us by:\r\n\r\n1) phone: 380664020134\r\n2) email: test@zeeraks.com\r\n3) knock on tubes :)\r\n\r\nBR, me)',
      termsAndConditions: 'https://dummylinktosomepage.org/terms-and-conditions',
      privacyPolicy: 'https://dummylinktosomepage.org/privacy',
      knowledgeBase: 'https://dummylinktosomepage.org/faq',
    },
    analytics: {
      gtmID: '',
      gaId: '',
      matomoAnalyticsEnabled: false,
      matomoHost: '',
      matomoSiteId: '',
      matomoToken: '',
      appInsightId: '',
      muxEnvKey: '',
    },
  },
};

export default function mocker(mock: MockAdapter) {
  mock.onGet(/\/api\/v1.0\/auth/).reply(() => [200, BODY]);
  mock.onPost(/\/api\/v1.0\/auth/).reply(_post);

  mock.onPost(/\/api\/v1.0\/registration/).reply(_operation);
  mock.onPost(/\/api\/v1.0\/restore-password/).reply(_operation);

  mock.onPost(/\/api\/v1.0\/change-password/).reply(_operation);
  mock.onGet(/\/api\/v1.0\/check-token/).reply(() => [200, { logoPath: '', isValid: true }]);
  mock.onPost(/\/api\/v1.0\/verify/).reply(_operation);
  mock.onGet(/\/api\/v1.0\/activation-info/).reply(() => [200, { logoPath: '' }]);
}

function _post(config: AxiosRequestConfig) {
  const body: WebAuthData = JSON.parse(config.data);
  return body.password === PASSWORD && body.username === USERNAME ? [200, BODY] : [401, {}];
}

function _operation() {
  const operation = startOperation('id');
  return [200, toBackendFormat(operation)];
}
