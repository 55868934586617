import { Watch } from '@ravnur/decorators';
import ModalService, { IModalManager, ModalConstructor } from '@ravnur/modal';
import { Vue } from 'vue-class-component';

export default class PopupManager extends Vue implements IModalManager {
  declare $refs: {
    rootmodal: (Vue & { resolvedValue: any }) | undefined;
  };

  private modal: Nullable<ModalConstructor<any>> = null;
  private params: Record<string, unknown> = {};
  private slots: Record<string, () => unknown> = {};
  private modalKey = 1;

  public get hasModalActive() {
    return !!this.modal;
  }

  created() {
    ModalService.registry(this);
  }

  showModal<R>(
    Modal: ModalConstructor<R>,
    props: Record<string, unknown>,
    slots: Record<string, () => unknown> = {}
  ): Promise<R> {
    this.modal = Modal;
    this.params = props;
    this.slots = slots;
    this.modalKey = Date.now();
    return new Promise((resolve, reject) => {
      ModalService.bus.on('*', (type, payload) => {
        ModalService.bus.all.clear();
        if (type === 'confirm') {
          resolve(payload as R);
        } else {
          reject(payload);
        }
        this.destroyModal();
      });
    });
  }

  render() {
    return (
      <>
        {this.renderModal()}
        <div id="modals" />
      </>
    );
  }

  renderModal() {
    const { modal: Modal } = this;
    if (!Modal) {
      return;
    }
    return (
      <Modal
        {...this.params}
        key={this.modalKey}
        ref="rootmodal"
        close={this.handleClosePopupEvent}
        confirm={this.handleConfirmPopupEvent}
        v-slots={this.slots}
      />
    );
  }

  private handleClosePopupEvent() {
    ModalService.bus.emit('close');
  }

  private handleConfirmPopupEvent(payload?: unknown) {
    payload = payload || this.$refs.rootmodal?.resolvedValue;
    ModalService.bus.emit('confirm', payload);
  }

  @Watch('$route.query')
  @Watch('$route.name')
  private destroyModal() {
    this.modal = null;
    this.params = {};
  }
}
