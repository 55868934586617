import curry2, { CurriedFunction2 } from './curry2';

interface CurriedFunction3<A, B, C, R> {
  (t1: A): CurriedFunction2<B, C, R>;
  (t1: A, t2: B): (t3: C) => R;
  (t1: A, t2: B, t3: C): R;
}

export default function curry3<A, B, C, R>(
  f: (a1: A, b1: B, c1: C) => R
): CurriedFunction3<A, B, C, R> {
  function curriedFunction(a1: A): CurriedFunction2<B, C, R>;
  function curriedFunction(a1: A, b1: B): (c1: C) => R;
  function curriedFunction(a1: A, b1: B, c1: C): R;
  function curriedFunction(a1: A, b1?: B, c1?: C): unknown {
    switch (arguments.length) {
      case 1:
        return curry2(function _c1(b2: B, c2: C): R {
          return f(a1, b2, c2);
        });
      case 2:
        return function _c2(c2: C): R {
          return f(a1, b1 as B, c2);
        };
      case 3:
        return f(a1, b1 as B, c1 as C);
    }
  }
  return curriedFunction;
}
