export default function htmlDescriptionCleaner(description: string | undefined) {
  return !description || description?.trim() === ''
    ? description
    : description
        .replaceAll(/&nbsp;/g, ' ')
        .replaceAll('</p>', ' ')
        .replaceAll('</li>', ' ')
        .replaceAll('</h1>', ' ')
        .replaceAll('</h2>', ' ')
        .replaceAll('</h3>', ' ')
        .replaceAll(/<[^>]*>/g, '');
}
