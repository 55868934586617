import { Attachment } from './Attachment';
import { Category } from './Category';
import { ThumbnailableEntity, ThumbnailInfo, OrderableEntity } from './Entity';
import { Metadata } from './Metadata';
import { SearchCaptionsMatch } from './SearchMatches';
import { Tag } from './Tag';
import { User } from './User';

export enum Media$RestrictedAllow {
  USERS = 'users',
  GROUPS = 'groups',
  ROLES = 'roles',
}

export enum Media$Types {
  AUDIO = 'Audio',
  VIDEO = 'Video',
  DOCUMENT = 'Document',
  PLAYLIST = 'Playlist',
  AUDIO_PLAYLIST = 'AudioPlaylist',
  VIDEO_PLAYLIST = 'VideoPlaylist',
}

export enum Media$Privacies {
  PUBLIC = 'Public',
  UNLISTED = 'Unlisted',
  RESTRICTED = 'Restricted',
}

export type Media$RelatedLink = {
  path: string;
  name: string;
};

export type FeaturedCarousel = {
  id: string;
  keyword: string;
  displayName: string;
  type: string;
  query: string;
  order: number;
  isEnabled: boolean;
  createdDate: string;
  mediaItems: Media[];
};

export type LookupItem = {
  name: string;
  icon?: string;
  tooltip?: string;
  mediaPosting?: number; // 0 no approve, 1 need approve, 2 not allowed
} & Entity;

export enum Media$ExternalPage {
  NONE = 'None',
  PUBLIC = 'Public',
  // PROTECTED = 'Protected',
}

export enum Media$AvailabilityStatus {
  DISABLED = 'Disabled',
  AVAILABLE = 'Available',
}

export type BasicMediaEntity = {
  title: string;
  description: string;
  owner: User;
  createdDate: string;

  views: number;
  privacy: Media$Privacies;
  allowExternalPage: Media$ExternalPage;
  isPublished: boolean;
  availability: Media$AvailabilityStatus;
} & ThumbnailableEntity;

export type PlaylistEntity = {
  type: Media$Types.AUDIO_PLAYLIST | Media$Types.VIDEO_PLAYLIST;
  itemsCount: number;
} & BasicMediaEntity;

export type BasicSingleMediaEntity = {
  likes: number;
  rating?: number;
  commentsCount: number;
  hasMetadata: boolean;
  expirationDate: Nullable<string>;
} & BasicMediaEntity;

export type AudioEntity = {
  type: Media$Types.AUDIO;
  duration: number;
  hasCC: boolean;
} & BasicSingleMediaEntity;

export type VideoEntity = {
  type: Media$Types.VIDEO;
  duration: number;
  hasCC: boolean;
} & BasicSingleMediaEntity;

export type DocumentEntity = {
  type: Media$Types.DOCUMENT;
  fileExtension: string;
  fileSize: string;
  fileUri: string;
} & BasicSingleMediaEntity;

export type SinglePlayableMedia = AudioEntity | VideoEntity;
export type SingleMediaEntity = AudioEntity | VideoEntity | DocumentEntity;
export type Media = PlaylistEntity | AudioEntity | VideoEntity | DocumentEntity;

export type SearchableMedia = {
  captionsMatches?: SearchCaptionsMatch[];
} & Media;

export type Media$Details = {
  // single-mode
  relatedLinks: Media$RelatedLink[];
  sites: string[];
  categories: Category[];
  tags: Tag[];
  isFollowingOwner: boolean;
  iLikeIt: boolean;
  inFavorite: boolean;
  allowedUsers: LookupItem[];
  allowComenting: boolean;
  groups: LookupItem[];
  hasFlaggedByMe: boolean;
  isEnabledEmbedding: boolean;
  isEnabledDownload: boolean;
  attachments?: Attachment[];
} & Media;

export type MyMedia = {
  audioTrackLang: string;
  isEnabledDownload: boolean;

  optionalThumbnails: ThumbnailInfo[];

  allowRating: boolean;
  hideRelatedMedia: boolean;

  publishDate: Nullable<string>;
  expirationDate: Nullable<string>;

  relatedMedia: Media[];
  metadata: Metadata[];

  items: Nullable<OrderableMedia[]>;
} & Media$Details;

export type ExpandedMedia = {
  isAutoGenerateCaption: boolean;

  notifyAllReviewers: boolean;
  selectedReviewers: LookupItem[];
} & MyMedia;

export type PredefinedMediaProperties = Partial<ExpandedMedia>;

export interface Media$DownloadableSource {
  quality: number;
  format: string;
  size: string;
  src: string;
  type: 'mp4' | 'origin';
}

export type Media$ReportReson = {
  name: string;
} & Entity;

export type Media$DailyStatistic = {
  date: string;
  views: number;
};

export type Media$Statistic = {
  views: Nullable<number>;
  mobile: Nullable<number>;
  likes: Nullable<number>;
  rating?: Nullable<number>;
  favorites: Nullable<number>;

  lastMonthViews: Media$DailyStatistic[];
};

export type OrderableMedia = Media & OrderableEntity;

export type ClipInfo = {
  from: number;
  to: number;
  title: string;
  description: string;
  tags: Tag[];
};

export type TrimInfo = {
  from: number;
  to: number;
  title: string;
};
