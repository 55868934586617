export default function getScrollableParent(node: any): Element | null {
  const body = document.body;
  while (node) {
    if (_hasScroll(node)) {
      return node === body || node.contains(body) ? mainScrollingElement() : node;
    }

    if (!node.parentElement) {
      return null;
    }
    node = node.parentElement;
  }

  return null;
}

export function mainScrollingElement(): Element {
  return document.scrollingElement || document.documentElement;
}

function _hasScroll(el: HTMLElement): boolean {
  return el.scrollHeight > (el.clientHeight || el.offsetHeight) + 2;
}
