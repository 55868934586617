import MockAdapter from 'axios-mock-adapter/types';
import factory from './mock-factory';
import { _generateLiveEvent, _generateMockList } from './helper-fakers';

const data = _generateMockList(3, _generateLiveEvent);

export default function mocker(adapter: MockAdapter) {
  const { _load } = factory(data, 'id', ['title']);
  adapter.onGet(/\/api\/v1\.0\/live\/event\/\d+\/broadcast/).reply(_getBroadcast);
  adapter.onGet('/api/v1.0/live/event').reply(_load);

  function _broadcastEvent() {
    return {
      liveEventStatus: 0,
      streamingUrl: '',
      mediaId: null,
      hasStreamingData: false,
    };
  }

  function _getBroadcast() {
    return [200, _broadcastEvent()];
  }
}
