import { Vue, prop } from 'vue-class-component';

import { $Props } from '../../typings/tsx';
import './spinner.scss';

const CN = 'spinner';

class Props {
  isPrimary = prop({ default: true });
  think = prop({ default: false });
  size = prop<Component$Size>({ default: 'lg' });
}

export type Spinner$Props = $Props<Props>;

export default class Spinner extends Vue.with(Props) {
  public render() {
    const { isPrimary, think, size } = this;
    const cn = {
      [CN]: true,
      [`${CN}--primary`]: isPrimary,
      [`${CN}--think`]: think,
      [`${CN}--${size}`]: size,
    };

    return <span class={cn} />;
  }
}
