import { Player$CombineOptions } from '@ravnur/player/src/types/Options';
import { Player$PlaylistMode } from '@ravnur/player/src/types/Playlist';

import { Category } from './Category';
import { ThumbnailableEntity, ThumbnailInfo, OrderableEntity } from './Entity';
import { Metadata } from './Metadata';
import { SearchMatches } from './SearchMatches';
import { Tag } from './Tag';
import { User } from './User';

export enum MediaAnalyzedFields {
  TITLE = 'Title',
  MEDIA_ID = 'MediaId',
}

export enum Media$RestrictedAllow {
  USERS = 'users',
  GROUPS = 'groups',
  ROLES = 'roles',
}

export enum Media$Types {
  AUDIO = 'Audio',
  VIDEO = 'Video',
  DOCUMENT = 'Document',
  PLAYLIST = 'Playlist',
  AUDIO_PLAYLIST = 'AudioPlaylist',
  VIDEO_PLAYLIST = 'VideoPlaylist',
}

export enum Media$Privacies {
  PUBLIC = 'Public',
  UNLISTED = 'Unlisted',
  RESTRICTED = 'Restricted',
}

export enum Media$AvailabilityStatus {
  DISABLED = 'Disabled',
  AVAILABLE = 'Available',
  NOT_REVIEWED = 'NotReviewed',
}

export enum TimedataState {
  NOT_REVIEWED = 'NotReviewed',
  BLOCKED = 'Blocked',
  AVAILABLE = 'Available',
}

export enum TimedataType {
  CAPTIONS = 'Captions',
  CUE_POINT = 'CuePoints',
}

export const PLAYER_BASE_OPTIONS: Partial<Player$CombineOptions> = {
  showPlay: true,
  showProgress: true,
  showVolume: true,
  showFullScreen: true,
  showClosedCaptions: true,
  showTOC: true,
  showAnnotations: true,
  showQuality: true,
  savePlayTime: true,
  accessibility: true,

  // RMP-1082 Hiding audio tracks because will not use in RMP
  // Check bug explanation in ticket comment.
  showAudioTracks: false,

  showPoster: true,
  showPlaceholder: true,
  showPlaybackRate: true,
  showSettings: true,
  showDownload: true,
  showHelp: true,
  showCCLayout: true,
  showBottomNext: false,
  showBottomPrev: false,
  showCaptionSearch: true,

  // Disable
  showForward: false,
  showBackward: false,
  showTheaterMode: false,
  showPrev: false,
  showTitle: false,
  showNext: false,
  showNextFrame: false,
  showPrevFrame: false,
};

export const PLAYER_IOS_OPTIONS = {
  showPlay: true,
  showProgress: true,
  showVolume: true,
  showFullScreen: true,
  showPoster: true,
  showPlaceholder: true,

  showClosedCaptions: false,
  showTOC: false,
  showAnnotations: false,
  showQuality: false,
  showAudioTracks: false,
  showPlaybackRate: false,
  showSettings: false,
  showDownload: false,
  showHelp: false,
  showCCLayout: false,
  showBottomNext: false,
  showBottomPrev: false,
  showCaptionSearch: false,
  showForward: false,
  showBackward: false,
  showTheaterMode: false,
  accessibility: false,
  showPrev: false,
  showTitle: false,
  showNext: false,
  showNextFrame: false,
  showPrevFrame: false,
  savePlayTime: true,
};

export type Media$RelatedLink = {
  path: string;
  name: string;
};

export type LookupItem = {
  name: string;
  icon?: string;
  applicationId?: string;
  tooltip?: string;
  mediaPosting?: number; // 0 no approve, 1 need approve, 2 not allowed
} & Entity;

export enum Media$ExternalPage {
  NONE = 'None',
  PUBLIC = 'Public',
  // PROTECTED = 'Protected',
}

export type BasicMediaEntity = {
  title: string;
  description: string;
  owner: User;
  createdDate: string;
  views: number;
  privacy: Media$Privacies;
  allowExternalPage: Media$ExternalPage;
  isPublished: boolean;
  availability: Media$AvailabilityStatus;
} & ThumbnailableEntity;

export type PlaylistEntity = {
  type: Media$Types.AUDIO_PLAYLIST | Media$Types.VIDEO_PLAYLIST;
  itemsCount: number;
} & BasicMediaEntity;

export type BasicSingleMediaEntity = {
  likes: number;
  rating?: number;
  commentsCount: number;
  hasMetadata: boolean;
  expirationDate: Nullable<string>;
  isRetentionPolicy?: boolean; // only for myacc. Types should be refactored in scope of RMP-1736
} & BasicMediaEntity;

export type AudioEntity = {
  type: Media$Types.AUDIO;
  duration: number;
  hasCC: boolean;
} & BasicSingleMediaEntity;

export type VideoEntity = {
  type: Media$Types.VIDEO;
  duration: number;
  hasCC: boolean;
  captionsMatches?: {
    language: string;
    highlights: [
      {
        text: string;
        from: number;
      }
    ];
  };
} & BasicSingleMediaEntity;

export type DocumentEntity = {
  type: Media$Types.DOCUMENT;
  fileExtension: string;
  fileSize: string;
  fileUri: string;
} & BasicSingleMediaEntity;

export type SinglePlayableMedia = AudioEntity | VideoEntity;
export type SingleMediaEntity = AudioEntity | VideoEntity | DocumentEntity;
export type Media = PlaylistEntity | AudioEntity | VideoEntity | DocumentEntity;

export type SearchableMedia = {
  matches?: SearchMatches;
} & Media;

export type Media$Details = {
  // single-mode
  relatedLinks: Media$RelatedLink[];
  sites: string[];
  categories: Category[];
  tags: Tag[];
  isFollowingOwner: boolean;
  iLikeIt: boolean;
  inFavorite: boolean;
  allowedUsers: LookupItem[];
  allowComenting: boolean;
  groups: LookupItem[];
  hasFlaggedByMe: boolean;
  isEnabledEmbedding: boolean;
  isEnabledDownload: boolean;
  isRetentionPolicy?: boolean; // only for myacc. Types should be refactored in scope of RMP-1736
} & Media;

export type MyMedia = {
  audioTrackLang: string;
  isEnabledDownload: boolean;

  optionalThumbnails: ThumbnailInfo[];

  allowRating: boolean;
  hideRelatedMedia: boolean;

  publishDate: Nullable<string>;
  expirationDate: Nullable<string>;

  relatedMedia: Media[];
  metadata: Metadata[];

  items: Nullable<OrderableMedia[]>;
  isNotVod: boolean;
} & Media$Details;

export type ExpandedMedia = {
  isAutoGenerateCaption: boolean;

  notifyAllReviewers: boolean;
  selectedReviewers: LookupItem[];
} & MyMedia;

export type PredefinedMediaProperties = Partial<ExpandedMedia>;

export interface Media$DownloadableSource {
  quality: number;
  format: string;
  size: string;
  src: string;
  type: 'mp4' | 'origin';
}

export type Media$ReportReson = {
  name: string;
} & Entity;

export type Media$DailyStatistic = {
  date: string;
  views: number;
};

export type Media$Statistic = {
  views: Nullable<number>;
  mobile: Nullable<number>;
  likes: Nullable<number>;
  rating?: Nullable<number>;
  favorites: Nullable<number>;

  lastMonthViews: Media$DailyStatistic[];
};

export type OrderableMedia = Media & OrderableEntity;

export type ClipInfo = {
  start: number;
  duration: number;
  title: string;
  description: string;
  tags: Tag[];
};

export type TrimInfo = {
  start: number;
  duration: number;
  title: string;
};

export type EmbedCodeOptions = {
  size: string;
  from: number;
  to?: number | undefined;
  autoStart: boolean;
  showAnnotations: boolean;
  muted: boolean;
  width: string;
  height: string;
  playlistMode: Player$PlaylistMode;
  loop: boolean;
  hidePlaylist: boolean;
};

export type Timedata = {
  label: string;
  lang: string;
  langid: string;
  segments: Nullable<TimedataSegment[]>;
  src: string;
  state: TimedataState;
  type: TimedataType;
};

export type TimedataSegment = {
  from: number;
  to: number;
  text: string;
} & Entity;

export type TimedataThumbnail = {
  id: number;
  from: number;
  to: number;
  text: string;
};
