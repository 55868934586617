import gt from 'lodash/gt';
import inRange from 'lodash/inRange';

import getScrollableParent from './getScrollableParent';
import scrollIntoView from './scrollIntoView';

export default function scrollIntoViewIfNeeded(el: Element, useAnimation = false, duration = 250) {
  const parent = getScrollableParent(el);

  if (!parent) {
    return;
  }

  const { top, bottom } = el.getBoundingClientRect();
  const rect = parent.getBoundingClientRect();

  let visible = true;
  visible = visible && gt(top, 0);
  visible = visible && inRange(top, rect.top, rect.bottom);
  visible = visible && inRange(bottom, rect.top, rect.bottom);

  if (!visible) {
    scrollIntoView(el, useAnimation, duration);
  }
}
