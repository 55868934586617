import {
  AdvancedSearch$MatchState,
  AdvancedSearch$MatchRule,
  AdvancedSearch$ServerRule,
  AdvancedSearch$ServerState,
} from '@/types/AdvancedSearch';

export default function toServerFormat(
  state: AdvancedSearch$MatchState
): AdvancedSearch$ServerState {
  return {
    M: state.match,
    P: state.rules.filter((r) => r.value).map(_encodeRule),
  };
}

function _encodeRule(rule: AdvancedSearch$MatchRule): AdvancedSearch$ServerRule {
  const res: AdvancedSearch$ServerRule = {
    F: rule.type,
    FV: rule.value,
    O: rule.cond,
  } as any;
  if (rule.secondValue) {
    res.SV = rule.secondValue;
  }
  if (rule.nested) {
    res.N = toServerFormat(rule.nested as any);
  }
  return res;
}
