import { Player$Source } from '@ravnur/player/src/types/Source';
import {
  Media,
  Media$Types,
  PlaylistEntity,
  SinglePlayableMedia,
  SingleMediaEntity,
  VideoEntity,
  DocumentEntity,
  SearchableMedia,
  Media$Privacies,
  Media$Details,
} from '../types/Media';

export const REAL_MEDIA_TYPES: Media$Types[] = [
  Media$Types.VIDEO,
  Media$Types.AUDIO,
  Media$Types.DOCUMENT,
  Media$Types.AUDIO_PLAYLIST,
  Media$Types.VIDEO_PLAYLIST,
];

export const PLAYLIST_TYPES: Media$Types[] = [
  Media$Types.VIDEO_PLAYLIST,
  Media$Types.AUDIO_PLAYLIST,
];

export const SINGLE_TYPES: Media$Types[] = [
  Media$Types.AUDIO,
  Media$Types.VIDEO,
  Media$Types.DOCUMENT,
];

export const PLAYABLE_TYPES: Media$Types[] = [Media$Types.AUDIO, Media$Types.VIDEO];

export function isMediaEntity(e: Entity): e is Media {
  const type = (e as any).type;
  return type ? REAL_MEDIA_TYPES.some((t) => t === type) : false;
}

export function isPlaylist(m: Media | Media$Details): m is PlaylistEntity {
  return PLAYLIST_TYPES.some((t) => t === m.type);
}

export function getPlaylistMediaCount(m: Media): number | void {
  if (isPlaylist(m)) return m.itemsCount;
}

export function isPlayable(m: Media): m is SinglePlayableMedia {
  return PLAYABLE_TYPES.some((t) => t === m.type);
}

export function isSingleMedia(m: Media): m is SingleMediaEntity {
  return SINGLE_TYPES.some((t) => t === m.type);
}

export function isVideo(m: Media): m is VideoEntity {
  return m.type === Media$Types.VIDEO;
}

export function isAudio(m: Media): m is VideoEntity {
  return m.type === Media$Types.AUDIO;
}

export function isDocument(m: Media): m is DocumentEntity {
  return m.type === Media$Types.DOCUMENT;
}

export function isSearchableMedia(m: Media): m is SearchableMedia {
  return !!(m as any).matches;
}

export function isRestrictedForUser(m: Media | Player$Source): boolean {
  // If media is restricted for current user BE will return some required values as 'null'
  return m.privacy === Media$Privacies.RESTRICTED && m.owner === null;
}
