import axios from 'axios';

export enum LogLevel {
  ERROR = 'error',
  WARN = 'warn',
  LOG = 'log',
  DEBUG = 'debug',
}

const URL_PREFIX = '/api/v1.0/log';
const FLUSH_INTERVAL = 10000;

let STORE: any[] = [];

export default class LoggerService {
  constructor(private name: string) {}

  public debug(...args: any[]) {
    _push(LogLevel.DEBUG, this.name, args);
  }

  public log(...args: any[]) {
    _push(LogLevel.LOG, this.name, args);
  }

  public warn(...args: any[]) {
    _push(LogLevel.WARN, this.name, args);
  }

  public error(...args: any[]) {
    _push(LogLevel.ERROR, this.name, args);
  }

  public fatal(report: { message: string; stack: any[] }) {
    _push(LogLevel.ERROR, this.name, [report.message, report.stack]);
  }
}

function _push(level: LogLevel, loggerName: string, args: any[]) {
  const texts: string[] = toConsole(loggerName, level, args);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line prefer-spread
  console[level].apply(console, texts as any);
  STORE.push(texts);
}

function toConsole(loggerName: string, level: LogLevel, messages: any[]): string[] {
  messages = messages.map(_stringify);
  return [loggerName, level.toUpperCase(), ...messages.filter((_) => _)];
}

function _stringify(o: any): string {
  if (o instanceof Error) {
    return o.message;
  }
  if (o instanceof Object) {
    return JSON.stringify(o);
  }

  if (typeof o === 'string') {
    return o;
  }

  if (typeof o === 'number' || typeof o === 'boolean') {
    return o.toString();
  }

  return '';
}

export function flush() {
  let auth;
  const guard: string = localStorage.getItem('guard') || '{}';
  try {
    auth = JSON.parse(guard).auth;
  } catch (e) {
    return _flushDelayed();
  }

  if (!STORE.length || !auth) {
    return _flushDelayed();
  }

  const data = JSON.stringify(STORE);
  STORE = [];
  return axios.post(URL_PREFIX, data, { headers: { Authorization: auth } }).then(_flushDelayed);
}

function _flushDelayed() {
  setTimeout(flush, FLUSH_INTERVAL);
  return false;
}

_flushDelayed();
