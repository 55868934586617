import { defineComponent } from 'vue';

import './user-name.scss';

const CN = 'user-name';

const UserName = defineComponent({
  setup(props, ctx) {
    return () => (
      <div class={[`${CN}`, ctx.attrs.onclick && `${CN}__clickable`]} {...ctx.attrs}>
        {ctx.slots.default?.()}
      </div>
    );
  },
});

export default UserName;
