import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "match-builder" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]

export function render(_ctx, _cache) {
  const _component_chosen = _resolveComponent("chosen")
  const _component_l10n = _resolveComponent("l10n")
  const _component_fork = _resolveComponent("fork")
  const _component_r_button = _resolveComponent("r-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["match-builder__fields-wrapper", { 'match-builder__fields-wrapper--metadata-type': _ctx.isMetaDataField }])
    }, [
      _createVNode(_component_chosen, {
        class: "match-builder__common-fields",
        options: _ctx.commonFields,
        value: _ctx.commonType,
        onInput: _ctx.onChangeType,
        "key-for-label": "displayName",
        "key-for-value": "name",
        size: "sm",
        "opener-position": _ctx.openerPosition
      }, {
        option: _withCtx((props) => [
          _createElementVNode("span", {
            textContent: _toDisplayString(props.item.displayName),
            class: _normalizeClass({ 'match-builder__meta-options': props.item.name === 'fake' })
          }, null, 10, _hoisted_2)
        ]),
        _: 1
      }, 8, ["options", "value", "onInput", "opener-position"]),
      (_ctx.isMetaDataField)
        ? (_openBlock(), _createBlock(_component_chosen, {
            key: 0,
            class: "match-builder__meta-fields",
            options: _ctx.metadataFields,
            value: _ctx.type,
            onInput: _ctx.onChangeType,
            "key-for-label": "name",
            size: "sm",
            "opener-position": _ctx.openerPosition
          }, {
            option: _withCtx((props) => [
              _createElementVNode("span", {
                textContent: _toDisplayString(props.item.name)
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }, 8, ["options", "value", "onInput", "opener-position"]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.conditions && _ctx.type != null)
      ? (_openBlock(), _createBlock(_component_chosen, {
          key: 0,
          class: "match-builder__conditions",
          options: _ctx.conditions,
          "key-for-label": "tkey",
          onInput: _ctx.onChangeCond,
          value: _ctx.cond,
          size: "sm",
          "opener-position": _ctx.openerPosition
        }, {
          option: _withCtx((props) => [
            _createVNode(_component_l10n, {
              group: "common",
              tkey: props.item.tkey
            }, null, 8, ["tkey"])
          ]),
          _: 1
        }, 8, ["options", "onInput", "value", "opener-position"]))
      : _createCommentVNode("", true),
    (_ctx.cond != null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_fork, {
            class: _normalizeClass(["match-builder__value", { 'match-builder__value--short': _ctx.secondValueComponent }]),
            name: _ctx.valueComponent,
            value: _ctx.value,
            onInput: _ctx.onChangeValue,
            "opener-position": _ctx.openerPosition
          }, null, 8, ["name", "value", "onInput", "class", "opener-position"]),
          (_ctx.secondValueComponent)
            ? (_openBlock(), _createBlock(_component_fork, {
                key: 0,
                class: "match-builder__value match-builder__value--short",
                name: _ctx.secondValueComponent,
                value: _ctx.secondValue,
                onInput: _ctx.onChangeSecondValue,
                "opener-position": _ctx.openerPosition
              }, null, 8, ["name", "value", "onInput", "opener-position"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showSettings)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createVNode(_component_r_button, {
            class: "match-builder__remove-btn",
            mode: "frameless",
            color: "grey",
            size: "sm",
            icon: "close",
            onClick: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.$emit('remove')), ["prevent"])),
            title: _ctx.$t('common', 'query_builder__remove_rule')
          }, null, 8, ["title"]),
          (!_ctx.hideNestedBtn && _ctx.cond != null)
            ? (_openBlock(), _createBlock(_component_r_button, {
                key: 0,
                class: "match-builder__nested-btn",
                mode: "frameless",
                color: "grey",
                size: "sm",
                icon: "subdirectory",
                onClick: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.$emit('nested')), ["prevent"])),
                title: _ctx.$t('common', 'query_builder__add_nested')
              }, null, 8, ["title"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}