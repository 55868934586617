import http from './http';
import wait from './operation';

export type BooleanAsString = 'true' | 'false';

export interface PagerListResponse<E extends Entity> {
  items: E[];
  total: number;
}

export type BasePaginatedParams = {
  q: string;
  count: string;
  offset: string;
  sortField: string;
  sortType: 'asc' | 'desc';
} & Dictionary<string | Array<Nullable<string>> | null | undefined>;

export default class BaseRepository<
  T extends Entity,
  LoadParams extends Record<string, unknown> = Record<string, unknown>,
  ListResponse = T[]
> {
  constructor(source: string) {
    Object.defineProperty(this, '_resource', {
      get: () => source,
    });
  }

  get _resource(): string {
    throw new Error('Unsupported operation exception');
  }

  public load(params: Partial<LoadParams> = {}): Promise<ListResponse> {
    return http.get(this._resource, { params });
  }

  public get(id: string, params: Record<string, unknown> = {}): Promise<T> {
    return http.get(`${this._resource}/${id}`, { params });
  }

  public async remove(id: string): Promise<Operation> {
    try {
      const op: Operation = await http.delete(`${this._resource}/${id}`);
      return wait(op);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async clear(): Promise<any> {
    try {
      const op: Operation = await http.delete(this._resource);
      return wait(op);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async save(entity: Partial<T>, forceReload = false): Promise<Partial<T> & Pick<T, 'id'>> {
    const { id } = entity;
    const path = id ? `${this._resource}/${id}` : this._resource;
    const promise: Promise<Operation> = id ? http.put(path, entity) : http.post(path, entity);
    const op: Operation = await promise;

    try {
      const operation: Operation = await wait(op);
      const entityId = id || operation.entityId;
      if (forceReload && entityId) {
        return this.get(entityId);
      }
      return { ...entity, id: entityId };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async saveMany(list: Array<Partial<T>>): Promise<Operation> {
    try {
      const op: Operation = await http.post(this._resource, list);
      return wait(op);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async removeMany(list: T[]): Promise<Operation> {
    const ids = list.map((e) => e.id);
    const operation: Operation = await http.delete(this._resource, {
      params: { ids },
    });
    return wait(operation);
  }

  public async action<A extends string, D extends Record<string, unknown>>(
    action: A,
    data: Record<string, unknown> | Array<Record<string, unknown>> = {},
    entityId = ''
  ): Promise<D> {
    const path = entityId
      ? `${this._resource}/${entityId}/${action}`
      : `${this._resource}/${action}`;
    const operation: Operation<D> = await http.post(path, data);
    try {
      const op = await wait(operation);
      return op.data as D;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async undo(
    action: string,
    data: Record<string, unknown> = {},
    entityId = ''
  ): Promise<boolean> {
    const path = entityId
      ? `${this._resource}/${entityId}/${action}`
      : `${this._resource}/${action}`;
    const operation: Operation = await http.delete(path, data);
    try {
      await wait(operation);
      return true;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
