import $t from '@ravnur/l10n/$t';
import { Vue } from 'vue-class-component';

import { Featured as FeaturedType } from '@/types/Featured';

import './featured.scss';

const CN = 'featured';

export default class Featured extends Vue {
  imageWidth = 0;

  get loading() {
    return this.store.featuredVideos.loading;
  }

  get featured() {
    return this.store.featuredVideos.list;
  }

  get slots() {
    return {
      item: this.renderItemSlot,
    };
  }

  get renderFeatured() {
    return this.featured.length > 0 ? (
      <div class={CN}>
        <carousel
          autoScroll={false}
          dotTitleGen={this.getTitle}
          list={this.featured}
          showDotes={true}
          v-slots={this.slots}
        />
      </div>
    ) : (
      <div></div>
    );
  }

  render() {
    return this.loading ? (
      <div class={`${CN}__spinner`}>
        <spinner />
      </div>
    ) : (
      this.renderFeatured
    );
  }

  private renderItemSlot({ item }: { item: FeaturedType; index: number }) {
    // const src = item?.thumbnail ? item?.thumbnail.url : '';

    return (
      <div class={`${CN}__item`} onClick={() => this.goto(item)}>
        <div class={`${CN}__description`}>
          <div class={`${CN}__description-info`}>
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
                fill-rule="evenodd"
              />
              <path
                clip-rule="evenodd"
                d="M9.52814 7.11833C9.8533 6.94431 10.2478 6.96338 10.5547 7.16795L16.5547 11.168C16.8329 11.3534 17 11.6656 17 12C17 12.3344 16.8329 12.6466 16.5547 12.8321L10.5547 16.8321C10.2478 17.0366 9.8533 17.0557 9.52814 16.8817C9.20298 16.7077 9 16.3688 9 16V8C9 7.63121 9.20298 7.29235 9.52814 7.11833ZM11 9.86852V14.1315L14.1972 12L11 9.86852Z"
                fill-rule="evenodd"
              />
            </svg>
            <l10n group="home" tkey="featured__title" />
          </div>
          <div class={`${CN}__description-title`} data-testid="featured-title">
            {item?.title}
          </div>
          <div class={`${CN}__description-author`} data-testid="featured-author">
            {$t('home', 'featured__by', { name: item.owner.displayName })}
          </div>
        </div>
        <div class={`${CN}__image`}>
          <div class={`${CN}__image-overlay`} />
          {/* <img alt={item?.title} src={src} /> */}
          <thumbnail data-testid="featured-thumb" entity={item} showType={false} />
          <icon type="play-circle-outline" />
        </div>
      </div>
    );
  }

  private goto(featured: FeaturedType) {
    this.$router.push({ name: 'Media', params: { id: featured.id } });
  }

  private getTitle(item: FeaturedType): string {
    return item.title;
  }

  created() {
    this.store.featuredVideos.load();
  }
}
