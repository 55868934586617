import { defineComponent, PropType, ref } from 'vue';

import AuthContainersSwitcher from '../auth-containers-switcher/auth-containers-switcher';

export default defineComponent({
  props: {
    keys: { type: Array as PropType<string[]>, required: true, default: [] },

    onChangedIndex: {
      type: Function as PropType<(index: number) => void>,
      default: () => () => void 0,
    },
  },
  setup(props, ctx) {
    const index = ref(0);
    const setIndex = (i: number) => {
      index.value = i;
      props.onChangedIndex(i);
    };

    return () => (
      <AuthContainersSwitcher
        index={index.value}
        keys={props.keys}
        v-slots={ctx.slots}
        onInput={setIndex}
      />
    );
  },
});
