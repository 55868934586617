import { ThumbnailInfo } from '@/types/Entity';
import { Folder } from '@/types/Folder';
import { Group, Group$JoinState, Group$PinnedMedia, Group$Privacy } from '@/types/Group';
import {
  Media$AvailabilityStatus,
  Media$Details,
  Media$ExternalPage,
  Media$Privacies,
  Media$Types,
} from '@/types/Media';
import { Tag } from '@/types/Tag';
import { User } from '@/types/User';
import { faker } from '@faker-js/faker';
import { LiveChannel } from '@/types/Broadcast';
import { LiveEvent } from '@/types/Live';
import { LiveEvent$Status, LiveSource$Type } from '@ravnur/shared/types/Live';

export function _generateMockList<T>(count: number, generator: () => T): T[] {
  return new Array(count).fill(null).map(generator);
}

export function _generateUser(): User {
  return {
    id: `${faker.datatype.number()}`,
    displayName: faker.name.fullName(),
    userName: faker.internet.userName(),
    email: faker.internet.email(),
    userpic: faker.internet.avatar(),
    applications: [],
  };
}

export function _generateThumbnail(): ThumbnailInfo {
  return {
    name: faker.lorem.sentences(),
    url: faker.datatype.boolean() ? faker.image.imageUrl() : '',
  };
}

export function _generateMedia(): Media$Details {
  return {
    id: `${faker.datatype.number()}`,
    thumbnail: _generateThumbnail(),
    title: faker.lorem.sentence(),
    owner: _generateUser(),
    createdDate: faker.date.past().toISOString(),
    views: +faker.random.numeric(2),
    privacy: faker.helpers.arrayElement([
      Media$Privacies.PUBLIC,
      Media$Privacies.RESTRICTED,
      Media$Privacies.UNLISTED,
    ]),
    type: faker.helpers.arrayElement([
      Media$Types.VIDEO,
      Media$Types.AUDIO_PLAYLIST,
      Media$Types.VIDEO_PLAYLIST,
      Media$Types.AUDIO,
    ]),
    description: faker.lorem.text(),
    duration: +faker.random.numeric(2),
    likes: +faker.random.numeric(2),
    commentsCount: +faker.random.numeric(2),
    itemsCount: +faker.random.numeric(2),
    hasCC: faker.datatype.boolean(),
    hasMetadata: faker.datatype.boolean(),
    availability: faker.helpers.arrayElement([
      Media$AvailabilityStatus.AVAILABLE,
      Media$AvailabilityStatus.DISABLED,
    ]),
    expirationDate: faker.date.soon().toISOString(),
    allowComenting: faker.datatype.boolean(),
    relatedLinks: [],
    sites: [],
    categories: [],
    tags: _generateMockList(+faker.random.numeric(1), _generateTag),
    isFollowingOwner: faker.datatype.boolean(),
    iLikeIt: faker.datatype.boolean(),
    inFavorite: faker.datatype.boolean(),
    allowedUsers: [],
    hasFlaggedByMe: faker.datatype.boolean(),
    groups: [],
    isEnabledEmbedding: faker.datatype.boolean(),
    isEnabledDownload: faker.datatype.boolean(),
    allowExternalPage: faker.helpers.arrayElement([
      Media$ExternalPage.PUBLIC,
      Media$ExternalPage.NONE,
    ]),
    isPublished: faker.datatype.boolean(),
  };
}

export function _generateFolder(): Folder {
  return {
    id: `${faker.datatype.number()}`,
    name: faker.lorem.words(2),
    description: faker.lorem.text(),
    isDefault: faker.datatype.boolean(),
    parentId: null,
    mediaCount: +faker.random.numeric(2),
  };
}

export function _generateGroup(): Group {
  const mediaCount = +faker.random.numeric(2);

  return {
    id: `${faker.datatype.number()}`,
    name: faker.lorem.words(3),
    description: faker.lorem.text(),
    thumbnail: _generateThumbnail(),
    joinState: faker.helpers.arrayElement([
      Group$JoinState.MEMBER,
      Group$JoinState.REQUEST,
      Group$JoinState.NON_MEMBER,
    ]),
    isAdmin: faker.datatype.boolean(),
    privacy: faker.helpers.arrayElement([
      Group$Privacy.PRIVATE,
      Group$Privacy.PUBLIC,
      Group$Privacy.RESTRICTED,
    ]),
    mediaPosting: 0,
    founder: _generateUser(),
    createdDate: faker.date.past().toISOString(),
    bgColor: faker.color.rgb(),
    hideMembers: faker.datatype.boolean(),
    enablePinned: faker.datatype.boolean(),
    mediaCount,
    membersCount: +faker.random.numeric(2),
    membersCountForApproval: +faker.random.numeric(2),
    mediaCountForApproval: +faker.random.numeric(2),
    folders: _generateMockList(2, _generateFolder),
    pinnedMedia: _generateMockList(2, _generatePinnedMedia),
  };
}

export function _generatePinnedMedia(): Group$PinnedMedia {
  return {
    order: +faker.random.numeric(1),
    media: _generateMedia(),
  };
}

export function _generateTag(): Tag {
  return {
    id: faker.datatype.uuid(),
    name: faker.lorem.words(+faker.random.numeric(1)),
  };
}

export function _generateChannel(): LiveChannel {
  return {
    activeEventId: null,
    allowEmbedding: faker.datatype.boolean(),
    description: faker.lorem.text(),
    enableQA: faker.datatype.boolean(),
    id: `${faker.datatype.number()}`,
    path: faker.lorem.word(),
    splashPath: faker.image.imageUrl(),
    title: faker.lorem.sentence(),
    streamingUrl: faker.internet.url(),
    playlistId: null,
  };
}

export function _generateLiveEvent(): LiveEvent {
  return {
    id: `${faker.datatype.number()}`,
    title: faker.lorem.sentence(),
    description: faker.lorem.text(),
    status: faker.helpers.arrayElement([
      LiveEvent$Status.ON_AIR,
      LiveEvent$Status.EXPIRED,
      LiveEvent$Status.FINISHED,
      LiveEvent$Status.PREVIEW,
      LiveEvent$Status.SCHEDULED,
    ]),
    startDateTime: faker.date.soon().toISOString(),
    scheduledStartTime: faker.date.soon().toISOString(),
    channels: [
      {
        id: `${faker.datatype.number()}`,
        title: faker.lorem.sentence(),
        path: faker.lorem.word(),
        splashPath: faker.datatype.boolean() ? faker.image.imageUrl() : '',
      },
    ],
    owner: _generateUser(),
    duration: +faker.random.numeric(2),
    splashPath: faker.datatype.boolean() ? faker.image.imageUrl() : '',
    liveSourceType: faker.helpers.arrayElement([LiveSource$Type.RMS, LiveSource$Type.GENERIC]),
    attachments: [
      {
        id: `${faker.datatype.number()}`,
        kind: 'agenda',
        url: 'https://fake-pdf.com/some.pdf',
        originalFileName: 'some.pdf',
        uploadedBy: null,
        uploadedDate: faker.date.past().toISOString(),
      },
    ],
  };
}
