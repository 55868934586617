import WithModel from '@ravnur/core/WithModel';
import $t from '@ravnur/l10n/$t';
import { defineComponent } from 'vue';

const NakedToggler = defineComponent({
  props: {
    password: { type: String, required: true },
    value: { type: Boolean, required: true },
  },
  emits: {
    'update:value': (payload: boolean) => {
      return typeof payload === 'boolean';
    },
  },
  setup(props, ctx) {
    const toggle = () => ctx.emit('update:value', !props.value);

    return () => {
      if (!props.password.trim()) {
        return;
      }

      return (
        <r-button
          color="primary"
          icon={props.value ? 'visibility-off' : 'eye'}
          mode="borderless"
          onclick={toggle}
          slot="actions"
          tabIndex={-1}
          title={$t('login', props.value ? 'hidePassword' : 'showPassword')}
        />
      );
    };
  },
});

export default WithModel(NakedToggler, 'value');
