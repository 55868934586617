import $t from '@ravnur/l10n/$t';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent, PropType, ref } from 'vue';

import { messageFromError } from '../../transformers/message-from-error';
import { AuthRepository } from '../../types/AuthService';
import { Message } from '../../types/Message';
import AuthSubmitBtn from '../auth-submit-btn/auth-submit-btn';
import BaseAuthForm from '../base-auth-form/base-auth-form';
import PasswordField from '../password-field/password-field';

import './web-login-form.scss';

const CN = 'web-login-form';

export default defineComponent({
  props: {
    repository: { type: Object as PropType<AuthRepository<unknown>>, required: true },
    onAuth: { type: Function as PropType<<T>(data: T) => void>, required: true },
    onMessage: { type: Function as PropType<(message: Message) => void>, required: true },
  },
  setup(props, ctx) {
    const username = ref('');
    const password = ref('');

    const $v = useVuelidate(
      {
        username: { required },
        password: { required },
      },
      { username, password },
      { $autoDirty: true } as any
    );

    const login = async () => {
      try {
        const resp = await props.repository.auth({
          username: username.value,
          password: password.value,
        });
        props.onAuth(resp);
      } catch (e: any) {
        props.onMessage(messageFromError(e));
      }
    };

    return () => (
      <BaseAuthForm submit={login} v={$v.value}>
        <text-field
          class={`${CN}__field`}
          errors={$v.value.username.$errors}
          icon="user"
          placeholder={$t('login', 'username')}
          vModel={[username.value, 'value']}
        />

        <PasswordField
          class={`${CN}__field`}
          errors={$v.value.password.$errors}
          placeholder="password"
          vModel={[password.value, 'value']}
        />

        <div class={`${CN}__actions`}>
          <AuthSubmitBtn ref="button" label="login" />
          {ctx.slots.actions?.()}
        </div>
      </BaseAuthForm>
    );
  },
});
