import { ref } from 'vue';

import { Notification$Type, RavnurNotification } from './typings';

export const NOTIFICATION_VISIBILITY_TIME = 6000; // ms
const notifications = ref<RavnurNotification[]>([]);

export function hide(notification: RavnurNotification) {
  clearTimeout(notification.timerId);
  notifications.value = notifications.value.filter((n) => n.timerId !== notification.timerId);
}

export function clear() {
  notifications.value.length = 0;
}

export function useNotifications() {
  return { notifications, hide };
}

export function showInfoNotification(message: string): void {
  show(message, 'info');
}

export function showDoneNotification(message: string): void {
  show(message, 'done');
}

export function showErrorNotification(message: string): void {
  show(message, 'error');
}

function show(message: string, type: Notification$Type) {
  const timestamp = Date.now();
  const notification: RavnurNotification = { message, type, timestamp };
  notifications.value.push(notification);
  notification.timerId = setTimeout(() => hide(notification), NOTIFICATION_VISIBILITY_TIME) as any;
}
