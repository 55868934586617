import MockAdapter from 'axios-mock-adapter/types';
import { faker } from '@faker-js/faker';
import { Media$Details } from '@/types/Media';
import { Comment$Details } from '@/types/Comment';
import toBackendFormat from '../transformers/http/toBackendFormat';
import { _generateMedia, _generateMockList, _generateUser } from './helper-fakers';

const count = +faker.random.numeric(2);
const data = _generateMockList<Media$Details>(count, _generateMedia as any);

export default function mocker(mock: MockAdapter) {
  mock.onGet('/api/v1.0/media').reply(_load);
  mock.onGet(/\/api\/v1\.0\/media\/\d+\/related/).reply(_loadRelated);
  mock.onGet(/\/api\/v1\.0\/media\/\d+\/comment/).reply(_loadComments);
  mock.onGet(/\/api\/v1\.0\/media\/\d+/).reply(_get);
}

function _get() {
  const media = faker.helpers.arrayElement(data);
  return [200, toBackendFormat(media)];
}

function _load() {
  return [200, toBackendFormat({ tota: count, items: data, facets: [] })];
}

function _loadRelated() {
  const total = +faker.random.numeric(2);
  const data = _generateMockList<Media$Details>(total, _generateMedia);

  return [200, toBackendFormat({ facets: [], items: data, total })];
}

function _loadComments() {
  const total = +faker.random.numeric(2);
  const data = _generateMockList(total, _generateComment);

  return [200, toBackendFormat({ items: data, total })];
}

function _generateComment(): Comment$Details {
  return {
    id: `${faker.datatype.number()}`,
    parentId: null,
    owner: _generateUser(),
    createdDate: faker.date.past().toISOString(),
    message: faker.lorem.text(),
    repliedTo: _generateUser(),
    likesCount: +faker.random.numeric(1),
    iLikeIt: faker.datatype.boolean(),
    totalChildrenCount: 0,
    children: [],
  };
}
