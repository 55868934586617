import memoize from 'lodash/memoize';

import { findFormIndex, get, getPluralRuleIndex } from './service';

export type TranslationType = Translation;
export type TranslationLocale = Translation$Locale;

const parse = memoize(toTokens);

interface Token {
  type: 'text' | 'named';
  value: string;
}

export default function $t(
  group: string,
  key: string,
  params: Dictionary<unknown> = {},
  pluralCount?: number | null
): string {
  const translation = get(group, key);
  if (!translation) {
    console.warn(`translation ${group}.${key} is not defined`);
    return `${group}.${key}`;
  }

  return compile(translation, params, pluralCount);
}

function toTokens(format: string): Token[] {
  const tokens: Token[] = [];
  let position = 0;

  let text = '';
  while (position < format.length) {
    let char: string = format[position++];
    if (char === '{') {
      if (text) {
        tokens.push({ type: 'text', value: text });
      }

      text = '';
      let sub = '';
      char = format[position++];
      while (char !== '}') {
        sub += char;
        char = format[position++];
      }

      tokens.push({ value: sub, type: 'named' });
    } else {
      text += char;
    }
  }

  if (text) {
    tokens.push({ type: 'text', value: text });
  }

  return tokens;
}

function _findInPluralPattern(translation: Translation, pluralCount?: number | null): string {
  if (pluralCount == null) {
    return '';
  }

  const ruleIndex = getPluralRuleIndex(translation);
  const formIndex = findFormIndex(ruleIndex, pluralCount);
  const patterns = translation.value.split('|');
  return patterns[formIndex];
}

function compile(
  translation: Translation,
  params: Record<string, unknown>,
  pluralCount?: number | null
): string {
  const pattern = translation.isPlural
    ? _findInPluralPattern(translation, pluralCount)
    : translation.value;

  return parse(pattern)
    .map((token: Token) => {
      if (token.type === 'text') {
        return token.value;
      }
      const prop = token.value;
      const value = params[prop];
      return value == null ? '' : `${value}`;
    })
    .join('');
}
