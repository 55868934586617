import { createAuthenticationInterseptors } from '@ravnur/auth/helpers/axios-interceptors';
import axios from 'axios';
import { stringify } from 'qs';

import { instance as typedStore } from '@/store';
import toBackendFormat from '@/transformers/http/toBackendFormat';
import toFrontendFormat from '@/transformers/http/toFrontendFormat';

const UNAUTH_CODE = 401;

const authInterceptors = createAuthenticationInterseptors();
axios.interceptors.request.use(authInterceptors.request);
axios.interceptors.response.use(authInterceptors.response);

axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => stringify(params, { arrayFormat: 'repeat' });
  config.params = toBackendFormat(config.params);
  config.data = toBackendFormat(config.data);
  config.withCredentials = true;
  return config;
});

axios.interceptors.response.use(
  (resp) => {
    resp.data = toFrontendFormat(resp.data);
    return resp;
  },
  (error) => {
    if (error.response?.status === UNAUTH_CODE && typedStore.user.isAuth) {
      if (!typedStore.isLogouting) {
        location.reload();
      }
    }
    return Promise.reject(toFrontendFormat(error));
  }
);
