import { defineComponent, PropType } from 'vue';

import $t from './$t';

export type Props<T extends string = string> = {
  tag?: string;
  group: string;
  tkey: T;
  params?: Record<string, unknown>;
  pluralization?: number | null;
};

export default defineComponent({
  props: {
    tag: { type: String, default: 'span' },
    group: { type: String, required: true },
    tkey: { type: String, required: true },
    params: { type: Object as PropType<Dictionary<string | number>>, default: () => ({}) },
    pluralization: { type: Number },
  },
  setup(props) {
    const Tag = props.tag;
    return () => <Tag>{$t(props.group, props.tkey, props.params, props.pluralization)}</Tag>;
  },
});
