import './icon/icon-storage';

import L10n from '@ravnur/l10n/l10n';

import { App } from 'vue';

import ResizeObserver from '../components/resize-observer/resize-observer';

import Alert from './alert/alert';
import Avatar from './avatar/avatar';
import Badge from './badge/badge';
import Button from './button/button';
import Checkbox from './checkbox/checkbox';
import Chosen from './chosen/chosen';
import DateHolder from './date-holder/date-holder';
import Datepicker from './datepicker/datepicker';
import ErrorMessages from './error-messages/error-messages';
import Icon from './icon/icon';
import Modal from './modal/modal.vue';
import MoreActions from './more-actions/more-actions';
import MultilineField from './multiline-field/multiline-field';
import Overlay from './overlay/overlay';
import Paginator from './paginator/paginator';
import Popup from './popup/popup';
import Progressbar from './progressbar/progressbar';
import RFieldset from './r-fieldset/r-fieldset';
import RadioButton from './radiobutton/radiobutton';
import Scrollable from './scrollable/scrollable.vue';
import Slider from './slider/slider';
import Spinner from './spinner/spinner';
import Tabs from './tabs/tabs';
import TextField from './text-field/text-field';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default function component(app: App) {
  app.component('alert', Alert);
  app.component('avatar', Avatar);
  app.component('badge', Badge);
  app.component('checkbox', Checkbox);
  app.component('chosen', Chosen);
  app.component('date-holder', DateHolder);
  app.component('datepicker', Datepicker);
  app.component('error-messages', ErrorMessages);
  app.component('icon', Icon);
  app.component('intersection-observer', IntersectionObserver);
  app.component('l10n', L10n as any);
  app.component('more-actions', MoreActions);
  app.component('overlay', Overlay);
  app.component('paginator', Paginator);
  app.component('popup', Popup);
  app.component('progressbar', Progressbar);
  app.component('r-button', Button);
  app.component('r-fieldset', RFieldset);
  app.component('radiobutton', RadioButton);
  app.component('resize-observer', ResizeObserver);
  app.component('scrollable', Scrollable as any); // eslint-disable-line
  app.component('slider', Slider);
  app.component('spinner', Spinner);
  app.component('tabs', Tabs);
  app.component('text-field', TextField);
  app.component('modal', Modal as any); // eslint-disable-line
  app.component('multiline-field', MultilineField);
  app.component('QuillEditor', QuillEditor);
}
