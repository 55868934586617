import { ProcessingContent } from '@ravnur/processing';
import { ComputedRef, defineComponent, inject } from 'vue';

import ApplicationFooter from '../components/application-footer/application-footer';
import ApplicationHeader from '../components/application-header/application-header';
import Notifications from '../components/notifications/notifications';

import LayoutSizeProvider from './layout-size-provider';
import PopupManager from './popup-manager';

export default defineComponent({
  setup(props, ctx) {
    const sidebarOpen = inject<ComputedRef<boolean>>('sidebar-open');

    return () => (
      <LayoutSizeProvider>
        <div class="app">
          <ApplicationHeader v-slots={{ default: ctx.slots.header }} />
          <main class={['app__content', sidebarOpen?.value ? 'app__content--slide' : '']}>
            <router-view />
          </main>
          <ApplicationFooter />
          <ProcessingContent />
          <Notifications />
          <PopupManager />
          {ctx.slots.matomo?.()}
        </div>
      </LayoutSizeProvider>
    );
  },

  beforeCreate() {
    if (window.location.pathname !== '/') {
      window.location.href = window.location.origin;
    }
  },
});
