import { keyById } from '@ravnur/nanoutils/keyBy';
import { State, Mutation, Action, Getter } from 'vuex-simple';

import { IThesaurusRepository } from '@/repositories/thesaurus-repository';

export class ThesaurusModule<E extends Entity> {
  @State()
  public loading = false;

  @State()
  private items: E[] = [];

  @State()
  private dirty = false;

  constructor(public repository: IThesaurusRepository<E>) {}

  @Getter()
  public get list(): E[] {
    return this.items;
  }

  @Getter()
  public get cache(): Dictionary<E> {
    return keyById(this.items);
  }

  @Action()
  public async load() {
    const { loading, dirty } = this;
    if (loading || dirty) {
      return;
    }
    this.startLoading();

    try {
      const { items } = await this.repository.fetchAll({ count: 1000, offset: 0 });
      this.set(items);
    } catch (e) {
      return Promise.reject(e);
    }

    this.stopLoading();
  }

  @Action()
  public async save(entity: Partial<E>): Promise<E> {
    try {
      const { id } = await this.repository.save(entity);
      if (!id) {
        return entity as E;
      }

      const saved = await this.repository.get(id);
      this.change(saved);
      return saved;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async get(id: string) {
    return this.repository.get(id);
  }

  @Action()
  public async remove(entity: Entity) {
    try {
      await this.repository.remove(entity.id);
      this.mRemoveMany([entity]);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @Mutation()
  public logout() {
    this.items = [];
    this.dirty = false;
  }

  @Mutation()
  private change(entity: E) {
    const { items, dirty } = this;
    if (!dirty) {
      return;
    }
    const cache = keyById(items);
    cache[entity.id] = entity;
    this.items = Object.keys(cache).map((id) => cache[id]) as E[];
  }

  @Mutation()
  private set(items: E[]) {
    this.items = items;
  }

  @Mutation()
  private mRemoveMany(items: Entity[]) {
    const cache = keyById(items);
    this.items = this.items.filter((e) => !cache[e.id]);
  }

  @Mutation()
  private startLoading() {
    this.loading = true;
  }

  @Mutation()
  private stopLoading() {
    this.loading = false;
    this.dirty = true;
  }
}
