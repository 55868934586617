import isMobile from '@ravnur/helpers/featured.detect/isMobile';
import { Vue, Options } from 'vue-class-component';

import { PAGINATOR_SIZE, MOBILE_PAGINATOR_SIZE } from '../../config';
import { $Props } from '../../typings/tsx';
import './paginator.scss';

class Props {
  value!: number;
  count!: number;
}

type Emits = {
  onInput: (value: number) => void;
};

export type Paginator$Props = $Props<Props, Emits, 'value'>;

const CN = 'paginator';

@Options({
  name: 'paginator',
  emits: ['input', 'update:value'],
})
export default class Paginator extends Vue.with(Props) {
  private get pages(): number[][] {
    return _pages(this.value, this.count);
  }

  private get isFirstPage(): boolean {
    return !this.value;
  }

  private get isLastPage(): boolean {
    return this.value === this.count - 1;
  }

  render() {
    return (
      <div class={CN}>
        <button
          class={[`${CN}__move-btn`, `${CN}__move-btn--prev`]}
          disabled={this.isFirstPage}
          title="Previous page"
          type="button"
          onClick={this.prev}
        >
          <icon type="arrow-back" />
        </button>

        <div class={`${CN}__desc`}>
          <span class={`${CN}__desc-page`}>{this.value + 1}</span>
          <l10n class={`${CN}__desc-text`} group="common" tkey="paginator__mobile_desc" />
          <span class={`${CN}__desc-page`}>{this.count}</span>
        </div>

        <ul class={`${CN}__pages-wrapper`}>{this.pages.map(this.renderBlock)}</ul>

        <button
          class={[`${CN}__move-btn`, `${CN}__move-btn--next`]}
          disabled={this.isLastPage}
          title="Next page"
          type="button"
          onClick={this.next}
        >
          <icon type="arrow-forward" />
        </button>
      </div>
    );
  }

  private renderBlock(arr: number[], j: number) {
    const { value } = this;
    return (
      <li key={j} class={`${CN}__pages-block`}>
        <ul class={`${CN}__pages`}>
          {arr.map((page) => {
            const cn = {
              [`${CN}__page-btn`]: true,
              [`${CN}__page-btn--current`]: page === value,
            };
            return (
              <li key={page} class={`${CN}__page`}>
                <button class={cn} type="button" onClick={() => this.goto(page)}>
                  {page + 1}
                </button>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }

  private goto(page: number) {
    this.$emit('input', page);
    this.$emit('update:value', page);
  }

  private prev() {
    if (this.isFirstPage) {
      return;
    }

    this.goto(this.value - 1);
  }

  private next() {
    if (this.isLastPage) {
      return;
    }

    this.goto(this.value + 1);
  }
}

function _pages(current: number, total: number): number[][] {
  let pagination_size = PAGINATOR_SIZE;

  if (isMobile) {
    pagination_size = MOBILE_PAGINATOR_SIZE;
  }

  if (total <= pagination_size + 2) {
    return [_times(total)];
  }

  const half = Math.floor(pagination_size / 2);
  const last = total - 1;

  let from = Math.max(0, current - half);
  from = Math.min(from, last - pagination_size);

  const main = _times(from, from + pagination_size);

  const res = [main];

  if (!main.some(eq(1))) {
    res.unshift([0]);
  } else if (!main.some(eq(0))) {
    main.unshift(0);
  }

  if (!main.some(eq(last - 1))) {
    res.push([last]);
  } else if (!main.some(eq(last))) {
    main.push(last);
  }

  return res;
}

function _times(from: number, to?: number): number[] {
  if (!to) {
    to = from;
    from = 0;
  }

  const res = [];
  for (let i = from; i < to; i++) {
    res.push(i);
  }
  return res;
}

function eq(a: number) {
  return function i(b: number) {
    return a === b;
  };
}
