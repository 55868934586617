export type CurriedFunction1<A, R> = (a: A) => R;

export interface CurriedFunction2<A, B, R> {
  (a: A): CurriedFunction1<B, R>;
  (a: A, b: B): R;
}

export default function curry2<T1, T2, R>(fnc: (t1: T1, t2: T2) => R): CurriedFunction2<T1, T2, R> {
  function curriedFunction(t1: T1): (t2: T2) => R;
  function curriedFunction(t1: T1, t2: T2): R;
  function curriedFunction(t1: T1, t2?: T2): unknown {
    return arguments.length >= 2 && typeof t2 !== 'undefined'
      ? fnc(t1, t2)
      : function _c(t: T2) {
          return fnc(t1, t);
        };
  }

  return curriedFunction;
}
