import { ErrorObject } from '@vuelidate/core';
import { withModifiers } from 'vue';
import { Vue, Options } from 'vue-class-component';

import BaseChosen, { OpenerSlotProps } from '../../components/base-chosen/base-chosen';
import OptionSnippet from '../../components/option-snippet/option-snippet';
import ValidationErrors from '../../components/validation-errors/validation-errors';

import { CommonProps, WithModel } from '../../typings/tsx';

import './chosen.scss';

const CN = 'chosen';

type Props<T extends Entity, V extends string> = Pick<Chosen<T, V>, 'value' | 'options'> &
  Partial<
    Pick<
      Chosen<T, V>,
      | 'label'
      | 'placeholder'
      | 'size'
      | 'disabled'
      | 'keyForLabel'
      | 'keyForValue'
      | 'errors'
      | 'openerPosition'
      | 'isSelectable'
    >
  > &
  CommonProps;

type Emits<V> = {
  onInput?: (val: V) => void;
};

export type Chosen$Props<T extends Entity, V extends string> = WithModel<Props<T, V>, 'value'> &
  Emits<V>;

@Options({
  name: 'chosen',
  emits: ['input', 'update:value'],
  props: {
    value: { required: true },
    label: { default: '' },
    placeholder: { default: '' },
    options: { default: [] },
    size: { default: 'md' },
    disabled: { default: false },
    keyForValue: { default: 'id' },
    keyForLabel: { default: 'label' },
    openerPosition: { default: 'bottom' },
    isSelectable: { default: () => () => true },
    errors: { default: [] },
    requiredAsterisk: { default: false },
  },
})
export default class Chosen<T, V extends string> extends Vue {
  public readonly value!: V;
  public readonly label!: string;
  public readonly placeholder!: string;
  public readonly options!: T[];
  public readonly size!: Component$Size;
  public readonly disabled!: boolean;
  public readonly keyForValue!: T extends Record<string, unknown>
    ? Nullable<keyof SubType<T, V>>
    : never;
  public readonly keyForLabel!: T extends Record<string, unknown> ? keyof T : never;
  public readonly errors: ErrorObject[];
  public readonly openerPosition!: Component$OpenerPosition;
  public readonly isSelectable!: Predicate<T>;
  public readonly requiredAsterisk?: boolean;

  private elementId: string = Math.random().toString(36).substring(2);

  private get selected() {
    return this.options.find(this.isCurrent) || null;
  }

  private get chosenSlots() {
    return {
      button: this.renderButton,
      option: this.$slots.option,
      header: this.$slots.header,
    };
  }

  private get classes() {
    return {
      [CN]: true,
      [`${CN}--sm`]: this.size === 'sm',
      [`${CN}--top`]: this.openerPosition === 'top',
      [`${CN}--disabled`]: this.disabled,
      [`${CN}--error`]: !!this.errors.length,
      [`${CN}--low`]: !this.label,
    };
  }

  private isCurrent(option: T): boolean {
    if (!this.keyForValue) {
      return this.value === this.detectItemId(option);
    }
    return this.value === this.detectItemId(option);
  }

  private select(option: T) {
    const id = this.detectItemId(option);
    this.$emit('input', id);
    this.$emit('update:value', id);
  }

  render() {
    return (
      <div class={this.classes}>
        <label class={`${CN}__label`} for={this.elementId}>
          {this.label}
          <b class={`${CN}__label--asterisk`}>{this.requiredAsterisk && '*'}</b>
        </label>
        <BaseChosen
          closeOnSelect={true}
          current={this.selected}
          disabled={this.disabled}
          isSelectable={this.isSelectable}
          keyForLabel={this.keyForLabel}
          openerPosition={this.openerPosition}
          options={this.options}
          v-slots={this.chosenSlots}
          onInput={this.select}
        />
        <ValidationErrors errors={this.errors} />
      </div>
    );
  }

  private renderButton({ toggle }: OpenerSlotProps) {
    return (
      <button
        class={`${CN}__toggler`}
        id={this.elementId}
        type="button"
        onClick={withModifiers(toggle, ['prevent'])}
      >
        {this.renderButtonText()}
        <icon class={`${CN}__icon`} size="md" type="arrow-down" />
      </button>
    );
  }

  private renderButtonText() {
    if (this.selected) {
      return (
        <OptionSnippet
          class={`${CN}__value`}
          item={this.selected}
          propName={this.keyForLabel}
          tag="span"
          v-slots={{ default: this.$slots.option }}
        />
      );
    }

    const text = this.placeholder || <>&nbsp;</>;
    return <span class={`${CN}__placeholder`}>{text}</span>;
  }

  private detectItemId(option: T): V {
    const { keyForValue } = this;
    return typeof keyForValue === 'string' ? option[keyForValue] : (option as any);
  }
}
