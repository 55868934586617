import $t from '@ravnur/l10n/$t';
import * as l10n from '@ravnur/l10n/service';
import { Vue, Options } from 'vue-class-component';

import { FORM_CTX_PROVIDER_KEY } from '../../constants';
import { AS_LIST, VALIDATIONS, Translations } from '../../translations';

import { FormContext } from '../../types/FormContext';

import '../../icons';
import './base-login-page.scss';

class Props {
  logo!: Nullable<string>;
  title!: keyof Translations;
}

const CN = 'base-login-page';

@Options({
  provide(this: BaseLoginPage) {
    return {
      [FORM_CTX_PROVIDER_KEY]: this.context,
    };
  },
})
export default class BaseLoginPage extends Vue.with(Props) {
  protected context: FormContext = {
    disabled: false,
    submitting: false,
    submit: () => void 0,
  };

  beforeCreate() {
    l10n.addDefaultTranslations(AS_LIST);
    l10n.addDefaultTranslations(VALIDATIONS);
  }

  render() {
    return (
      <div class={CN}>
        <div class={`${CN}__form`}>
          {this.logo ? <img alt="Logo" class={`${CN}__logo`} src={this.logo} /> : null}
          <h1 class={`${CN}__title`}>{$t('login', this.title)}</h1>
          {this.$slots.default?.()}
        </div>
      </div>
    );
  }
}
