import { Teleport } from 'vue';
import { Vue, Options, prop } from 'vue-class-component';

import BodyScrollLocker from '../../components/body-scroll-locker/body-scroll-locker';
import OutsideClickListener from '../../components/outside-click-listener/outside-click-listener';
import { $Props } from '../../typings/tsx';
import './popup.scss';

const CN = 'popup';

// eslint-disable-next-line
const T: any = Teleport;

class Props {
  header = prop({ default: '' });
  showFooter = prop({ default: true });
  canConfirm = prop({ default: true });
  processing = prop({ default: false });

  confirmLabel = prop({ default: 'Confirm' });
  closeLabel = prop({ default: 'Close' });
  closeOnClickOutside = prop({ default: true });
}

type Emits = {
  onClose: () => void;
  onConfirm: () => void;
};

export type Popup$Props = $Props<Props, Emits>;

@Options({
  inheritAttrs: false,
  name: 'popup',
  emits: ['close', 'confirm'],
})
export default class Popup extends Vue.with(Props) {
  private isMounted = false;

  mounted() {
    this.isMounted = true;
  }

  render() {
    return (
      <T to="#modals">
        <overlay data-testid="overlay" enable={this.isMounted} />
        <BodyScrollLocker />
        <OutsideClickListener onClick={this.handleClickOutside}>
          <div class={CN} data-testid="popup" {...this.$attrs}>
            {this.renderHeader()}
            <div class={`${CN}__body`}>{this.$slots.default?.()}</div>
            {this.showFooter ? this.renderFooter() : null}
          </div>
        </OutsideClickListener>
      </T>
    );
  }

  private renderHeader() {
    if (!this.$slots.header && !this.header) {
      return;
    }

    return (
      <header class={`${CN}__header`}>
        <span class={`${CN}__title`}>{this.$slots.header?.() || this.header}</span>
        <r-button
          class={`${CN}__close-btn`}
          color="black"
          icon="close"
          mode="borderless"
          onclick={this.close}
          title="Close"
        />
      </header>
    );
  }

  private renderFooter() {
    return (
      <footer class={[`${CN}__footer`, !this.closeLabel ? `${CN}__footer--centered` : '']}>
        <r-button
          class={`${CN}__btn`}
          color="black"
          mode="borderless"
          onclick={this.close}
          v-show={this.closeLabel}
        >
          {this.closeLabel}
        </r-button>
        <r-button
          class={[`${CN}__btn`, `${CN}__btn--confirm`]}
          color="accent"
          disabled={!this.canConfirm}
          loading={this.processing}
          onclick={this.confirm}
        >
          {this.confirmLabel}
        </r-button>
      </footer>
    );
  }

  private handleClickOutside() {
    if (!this.closeOnClickOutside) {
      return;
    }

    this.close();
  }

  private close() {
    this.$emit('close');
  }

  private confirm() {
    this.$emit('confirm');
  }
}
