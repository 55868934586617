import MediaCard from '@ravnur/shared/components/media-card/media-card';
import { prop, setup, Vue } from 'vue-class-component';

import { FeaturedCarousel, Media } from '@/types/Media';
import usePortalSettings from '@/helpers/hooks/usePortalSettings';
import uidGenerator from '@/helpers/uid.generator';

import './featured-carousel-item.scss';

const CN = 'featured-carousel-item';

const MEDIA_CARD_LIMIT = 8;

class Props {
  count = prop<number>({ default: MEDIA_CARD_LIMIT });
  hasControls = prop<boolean>({ default: false });
  carousel = prop<FeaturedCarousel>({ required: true });
  mediaItems = prop<Media[]>({ default: [] });
}

export class FeaturedCarouselItem extends Vue.with(Props) {
  declare $refs: {
    [key: string]: HTMLDivElement;
  };

  private portalSettings = setup(() => usePortalSettings());

  public visibleControls = false;
  public scrollLeft = 0;
  public clientWith = 0;
  public scrollWith = 0;

  get refId() {
    return uidGenerator();
  }

  get hasItems() {
    return this.mediaItems.length > 0;
  }

  get canScrollRight() {
    return this.scrollLeft < this.scrollWith - this.clientWith;
  }

  get canScrollLeft() {
    return this.scrollLeft > 0;
  }

  render() {
    return this.hasItems ? (
      <div
        class={CN}
        onMouseenter={() => (this.visibleControls = true)}
        onMouseleave={() => (this.visibleControls = false)}
      >
        <div class={`${CN}__header`}>
          <div class={`${CN}__header-title`} data-testid="featured-carousel-title">
            {this.carousel.displayName}
          </div>
        </div>
        <div ref={this.refId} class={`${CN}__items`} onScroll={this.handleScroll}>
          {this.mediaItems.map((media) => (
            <div key={media.id} class={`${CN}__item`} data-testid="featured-carousel-item">
              <MediaCard
                showType
                goToMedia={() => this.goToMedia(media)}
                goToUser={
                  this.portalSettings?.isDisplayUserChannels
                    ? () => this.goToUser(media.owner.id)
                    : undefined
                }
                media={media}
                showCCBadge={true}
                showOverlay={true}
              />
            </div>
          ))}
        </div>
        <div
          class={[`${CN}__scroll-right`, this.visibleControls ? 'visible' : '']}
          v-show={this.canScrollRight && this.hasControls}
          onClick={this.toRight}
        >
          <icon type="arrow-right" />
        </div>
        <div
          class={[`${CN}__scroll-left`, this.visibleControls ? 'visible' : '']}
          v-show={this.canScrollLeft && this.hasControls}
          onClick={this.toLeft}
        >
          <icon type="arrow-left" />
        </div>
      </div>
    ) : (
      <div />
    );
  }

  public goToMedia(media: Media) {
    this.$router.push({ name: 'Media', params: { id: media.id } });
  }

  public goToUser(id: string) {
    this.$router.push({ name: 'User', params: { id } });
  }

  public toRight() {
    this.$refs[this.refId].scrollLeft += 400;
  }

  public toLeft() {
    this.$refs[this.refId].scrollLeft -= 400;
  }

  handleScroll(event: UIEvent) {
    this.scrollLeft = (event.target as Element).scrollLeft;
  }

  mounted() {
    this.clientWith = this.$refs[this.refId]?.clientWidth;
    this.scrollWith = this.$refs[this.refId]?.scrollWidth;

    if (this.$refs[this.refId]) {
      this.$refs[this.refId].scrollLeft = 0;
    }
  }
}
