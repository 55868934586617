import { Prop } from '@ravnur/decorators';
import { Vue } from 'vue-class-component';
import useEllipsis from '@/helpers/hooks/useEllipsis';
import sanitize from '@ravnur/helpers/dom/sanitizer';

import './ellipsis.scss';

const DEFAULT_SIZE = 50;
const CN = 'ellipsis';

export default class Ellipsis extends Vue {
  @Prop({ type: String, required: true }) value: string;
  @Prop({ type: Number, default: DEFAULT_SIZE }) size: number;
  @Prop({ type: Boolean, default: false }) asHtml: boolean;

  get localElipsis() {
    return useEllipsis(this.value, this.size);
  }

  render() {
    const { value, toggle, isCollapsed, isCollapsable } = this.localElipsis;

    return (
      <p class={CN}>
        {this.asHtml ? (
          <div v-html={sanitize(value.value)} />
        ) : (
          <span class={`${CN}__text`}>{value.value}</span>
        )}
        {isCollapsable.value && (
          <r-button class={`${CN}__more-btn`} color="primary" mode="frameless" onclick={toggle}>
            <l10n group="common" tkey={isCollapsed.value ? 'ellipsis__more' : 'ellipsis__less'} />
          </r-button>
        )}
      </p>
    );
  }
}
